export default class intersectionObserver{
  constructor() {
    if( !window.IntersectionObserver ) return false
    this.instans = new IntersectionObserver((entries) => this.callback(entries))
    this.callbackList = {}
  }
  observe(dom, uuid, callback) {
    if( !dom ) return
    this.instans.observe(dom)
    this.callbackList[uuid] = callback
  }
  callback(entries) {
    // console.log(entries, 'entries');
    let that = this
    entries?.forEach((item)=> {
      if (item.intersectionRatio > 0) {
        let id = item.target.dataset.id?.toString()
        let callback = that.callbackList[id]
        callback && callback()
      }
    })
  }
  unobserve(dom) {
    this.instans.unobserve(dom)
  }
}
