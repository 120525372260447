<template>
    <div>
        <div v-if="curRoutePath === '/car' || carTextNum < 1" class="cart-btn" @click.stop="checkChange">
            <!-- 
                购物车按钮
                购物车角标数量显示，超过99 显示 99+
            -->
            <span class="cart-num" v-if="carTextNum > 0"
                :class="carTextNum < 10 ? 'indivdual' : carTextNum < 100 ? 'ten' : 'hundred'">
                <span class="num">{{ carTextNum > 99 ? '99+' : carTextNum }}</span>
            </span>
            <img class="cart-icon" width="16px" height="16px" src="@/assets/images/common/cart.png">
            <span class="cart-text">我的购物车</span>
            <!-- <img class="icon-direction" width="6px" height="10px" src="@/assets/images/common/icon-direction.png"> -->
        </div>
        <Poptip v-else placement="bottom" trigger="hover" @on-popper-show="onCart()">
            <div class="cart-btn" @click.stop="checkChange">
                <!-- 
                    购物车按钮
                    购物车角标数量显示，超过99 显示 99+
                -->
                <span class="cart-num" v-if="carTextNum > 0"
                    :class="carTextNum < 10 ? 'indivdual' : carTextNum < 100 ? 'ten' : 'hundred'">
                    <span class="num">{{ carTextNum > 99 ? '99+' : carTextNum }}</span>
                </span>
                <img class="cart-icon" width="16px" height="16px" src="@/assets/images/common/cart.png">
                <span class="cart-text">我的购物车</span>
                <!-- <img class="icon-direction" width="6px" height="10px" src="@/assets/images/common/icon-direction.png"> -->
            </div>
            <template #content>
                <div class="search-cart-box">
                    <div class="search-cart-list" v-for="(sItem, sIndex) in storeList" :key="sIndex">
                        <div class="search-cart-activity" v-for="(aItem, aIndex) in sItem.activityList" :key="aIndex">
                            <div class="search-cart-item" v-for="(gItem, gIndex) in aItem.productList" :key="gIndex">
                                <div class="search-cart-img" @click.stop="navigateToGood(gItem, aItem)">
                                    <img :src="gItem.productLogo ? getFilePath('PS_01', gItem.productLogo) : defaultImg"
                                        alt="">
                                </div>
                                <div class="search-cart-con" @click.stop="navigateToGood(gItem, aItem)">
                                    <div class="search-goods-name">{{ gItem.productName }}</div>
                                    <div class="search-goods-date">{{ gItem.manufacture }}</div>
                                    <div class="search-goods-date">有效期至：{{ gItem.expireDate ?
                                        gItem.expireDate.substring(0,
                                            11)
                                        :
                                        "-" }}</div>
                                    <div class="search-goods-price">
                                        ¥{{ returnFloat(gItem.actualPrice, gItem.price) }}
                                        <span>x{{ gItem.num }}</span>
                                    </div>
                                </div>
                                <div class="searct-cart-del" @click.stop="deleteSkuItemFn(gItem, gIndex, aIndex, sIndex, aItem)"></div>
                            </div>
                        </div>
                    </div>

                    <div class="search-cart-list">
                        <div class="search-cart-item lose" v-for="(gItem, gIndex) in invalidProductList" :key="gIndex">
                            <div class="search-cart-img" @click.stop="navigateToGood(gItem, aItem)">
                                <img :src="gItem.productLogo ? getFilePath('PS_01', gItem.productLogo) : defaultImg"
                                    alt="">
                            </div>
                            <div class="search-cart-con" @click.stop="navigateToGood(gItem, aItem)">
                                <div class="search-goods-name">{{ gItem.productName }}</div>
                                <div class="search-goods-date">{{ gItem.manufacture }}</div>
                                <span class="lose-state">{{ getReason(gItem) }}</span>
                            </div>
                            <div class="searct-cart-del" @click.stop="deleteSkuItemFn(gItem, gIndex)">
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;height:66px"></div>
                    <div class="search-cart-bot">
                        <div class="search-cart-num">
                            商品种类：<span>{{ carTextNum || 0 }}</span>
                        </div>
                        <div class="search-cart-btn" @click.stop="checkChange">去购物车</div>
                    </div>
                    <Spin size="large" fix v-if="isLoading">
                        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                        <div>加载中...</div>
                    </Spin>
                </div>
            </template>
        </Poptip>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Message } from 'view-design'
import { getCartList, deleteGoodsBatch } from '@/common/service'
import { ACTIVITY_TYPE, getFilePath, getLimitInfo, GOOD_CODE, returnFloat } from '@/common/utils'
import Loading from '@/components/Loading.vue'
import asyncQueryCartMixin from '@/common/mixins/asyncQueryCartMixin'
export default {
    name: 'CartBtn',
    components: {
        Loading
    },
    mixins: [asyncQueryCartMixin],
    data() {
        return {
            cartData: {},
            getFilePath,
            returnFloat,
            defaultImg: require('@/assets/images/common/good_default.png'),
            isLoading: false,
            invalidProductList: [], // 失效商品
            storeList: [],
            cartData: {},
            curRoutePath: this.$route.path,
        }
    },
    computed: {
        ...mapGetters({
            carTextNum: 'car/getCarTextNum',
            disassociationState: 'disassociationState'
        }),
    },
    created() {
        this.$store.dispatch('car/updateCartNum')
    },
    methods: {
        onCart() {
            if (this.isLoading === true || this.curRoutePath === "/car") {
                return;
            }
            const selectedProductList = []; const presentProductList = []; const cancelProductList = []
            if (this.selectedProductList?.length > 0) {
                for (const sItem of this.selectedProductList) {
                    for (const aItem of sItem?.activityList) {
                        for (const gItem of aItem?.productList) {
                            const flag = (aItem.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType === ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present === true
                            if (gItem.selected) {
                                if (!flag) {
                                    selectedProductList.push({
                                        id: gItem.id,
                                        productId: gItem.productId,
                                        activityId: aItem.activityId,
                                        activityType: aItem.activityType,
                                        orgId: gItem.orgId,
                                        num: gItem.num,
                                        price: gItem.price,
                                        actualPrice: gItem.actualPrice
                                    })
                                }
                            } else {
                                if (!flag) {
                                    cancelProductList.push({
                                        id: gItem.id,
                                        productId: gItem.productId,
                                        activityId: aItem.activityId,
                                        activityType: aItem.activityType,
                                        orgId: gItem.orgId,
                                        num: gItem.num,
                                        price: gItem.price,
                                        actualPrice: gItem.actualPrice
                                    })
                                }
                            }
                            // 31自动换购 32手动换购
                            if (flag) {
                                presentProductList.push({
                                    productId: gItem.productId,
                                    activityId: aItem.activityId,
                                    activityType: aItem.activityType,
                                    orgId: gItem.orgId,
                                    selected: gItem.selected,
                                    num: gItem.num,
                                    actualPrice: gItem.actualPrice,
                                    price: gItem.price
                                })
                            }
                        }
                    }
                }
            }
            this.isLoading = true
            getCartList({
                presentProductList,
                cancelProductList,
                selectedProductList
            }).then(res => {
                this.isLoading = false
                if (res?.code === 200) {
                    this.invalidProductList = []
                    this.storeList = []
                    this.cartData = res.data
                    if (res.data?.invalidProductList && res.data.invalidProductList.length > 0) {
                        this.invalidProductList = res.data.invalidProductList
                    }
                    if (res.data?.storeList && res.data.storeList.length > 0) {
                        this.storeList = res.data.storeList
                    }
                }
            }).catch(err => {
                this.isLoading = false
                // 如果当前登录用户必要信息缺失600 传入的customer不是当前用户的关联客户 提示602
                if (err?.data?.code === '600') {
                    Message.error('当前账号未关联企业，请先关联')
                    return
                }
                Message.error(err?.data?.message || '查询购物车异常')
            })
        },
        checkChange() {
            if (!this.disassociationState) {
                this.toDisassociation()
            } else {
                if (this.$route?.name === "carPage") {
                    this.$emit("queryCart")
                } else {
                    this.$router.push({
                        path: '/car'
                    })
                }
            }
        },
        // 去关联
        toDisassociation() {
            this.$Modal.confirm({
                title: '提示',
                content: '<p>请先关联采购主体（药店/医院/诊所/企业）。未关联前无法获取商品全部内容以及采购下单</p>',
                okText: '去关联',
                cancelText: '暂不',
                onOk: () => {
                    this.$router.push('/affiliated')
                },
                onCancel: () => {
                    // this.$message.info('Clicked cancel')
                }
            })
        },
        // 商品详情
        navigateToGood(item, sItem) {
            if (item.status === 'lose') {
                return
            }
            // 跳转商品信息
            const flag = (item.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && item?.expandMap?.present == true // 是否换购品
            const giftFlag = (sItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || sItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && item?.expandMap?.present === true // 是否赠品
            const isNeedActivityType = sItem.activityType == ACTIVITY_TYPE.SPECIAL || sItem.activityType == ACTIVITY_TYPE.NEARTERM
            const activityType = isNeedActivityType || flag ? sItem.activityType : 0
            const activityId = isNeedActivityType || flag ? sItem.activityId : 0
            if (flag) {
                if (this.$route.path != "/detail") {
                    this.$router.push({
                        path: `/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}&isReplace=true`
                    })
                } else {
                    this.$router.replace({
                        path: `/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}&isReplace=true`
                    })
                }
            } else if (giftFlag) {
                if (this.$route.path != "/detail") {
                    this.$router.push({ path: `/giftDetail?id=${item.productId}&activityType=${sItem.activityType}&activityId=${sItem.activityId}` })
                } else {
                    this.$router.replace({ path: `/giftDetail?id=${item.productId}&activityType=${sItem.activityType}&activityId=${sItem.activityId}` })
                }

            } else {
                if (this.$route.path != "/detail") {
                    this.$router.push({
                        path: `/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}`
                    })
                } else {
                    this.$router.replace({
                        path: `/detail?productId=${item.productId}&activityType=${activityType}&activityId=${activityId}`
                    })
                }
            }
        },
        // 删除商品
        deleteSkuItemFn(item, index, sIndex, shopIndex, sItem) {
            const selectedProductList = []; const presentProductList = []
            if (this.storeList.length > 0) {
                for (const sItem of this.storeList) {
                    for (const aItem of sItem?.activityList) {
                        for (const gItem of aItem?.productList) {
                            const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE ||
                                aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
                            if (gItem.checked && item.id != gItem.id) {
                                if (!flag) {
                                    selectedProductList.push({
                                        id: gItem.id,
                                        productId: gItem.productId,
                                        activityId: aItem.activityId,
                                        actualPrice: gItem.actualPrice,
                                        activityType: aItem.activityType
                                    })
                                }
                            }
                            // 31自动换购 32手动换购 71自动赠品 72手动赠品
                            if (flag) {
                                presentProductList.push({
                                    productId: gItem.productId,
                                    activityId: aItem.activityId,
                                    activityType: aItem.activityType,
                                    orgId: gItem.orgId,
                                    selected: gItem.checked,
                                    num: gItem.num,
                                    actualPrice: gItem.actualPrice,
                                    price: gItem.price
                                })
                            }
                        }
                    }
                }
            }
            this.isLoading = true
            deleteGoodsBatch({
                productList: [{ id: item.id }],
                presentProductList,
                selectedProductList
            }).then((res) => {
                this.isLoading = false
                if (res?.code === 200) {
                    Message.success('删除成功')
                    this.$store.dispatch('car/updateCartNum')
                    this.storeList = []
                    this.invalidProductList = []
                    this.cartData = res.data
                    if (res.data?.invalidProductList && res.data.invalidProductList.length > 0) {
                        this.invalidProductList = res.data.invalidProductList
                    }
                    if (res.data?.storeList && res.data.storeList.length > 0) {
                        this.storeList = res.data.storeList
                    }
                }
                setTimeout(() => {
                    if (res.data.storeList && res.data.storeList.length > 0) {
                        this.setSelectedProductList(JSON.parse(JSON.stringify(this.storeList)))
                    } else {
                        this.setSelectedProductList(JSON.parse(JSON.stringify([])))
                    }
                }, 500)
            }).catch((err) => {
                this.isLoading = false
                Message.error(err?.data?.message)
            })
        },
        getReason(item, sItem) {
            if (item.reason) {
                if (item.reason == '102') {
                    return `${item.startBuyNum}${item.productUnit}起购`
                } else if (item.reason == '103') {
                    return `商家设置加购倍数为${item.addBuyNum}`
                } else if (item.reason == '104' || item.reason == '105') {
                    return this.getTip(item, sItem)
                } else {
                    return GOOD_CODE[item.reason]
                }
            } else {
                return ''
            }
        },
        getTip(gItem, sItem) {
            return getLimitInfo(gItem)
        },
    }
}
</script>

<style lang="less" scoped>
.cart-btn {
    width: 126px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    padding: 0 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #EEE;

    .icon-direction,
    .cart-num {
        position: absolute;
    }

    .cart-num {
        width: 28px;
        height: 15px;
        line-height: 15px;
        top: -10px;
        right: 6px;
        text-align: center;
        background-size: 100% 100%;

        .num {
            font-size: 12px;
            color: #ffffff;
        }

        &.indivdual {
            width: 16px;
            height: 16px;
            background-image: url(../assets/images/common/car-num-indivdual.png);
        }

        &.ten {
            background-image: url(../assets/images/common/car-num-ten.png);
            width: 24px;
            height: 16px;
        }

        &.hundred {
            width: 32px;
            height: 16px;
            background-image: url(../assets/images/common/car-num-hundred.png);
        }
    }

    .cart-icon {
        width: 16px;
        height: 16px;
    }
    &:hover {
        border: 1px solid rgba(250, 179, 87, 1);
    }
    .cart-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 400;
        margin-left: 8px;

        &:hover {
            color: rgba(230, 125, 0, 1);
        }
    }

    .icon-direction {
        top: 15px;
        right: 23px;
    }
}

::v-deep .ivu-poptip-body {
    padding: 8px;
}

.search-cart-box {
    width: 363px;
    min-height: 260px;
    max-height: 350px;
    overflow-y: auto;

    .search-cart-list {
        width: 100%;

        .search-cart-item {
            width: 100%;
            height: 110px;
            display: flex;
            position: relative;
            cursor: pointer;
            padding: 12px;

            .search-cart-img {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;

                img {
                    width: 64px;
                    height: 64px;
                }
            }

            .search-cart-con {
                width: 208px;
            }

            .search-goods-name {
                width: 208px;
                height: 22px;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .search-goods-date {
                width: 208px;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.lose {
                .search-cart-img {
                    opacity: 0.5;
                }
                .search-goods-name,
                .search-goods-date {
                    color: rgba(0, 0, 0, 0.4);
                }
            }

            .search-goods-price {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #CB272D;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.9);

                }
            }

            .lose-state {
                font-size: 12px;
                line-height: 20px;
                color: #CB272D;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .searct-cart-del {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 15px;
                right: 12px;
                background: url('../assets/images/search/delete.png') no-repeat center;
                background-size: 100%;
                &:hover {
                    background: url('../assets/images/search/delete-hover.png') no-repeat center;
                    background-size: 100%;
                }
            }
        }
    }

    .search-cart-bot {
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 6px;
        left: 0;
        padding: 12px;
        box-shadow: 0px -16px 20px 0px rgba(0, 0, 0, 0.04);
        background-color: #ffffff;

        .search-cart-num {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;

            span {
                color: #ce7720;
                font-weight: 500;
            }
        }

        .search-cart-btn {
            width: 92px;
            height: 36px;
            border-radius: 4px;
            background-color: #F99D33;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>