const customerInfo = localStorage.getItem('customerInfo')
const orderInfo = localStorage.getItem('orderInfo')
const refundInfo = localStorage.getItem('refundInfo')
const state = {
  customerInfo: customerInfo ? JSON.parse(customerInfo) : {},
  orderInfo: orderInfo ? JSON.parse(orderInfo) : {},
  refundInfo: refundInfo ? JSON.parse(refundInfo) : {}
}
const getters = {
  getCustomerInfo(state) {
    return state.customerInfo
  },
  getOrderInfo(state) {
    return state.orderInfo
  },
  getRefundInfo(state) {
    return state.refundInfo
  }
}
const mutations = {
  SET_CUSTOMER_INFO(state, data) {
    localStorage.setItem('customerInfo', JSON.stringify(data))
    state.customerInfo = data
  },
  SET_ORDER_INFO(state, data) {
    localStorage.setItem('orderInfo', JSON.stringify(data))
    state.orderInfo = data
  },
  SET_REFUND_INFO(state, data) {
    localStorage.setItem('refundInfo', JSON.stringify(data))
    state.refundInfo = data
  }
}
const actions = {
  setCustomerInfo({ commit }, data) {
    commit('SET_CUSTOMER_INFO', data)
  },
  setOrderInfo({ commit }, data) {
    commit('SET_ORDER_INFO', data)
  },
  setRefundInfo({ commit }, data) {
    commit('SET_REFUND_INFO', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
