<template>
  <div class="user-tool-wrapper">
    <ContentLayout class="user-tool-wrapper__content">
      <div class="left">
        <img class="logo" :src="require('@/assets/logo.png')" alt="">
        <span class="slot-wrapper">
          <template v-if="['user_regist', 'privacy'].includes($route.name)">
            欢迎注册
          </template>
          <template v-else>
            <slot><LinkButton @click="jumpForgetPwd">忘记密码</LinkButton></slot>
          </template>
        </span>
      </div>
      <div class="right">
        已有账号，<LinkButton @click="jumpLogin">去登录</LinkButton>
      </div>
    </ContentLayout>
  </div>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'
import LinkButton from '@/components/common/LinkButton'
import UserJumpMixins from '@/common/mixins/user/user-jump-mixins'
export default {
  name: 'UserToolBar',
  components: {
    ContentLayout,
    LinkButton
  },
  mixins: [UserJumpMixins]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
//$grayTxt: rgba(0, 0, 0, 0.6);
.user-tool-wrapper {
  padding: 24px 0 16px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  &__content {
    display: flex;
    justify-content: space-between;
    height: 48px;
    .left {
      .logo {
        width: 195px;
        height: 48px;
        margin-right: 40px;
        margin-top: 5px;
      }
      .slot-wrapper {
        position: relative;
        top: -15px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 16px;
        font-weight: 500;
      }
    }
    .right {
      color: rgba(0, 0, 0, 0.60);
      line-height: 64px;
      font-size: 16px;
    }
    .to-login-class {
      color: #ff9900;
    }
  }
}
</style>
