<template>
  <div class="notice-detail">
    <div class="head">
      <div class="search-bar" :class="showBoxShadow && 'box-shadow'">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true" />
      </div>
      <div style="background: #fff;">
        <allProductClass />
      </div>
      <!-- <Tabs :list="tabList" ></Tabs> -->
      <div class="breadcrumb" v-if="type=='home'">
        <div class="breadcrumb-warp">
          <span class="text">您所在的位置</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem to="/">首页</BreadcrumbItem>
            <BreadcrumbItem to="/notice">商城公告</BreadcrumbItem>
            <BreadcrumbItem >{{ noticeObj.noticeTitle }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div class="breadcrumb" v-if="type=='shopHome'">
        <div class="breadcrumb-warp">
          <span class="text">您所在的位置</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem :to="toShopHome">商家首页</BreadcrumbItem>
            <BreadcrumbItem :to="toShopNotice">商家公告</BreadcrumbItem>
            <BreadcrumbItem >{{ noticeObj.noticeTitle }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
    </div>
    <div class="notice-detail-content">
      <p class="title">{{ noticeObj.noticeTitle }}</p>
      <p class="notict-content">富文本内容</p>
      <p class="notict-content" v-html="Xss(noticeObj.noticeContent)" />
      <p class="notict-content">
        <span>作者：{{ noticeObj.createBy }} </span>
        <span style="margin-left: 80px;">来源：{{ noticeObj.source }} </span>
      </p>
      <template v-for="(item, index) in volList">
        <p v-if="item?.fileName" :key="index" class="notict-content">附件： {{ item?.fileName }} <a :href="getFilePath('orig', item?.fileId)">下载</a></p>
      </template>
    </div>
  </div>
</template>
<script>
import {
  getNoticeDetail
} from '@/common/service/productDetail.js'
import {getOrgNoticeDetail} from '@/common/service/shopHome.js'
import { getPcTabData } from '@/common/service/home'
import { getFilePath } from '@/common/utils/base'
import SearchBar from '@/components/SearchBar.vue'
import Tabs from '@/components/Tabs'
import Xss from '@/utils/xss'
import allProductClass from '../../../home/coms/allProductClass.vue' // 全部商品分类

export default {
  name: '',
  components: { SearchBar, Tabs, allProductClass},
  data() {
    return {
      noticeObj: {},
      volList: [],
      loadProgress: 0, // 加载index
      // CustomBar: this.CustomBar,
      dltDownLvNew: '', // 已下载
      dltDownLvAll: '', // 总长度
      dltDownLvWc: '', // 完成率
      downloadUlr: '', // 下载地址
      suffix: '', // 文件后缀
      urlStr: '',
      isVidio: false,
      getFilePath,
      Xss,
      tabList: [
        {
            id:121,
            name: '全部商品分类',
            type: 'home'
        },
        {
            id:122,
            name: '首页'
        },
        {
            id:124,
            name: '全部商品'
        },
        {
            id:125,
            name: '专题页'
        },
        {
            id:126,
            name: '活动页'
        }
      ],
      type:'home',
      toShopHome:'',
      toShopNotice:''
    }
  },
  created() {
    let id= this.$route.query.id
    this.type = this.$route.query.type||'home'
    let orgId = this.$route.query.orgId
    this.toShopHome = '/shopHome?orgId='+orgId
    this.toShopNotice = '/notice?type=shopHome&orgId='+orgId
    this.getDetail(id)
    this.getPcTabData()
  },
  methods: {
    async getPcTabData() {
      const { data } = await getPcTabData()
      this.tabList = [...data.configContent.defaultList, ...data.configContent.list]
    },
    agreeCancel1() {
      this.isVidio = false
    },
    agreeConfirm1() {
      this.isVidio = false
    },
    async getDetail(id) {
      const func = this.type == 'home'? getNoticeDetail(id) : getOrgNoticeDetail(id)
      const { data } = await func
      this.volList = data.cmsAttachVoList.filter(item => item.fileType == 'accessory')
      this.noticeObj = data
    },
    fomatTime(val) {
      return val.slice(0, 10)
    },
    // 下载
    closeMark() {
      this.isVidio = false
    },
    downLoad6(item) {
      console.log(item, 'item')
      // uni.showLoading({
      //   title: 'Loading...',
      //   mask: true
      // })
      console.log(item.filePath, 390, item) // http://172.17.30.73:9006/spang-safety/file/temp/20220815/1660493727470567.mp4

      // var e = item.filePath
      const e = getFilePath('orig', item.fileId)
      const imgType = ['png', 'jpg', 'jpeg']
      const audioType = ['mp4', 'MP4']

      const _this = this
      // 下载地址
      this.downloadUlr = e
      // 获取地址后缀
      console.log(e)
      this.suffix = item.fileId.split('.')[item.fileId.split('.').length - 1]

      // 判断是否为(图片或视频)
      if (imgType.includes(this.suffix) || audioType.includes(this.suffix)) {
        console.log('00000')
        // uni.hideLoading()
        // this.urlStr = item.filePath
        this.urlStr = getFilePath('orig', item.fileId)
        this.isVidio = true
      } else {
        // 下载文件为非图片和视频文件
        const _this = this
        const downloadTaskt = uni.downloadFile({
          url: e, // 下载地址接口返回
          success: (data) => {
            // uni.hideLoading()
            if (data.statusCode === 200) {
              const sFilePath = data.tempFilePath
              const sFileName = _this.downloadUlr.split('/')[_this.downloadUlr.split('/').length - 1] // 原来的文件名
              console.log(sFileName, 476)
              // 文件保存到本地
              // uni.saveFile({
              //   tempFilePath: data.tempFilePath, // 临时路径
              //   success: function(res) {
              //     const savedFilePath = res.savedFilePath
              //     // let osname = plus.os.name;

              //     const port = uni.getSystemInfoSync().platform
              //     if (port == 'android') {
              //       uni.showToast({
              //         icon: 'none',
              //         mask: true,
              //         title: '保存成功',
              //         duration: 1000,
              //       })
              //       // _this.fSetFileName(res.savedFilePath, sFilePath);
              //       uni.openDocument({
              //         filePath: res.savedFilePath,
              //         showMenu: true,
              //         success: function(res) {
              //           // console.log('打开文档成功');
              //         }
              //       })
              //     }
              //     // ios手机直接打开文件，手动存储文件到手机，Android手机从根目录创建文件夹，保存文件并改名
              //     //       if (osname == 'Android') {
              //     //           uni.showToast({
              //     //           icon: 'none',
              //     //           mask: true,
              //     //           title: '保存成功',
              //     //           duration: 1000,
              //     //        });
              //     //         _this.fSetFileName(res.savedFilePath, sFilePath);
              //     //  }
              //     setTimeout(() => {
              //       if (item.fileId.indexOf('.docx') != -1) {
              //         uni.openDocument({
              //           fileType: 'docx',
              //           filePath: res.savedFilePath,
              //           showMenu: true,
              //           success: function(res) {
              //             // console.log('打开文档成功');
              //           }
              //         })
              //         return
              //       }
              //       // 打开文档查看
              //       uni.openDocument({
              //         filePath: res.savedFilePath,
              //         showMenu: true,
              //         success: function(res) {
              //           // console.log('打开文档成功');
              //         }
              //       })
              //     }, 1000)
              //   },
              //   fail: function(res) {
              //     uni.showToast({
              //       icon: 'none',
              //       mask: true,
              //       title: '下载失败'
              //     })
              //   }
              // })
            }
          }
        })
      }
    },
    fSetFileName(sFilePath, sFileName) {
      var sFileName = sFileName.split('/')[sFileName.split('/').length - 1] // 原来的文件名

      const aFileUrl = sFilePath.split('/').splice(0, sFilePath.split('/').length - 1) // saveFile API保存的本地地址

      const url = this.downloadUlr // 下载地址
      // 'url下载地址（和上面的一样）'
      const dtask = plus.downloader.createDownload(url, {
        filename: 'file://storage/emulated/0/AAA/' + sFileName // 在手机存储更目录创建一个叫AAA的文件夹，把文件存储进去，并更改会原名
      },
      (d, status) => {
        if (status == 200) {
          const fileSaveUrl = plus.io.convertLocalFileSystemURL(d.filename)
        } else {
          // 下载失败
          plus.downloader.clear() // 清除下载任务
        }
      })
      dtask.start()
    },
    // 给下载的文件重命名
    fSetFileName(sFilePath, sFileName) {
      var sFileName = sFileName.split('/')[sFileName.split('/').length - 1]// 原来的文件名

      const aFileUrl = sFilePath.split('/').splice(0, sFilePath.split('/').length - 1)// saveFile API保存的本地地址

      const url = this.downloadUlr// 下载地址
      // 'url下载地址（和上面的一样）'
      const dtask = plus.downloader.createDownload(url, {
        filename: 'file://storage/emulated/0/AAA/' + sFileName // 在手机存储更目录创建一个叫AAA的文件夹，把文件存储进去，并更改会原名
      },
      (d, status) => {
        if (status == 200) {
          const fileSaveUrl = plus.io.convertLocalFileSystemURL(d.filename)
        } else {
          // 下载失败
          plus.downloader.clear() // 清除下载任务
        }
      })
      dtask.start()
    }
  }
}
</script>
<style scoped lang='less'>
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
  .notice-detail {
    // position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .breadcrumb {
    background: #eee;
    padding: 4px 0;
    .breadcrumb-warp {
      width: 1200px;
      margin: 0px auto 0;
      display: flex;
    }
    .text {
      margin-right: 32px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
  }
  .notice-detail-content {
    width: 1200px;
    flex: 1;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom:32px;
    background: #FFFFFF;
    border-radius: 8px;
    padding:24px;
    .title {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.9);
      font-weight: 500;
    }
    .notict-content{
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

</style>