<template>
  <div class="bottom-bar noData">
    <div class="bottom-bar-content">
      <div class="select-del">
        <Checkbox v-model="checkAll" @on-change="handleCheckAll">全选</Checkbox>
        <span v-if="whetherChecked('del')" class="del" @click="deleteMultiple">删除已选商品</span>
      </div>
      <div class="right">
        <div class="sub-total">
          <div class="sum">
            <!-- {{ activityDiscountAmount > 0 ? '合计优惠': '' }}<span v-if="activityDiscountAmount > 0">¥{{ returnFloat(activityDiscountAmount || '0') }}</span> -->
          </div>
          <div class="num">
            <div class="num-wrap">
              <template v-if="detailData.totalNum > 0">
                <span>已选<i>{{ detailData.selectedCount || 0 }}</i> </span>种，共计<i>{{ detailData.totalNum || 0
                }}</i>件
              </template>
            </div>
            <div class="price-wrap">
              <span class="num-price mr-left">商品金额<span class="mr-lf-8 font-style-r">¥{{ returnFloat(totalPrice || '0',
                                                                                                     '0.01')
              }}</span></span>
            </div>
          </div>
        </div>
        <div class="gray-line" />
        <div class="total">
          <div class="amount-price">
            <span class="amount">总金额(含运费)：</span>
            <span class="price">¥{{ returnFloat(actualPrice || '0', '0.01') }}</span>
          </div>
          <div v-if="selectedStoreList?.length > 0" class="detailed">
            <span v-if="activityDiscountAmount > 0" class="activity-discountAmount">优惠合计
              <i class="activity-num">-¥{{
                returnFloat(activityDiscountAmount || '0', '0.01') }}</i>
            </span>
            <Poptip v-model="detailedModal" placement="top-end">
              <span class="detail-text">查看明细</span>
              <Icon type="ios-arrow-forward" style="transform: rotate(-90deg);" />
              <template #content>
                <div class="detail-content">
                  <div v-for="(item, index) in selectedStoreList" :key="index" class="item">
                    <div class="company-name">{{ item.storeName }}</div>
                    <div class="info">
                      <div class="row"><span>商品金额</span><span class="detail-price">¥&nbsp;{{
                        returnFloat(item.totalPrice || '0', '0.01') }}</span></div>
                      <div class="row" :class="{ active: item.showInfo }">
                        <span>优惠合计</span>
                        <div @click="onInfo(item, index)">
                          <span class="detail-price">-&nbsp;¥&nbsp;{{
                            item.discountAmount > 0 ? returnFloat(item.discountAmount || '0', '0.01') : '0.00' }}</span>
                          <Icon v-if="item.discountAmount > 0" class="info-icon" type="ios-arrow-down" />
                        </div>
                      </div>
                      <div class="info-box" :class="{ active: item.showInfo }">
                        <div
                          v-for="(aItem, aIndex) in item.activityCouponList"
                          :key="index + aIndex + 'a'"
                          class="info-box-row"
                        >
                          <div class="w-flex w-flex-align-center">
                            <span>{{ aItem.activityType == "41" || aItem.activityType == "42" ? "组合优惠" : "满类活动"
                            }}</span>
                            <div
                              v-if="aItem.activityType != '41' && aItem.activityType != '42'"
                              class="info-box-goods"
                              :title="item.activityCouponNames"
                            >
                              (<span class="info-box-coupon">
                                {{ aItem.activityAllDesc }}
                              </span>)
                            </div>
                          </div>
                          <span>-¥{{ returnFloat(aItem.activityTotalAmount || 0, '0.01') }}</span>
                        </div>
                        <div
                          v-for="(aItem, aIndex) in item.universalCouponList"
                          :key="index + aIndex + 'b'"
                          class="info-box-row"
                        >
                          <div class="w-flex w-flex-align-center">
                            <span v-if="aItem.couponType == 'coupon_full_decrement'">满减券</span>
                            <span v-else-if="aItem.couponType == 'coupon_full_discount'">满折券</span>
                            <span v-else-if="aItem.couponType == 'coupon_full_rebate'">满返券</span>
                            <span v-else-if="aItem.couponType == 'coupon_new_customer'">新客券</span>
                            <div class="info-box-goods">
                              (<span class="info-box-coupon">
                                {{ aItem.couponMsg }}
                              </span>)
                            </div>
                          </div>
                          <span>-¥{{ returnFloat(item.universalCouponAmount || 0, '0.01') }}</span>
                        </div>
                        <div
                          v-if="item.productCouponList && item.productCouponList.length > 0 && item.productCouponAmount > 0"
                          class="info-box-row"
                        >
                          <div class="w-flex w-flex-align-center">
                            <span>商品券</span>
                            <div class="info-box-goods" :title="item.productCouponNames">
                              (<span
                                v-for="(aItem, aIndex) in item.productCouponList"
                                :key="index + aIndex + 'c'"
                                class="info-box-coupon"
                              >
                                {{ aItem.couponMsg }}
                                <span
                                  v-if="item.productCouponList.length > 1 && aIndex + 1 < item.productCouponList.length"
                                >,</span>
                              </span>)
                            </div>
                          </div>
                          <span>-¥{{ returnFloat(item.productCouponAmount || 0, '0.01') }}</span>
                        </div>
                      </div>
                      <div class="row"><span>运费</span><span class="detail-price">¥&nbsp;{{
                        returnFloat(item.orgPostage.postage || '0', '0.01') }}</span></div>
                      <div class="detail-amount">
                        <div class="left">已选商品<span>{{ item.selectedCount }}</span>种，共计<span>{{ item.totalNum }}</span>件
                        </div>
                        <div class="right">合计：<span>{{ returnFloat(item.actualPrice || '0', '0.01') }}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Poptip>
          </div>
        </div>
        <div
          class="foot"
          :disabled="isClickStatus"
          :class="{ 'bottom-btn-disabled': !whetherChecked(), 'disabledBtn': isClickStatus }"
          @click="jumpToOrderConfirm"
        >
          {{ detailData.unReceiveFlag === true ? '领券结算' : '结算' }}
        </div>
      </div>
    </div>
    <Modal
      v-model="clearAllModal"
      :mask-closable="false"
      scrollable
      title="提示"
      @on-ok="deleteMultipleGoods"
      @on-cancel="clearAllModal = false"
    >
      确认将这{{ checkedNum }}个商品删除？
    </Modal>
  </div>
</template>

<script>
import { Message, Modal } from 'view-design'
import { accountProduct } from '@/common/service'
import { returnFloat, ACTIVITY_TYPE, setStorageSync } from '@/common/utils'
import { mapMutations, mapState } from 'vuex'
import CryptoJS from 'crypto-js'
import { uniqBy } from 'lodash'

export default {
  props: {
    actualPrice: {
      type: [String, Number],
      default: ''
    },
    totalPrice: {
      type: [String, Number],
      default: ''
    },
    checkedAllStatus: {
      type: Boolean,
      default: false
    },
    validCartList: {
      type: Array,
      default: () => []
    },
    activityDiscountAmount: {
      type: [String, Number],
      default: ''
    },
    customerObj: {
      type: Object,
      default: () => { }
    },
    selectedItemsNumSub: {
      type: Number,
      default: 0
    },
    selectedItemsNumTotalSub: {
      type: Number,
      default: 0
    },
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    emptyData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      indeterminate: false, // 半选
      checkAll: false, // 全选
      detailedModal: false, // 查看明细
      clearAllModal: false, // 删除所有商品
      checkedNum: 0, // 选中商品数量
      isClickStatus: false,
      returnFloat,
      selectedStoreList: []// 已选的店铺信息
    }
  },
  computed: {
    ...mapState({
      selectedProductList: state => state.car.selectedProductList
    })
  },
  watch: {
    checkedAllStatus(newValue) {
      this.checkAll = newValue
    },
    validCartList: {
      handler(newVal, oldVal) {
        let flag = false
        let arr = []
        if (newVal?.length > 0) {
          arr = JSON.parse(JSON.stringify(newVal)) || []
          for (const sItem of arr) {
            sItem.showInfo = false
            for (const aItem of sItem?.activityList) {
              flag = aItem?.productList.some(gItem => gItem.checked == true)
              if (flag) {
                sItem.isShowDetail = true
                break
              } else {
                sItem.isShowDetail = false
              }
            }
          }
          arr = arr?.filter(sItem => sItem.isShowDetail) || []
          if (arr && arr.length > 0) {
            arr.forEach((item) => {
              item.productCouponNames = ''
              item.activityCouponNames = ''
              item.activityCouponList = []
              if (item.activityList && item.activityList.length > 0) {
                item.activityList.forEach((ele, index) => {
                  item.activityCouponNames = index > 0 ? item.activityCouponNames + '，' + ele.activityAllDesc : ele.activityAllDesc
                })
                item.activityCouponList = item.activityList.filter((ele) => ['11', '12', '13', '14', '15', '41', '42'].includes(ele.activityType) && ele.activityTotalAmount && ele.activityTotalAmount > 0 && ele.productList && ele.productList.some((pItem) => pItem.checked === true))
              }
              if (item.productCouponList && item.productCouponList.length > 0) {
                item.productCouponList.forEach((ele, index) => {
                  item.productCouponNames = index > 0 ? item.productCouponNames + '，' + ele.couponMsg : ele.couponMsg
                })
              }
            })
          }
        } else {
          // 全选按钮未选中状态
          this.checkAll = false
        }

        this.selectedStoreList = arr
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    // 全选
    handleCheckAll() {
      // this.checkAll = !this.checkAll
      this.$emit('updateCheckedAll', this.checkAll)
    },
    dealPackageMailSettle(res) {
      // 店铺起配包邮校验
      // console.log('postagePrompt',res)
      if (Object.keys(res?.data?.postagePrompt).length > 0) {
        // 店铺起配包邮校验
        this.$emit('checkGoodPackageMail', res?.data.postagePrompt)
        // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      } else {
        // 去结算
        this.$router.push({
          path: `/trade/orderConfirm`
        })
        this.$zhuge.track("购物车-点击结算按钮", {})
      }
    },
    dealJumpSettle() {
      this.$emit('initChangeData')
      const selectedProducts = []
      const presentProductList = []
      const settlePresentProductList = []
      let unReceiveCouponList = [] // 未领取优惠券列表
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              const flag = (aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present == true
              const isAutoFlag = aItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE && gItem.expandMap?.present == true
              if (gItem.checked) {
                if (!flag) {
                  selectedProducts.push({
                    id: gItem.id,
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    num: gItem.num,
                    price: gItem.price,
                    actualPrice: gItem.actualPrice,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId
                  })
                }
                // 31自动换购 32手动换购
                if (flag) {
                  presentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: gItem.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                  settlePresentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: gItem.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                }
              } else {
                if (isAutoFlag) {
                  settlePresentProductList.push({
                    productId: gItem.productId,
                    activityId: aItem.activityId,
                    activityType: aItem.activityType,
                    orgId: gItem.orgId,
                    selected: gItem.checked,
                    num: gItem.num,
                    actualPrice: gItem.actualPrice,
                    price: gItem.price
                  })
                }
              }
            }
          }
        }

        this.validCartList.forEach((item) => {
          if (item.couponList && item.couponList.length > 0) {
            const arrC = item.couponList.filter((cItem) => cItem.couponType === 'coupon_new_customer' && cItem.state == 1)
            unReceiveCouponList = unReceiveCouponList.concat(arrC)
          }
          if (item.merchantCouponAmounts && item.merchantCouponAmounts.length > 0) {
            const arrM = item.merchantCouponAmounts.filter((cItem) => cItem.unReceiveFlag == true)
            unReceiveCouponList = unReceiveCouponList.concat(arrM)
          }
        })
      }
      if (unReceiveCouponList && unReceiveCouponList.length > 0) {
        // 根据优惠券编码进行去重
        unReceiveCouponList = uniqBy(unReceiveCouponList, 'couponId')
        unReceiveCouponList.forEach((item) => {
          item.sign = CryptoJS.AES.encrypt(String(item.couponId), CryptoJS.enc.Utf8.parse('RYM-COUPON-ID-16'), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString()
        })
      }
      const arr = unReceiveCouponList.map((item) => {
        return {
          couponId: item.couponId,
          sign: item.sign
        }
      })
      // 判断券列表
      if (this.isClickStatus) {
        return
      }
      this.isClickStatus = true
      accountProduct({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        presentProductList,
        selectedProductList: selectedProducts,
        cancelProductList: [],
        unReceiveCouponList: this.detailData.unReceiveFlag ? arr : [],
        unReceiveFlag: this.detailData.unReceiveFlag
      }).then(res => {
        this.isClickStatus = false
        if (res.code == 200) {
          if (this.detailData.unReceiveFlag === true) {
            Message.success('已自动为您领取优惠券')
          }
          setStorageSync('customerObj', this.customerObj)
          // console.log('res',res)
          if (res?.data?.batchStock && Object.keys(res?.data?.batchStock).length > 0 || (res?.data?.activityLimit && Object.keys(res?.data?.activityLimit).length > 0) || (res?.data?.residuePurchaseCtrl && Object.keys(res?.data?.residuePurchaseCtrl).length > 0)) {
            // 批量校验商品库存发生变化、手动加购活动被换购商品最大限购数量校验、批量校验商品限购数量
            this.$emit('batchVerify', res?.data)
          } else if (Object.keys(res?.data?.changeProduct).length > 0) {
            // 商品发生变化
            this.$emit('goodChange', res?.data, selectedProducts, settlePresentProductList)
          } else {
            setStorageSync('settleProducts', selectedProducts)
            setStorageSync('settlePresentProductList', settlePresentProductList)
            this.dealPackageMailSettle(res)
          }
        } else {
          Message.error(res.message)
        }
      }).catch(err => {
        this.isClickStatus = false
        if (err?.data?.code == 600) {
          Message.error(err?.data?.message)
        } else if (err?.data?.message?.includes('起配信息')) {
          Message.info(err?.data?.message)
        } else if (err?.data?.code === 566 || err?.data?.code === 569) {
          Modal.info({
            content: `${err?.data?.message || '商品信息有变化'}`,
            okText: '我知道了',
            title: '温馨提示',
            onOk: () => {
              this.$emit('queryCart')
            }
          })
          return
        } else {
          Message.info(err?.data?.message)
        }
        setTimeout(() => {
          this.$emit('refreshPage')
        }, 2000)
      })
    },
    // 结算
    jumpToOrderConfirm() {
      // 未选择商品
      if (!this.whetherChecked('trade')) { return }
      this.dealJumpSettle()
    },
    // 多个删除
    deleteMultipleGoods() {
      this.clearAllModal = false
      this.$emit('delMulti')
    },
    whetherChecked(type) {
      let canBuy = false
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          if (sItem?.activityList?.length > 0) {
            for (const aItem of sItem?.activityList) {
              if (aItem?.productList?.length > 0) {
                for (const gItem of aItem?.productList) {
                  if (gItem.checked) {
                    canBuy = true
                  }
                }
              }
            }
          }
        }
      }
      if (type === 'del') {
        if (this.invalidCartList?.length > 0) {
          for (const IItem of this.invalidCartList) {
            if (IItem.checked) {
              canBuy = true
            }
          }
        }
      }
      if (!canBuy) {
        if (type === 'trade') {
          Message.error('您还没有选择商品')
        }
        return false
      } else {
        return true
      }
    },
    // 删除
    deleteMultiple() {
      let checkedNum = 0
      if (this.validCartList?.length > 0) {
        for (const sItem of this.validCartList) {
          if (sItem?.activityList?.length > 0) {
            for (const aItem of sItem?.activityList) {
              if (aItem?.productList?.length > 0) {
                for (const gItem of aItem?.productList) {
                  if (gItem.checked) {
                    checkedNum++
                  }
                }
              }
            }
          }
        }
      }
      if (this.invalidCartList?.length > 0) {
        for (const IItem of this.invalidCartList) {
          if (IItem.checked) {
            checkedNum++
          }
        }
      }
      this.checkedNum = checkedNum
      if (this.whetherChecked('del')) {
        this.clearAllModal = true
      }
    },
    onInfo(item, index) {
      if (item.discountAmount && item.discountAmount > 0) {
        this.selectedStoreList[index].showInfo = !this.selectedStoreList[index].showInfo
        this.selectedStoreList = Object.assign([], this.selectedStoreList)
      }
    }
  }
}
</script>

<style scoped lang="less">
.bottom-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: static;
  bottom: 0;
  left: 0;
  z-index: 100;

  .bottom-bar-content {
    margin: 0 auto;
    width: 1200px;
    height: 68px;
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;
    // border-radius: 8px;
    background: var(--font-white-0, #FFF);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #ccc;
    box-shadow: 0px -16px 20px -10px rgba(0, 0, 0, 0.04);

    .select-del {
      ::v-deep .ivu-checkbox-wrapper {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);

        .ivu-checkbox {
          margin-right: 8px;
        }

      }

      .del {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
        cursor: pointer;
      }

      .del:hover {
        color: #F76560;
      }

      .del:active {
        color: #CB272D;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .sub-total {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .num {
          display: flex;
          align-items: center;

          .num-wrap {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400 !important;

            i {
              font-style: normal;
              color: #ce7720;
              font-weight: 500 !important;
            }

          }

          .price-wrap {
            margin-left: 11px;

            .num-price,
            .num-price>span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.9);
              font-family: "PingFang SC";
            }

          }

          .mr-left {
            margin-left: 5px;
          }

          .mr-lf-8 {
            margin-left: 8px;
          }

          .font-style-r {
            font-weight: 600 !important;
          }
        }

        .sum {
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.6);
          margin-top: 2px;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 500;
            margin-left: 8px;
            margin-right: 8px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      .gray-line {
        width: 1px;
        height: 52px;
        background-color: #e7e7e7;
        margin: 0 24px;
      }

      .total {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        // margin-left: 36px;
        .amount-price {
          display: flex;
          align-items: center;

          .amount {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 400;
          }

          .price {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #cb272d;
          }
        }

        .detailed {
          .activity-discountAmount {
            font-family: "PingFang SC";
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 400 !important;
            font-style: normal;
            margin-right: 16px;

            .activity-num {
              color: var(--font_black-1, rgba(0, 0, 0, 0.90));
              font-weight: 600 !important;
              font-style: normal;
            }
          }

          .detail-text {
            cursor: pointer;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            margin-right: 2px;
          }

          ::v-deep .ivu-poptip {
            .ivu-poptip-body {
              width: 528px;
              padding: 16px;
              max-height: 392px;
              overflow-y: scroll;
              box-sizing: border-box;

              &::-webkit-scrollbar {
                display: none;
              }

              .ivu-poptip-body-content {
                .detail-content {
                  .item {
                    margin-bottom: 16px;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    .company-name {
                      padding: 8px 26px;
                      box-sizing: border-box;
                      font-size: 14px;
                      line-height: 22px;
                      color: rgba(0, 0, 0, 0.9);
                      background: #f5f5f5;
                      border-top-left-radius: 8px;
                      border-top-right-radius: 8px;
                    }

                    .info {
                      border: 1px solid #f5f5f5;
                      border-top: none;
                      border-bottom-left-radius: 8px;
                      border-bottom-right-radius: 8px;
                      padding: 0px 20px 12px;

                      .row {
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.6);
                        display: flex;
                        justify-content: space-between;
                        margin-top: 4px;

                        &:first-child {
                          padding-top: 12px;
                          margin-top: 0;
                        }

                        .detail-price {
                          font-weight: 500;
                          color: rgba(0, 0, 0, 0.9);
                        }

                        .info-icon {
                          transition: all .35s ease-in-out;
                        }

                        &.active {
                          .info-icon {
                            transform: rotate(-180deg);
                          }
                        }
                      }

                      .info-box {
                        height: 0;
                        background-color: rgba(250, 250, 250, 1);
                        padding: 0px 20px;
                        margin: 3px 0;
                        border-radius: 4px;
                        overflow: hidden;

                        .info-box-row {
                          font-size: 12px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.9);
                          display: flex;
                          justify-content: space-between;

                          .info-box-coupon {
                            color: rgba(0, 0, 0, 0.6);
                            display: inline-block;
                          }

                          .info-box-goods {
                            width: 320px;
                            overflow: hidden;
                            margin-left: 3px;
                          }
                        }

                        &.active {
                          height: auto;
                          padding: 5px 20px;
                          overflow: auto;
                        }
                      }

                      .detail-amount {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 4px;

                        .left {
                          font-size: 14px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.6);

                          span {
                            color: #ce7720;
                            margin: 0 4px;
                          }
                        }

                        .right {
                          font-size: 14px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.9);

                          span {
                            color: #d4380d;
                            font-weight: 500;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .foot {
        // padding: 12px 40px;
        // line-height: 42px;
        background-color: #f99d33;
        color: var(--font-white-0, #FFF);
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        // line-height: 26px;
        // border-radius: 76px;
        cursor: pointer;
        margin-left: 24px;
        width: 136px;
        line-height: 68px;
        text-align: center;
        // padding: 8px 32px;

      }

      .foot:hover {
        background-color: #FAB357;
      }

      .foot:active {
        background-color: #E67D00;
      }

    }
  }

}

.noData {
  position: fixed !important;
}

.bottom-btn-disabled {
  background-color: #fdda9f !important;
}

.disabledBtn {
  background-color: #fdda9f !important;

  &::after {
    border: none;
  }
}

::v-deep .ivu-poptip-inner {
  border-radius: 8px;
}

// ::v-deep .ivu-checkbox:hover {
//   border-color: red;
// }

::v-deep .ivu-checkbox:hover .ivu-checkbox-inner {
  /* 在这里设置你想要的悬停颜色 */
  border-color: #F99D33;
}
</style>
