import { render, staticRenderFns } from "./TopMenu.vue?vue&type=template&id=4a2a9d71&scoped=true&"
import script from "./TopMenu.vue?vue&type=script&lang=js&"
export * from "./TopMenu.vue?vue&type=script&lang=js&"
import style0 from "./TopMenu.vue?vue&type=style&index=0&id=4a2a9d71&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a2a9d71",
  null
  
)

export default component.exports