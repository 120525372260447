import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";

//各消息分组未读消息数与第一条消息简略内容 
export const getUnreadMsgCountAndLatestMsg = () => get({
	url:`${api.baseUrl}/userMessageInbox/getUnreadMsgCountAndLatestMsg`,
})

export const queryMsgListAndMarkRead = (data) => post({
	url:`${api.baseUrl}/userMessageInbox/queryMsgListAndMarkRead`,
  data,
  isLoading : true
})

//删除物流
export const deleteMsg = (data) => post({
	url:`${api.baseUrl}/userMessageInbox/deleteMsg?msgId=${data}`,
  data,
  isLoading : true
})

//缺货登记查询商家
export const searchBase = (data) => post({
	url:`${api.baseUrl}/org/info/searchBase`,
  data,
  isLoading : true
})

//提交缺货登记
export const commitPrdRegister = (data) => post({
	url:`${api.baseUrl}/behavior/ubuserprdregister/commitPrdRegister`,
  data,
  isLoading : true
})
