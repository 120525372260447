<template>
  <div class="home-notice-wrap">
    <Tabs v-model="current">
      <TabPane v-for="(item, index) in tabList" :key="index" :label="item.name" />
      <template #extra>
        <div class="notic-icon" @click="noticListHandler">
          更多
          <Icon :size="12" type="ios-arrow-forward" />
        </div>
      </template>
    </Tabs>

    <div class="list">
      <div v-if="list.length>0">
        <p v-for="item in list" :key="item.id" class="item line-clamp-1" @click="detail(item.id)">{{ item.noticeTitle }}</p>
      </div>
      <div v-else class="empty-notice">
        暂无公告信息
      </div>
    </div>
  </div>
</template>

<script>
import { getRecent, getValMapList, findNoticeListPage } from '@/common/service'

export default {
  name: 'Notice',
  components: {},
  props: {
    isShowCoupon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      tabList: [],
      current: 0,
      tabKey: '',
      type: 'home'
    }
  },
  watch: {
    current(val) {
      this.tabKey = this.tabList[val].value
      this.getList(this.tabKey)
    }
  },
  created() {
    this.getTabs()
  },
  methods: {
    async getRecent() {
      const { data } = await getRecent({ size: 4 })
      this.list = data
    },
    detail(id) {
      this.$router.push('/notice/detail?id=' + id)
    },
    noticListHandler() {
      this.$router.push('/notice')
    },
    async getList(itemKey) {
      const param = {
        condition: {
          noticeStatus: 'issue',
          keywords: null,
          noticeTypeCode: itemKey,
          orgId: 0
        },
        descs: ['sorted', 'create_time'],
        pageNum: 1,
        pageSize: 4
      }
      const { data } = await findNoticeListPage(param)
      this.list = data.records
      return
    },

    getTabs() {
      getValMapList('cms_notice_type').then((res) => {
        if (res.code === 200) {
          this.tabKey = res.data?.[0].itemKey
          this.getList(this.tabKey)
          this.tabList = res.data?.map((item) => {
            return {
              name: item.itemValue,
              value: item.itemKey
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.home-notice-wrap {
  background: #ffffff;
  // border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  ::v-deep .ivu-tabs-bar {
    margin-bottom: 0px;
  }
  ::v-deep .ivu-tabs-nav {
    .ivu-tabs-tab {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding: 2px 0;
      color: rgba(0, 0, 0, 0.9);
      &:hover{
        color: #e67d00;
      }
    }
    .ivu-tabs-tab-active {
      color: #e67d00;
      font-weight: 500;
    }
  }

  .notic-icon {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    &:hover{
      color: #e67d00;
      .ivu-icon{
          color: #e67d00 !important;
        }
    }
  }

  .ivu-icon {
    margin-top: -2px;
    color: rgba(0, 0, 0, 0.6);
  }

  .list {
    height: 96px;
    padding-top: 6px;
    .item {
      margin: 2px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;

      &:hover {
        color: #e67d00;
      }
    }
    .empty-notice {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.40);
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>
