import { post, get } from '@/common/utils'
import { api } from '@/common/service/config'

export async function getMsgTabs(data) {
  return get({
    url: `${api.baseUrl}/userMessageInbox/getUnreadMsgCountAndLatestMsg`,
    isLoading: true
  })
}

export async function getMsgListApi(data) {
  return post({
    url: `${api.baseUrl}/userMessageInbox/queryMsgListAndMarkRead`,
    data,
    isLoading: true
  })
}
export async function delMsg(data) {
  return post({
    url: `${api.baseUrl}/userMessageInbox/deleteMsgIds`,
    data
  })
}

/**
 * 获取购物车角标数量
 */
export const getMsgNum = () => get({
	url: `${api.baseUrl}/userMessageInbox/queryMsgUnReadCount`,
})

/**
 * 获取消息列表
 */
export async function queryAllMsgList(data) {
  return post({
    url: `${api.baseUrl}/userMessageInbox/queryAllMsgList`,
    data,
    isLoading: true
  })
}

/*
 * 标记消息已读
 */
export async function markMsgRead(data) {
  return get({
    url: `${api.baseUrl}/userMessageInbox/markMsgRead?msgId=${data}`,
  })
}