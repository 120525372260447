import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决跳转到当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const modules = require.context('./modules', false, /\.js/)

Vue.use(VueRouter)

const routes = []
modules.keys().forEach(module => {
  routes.push(...(modules(module).default || modules(modules)))
})
// console.log(routes)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
