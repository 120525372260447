
import CenterIndex from '@/views/personnal/center'
export default [
  {
    path: '/coupon/index',
    component: () => import('@/views/personnal/coupon/index.vue'),
    meta: {
      title: '我的优惠券'
    }
  },
  {
    path: '/coupon/goodslist',
    name: 'CouponGoodsList',
    component: () => import('@/views/personnal/coupon/couponGoodsList.vue'),
    meta: {
      title: '可用优惠券商品'
    }
  }

]
