// 多行文本
<template>
  <div class="v-many-text" :id="'nav' + id" @click="cmsLink(options.link)">
    <p class="text" :class="fold&& 'line-clamp-3'" ref="textRef">
      {{ options.text }}
    </p>
    <div class="more" v-if="showMore" @click="fold=!fold">
      <Icon type="ios-arrow-down" size="18" v-if="fold"/>
      <Icon type="ios-arrow-up"  size="18" v-if="!fold"/>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
export default {
  name: "v-many-text",
  props: {
    options: Object,
    id: String
  },
  data() {
    return {
      cmsLink: cmsLink.bind(this),
      showMore: false,
      fold: true
    };
  },
  components: {
  },
  created() {
    this.$store.commit('setHomeNav', this.id)
  },
  methods: {
    setTextHight() {
      if( this.options.text ) {
        this.$nextTick(()=> {
          setTimeout(() => {
            if(this.$refs.textRef.scrollHeight> 84) {
              this.showMore = true
            }
          }, 0);
        })
      }
    }
  },
  computed: {
  },
  mounted() {
    const options = this.options
    options.type = 'v-many-text'
    this.$emit('load', options)
    this.setTextHight()
  },
  watch: {
    'options.text'() {
      this.setTextHight()
    }
  }
};
</script>
<style scoped lang="less">
.v-many-text {
  width: 1200px;
  padding: 24px 32px;
  background: #FFFFFF;
  border-radius: 8px;
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.6);
    white-space: pre-line;
  }
  .more {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    height: 16px;  
    color: rgba(0,0,0,.6);
    user-select: none;
    cursor: pointer;
  }
}
</style>
