<template>
    <div>
        <div v-if="curRoutePath === '/msg/index'" class="search-buy-btn" @click="goMessage(1)" @mouseover="showMsg = true" @mouseleave="showMsg = false">
            <span class="cart-num" v-if="totalMsgNum > 0"
                :class="totalMsgNum < 10 ? 'indivdual' : totalMsgNum < 100 ? 'ten' : 'hundred'">
                <span class="num">{{ totalMsgNum > 99 ? '99+' : totalMsgNum }}</span>
            </span>
            <img v-if="showMsg" class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/bell-active.png">
            <img v-else class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/bell.png">
            <span class="search-buy-text">消息</span>
        </div>
        <Poptip v-else placement="bottom" offset="7 " padding="4px 4px" trigger="hover" width="108" @on-popper-show="onMessage()">
            <div class="search-buy-btn" @click="goMessage(1)" @mouseover="showMsg = true" @mouseleave="showMsg = false">
                <span class="cart-num" v-if="totalMsgNum > 0"
                    :class="totalMsgNum < 10 ? 'indivdual' : totalMsgNum < 100 ? 'ten' : 'hundred'">
                    <span class="num">{{ totalMsgNum > 99 ? '99+' : totalMsgNum }}</span>
                </span>
                <img v-if="showMsg" class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/bell-active.png">
                <img v-else class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/bell.png">
                <span class="search-buy-text">消息</span>
            </div>
            <template #content>
                <div class="search-msg-box" :class="{active: totalMsgNum > 0}">
                    <div class="search-msg-item" @click.stop="goMessage(1)">
                        通知提醒    
                        <span class="msg-num" v-if="noticeMsgNum > 0"
                            :class="noticeMsgNum < 10 ? 'indivdual' : noticeMsgNum < 100 ? 'ten' : 'hundred'">
                            <span class="num">{{ noticeMsgNum < 11 ? noticeMsgNum : noticeMsgNum < 100 ? '10+' : '99+' }}</span>
                        </span>
                    </div>
                    <div class="search-msg-item" @click.stop="goMessage(2)">
                        交易物流
                        <span class="msg-num" v-if="logisticsMsgNum > 0"
                            :class="logisticsMsgNum < 10 ? 'indivdual' : logisticsMsgNum < 100 ? 'ten' : 'hundred'">
                            <span class="num">{{ logisticsMsgNum < 11 ? logisticsMsgNum : logisticsMsgNum < 100 ? '10+' : '99+' }}</span>
                        </span>
                    </div>
                    <div class="search-msg-item" @click.stop="goMessage(3)">
                        优惠活动
                        <span class="msg-num" v-if="activityMsgNum > 0"
                            :class="activityMsgNum < 10 ? 'indivdual' : activityMsgNum < 100 ? 'ten' : 'hundred'">
                            <span class="num">{{ activityMsgNum < 11 ? activityMsgNum : activityMsgNum < 100 ? '10+' : '99+' }}</span>
                        </span>
                    </div>
                    <div class="search-msg-item" @click.stop="goMessage(4)">
                        首营交换
                        <span class="msg-num" v-if="exchangeMsgNum > 0"
                            :class="exchangeMsgNum < 10 ? 'indivdual' : exchangeMsgNum < 100 ? 'ten' : 'hundred'">
                            <span class="num">{{ exchangeMsgNum < 11 ? exchangeMsgNum : exchangeMsgNum < 100 ? '10+' : '99+' }}</span>
                        </span>
                    </div>
                </div>
            </template>
        </Poptip>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'CartBtn',
    data() {
        return {
            curRoutePath: this.$route.path,
            showMsg: false
        }
    },
    computed: {
        ...mapState({
            totalMsgNum: (state) => state.message.totalMsgNum,
            noticeMsgNum: (state) => state.message.noticeMsgNum,
            logisticsMsgNum: (state) => state.message.logisticsMsgNum,
            activityMsgNum: (state) => state.message.activityMsgNum,
            exchangeMsgNum: (state) => state.message.exchangeMsgNum,
        }),
    },
    created() {
        // this.$store.dispatch('car/updateCartNum')
    },
    methods: {
        onMessage() {
            this.curRoutePath = this.$route.path
            console.log("当前路由", this.curRoutePath, this.$route)
            this.$store.dispatch('car/updateCartNum')
        },
        goMessage(type) {
            if (this.$route.path !== '/msg/index') this.$router.push({
                path: '/msg/index',
                query: {
                    type: type
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.search-buy-btn {
    width: 112px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    margin-left: 12px;

    .search-buy-icon {
        width: 16px;
        height: 16px;
    }

    .search-buy-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 400;
        margin-left: 8px;
    }

    &:hover {
        border: 1px solid rgba(250, 179, 87, 1);
        .search-buy-text {
            color: rgba(230, 125, 0, 1);
        }
    }
}

.cart-num {
    position: absolute;
}

.cart-num {
    width: 28px;
    height: 15px;
    line-height: 15px;
    top: -10px;
    right: 6px;
    text-align: center;
    background-size: 100% 100%;

    .num {
        font-size: 12px;
        color: #ffffff;
    }

    &.indivdual {
        width: 16px;
        height: 16px;
        background-image: url(../assets/images/common/car-num-indivdual.png);
    }

    &.ten {
        background-image: url(../assets/images/common/car-num-ten.png);
        width: 24px;
        height: 16px;
    }

    &.hundred {
        width: 32px;
        height: 16px;
        background-image: url(../assets/images/common/car-num-hundred.png);
    }
}

.msg-num {
    width: 16px;
    height: 16px;
    text-align: center;
    background-size: 100% 100%;
    background: rgba(245, 63, 63, 1);
    border-radius: 50%;
    font-size: 9.6px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    &.ten {
        width: 26px;
        height: 16px;
        border-radius: 8px;
        padding: 0 4px;
    }
}

::v-deep .ivu-poptip-popper {
    min-width: 112px;
    padding: 0 4px;
}

.search-msg-box {

    .search-msg-item {
        width: 104px;
        height: 32px;
        padding: 0px 12px 0px 12px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        &:hover {
            color: rgba(230, 125, 0, 1);
            background: rgba(255, 248, 232, 1);
        }
    }

    &.active {
        .search-msg-item {
            justify-content: flex-start;
        }
    }
}
</style>