<template>
  <div class="center">
    <div style="line-height: 22px; padding: 10px 0; font-size: 12px;">
      <Breadcrumb>
        <BreadcrumbItem class="black_2">我的订单</BreadcrumbItem>
        <BreadcrumbItem class="black_2" to="/allOrder/list">全渠道订单</BreadcrumbItem>
        <BreadcrumbItem class="black_2" :to="`/order/detail?orgOrderId=${$route.query.orgOrderId}`">订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'RouterContainer',
  watch: {
    $route(to, from) {
      console.log(to, from)
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  data() {
    return {}
  }

}
</script>
  <style lang="less">
  .center {
    width: 1200px;
    color: rgba(0, 0, 0, 0.9);

  }
  .black_2 {
    a{
      color: rgba(0, 0, 0, 0.60) !important;
    }
    span{
      color: rgba(0, 0, 0, 0.60) !important;
    }
    
  }
  .black_1 {
    a{
      color: rgba(0, 0, 0, 0.90) !important;
    }
    span{
      color: rgba(0, 0, 0, 0.90) !important;
    }
    
  }
  </style>

