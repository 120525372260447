<!-- 资质到期弹窗，首页部分，非必要资质 -->
<template>
  <div class="qualifications" />
</template>

<script>
import { pullAll, popSetQualification } from '@/common/service/home'
import { getPollingTime } from '@/common/utils/aptitudePolling'
import { getOrderCancelTime } from '@/common/service'
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    customerList: Object // 企业列表
  },
  data() {
    return {
    }
  },
  watch: {
    customerList(newValue) {
      this.cancleOrderSelect = newValue
    }
  },
  methods: {
    // 调接口，判断是否有过期资质
    async check() {
      const me = this
      // 获取时间值级
      const resultTImes = await getOrderCancelTime('aptitude_polling_time')
      const notNotifyTime = resultTImes.data.find((item) => item.itemKey === 'not_notify_time').itemValue
      // 调用接口  有过期资质就弹出，没有过期资质调用协议校验
      const res = await pullAll()
      if (res.data.length > 0) {
        const data = res.data[0]
        const cert = res.data[0].qualificationExpiredModel
        if (cert.qualifications.length > 0) {
          this.$emit('showMaskHandle', true) // 关闭下拉刷新
          // 是否有必要资质
          // eslint-disable-next-line eqeqeq
          const isRequired = !!cert.qualifications.find((item) => item.isRequired == 1)
          this.$Modal.info({
            title: '资质到期提醒',
            closable: true,
            content: cert.qualifications.length === 1 ? `您的“${cert.qualifications[0].certName}”已到期，请及时更新` : `您有${cert.qualifications.length}个资质已到期，请及时更新`,
            cancelText: '暂不更新',
            okText: '去更新',
            async onOk() {
              const params = {
                msgType: data.msgType,
                userId: data.userId,
                notNotifyTime: notNotifyTime,
                qualificationIds: []
              }
              for (const i in cert.qualifications) {
                params.qualificationIds.push(cert.qualifications[i].certId)
              }
              params.qualificationIds = params.qualificationIds.join(',')
              const response = await popSetQualification(params)
              if (response.data) {
                getPollingTime()
                me.$router.push({
                  path: '/center/certification'
                })
              }
            },
            onCancel() {
              if (!isRequired) {
                // uni.navigateTo({
                //   url: `/pages/personal/popup/index?data=${encodeURIComponent(JSON.stringify(params))}`,
                // })
              }
            }
          })
        }
      } else {
        // 开启轮询
        getPollingTime()
        // 没有过期资质就调用协议校验
        this.$emit('otherHandle')
      }
    }
  }
}
</script>

<style scoped lang='less'>
.box {
  width: 650rpx;
  background: #fff;
  border-radius: 16rpx;

  .region {
    padding: 64rpx 48rpx;
    box-sizing: border-box;

    .title {
      font-size: 32rpx;
      font-weight: 500;
      line-height: 48rpx;
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
    }

    .content {
      font-size: 32rpx;
      line-height: 48rpx;
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      margin-top: 16rpx;
    }
  }

  .handle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    height: 113rpx;
    background: #fff;
    border-bottom-left-radius: 16rpx;
    border-bottom-right-radius: 16rpx;

    .btn {
      width: 50%;
      line-height: 113rpx;
      text-align: center;
    }

    .cancel {
      color: rgba(0, 0, 0, 0.6);
    }

    .confirm {
      border-left: 1px solid #eee;
      color: #ce7720;
    }
  }
}

.items {
  width: 100%;
  background: #f9f9f9;

  .item {
    width: 100%;
    text-align: center;
    font-size: 32rpx;
    line-height: 48rpx;
    color: rgba(0, 0, 0, 0.9);
    padding: 24rpx 0rpx;
    background: #fff;

    &:first-child {
      border-bottom: 1px solid #eee;
    }
  }

  .cancel {
    margin-top: 20rpx;
  }
}
</style>
