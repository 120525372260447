<template>
  <div class="matching-shipping">
    <Modal v-model="shippingModal" :footer-hide="true" :transfer="false" class-name="vertical-center-modal" @on-visible-change="modalClose">
      <template #header>
        起配/包邮提醒
      </template>
      <div v-for="(item, key) in changePackageMailList" :key="key">
        <div v-if="item&&item.length>0" class="not-full">
          <div class="text">{{ getMailTitle(key) }}</div>
          <div class="content-item">
            <div v-for="(sItem, sIndex) in item" :key="sIndex" class="business-box">
              <div class="name">{{ sItem.orgName }}</div>
              <div class="info">
                <div class="info-content">
                  <div v-if="key=='delivery'" class="left">{{ sItem.saleAmount?sItem.saleAmount:0 }}元起配，{{ sItem.noPostage?sItem.noPostage:0 }}元包邮，商品金额{{returnFloat(sItem.amount||0, '0.01')}}元，起配还差<span>{{ sItem.difference?returnFloat(sItem.difference, '0.01'):'0.00' }}</span>元</div>
                  <div v-else class="left">满{{ sItem.noPostage?sItem.noPostage:0 }}元包邮，商品金额{{returnFloat(sItem.amount||0, '0.01')}}元，包邮还差<span>{{ sItem.difference?returnFloat(sItem.difference, '0.01'):'0.00' }}</span>元</div>
                  <div class="right" @click="jumpShopPage(sItem)">
                    去凑单
                    <Icon type="ios-arrow-forward" />
                  </div>
                </div>
                <div class="tag">
                  <p v-if="sItem.postage>0" class="typeOne">+运费{{ sItem.postage?returnFloat(sItem.postage, '0.01'):'0.00' }}元</p>
                  <p v-if="sItem.allowed && key=='delivery'" class="typeTwo">暂不参与提单</p>
                </div>
                <div class="freight_voucher" v-if="sItem.expandMap&&sItem.expandMap.freightCouponList&&sItem.expandMap.freightCouponList.length>0">
                  <div class="freight_mark">
                    <span class="freight_txt">券</span>
                  </div>
                  <div class="freight_wrap">
                    <p>
                    <span class="freight_tip" v-for="(vItem, vIndex) in sItem.expandMap.freightCouponList" :key="vItem.couponCustomerNo">{{ vItem.couponLabel }}{{vIndex!=sItem.expandMap.freightCouponList.length-1?'；':''}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <span class="back-car" @click="close">{{ btnLeftText }}</span>
        <span v-if="!cannotSettle&&!isNeedGoBack" class="continue" @click="goSettle">{{ btnRightText }}</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import { returnFloat, getFilePath, ACTIVITY_TYPE } from '@/common/utils'
import { mapMutations } from 'vuex'
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    changePackageMailList: {
      type: Object,
      default: () => { }
    },
    activityLimit: {
      type: Object,
      default: () => { }
    },
    checkGoodType: {
      type: String,
      default: '' // goodChange goodPackageMail
    },
    validCartList: {
      type: Array,
      default: () => []
    },
    batchVerifyList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      shippingModal: false,
      returnFloat: returnFloat,
      checkType: '',
      clickButton: false,
    }
  },
  computed: {
    isNeedGoBack() {
      return this.batchVerifyList && Object.keys(this.batchVerifyList).length > 0 || (this.activityLimit && Object.keys(this.activityLimit).length > 0)
    },
    btnLeftText() {
      return this.isNeedGoBack || this.checkType === 'goodPackageMail' ? '返回购物车' : '取消'
    },
    btnRightText() {
      return '继续结算'
    },
    cannotSettle() {
      if (this.checkType === 'goodPackageMail') {
        for (const key in this.changePackageMailList) {
          if (key === 'delivery') {
            let num = 0
            const arrIds = []
            if (this.validCartList?.length > 0) {
              for (const sItem of this.validCartList) {
                for (const aItem of sItem?.activityList) {
                  for (const gItem of aItem?.productList) {
                    if (gItem.checked == true && !arrIds.includes(sItem.storeId)) {
                      num++
                      arrIds.push(sItem.storeId)
                      break
                    }
                  }
                }
              }
            }
            const flag = this.changePackageMailList[key].every(item => item.allowed == true)
            // console.log('num',this.changePackageMailList,this.changePackageMailList[key].length,num,flag)
            return flag && this.changePackageMailList[key].length == num
          } else {
            return false
          }
        }
      } else {
        return false
      }
      return false
    }
  },
  watch: {
    showPopup(newVal) {
      if (newVal) {
        if (this.changePackageMailList && Object.keys(this.changePackageMailList).length > 0) {
          for (const key in this.changePackageMailList) {
            if (!this.changePackageMailList[key]) {
              delete this.changePackageMailList[key]
            }
          }
        }
      }
      this.shippingModal = newVal
    },
    checkGoodType(newVal) {
      this.checkType = newVal
    }
  },
  methods: {
    modalClose(e){
      if(!e && !this.clickButton){
        // 购物车页面关闭弹窗
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
        this.shippingModal = false
        this.$emit('update:show-popup', this.shippingModal)
        this.$bus.$emit('getCartListFn')
      }
    },
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    goSettle() { // 结算页面继续提交订单
      this.clickButton = true
      if (this.checkType === 'goodPackageMail') {
        // 购物车页面弹窗展示去结算
        this.shippingModal = false
        this.$emit('update:show-popup', this.shippingModal)
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击继续结算按钮", {})
      } else {
        this.dealJumpSettle()
      }
    },
    dealJumpSettle() {
      // 店铺起配包邮校验
      if (Object.keys(this.changePackageMailList).length > 0) {
        // 店铺起配包邮校验
        this.$emit('checkGoodPackageMail', this.changePackageMailList)
        // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      } else {
        // 去结算
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击结算按钮", {})
      }
    },
    close() {
      this.clickButton = true
      // 购物车页面关闭弹窗
      // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
      this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      this.shippingModal = false
      this.$emit('update:show-popup', this.shippingModal)
      this.$bus.$emit('getCartListFn')
    },
    // 去商家首页
    jumpShopPage(item) {
      // 跳转至商城店铺明细页面
      this.$router.push({
        path: `/shopHome?orgId=${item.orgId}`
      })
    },
    getMailTitle(key) {
      let tipTxt = ''
      switch (key) {
        case 'delivery':
          tipTxt = '以下商家未满起配金额，补单满起配后商家进行配送：'
          break
        case 'noPostage':
          tipTxt = '以下商家未包邮，下单需额外支付运费：'
          break
        default:
          break
      }
      return tipTxt
    }
  }
}
</script>

<style scoped lang="less">
.matching-shipping {
  ::v-deep .vertical-center-modal {
    .ivu-modal {
      width: 688px !important;
      .ivu-modal-content {
        .ivu-modal-header {
          border-bottom: none;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
          padding:16px 32px 4px;
        }
        .ivu-modal-close{
          top:16px;
          right:32px;
        }
        .ivu-modal-body {
          padding: 8px 32px 16px;
          .not-full {
            .text {
              font-size: 16px;
              line-height: 24px;
              color: #000;
              margin-bottom: 12px;
            }
            .content-item{
              max-height:300px;
              overflow-y:scroll;
              &::-webkit-scrollbar {
                display: none;
              }
            }
            .business-box {
              margin-bottom: 16px;
              &:last-child{
                margin-bottom: 0;
              }
              .name {
                font-size: 18px;
                line-height: 26px;
                color: rgba(0, 0, 0, 0.9);
                padding: 16px 26px 18px 26px;
                box-sizing: border-box;
                background-color: #f5f5f5;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }
              .info {
                padding: 10px 26px 20px 26px;
                box-sizing: border-box;
                border: 1px solid #eee;
                border-top: none;
                border-radius: 0px 0px 8px 8px;
              }
              .info-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left {
                  font-size: 16px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.6);
                  span {
                    color: #cb272d;
                  }
                }
                .right {
                  font-size: 16px;
                  line-height: 24px;
                  color: #CB272D;
                  cursor: pointer;
                }
              }
              .tag {
                display:flex;
                align-items:center;
                .typeOne {
                  border: 0.5px solid #3853E2;
                  background-color: #F1F5FE;
                  color: #3853E2;
                  font-size: 14px;
                  line-height: 22px;
                  border-radius: 4px;
                  padding: 2px 4px;
                  margin-right: 10px;
                  margin-top:10px;
                }
                .typeTwo {
                  background-color: #eeeeee;
                  color: rgba(0, 0, 0, 0.4);
                  font-size: 14px;
                  line-height: 22px;
                  border-radius: 4px;
                  padding: 2px 4px;
                  margin-top:10px;
                }
              }
              .freight_voucher{
                margin-top:12px;
                display:flex;
                align-items: flex-start;
                .freight_mark{
                  padding:0 6px;
                  margin-right:4px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  background: #FFEDF1;
                  border: 0.5px solid #FF4D77;
                  border-radius: 4px;
                  .freight_txt{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #E72A57;
                  }
                }
                .freight_wrap{
                  display: flex;
                  flex: 1;
                  p{
                    width:536px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    .freight_tip{
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 22px;
                      color: rgba(0, 0, 0, 0.6);
                    }
                  }
                }
              }
            }
          }
          .modal-footer {
            display: flex;
            justify-content: flex-end;
            padding-top: 24px;
            span {
              padding: 5px 16px;
              border-radius: 3px;
              font-size: 14px;
              line-height: 22px;
              margin-left: 8px;
              cursor: pointer;
            }
            .back-car {
              background-color: #e7e7e7;
              color: rgba(0, 0, 0, 0.9);
            }
            .continue {
              background-color: #f99d33;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }
}
</style>
