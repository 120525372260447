<template>
  <div class="information">
    <div style="line-height: 22px; padding: 10px 0; font-size: 14px">
      <Breadcrumb>
        <BreadcrumbItem>完善委托信息</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="information-content">
      <div style="margin: 50px auto">
        <Entrust :custom-id="customId"  :edited="edit"/>
      </div>
    </div>
  </div>
</template>
<script>
import Entrust from './components/Entrust.vue'
export default {
  name: 'Association',
  components: {
    Entrust
  },
  data() {
    return {
      customId: 0,
      edit: false
    }
  },
  created() {
    this.customId = this.$route.query?.customId
    this.edit = this.$route.params?.edit
  }
}
</script>
<style scoped lang="less">
.information {
  width: 1200px;
  margin: 0 auto;
  &-content {
    margin-bottom: 20px;
    //padding: 24px 218px;
    padding: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);
    cursor: default;
    box-sizing: border-box;
    background-color: #fff;
  }
}
</style>
<style lang="less">
.information {
  .ivu-steps-title {
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .ivu-steps-title {
    margin-top: 3px;
  }
  .ivu-steps-status-process .ivu-steps-title {
    color: #181818 !important;
  }
}
</style>
