// 抹平ES数据差异
import { getFilePath } from '@/common/utils/base'
export function transformEsData(item) {
	// 转换图片
	if( item.mainPicUrl || item.fileId ) {
		item.picture = getFilePath('PS_01',  item.mainPicUrl || item.fileId)
	}
	item.mainPicUrl = item.picture
	item.startBuyNum = item.minPurchaseAmountIdentity
	item.singleLimitNum = item.maxPurchaseAmountIdentity
	// 拼接商品名称
	if (item.nameDisplayType === '1') {
	  const arr = [item.brandName, item.productName, item.generalChineseName, item.unitSpec]
	  item.displayName = arr.join('')
	} else {
	  item.displayName  = item.generalChineseName
	}
	// 商品销售价
	if(item.customerTypePrice && item.customerTypePrice.length) {
	    item.currentPrice = item.customerTypePrice?.at(0)?.price
	  }
	// 润划算商品, 有活动价
	if(item.marketing) {
	  item.activityId = item.marketing.activityId
	  item.activityType = String(item.marketing.activityType)
	  item.activityLabel = item?.marketing?.activityLabel
	  item.activityPrice = item.marketing?.customerTypePrice?.at(0)?.price
	// 普通商品/满减满折商品, 没有活动价
	}
	// else if(item.promotionMarketing && item.promotionMarketing.length) {
	//   item.activityId = item.promotionMarketing?.at(0)?.activityId
	//   item.activityType = String(item.promotionMarketing?.at(0)?.activityType)
	//   item.activityLabel = item?.promotionMarketing?.at(0)?.activityLabel?.at(0)
	// }
}