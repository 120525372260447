<template>
  <div>
    <div v-if="scene === 'info' && qualificationList?.num" class="tips margin-bottom-20">
      <img class="local_icon" src="@/assets/center/warning.svg">
      您有<strong>{{ qualificationList?.num }}</strong>个资质已到期，请及时更新
      <router-link to="/center/certification" class="right">
        <strong>去更新</strong>
      </router-link>
    </div>
    <div v-if="scene === 'enterprise' && refuseNum > 0" class="tips margin-bottom-20">
      <img class="local_icon" src="@/assets/center/warning.svg">
      部分信息/资质未通过，请修改后重新提交
    </div>
    <div v-if="customerInfo?.warnState === 1 || customerInfo?.warnState === 2" class="tips margin-bottom-20">
      <img class="local_icon" src="@/assets/center/warning.svg">
      您有<strong>{{ customerInfo?.lackRequiredVoList.length }}</strong>个资质需补充，请尽快提交
      <router-link :to="{name: 'AddMustCustCert',query: {name: 'AddMustCustCert'}}" class="right">
        <strong>去提交</strong>
      </router-link>
    </div>
    <div class="box change_ender_box">
      <div v-if="enterprises.length > 0" class="change-company">
        <div class="lable">{{ customerInfo?.customName }}</div>
        <img v-if="customerInfo?.controlState === 'frozen'" src="@/assets/center/frozen.svg">
        <div v-else style="display: flex;align-items: flex-end;">
          <img v-if="customerInfo?.state === 'under_auth'" src="@/assets/center/under_auth.svg">
          <img v-if="customerInfo?.state === 'auth'" src="@/assets/center/auth.svg">
          <img v-if="customerInfo?.state === 'un_auth'" src="@/assets/center/un_auth.svg">
        </div>

      </div>
      <div class="addr-content" v-if="currentAddress">
        <img  class="local_icon" src="@/assets/images/detail/map-pin.png" style="margin-right: -6px;">
        <span class="addr">
          {{ currentAddress }}
          <!-- <Icon v-if="addresses?.length > 1" type="md-arrow-dropdown" /> -->
        </span>
        <!-- <span class="oper" @click="gotoAddrManager">
          收货地址管理
          <Icon type="ios-arrow-forward" style="margin-left: -2px;"/>
        </span> -->
      </div>
      <div class="btn-oper">
        <router-link to="/affiliated">
          <div class="relevancy">
            <img class="local_icon" src="@/assets/center/add.svg">
            关联企业
          </div>
        </router-link>
        <div v-if="enterprises.length > 1" class="unbind-enterprise" @click="changeCompany">
          <img class="local_icon" src="@/assets/center/switch.svg" style="width: 13px;">
          切换企业
        </div>
        <div v-if="showUnbindBtn" class="unbind-enterprise" @click="unbindEnterprise">
          <img class="local_icon" src="@/assets/images/detail/link-off.png" alt="" srcset="">
          解绑企业
        </div>

      </div>

      <ComModal :is-show.sync="showModal" title="切换企业" @confirm="modalConfirm">
        <RadioGroup v-model="customerId" class="change-cust">
          <Radio v-for="item in enterprises" :key="item.customerId" :label="item.customerId">
            <div>
              <div class="rd-title">{{ item.customName }}</div>
              <div class="rd-addr">{{ `${item.districtName || '-'}${item.receiveAddr || '-'}` }}</div>
            </div>
          </Radio>
        </RadioGroup>
      </ComModal>
    </div>
  </div>
</template>
<script>
import { obtainCustomerListAPI, switchUserIdentityAPI, getMyCustomerInfoAPI, qualificationMy, getAddrListByCustomerId, switchReceiveAdd } from '@/common/service'
import { setStorageSync } from '@/common/utils/storage'
import { Option } from 'view-design'
import CustomerTag from './CustomerTag.vue'
import ComModal from '@/components/ComModal'
export default {
  name: 'CHangeEnterprise',
  components: {
    Option,
    CustomerTag,
    ComModal
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    scene: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      showModal: false,
      enterprises: [],
      customerId: '',
      addresses: [],
      currentAddress: '',
      qualificationList: null,
      refuseNum: 0,
      authenticationState: null,
      customerInfo: null
    }
  },
  computed: {
    // 我的企业菜单时才显示解绑
    showUnbindBtn() {
      return this.$route.path === '/center/enterprise'
    }
  },
  watch: {
    'currentAddress'(newValue) {
      const info = this.addresses?.find(item => item.label === this.currentAddress)
      if (info) {
        // this.setReceiveAddress(info.receiveAddrId)
      }
    }
  },
  created() {
    this.init()
  },

  
  mounted() {
    this.customerId = this.id
  },
  methods: {
    init(){
      this.getCustomerInfo()
      this.getObtainCustomerList()
      this.getQualification()
    },
    gotoAddrManager() {
      this.$router.push({
        path: '/center/addr'
      })
    },
    formatState(val) {
      const enumObj = {
        'under_auth': '认证中',
        'refuse_auth': '未通过',
        'auth': '已认证'
      }
      return enumObj[val]
    },
    // 切换企业
    async changeCompany() {
      this.showModal = true
    },

    async modalConfirm(closeLoading) {
      const { code } = await switchUserIdentityAPI(this.customerId)
      closeLoading()
      if (code === 200) {
        this.showModal = false
        this.$Message.success("切换企业成功")
        this.init()
      }
      this.getQualification()
      this.$emit('changeCompany')
    },
    handlerClick(val) {
      this.addresses?.forEach(item => {
        item.isCheck = 0
        if (item.receiveAddrId === val) {
          item.isCheck = 1
        }
      })
      const info = this.addresses?.find(item => item.receiveAddrId === val)
      if (info) {
        this.currentAddress = info.label
        this.setReceiveAddress(info.receiveAddrId)
      }
      console.log('handlerClick', val, info)
    },
    // 设置默认地址
    async setReceiveAddress(addressId) {
      try {
        const { code } = await switchReceiveAdd(addressId)
        if (code === 200) {
          this.$message.success('切换地址成功~')
          location.reload()
        }
      } catch (error) {
        this.$Message.warning(error?.data?.message)
      }
    },
    // 获取企业资质信息
    async getQualification() {
      const { code, data } = await qualificationMy()
      if (code === 200 && data?.length > 0) {
        this.qualificationList = data?.[0].qualificationExpiredModel
      } else {
        this.qualificationList = null
      }
    },
    // 获取企业信息
    async getCustomerInfo() {
      const res = await getMyCustomerInfoAPI()
      const { code, data } = res
      if (code === 200) {
        this.authenticationState = data.state
        this.customerInfo = data
        // this.customerInfo.warnState = 1
        // this.customerInfo.lackRequiredVoList = [
        //   {
        //     certificateName: '营业执照正或副本',
        //     certificateCode: null,
        //     category: 'cust',
        //     sortBy: 1,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317241',
        //     diagram: '20230130/78392e45-c2f3-42e2-bbe6-bf3a7de13063.png',
        //     showState: null,
        //     id: 5004609819317241,
        //     tips: '这是提示语666'
        //   },
        //   {
        //     certificateName: '药品经营许可证',
        //     certificateCode: null,
        //     category: 'cust',
        //     sortBy: 3,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317243',
        //     diagram: '',
        //     showState: null,
        //     id: 5004609819317243,
        //     tips: null
        //   },
        //   {
        //     certificateName: '法人委托书',
        //     certificateCode: null,
        //     category: 'cust_agent',
        //     sortBy: 8,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317248',
        //     diagram: 'rym_pub/20231127/c0bcc175-8902-4a35-a228-9142823c9dd2.jpg',
        //     showState: null,
        //     id: 5004609819317248,
        //     tips: '这是提示语dev-请上传法人委托书'
        //   }
        // ]
        this.$set(this, 'customerId', data.customerId)
        this.addresses = data.receiveAddrList?.map(item => {
          item.label = item.receiveDistrict?.provinceName + item.receiveDistrict?.cityName + item.receiveDistrict?.districtName + item?.receiveAddr
          return {
            ...item
          }
        })
        this.addresses = this.addresses?.filter(item => {
          return item.state === 'auth'
        })
        this.refuseNum = data.refuseNum
        this.currentAddress = `${this.customerInfo?.district?.provinceName || ''}${this.customerInfo?.district?.cityName || ''}${this.customerInfo?.district?.districtName || ''}${this.customerInfo?.addressLine || ''}`
        setStorageSync('customerInfo', data)
      }
    },
    // 获取企业列表
    async getObtainCustomerList() {
      const { code, data } = await obtainCustomerListAPI()
      if (code === 200) {
        this.enterprises = data.map((item) => {
          item.value = item.customerId
          item.label = item.customName
          return {
            ...item
          }
        })
      }
    },
    toPage(url, param) {
      const { name, path } = this.$router.history.current
      if (![name, path].includes(url)) {
        this.$router.push({
          path: url,
          query: { param }
        })
      }
    },

    // 解绑企业
    unbindEnterprise() {
      this.$Modal.confirm({
        title: '提示',
        content: '解绑企业之后，将无法查看历史下单数据，是否继续？',
        okText: '确定',
        cancelText: '取消',
        width: 480,
        onOk: () => {
          this.$router.push(`/center/enterprise/unbindEnterprise`)
        },
        onCancel: () => {
          // this.$message.info('Clicked cancel')
        }
      })
    }
  }
}
</script>
<style lang="less">
.tips {
  display: flex;
  height: 40px;
  padding: 9px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--brand-1, #FFF8E8);

  overflow: hidden;
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  text-overflow: ellipsis;
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */

  img{
    margin-right: 8px;
  }

  strong {
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--brand-7, #E67D00);
    text-overflow: ellipsis;

    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 2px;
  }

  .right{
    flex: 1;
    text-align: right;
  }
}
.change-company{
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  .lable{
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
  }

  .select-company {
    flex: 1;
    .ivu-select-arrow{
      right: inherit;
      color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
    }
    .ivu-select-selection{
      display: flex;
      align-items: center;
      border: none;
    }

    .ivu-select-prefix{
      margin-top: 0px !important;
    }
  }
  .ivu-select-selected-value{
    margin-right: 8px;
    font-size: 14px;
    height: 20px !important;
    line-height: 20px !important;
    margin-top: 1px;

    color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
  }
  .company-status {
    display: inline-block;
    margin-left: 5px;
    padding: 2px 4px;
    color: #a35511;
    font-size: 10px;
    background-color: #fdda9f;
    border-radius: 4px;
  }
}
.addr-content{
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .addr{
    color: var(--font_black-2, rgba(0, 0, 0, 0.60));
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */


    display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    -webkit-line-clamp: 2; /* 限制显示两行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
  }

  .oper{
    color: var(--brand-7, #E67D00);
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    cursor: pointer;
  }
}
.relevancy {
  display: flex;
  padding: 5px 12px;
  width: 100px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--3, linear-gradient(332deg, #FF5C17 17.15%, #FFAE35 82.37%));

  color: var(--font_white-0, #FFF);

  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.relevancy:hover {
  cursor: pointer;
  opacity: 0.6;
}

.btn-oper{
  display: flex;
  align-items: center;
  gap: 8px;
}

.unbind-enterprise{
  display: flex;
  padding: 5px 12px;
  width: 102px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--brand-6, #F99D33);

  color: var(--brand-7, #E67D00);

  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 157.143% */
}

.unbind-enterprise:hover{
  cursor: pointer;
  opacity: 0.6;
}

.ivu-select-prefix {
    float: left;
    margin-top: 5px;
  }
.fejs {
  display: block;
  font-size: 10px;
  padding: 0 4px;
  border: 1px solid #349425;
  border-radius: 4px;
  background-color: rgba(52, 148, 37, 0.08);
  color: #1F7C15;
  margin-right: 8px;
}
.auth_style {
  background: rgba(77, 172, 57, 0.1);
  color: #034D00;
}
.unauth_style {
  background: rgba(249, 157, 51, 0.15);
  color: #A35511
}
.mi_auth_style {
  background: rgba(56, 83, 226, 0.1);
  color: #2438BC;
  border: 1px solid #3853E2;
}
.caption-right:hover{
  color: #3853E2 !important;
  cursor: pointer;
}
.ivu-select-single .ivu-select-selection .ivu-select-head-with-prefix.ivu-select-selected-value {
  float: left;
  margin-left: 8px;
  padding-right: 0px;
}

.local_icon {
  width: 14px;
}

.rd-title{
  margin-top: -3px;
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space:normal
}
.rd-addr{
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */

  white-space:normal
}

.ivu-radio-inner{
  margin-right: 6px !important;
}

.change-cust{
  display: flex !important;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  gap: 16px;

}

.ivu-radio-wrapper{
  display: flex !important;
}
</style>
<style>
.change_ender_box{
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-image: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 80.93%, #FFEFE4 99.05%, #FFDED3 102.97%),url('~@/assets/center/cust-bg.png');
  background-repeat: no-repeat, no-repeat;
  background-position: center, right;
  background-size: contain;

  .ivu-select-dropdown{
    left: 420px !important;
  }
}

</style>
