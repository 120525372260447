import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
/**
 * 发起支付
 */
export const getOpenid = (code) =>
  get({
    url: `${api.baseUrl}/v1/wx/code?code=${code}`,
    isLoading: true
  })
/**
 * 获取结算页订单数据
 */
export const getSettleOrderDetail = (data) =>
  post({
    url: `${api.baseUrl}/order/cart/continueSettle`,
    data,
    isLoading: true
  })
/**
 * 创建交易
 */
export const createTrade = (data) =>
  post({
    url: `${api.baseUrl}/order/orderTotalInf/createOrders`,
    data: {
      ...data,
      source: 'PC'
    },
    isLoading: true
  })
/**
 * 获取客户收货人信息
 */
export const getByCustomerId = () =>
  get({
    url: `${api.baseUrl}/cust/custreceiveaddr/getByCustomerId`
  })
/**
 * 获取支付订单详情
 */
export const getOrderDetail = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/orderTotalDetail`,
    data
  })
/**
 * 获取支付订单取消时间
 */
export const getOrderCancelTime = (code) =>
  get({
    url: `${api.baseUrl}/opt/valueSetItem/list?setCode=${code}`
  })
/**
 * 获取客户信息
 */
export const getCustomerId = (data) =>
  post({
    url: `${api.baseUrl}/v1/wx/switchUserIdentity?id=${data.id}&userType=${data.userType}`,
    data
  })
/**
 * 去支付
 */
export const createOrderPay = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/toPay`,
    data
  })
/**
 * 校验订单支付中状态
 */
export const checkOrderPayStatus = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/paying`,
    data
  })
/**
 * 获取分享订单详情
 */
export const getShareOrderDetail = (ids) =>
  get({
    url: `${api.baseUrl}/order/cust/othersPayDetail?orderIds=${ids}`,
    isLoading: true
  })
/**
 * 获取客户发票信息
 */
export const getInvoiceList = () =>
  get({
    url: `${api.baseUrl}/customer/custinvoice/getMyInvoiceList`
  })
/**
 * 获取值集中的值
 */
export const getValMapList = (code) =>
  get({
    url: `${api.baseUrl}/opt/valueSetItem/list?setCode=${code}`
  })
/**
 * 查询客户资信
 */
export const getCustCredit = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/queryCustCredit`,
    data
  })
/**
 * 获取拼团结算页订单数据
 */
export const getGroupOrderDetail = (data) =>
  post({
    url: `${api.baseUrl}/order/cart/confrimSettlement`,
    data
  })
// 获取抽奖次数
export const getCustPaying = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/paying`,
    data
  })
// 查看红包活动
export const getRedPacket = (data) =>
  post({
    url: `${api.baseUrl}/redenvelope/customer/queryByOrgIdList`,
    data
  })

// 查询商家订单是否支持线下支付
export const getOfflinePay = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/queryOfflinePay`,
    data
  })

// 查询下单赠送抽奖次数
export const getOrderLottery = (data) =>
  post({
    url: `${api.baseUrl}/order/orderTotalInf/getOrderLottery`,
    data
  })

// 查询线下对公转账渠道
export const queryCorporateTransfer = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/queryCorporateTransfer`,
    data
  })
// 查询支付渠道
export const queryOrgPayChannelList = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/queryOrgPayChannelList`,
    data
  })
