export default {
  COMMON_LAYOUT: 'common_layout',
  HOME_COMMON_LAYOUT: 'home_common_layout',
  HOME: {
    HOME_PAGE: 'home_page'
  },
  USER: {
    LAYOUT: 'user_layout',
    LOGIN: 'user_login',
    REGIST: 'user_regist',
    FORGET_PWD: 'forget_pwd',
    PRIVACY: 'privacy',
    ANNOUNCE_FILE: 'announce_file',
    MODIFY_MOBILE: 'modify_mobile',
    HELPER_CENTER: 'helper_center',
    UserServiceAgreement: 'userServiceAgreement',
    PrivacyAgreement: 'privacyAgreement',
    FEED_BACK: 'FEED_BACK'
  },
  COMMON_VIEW: 'common_view'
}
