<template>
  <div v-if="friendsLink && Object.keys(friendsLink).length" class="friend-link-wrapper">
    <ContentLayout>
      <span v-if="friendsLink" class="title">{{ friendsLink.mainTitle }}</span>
      <template v-if="friendsLink.subTitle && friendsLink.subTitle.length">
        <span v-for="(item, index) in friendsLink.subTitle" :key="index" class="item">
          <span :href="item.link" @click="cmsLink(item.link)">{{ item.title }}</span>
        </span>
      </template>
    </ContentLayout>
  </div>
</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'
import { cmsLink } from '@/views/home'
export default {
  name: 'FriendsLink',
  components: {
    ContentLayout
  },
  props: {
    friendsLink: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      cmsLink: cmsLink.bind(this)
    }
  },
  methods: {
    openPage(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.friend-link-wrapper {
  // background-color: #E7E7E7 !important;
  height: 26px;
  // padding: 4px 0;
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  .title {
    height: 26px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.90);
    margin-right: 32px;
  }
  .item {
    height: 26px;
    line-height: 26px;
    margin-right: 16px;
    color: rgba(0, 0, 0, 0.40);
    &:hover {
      color: #CE7720;
    }
    span {
      cursor: pointer;
    }
  }
}
</style>
