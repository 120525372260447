<!--分类卡片-->
<template>
  <div v-if="show" :id="'nav' + options.id" class="v-product-card">
    <ContentTitle :title="options?.props?.title" />
    <div class="card-list">
      <div v-for="item in data" :key="item.id" class="card">
        <div class="head" @click="cmsLink.bind(this)(item.link)">
          <span class="title">{{ item.title }}</span>
          <div class="more" @click.stop="more(item)">
            更多
            <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
          </div>
        </div>
        <div class="card-wrap">
          <ProductCard
            v-for="(product, index) in item.records"
            :key="index"
            :goods-item="product"
            :is-org-abbr="false"
            :img-lazy="true"
            :show-add-car="false"
            :cms-position-title="options?.props?.title"
            is-cms
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * styleType  一图四商品: 4 一图五商品: 5 六图八商品:8
 */
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler } from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/ListCard.vue'
export default {
  name: 'VProductCard',
  components: {
    ContentTitle, ProductCard
  },
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
      show: false,
      data: []
    }
  },
  computed: {
    // 获取一图地址
    path() {
      return getFilePath('PS_05', this.options?.props?.fileId)
    },
    styleType() {
      return this.options?.props?.styleType
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const list = this.options?.props?.productsList
      const { leftTopActivityType, rightTopActivityType, leftBottomActivityType, rightBottomActivityType } = this.options?.props
      const activityArr = [leftTopActivityType, rightTopActivityType, leftBottomActivityType, rightBottomActivityType]
      list?.forEach((item, index) => {
        item.activityType = activityArr[index]
        getData.bind(this)(3, item.id, item.type, item.activityType).then((data) => {
          if (data?.length > 0) {
            item.records = data
            if (!this.show) {
              this.$store.commit('setHomeNav', this.options.id)
              this.show = true
            }
            this.setData()
          }
          this.$emit('load', this.options)
        }).catch(() => {
          this.$emit('load', this.options)
        })
      })
    },
    setData() {
      this.data = this.options?.props?.productsList?.filter((item) => item.records?.length > 0) || []
    },
    // 跳更多页
    more(data) {
      const cmsModelId = data.id
      const { type, activityType } = data
      this.$router.push(`/more?type=${type}&cmsModelId=${cmsModelId}&activityType=${activityType || ''}`)
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-product-card {
  width: 1200px;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  .head {
    display: flex;
    align-items: center;
    height: 58px;
    flex-shrink: 0;
    padding: 0 16px;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
    }
    .more {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
      cursor: pointer;
    }
  }
  .card-wrap {
    display: flex;
    height: 100%;
  }
  .card {
    width: 594px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    &:nth-child(1) {
      margin: 0 12px 0 0;
    }
    &:nth-child(2) {
      margin: 0 0 0 0;
    }
    &:nth-child(3) {
      margin: 12px 12px 0 0;
    }
     &:nth-child(3) {
      margin: 12px 0 0 0;
    }
    &:nth-child(4) {
      margin: 12px 0 0 12px;
    }
    &:nth-child(1) .head {
      background: linear-gradient(90.02deg, #F5EBDB 6.18%, #EDD2A9 68.41%), #FFFFFF;
      .title,.more{
        color:#4D1F00;
      }
    }
    &:nth-child(2) .head {
      background: linear-gradient(90.13deg, #D6E8C9 5.2%, rgba(168, 209, 127, 0.56) 75.6%);
      .title,.more{
        color:#334524;
      }
    }
    &:nth-child(3) .head {
      background: linear-gradient(90.13deg, #CBE8E1 5.2%, rgba(141, 207, 203, 0.56) 75.6%);
      .title,.more{
        color:#083676;
      }
    }
    &:nth-child(4) .head {
      background: linear-gradient(90.23deg, #DCE9F5 6.25%, #CDDEF7 75.88%);
      .title,.more{
        color:#334524;
      }
    }
  }
}

// 覆盖商品卡片样式
:deep(.card-wrap) {
  .list-card{
    width: 198px;
    height: auto;
    padding: 16px 12px;
    border-radius: 0;
    &:nth-child(2){
      border-left: 0.5px solid #eee;
      border-right: 0.5px solid #eee;
    }
  }

 .cover-box,.cover-mask{
   width: 120px!important;
   height: 120px!important;
 }
  .price-area-box{
    margin-top: 0;
  }
}
</style>
