<template>
  <div class="compose">
    <Modal v-model="composeModal" width="931" :footer-hide="true" :transfer="false" class-name="vertical-center-modal" @on-cancel="close">
      <template #header>
        <div class="desc">
          <p class="title">{{ activityItem.activityName }}</p>
          <p class="label">{{ activityItem.activityType==42?'自选组合':'固定组合' }} {{ activityItem.combineLabel }}</p>
        </div>
      </template>
      <div class="items">
        <div v-for="(item, index) in activityItem.productVoList" :key="index" class="item" @click="navigateToGood(item)">

          <div v-if="showMask(item)" class="mask">
            <div class="tip">
              <template v-if="CONTROL_MSG[item.reason]">{{ CONTROL_MSG[item.reason] }}</template>
              <template v-else-if="item.stock === 0">无货</template>
              <template v-else>达到限购</template>
            </div>
          </div>
          <span v-if="item.mustBuy==1" class="must">必购</span>
          <div class="img">
            <img :src="item.mainPicUrl?getFilePath('PS_01',item.mainPicUrl):defaultImg" alt="">
          </div>
          <div class="price">
            <span class="new-price">¥<span v-if="item.activityPrice>0">{{ returnFloat(item.activityPrice||0, item.sellPrice) }}</span></span>
            <span class="old-price" v-if="item.sellPrice>0">¥<span>{{ returnFloat(item.sellPrice||0) }}</span></span>
            <span class="old-price" v-else>未定价</span>
          </div>
          <div class="name">
            {{ item.generalChineseName||'' }}
          </div>
          <div v-if="item.expireDate" class="stock">
            有效期至：{{ item.expireDate.substring(0,11) }}
          </div>
          <div class="stock">
            库存：{{ item.stock||0 }}
          </div>
          <div class="check-num">
            <div v-if="activityItem.activityType!=ACTIVITY_TYPE.FIXEDCOMBINATION" class="check">
              <Checkbox v-model="item.checked" :label="item.id" :disabled="item.mustBuy==1||item.stock==0 || item.reason!==0" @click.native.stop="" @on-change="radioChange(item)" />
              选择此商品
            </div>
            <div v-else class="check">
              &nbsp;
            </div>
            <div class="num">x{{ item.num }}</div>
          </div>
        </div>
      </div>
      <div class="compose-footer">
        <div class="left">
          <span class="text">组合价</span>
          <span class="new-price">¥{{ returnFloat(computedPrice.actualPrice||0, 0.01) }}</span>
          <span class="old-price">¥{{ returnFloat(computedPrice.totalPrice||0, 0.01) }}</span>
        </div>
        <div class="right">
          已选
          <InputNumber v-model="activityItem.units" controls-outside :min="1" :max="activityItem.maxBuyUnits" @click.native.stop="combinationKeydown($event,activityItem)" @on-change="changeNum(activityItem)" />
          组
          <span class="add-car" :class="activityItem.unableBuy?'disable-add':''" @click="addCar">加入购物车</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { returnFloat, ACTIVITY_TYPE, getFilePath, mul,div,CONTROL_MSG, add } from '@/common/utils'
import { Message } from 'view-design'
import { addCombineActivityProducts,combineFetch,getQueryProducts, addCartForSelectedProduct, asyncQueryCart } from '@/common/service'
import { mapState, mapMutations } from 'vuex'
import asyncQueryCartMixin from '@/common/mixins/asyncQueryCartMixin'

export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    activityInfo: {
      type: Object,
      default: () => { }
    }
  },
  mixins: [asyncQueryCartMixin],
  data() {
    return {
      getFilePath,
      returnFloat,
      defaultImg: require('@/assets/images/common/good_default.png'),
      composeModal: false,
      activityItem: {
        productVoList: [],
        units: 0,
        maxBuyUnits: null,
      },
      ACTIVITY_TYPE,
      CONTROL_MSG
    }
  },
  computed: {
    ...mapState({
      selectedProductList: state => state.car.selectedProductList
    }),
    computedPrice() {
      const price = {
        totalPrice: 0,
        actualPrice: 0
      }
      this.activityItem.productVoList
        .filter(item => item.checked)
        .reduce((prev, cur) => {
          // 计算原价总金额
          prev.totalPrice=add(prev.totalPrice,mul(cur.sellPrice, cur.num))
          // 计算活动实付金额
          prev.actualPrice=add(prev.actualPrice,mul(cur.activityPrice, cur.num))
          return prev
        }, price)
      return {
        totalPrice: price.totalPrice,
        actualPrice: price.actualPrice
      }
    }
  },
  watch: {
    showPopup(newVal) {
      if (newVal) {
        this.composeModal = newVal
        this.getList();
      }
    }
  },
  methods: {
    showMask(item) {
      // 是否显示遮罩层 控销、库存为0 起购大于库存 或者 起购大于限购
      const { reason, stock, startBuyNum, singleLimitNum } = item
      return CONTROL_MSG[reason] || stock === 0 || startBuyNum > stock || ((singleLimitNum > 0) && (startBuyNum > singleLimitNum))
    },
    checkChooseGoods() {
      let unitsArr = []
      this.activityItem.productVoList.forEach(gItem=>{
        if(gItem.isSingleLimit>0&&mul(gItem.startBuyNum,this.activityItem.units)>gItem.singleLimitNum&&gItem.checked){
          let num = Math.floor(div(gItem.singleLimitNum,gItem.startBuyNum))
          unitsArr.push(num)
        }
      })
      if(unitsArr?.length>0){
        let minUnits = Math.min(...unitsArr)
        if(minUnits<=0){
          this.activityItem.unableBuy=true;
        }else{
          this.activityItem.unableBuy=false;
        }
      }else{
        this.activityItem.unableBuy=false;
      }
    },
    async getList() {
      const {activityId, activityType} = this.activityInfo
      try {
        const res = await combineFetch({params: [activityId]})
        const {data: selectProducts = []} = await getQueryProducts({
          activityId,
          activityType
        })
        this.activityItem = res.data[0]

        if ([ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(activityType)) {
          this.activityItem.productVoList = this.activityItem.productVoList.map(product => {
            return {...product, checked: false, num: product.startBuyNum}
          })
        }
        // 根据选中的商品 为当前的活动商品初始化
        let unitsArr = [];
        this.activityItem.productVoList.forEach(product => {
          const target = selectProducts.find(selected => selected.productId === product.id)
          if (target) {
            product.checked = true
            product.num = target.num
            // 控销商品 或者 0库存的商品 不能被选中
            product.checked = product.reason === 0 && product.stock > 0
            this.activityItem.units = div(product.num, product.startBuyNum)
            if(product.isSingleLimit>0){
              let num = Math.floor(div(product.singleLimitNum,product.startBuyNum))
              unitsArr.push(num)
            }
          }
        })
        if(unitsArr?.length>0){
          let minUnits = Math.min(...unitsArr)
          this.$set(this.activityItem,'maxBuyUnits',minUnits)
        }
        this.checkChooseGoods()
      } catch (e) {
        Message.error(e?.data?.message)
      }
    },
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    navigateToGood(item) {
      // 跳转商品信息
      const activityType = item.activityType == ACTIVITY_TYPE.SPECIAL ? item.activityType : 0
      const activityId = item.activityType == ACTIVITY_TYPE.SPECIAL ? item.activityId : 0
      this.$router.push({
        path: `/detail?productId=${item.id}&activityType=${activityType}&activityId=${activityId}`
      })
    },
    radioChange(item) { // 点击组合活动勾选按钮
      item.checked?this.$set(item, 'checked', true):this.$set(item, 'checked', false)
      if (item.checked) {
        const activityNum = Number(this.activityItem.units)
        // 设置数量
        item.num = mul(activityNum, item.startBuyNum)
      }
      this.checkChooseGoods()
    },
    close() {
      this.composeModal = false
      this.$nextTick(() => {
        this.activityItem = {
          productVoList: [],
          maxBuyUnits: null,
          num: 0
        }
      })
      this.$emit('update:show-popup', this.composeModal)
    },
    saveGoodInfo(res){
      if(this.selectedProductList?.length>0){
        let selectObj = {}
        for(let [sIndex,sItem] of this.selectedProductList?.entries()){
          for(let [aIndex,aItem] of sItem?.activityList?.entries()){
            if(this.activityItem.activityId==aItem.activityId){
              selectObj={
                sIndex,
                aIndex
              }
            }
          }
        }
        let productList=this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList||[]
        let selectProductList = this.activityItem.productVoList.filter(item=>item.checked==true)||[]
        if(selectProductList?.length>0){
          for(let pItem of selectProductList){
            let cIndex=productList?.findIndex(item=>item.productId==pItem.id)
            if(cIndex!=-1){
              this.selectedProductList[selectObj.sIndex].activityList[selectObj.aIndex].productList.splice(cIndex,1,{
                productId: pItem.id,
                activityId: this.activityItem.activityId,
                activityType: this.activityItem.activityType,
                orgId:pItem.orgId,
                num:pItem.num,
                actualPrice:pItem.activityPrice,
                price:pItem.sellPrice,
                checked:true,
                id:res?.data&&res?.data?.find(item=>item.activityId==this.activityItem.activityId&&item.productId==pItem.id).id
              })
            }else{
              this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList?.push({
                productId: pItem.id,
                activityId: this.activityItem.activityId,
                activityType: this.activityItem.activityType,
                orgId:pItem.orgId,
                num:pItem.num,
                actualPrice:pItem.activityPrice,
                price:pItem.sellPrice,
                checked:true,
                id:res?.data&&res?.data?.find(item=>item.activityId==this.activityItem.activityId&&item.productId==pItem.id).id
              })
            }
          }
          console.log('加购后selectedProductList', this.selectedProductList)
          // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.selectedProductList)))
          this.setSelectedProductList(JSON.parse(JSON.stringify(this.selectedProductList)))
        }
      }
    },
    addCar() { // 组合活动加入购物车
      if(this.activityItem.unableBuy){
        return
      }
      let selectedProductList = []
      selectedProductList = this.activityItem.productVoList?.filter(item => item.checked == true)
      if (selectedProductList.length < 2) {
        Message.error('至少勾选两个商品')
        return
      }
      const {activityId, activityType} = this.activityItem
      selectedProductList = selectedProductList.map(item => {
        return {
          productId: item.id,
          orgId: item.orgId,
          num: item.num,
          combineId: activityId,
          activityId: activityId,
          activityType: activityType
        }
      })
      const params = {
        activityId,
        activityType,
        selectedProductList
      }
      addCombineActivityProducts(params).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.getAddCartData(selectedProductList, selectedProductList[0].orgId)
          this.$bus.$emit('getCartListFn')
          this.close()
        }
        // this.saveGoodInfo(res)// 组合活动加入购物车后默认选中组合商品
      }).catch(err => {
        Message.error(err?.data?.message)
      })
    },
    combinationKeydown(e,aItem){
      let addElement = e.target.className.includes('ivu-input-number-controls-outside-up')||e.target.className.includes('ivu-icon-ios-add')
      let minusElement = e.target.className.includes('ivu-input-number-controls-outside-down')||e.target.className.includes('ivu-icon-ios-remove')
      if(addElement||minusElement){
        this.changeNum(aItem)
      }
      if(addElement){
        this.deal='add'
      }else if(minusElement){
        this.deal='plus'
      }else{
        this.deal=''
      }
    },
    changeNum(aItem){//组合活动修改套数
      let activityNum = Number(aItem.units)
      let unitsArr = [];
      this.activityItem.productVoList.forEach(gItem=>{
        if(gItem.isSingleLimit>0&&mul(gItem.startBuyNum,activityNum)>gItem.singleLimitNum&&gItem.checked==true){
          let num = Math.floor(div(gItem.singleLimitNum,gItem.startBuyNum))
          unitsArr.push(num)
        }
      })
      if(unitsArr?.length>0){
        let minUnits = Math.min(...unitsArr)
        this.$set(aItem,'maxBuyUnits',minUnits)
        if(minUnits<1){
          Message.warning('限购已达上限')
        }else{
          Message.warning(`剩余采购${minUnits}套`)
        }
        activityNum = minUnits
        if(activityNum<1){
          activityNum=1;
          this.$set(aItem,'units',activityNum)
          for(let gItem of aItem.productVoList){
            if(gItem.checked==true){
              this.$set(gItem, 'num', mul(activityNum,gItem.startBuyNum))
            }
          }
          return;
        }
      }
      if(activityNum<1){
        Message.warning('1套起购')
        activityNum=1;
      }
      this.$set(aItem,'units',activityNum)
      for(let gItem of aItem.productVoList){
        if(gItem.checked==true){
          this.$set(gItem, 'num', mul(activityNum,gItem.startBuyNum))
        }
      }
    },
    // // 获取添加购物车数据
    // async getAddCartData(addProductList, orgId) {
    //   console.log(addProductList)
    //   let tempObj = null
    //   const arr = this.selectedProductList.filter((item) => item.storeId === orgId)
    //   if (arr && arr.length > 0) {
    //     tempObj = this.getCartFieldData(arr)
    //   }
    //   const prams = {
    //     addProductList: addProductList,
    //     orgId: orgId,
    //     selectedProductList: tempObj?.selectedProductList || [],
    //     presentProductList: [],
    //     cancelProductList: []
    //   }
    //   const res = await addCartForSelectedProduct(prams)
    //   if (res?.code === 200 && res?.data?.storeList && res?.data?.storeList.length > 0) {
    //     const otherStoreList = this.selectedProductList.filter((item) => item.storeId !== orgId)
    //     this.setAsyncQueryCart([...res?.data?.storeList, ...otherStoreList])
    //     this.setSelectedProductList(JSON.parse(JSON.stringify([...res?.data?.storeList, ...otherStoreList])))
    //     this.$bus.$emit('getCartListFn')
    //     this.close()
    //   }
    // },
    // // 获取购物车字段数据
    // getCartFieldData(list) {
    //   let selectedProductList = []
    //   let presentProductList = []
    //   let cancelProductList = []
    //   for (const sItem of list) {
    //     for (const aItem of sItem?.activityList) {
    //       for (const gItem of aItem?.productList) {
    //         const flag = (aItem.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType === ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present === true
    //         if (gItem.selected) {
    //           if (!flag) {
    //             selectedProductList.push({
    //               id: gItem.id,
    //               productId: gItem.productId,
    //               activityId: aItem.activityId,
    //               activityType: aItem.activityType,
    //               orgId: gItem.orgId,
    //               num: gItem.num,
    //               price: gItem.price,
    //               actualPrice: gItem.actualPrice
    //             })
    //           }
    //         } else {
    //           if (!flag) {
    //             cancelProductList.push({
    //               id: gItem.id,
    //               productId: gItem.productId,
    //               activityId: aItem.activityId,
    //               activityType: aItem.activityType,
    //               orgId: gItem.orgId,
    //               num: gItem.num,
    //               price: gItem.price,
    //               actualPrice: gItem.actualPrice
    //             })
    //           }
    //         }
    //         // 31自动换购 32手动换购
    //         if (flag) {
    //           presentProductList.push({
    //             productId: gItem.productId,
    //             activityId: aItem.activityId,
    //             activityType: aItem.activityType,
    //             orgId: gItem.orgId,
    //             selected: gItem.checked,
    //             num: gItem.num,
    //             actualPrice: gItem.actualPrice,
    //             price: gItem.price
    //           })
    //         }
    //       }
    //     }
    //   }
    //   return {
    //     selectedProductList,
    //     presentProductList,
    //     cancelProductList
    //   }
    // },
    // // 设置异步查询购物车
    // async setAsyncQueryCart(storeList) {
    //   const tempObj = this.getCartFieldData(storeList)
    //   const prams = {
    //     selectedProductList: tempObj?.selectedProductList || [],
    //     presentProductList: tempObj?.presentProductList || [],
    //     cancelProductList: tempObj?.cancelProductList || [],
    //   }
    //   await asyncQueryCart(prams)
    // }
  }
}
</script>

<style scoped lang="less">
::v-deep .ivu-modal-wrap {
  .ivu-modal{
    top:0;
  }
  .ivu-modal-content{
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    box-shadow:none;
  }
  .ivu-modal-close{
    display:none;
  }
  .ivu-modal-header {
    width: 931px;
    height: 60px;
    border-bottom: none;
    padding:0;
    background-color: rgba(55, 55, 55, 0.6);
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    background-image:url('~@/assets/images/car/combination_bg.png');
    background-size:100% 100%;
    background-position:top left;
    .desc {
      width: 931px;
      height: 60px;
      display:flex;
      .title{
        display:flex;
        margin-top:20px;
        margin-left:140px;
        color: var(--font-black-1, rgba(0, 0, 0, 0.90));
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
      }
      .label{
        text-align:right;
        margin-top:30px;
        padding-right:32px;
        color: var(--warning-9, #774B04);
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
  .ivu-modal-body{
    padding:24px 32px 32px;
  }
}
::v-deep .vertical-center-modal{
  display: flex;
  align-items: center;
  justify-content: center;
}
.items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    width: 204px;
    background-color: #f5f5f5;
    padding: 18px 14px 14px;
    margin-bottom: 24px;
    margin-right: 16px;
    border-radius: 7.2px;
    position: relative;
    &:nth-child(4n) {
      margin-right: 0px;
    }
    .must {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3.6px 14.4px;
      background-color: #cc9213;
      font-size: 12.6px;
      line-height: 20px;
      color: #fff;
      border-radius: 7.2px 0px;
    }
    .img {
      padding: 0px 25px;
      box-sizing: border-box;
      img {
        width: 126px;
        height: 126px;
      }
    }
    .price {
      margin-top: 9px;
      .new-price {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #cb272d;
        margin-right: 8px;
      }
      .old-price {
        font-size: 10.8px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .name {
      font-size: 14.4px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stock {
      height: 18px;
      font-size: 10.8px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
    }
    .check-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10.8px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.9);
      .check {
        display: flex;
        align-items: center;
        ::v-deep .ivu-checkbox-wrapper {
          .ivu-checkbox {
            margin-right: 0px;
          }
          .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner{
            background-color: #A6A6A6;
            border-color: transparent;
          }
        }
      }
    }
    .reason{
      margin-top: 4px;
      font-size: 12px;
      line-height: 12px;
      color: #F53F3F;
    }
  }
}
.compose-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .left {
    .text {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.9);
      margin-right: 8px;
    }
    .new-price {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #cb272d;
      margin-right: 8px;
    }
    .old-price {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.4);
      text-decoration: line-through;
    }
  }
  .right {
    ::v-deep .ivu-input-number {
      margin: 0 8px;
      .ivu-input-number-input-wrap > .ivu-input-number-input {
        text-align: center;
      }
    }
    .add-car {
      display: inline-block;
      width: 132px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      background: #f99d33;
      border-radius: 6px;
      font-weight: 500;
      font-size: 15px;
      color: #fff;
      cursor: pointer;
      margin-left: 48px;
    }
    .disable-add{
      background: #E7E7E7;
    }
  }
}
.mask{
  position: absolute;
  z-index: 9;
  background: rgba(255,255,255,0.4);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .tip{
    border-radius: 50%;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 87px;
    margin:39px auto;
    background: rgba(0,0,0,0.4);
  }
}
</style>
