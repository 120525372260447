<template>
  <div class="content-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentLayout'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content-layout {
  width: 1200px;
  margin: 0 auto;
}
</style>
