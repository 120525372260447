<template>
  <div>
    <div v-if="show" class="home-helper-wrapper" :style="wrapperStyle">
      <ContentLayout class="home-helper-wrapper-content">
        <template v-if="config && config.botttomLinks || rightImage ||rightImage2">
          <div v-for="(item, index) in config.botttomLinks" :key="index" class="guide-item">
            <h4 v-if="item.mainTitle" class="title">{{ item.mainTitle }} </h4>
            <div v-for="(itemX, indexX) in item.subTitle" class="desc" :key="indexX">
              <span @click="cmsLink.bind(this)(itemX.link)">{{ itemX.title }}</span>
            </div>
          </div>
          <div v-if="rightImage" class="app-code" style="margin-right: 35px;margin-left: auto;">
            <div>
              <img :src="rightImage" alt="">
            </div>
          </div>
          <div v-if="rightImage2" class="app-code">
            <div>
              <img :src="rightImage2" alt="">
            </div>
          </div>
        </template>
      </ContentLayout>
    </div>
    <FriendsLink v-if="config && config.friendLinks" :friends-link="config.friendLinks" />
  </div>

</template>

<script>
import ContentLayout from '@/components/layout/ContentLayout'
import DemoQrCode from '@/assets/home/demo_qr_code.png'
import { getFilePath } from '@/common/utils'
import { getConfigByCode } from '@/common/service'
import FriendsLink from '@/components/home/FriendsLink'
import { cmsLink } from '@/views/home'
export default {
  name: 'HomeHelper',
  components: {
    FriendsLink,
    ContentLayout
  },
  data() {
    return {
      DemoQrCode,
      config: {

      },
      show: false,
      cmsLink: cmsLink.bind(this)
    }
  },
  computed: {
    wrapperStyle() {
      if (!this.show) {
        return {}
      }
      return {
        // background: `url(${getFilePath('orig', this.config?.bgImage?.fileId)})`,
        // backgroundSize: this.config?.bgImage?.extendWidth === 1 ? '100% 100%' : '1200px 100%'
      }
    },
    rightImage() {
      if (!this.config?.rightAdImage?.fileId) {
        return null
      }
      return getFilePath('orig', this.config?.rightAdImage?.fileId)
    },
    rightImage2() {
      if (!this.config?.rightAdImage2?.fileId) {
        return null
      }
      return getFilePath('orig', this.config?.rightAdImage2?.fileId)
    }
  },
  async mounted() {
    const { data } = await getConfigByCode('bottom_link')
    if (data.configContent && Object.keys(data.configContent).length) {
      this.show = true
    }
    this.config = data.configContent || {}
  },
  methods: {
    getFilePath,
    openPage(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home-helper-wrapper {
  background-repeat: no-repeat;
  .home-helper-wrapper-content {
    padding: 32px 0 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .guide-item {
      width: 90px;
      margin-right: 48px;
      h4 {
        margin: 0 0 16px 0
      }
      > * {
        margin-top: 12px;
      }
      span {
        cursor: pointer;
      }
    }
    .app-code {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // padding: 16px 24px;
      gap: 10px;
      width: 160px;
      background: #FFFFFF;
      border-radius: 8px;
       img {
         width: 100%;
        //  height: 123px;
       }
      div {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-top: -18px;
      }
    }
  }
}
.title {
  color: rgba(0, 0, 0, 0.90);
}
.desc {
  color: #383838;
}
</style>
