
import CenterIndex from '@/views/personnal/center'
export default [
  {
    path: '/msg',
    redirect: '/msg/index',
    component: CenterIndex,
    children: [
      {
        path: 'index',
        name: 'MsgList',
        component: () => import('@/views/personnal/msg/index.vue'),
        meta: {
          title: '消息通知'
        }
      }
    ]
  }
]
