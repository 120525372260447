<template>
   <Modal v-model="editShow"  :closable="false" :mask-closable="false" class-name="com-modal" :footer-hide="true">
        <div class="title-content">
            <div class="title">{{title}}</div>
            <img  class="close-icon" src="../assets/user/x.svg" @click="cancel">
        </div>

        <div class="content">
             <slot></slot>
        </div>

        <div class="footer">
            <Button  @click="cancel">取消</Button>
            <Button type="primary" :loading="loading" @click="confirm">确认</Button>
        </div>
    </Modal>
  </template>
  <script>
  export default {
    name: 'ComModal',
    props:{
        isShow:false,
        title:{
          type:String,
          default:'温馨提示'
        }
    },
    data() {
      return {
        editShow: false,
        loading:false
      }
    },
    watch:{
        isShow(){
            this.editShow = this.isShow
        }
    },
    methods: {
        cancel(){
            this.$emit("cancel")
            this.$emit("update:isShow",false)
        },
        confirm(){
            this.loading = true
            this.$emit("confirm",()=>{
              this.loading = false
            })
        }
    }
  }
  </script>
  <style lang="less" scoped>
  
  ::v-deep .com-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  
    .ivu-modal {
      top: 0;
  
      .ivu-modal-content {

        .ivu-modal-body {
            display: flex;
            padding: 20px 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            width: 100%;
            
          .title-content {
            text-align: center;
            display: flex;
            align-items: center;
            width: 100%;
  
            .title {
                color: var(--fontcolor-black-1, rgba(0, 0, 0, 0.90));
                /* medium/16 */
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */

                flex: 1;
                text-align: left
            }
  
            .close-icon {
                cursor: pointer;
            }
          }

          .content {

          }

          .footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            height: 32px;
          }
        }
      }
    }
  }

:deep(.tag){
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

:deep(.refuse_auth){
  border-radius: 4px;
  border: 1px solid var(--error-7, #CB272D);
  background: rgba(203, 39, 45, 0.08);


  color: var(--error-7, #CB272D);
  text-align: right;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}


:deep(.un_auth){
  border-radius: 4px;
  border: 1px solid var(--hbrand-6, #3853E2);
  background: rgba(56, 83, 226, 0.08);


  color: var(--hbrand-7, #2438BC);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

:deep(.auth){
  border-radius: 4px;
  border: 1px solid var(--success-7, #349425);
  background: rgba(52, 148, 37, 0.08);

  color: var(--success-8, #1F7C15);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
  </style>
  