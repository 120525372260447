import { getCartNum } from '@/common/service'
import { Message } from 'view-design'

const state = {
  selectedProductList: null, // 购物车选中商品信息
  carTextNum: 0, // 购物车数量
}

const getters = {
  getCarTextNum(state) {
    return state.carTextNum
  }
}

const mutations = {
  SET_SELECTED_PRODUCT_LIST(state, data) {
    state.selectedProductList = data
  },
  SET_CART_TEXT_NUM(state, data) {
    state.carTextNum = data
  },
}

const actions = {
  // 获取购物车数量
  updateCartNum({ state, commit }, params) {
    getCartNum()
      .then((res) => {
        commit('SET_CART_TEXT_NUM', res.data)
      })
      .catch((error) => {
        if (error?.data?.code == '600' || error?.data?.code == '601' || error?.data?.code == '602' || error?.data?.code == '603') {
          commit('SET_CART_TEXT_NUM', 0)
          return
        }
        Message.error(error?.data?.message)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
