import Vue from 'vue'
import { pullAllPolling, popSetQualification } from '@/common/service/home'
import { getOrderCancelTime } from '@/common/service'

let aptitudePollingTimer = null
let notNotifyTime = ''
const getPollingTime = async() => {
  const res = await getOrderCancelTime('aptitude_polling_time')
  const time = res.data.find((item) => item.itemKey === 'polling_time').itemValue * 1000
  notNotifyTime = res.data.find((item) => item.itemKey === 'not_notify_time').itemValue
  getPollingData(time)
}
const getPollingData = (time) => {
  aptitudePollingTimer = setInterval(() => {
    pullAllPolling().then(result => {
      const data = result.data
      if (result.data) {
        const cert = result.data.qualificationExpiredModel
        if (cert.qualifications.length > 0) {
          // 是否有必要资质
          const isRequired = !!cert.qualifications.find((item) => item.isRequired === 1)
          Vue.prototype.$Modal.info({
            title: '资质到期提醒',
            closable: true,
            content: cert.qualifications.length === 1 ? `您的“${cert.qualifications[0].certName}”已到期，请及时更新` : `您有${cert.qualifications.length}个资质已到期，请及时更新`,
            cancelText: '暂不更新',
            okText: '去更新',
            async onOk() {
              const params = {
                msgType: data.msgType,
                userId: data.userId,
                notNotifyTime: notNotifyTime,
                qualificationIds: []
              }
              for (const i in cert.qualifications) {
                params.qualificationIds.push(cert.qualifications[i].certId)
              }
              params.qualificationIds = params.qualificationIds.join(',')
              const response = await popSetQualification(params)
              if (response.data) {
                getPollingTime()
              }
            },
            onCancel() {
              if (!isRequired) {
                // uni.navigateTo({
                //   url: `/pages/personal/popup/index?data=${encodeURIComponent(JSON.stringify(params))}`,
                // })
              }
            }
          })
        }
      }
    })
  }, time)
}

const clearAptitudePollingTimer = () => {
  clearInterval(aptitudePollingTimer)
  aptitudePollingTimer = null
}

export {
  getPollingTime,
  clearAptitudePollingTimer
}
