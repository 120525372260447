const state = {
  refreshHome: true, // 是否刷新首页
  disassociationState: false, // 设置关联状态
  authFailInfo: ''
}
const getters = {
  disassociationState(state){
    return state.disassociationState
  }
}
const mutations = {
  setLoginOutRefresh(state) {
    state.refreshHome = !state.refreshHome
  },
  setDisassociationState(state, data) {
    state.disassociationState = data
  }
}
const actions = {
}

export default {
  state,
  mutations,
  actions,
  getters
}
