// 所商品
<template>
  <div v-if="list?.length> 0" :id="'nav' + options.id" class="v-many-product">
    <div class="head">
      <template v-if="hasTitle !== '2'">
        <img v-if="isIconTitle" class="icon-title" :src="getFilePath('PS_01', options?.props?.titleIcon)" alt="">
        <ContentTitle v-else :title="options?.props?.title" />
        <div class="more" @click="more">
          更多
          <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
        </div>
      </template>
    </div>
    <div class="row-list">
      <ProductCard
        v-for="(item, index) in list"
        :key="index"
        :goods-item="item"
        :is-org-abbr="false"
        class="list_item"
        :img-lazy="true"
        is-cms
        :cms-position-title="options?.props?.title"
        :show-add-car="false"
        show-platform-tag
        @click="detaiHandler(item, this.options.props.type)"
      />
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler } from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/ListCard.vue'
export default {
  name: 'VManyProduct',
  components: {
    ContentTitle, ProductCard
  },
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: []
    }
  },
  computed: {
    hasTitle() {
      return this.options?.props?.hasTitle ?? ''
    },
    isIconTitle() {
      const { titleType, titleIcon } = this.options?.props
      return titleType === '2' && titleIcon
    }

  },
  watch: {
    list: {
      handler(val) {
        if (val.length > 0) { this.$store.commit('setHomeNav', this.options.id) }
      },
      deep: true
    }
  },
  created() {
    try {
      getData.bind(this)(this.options.props?.styleType).then(() => {
        this.$emit('load', this.options)
      }).catch(() => {
        this.$emit('load', this.options)
      })
    } catch (error) {
      this.$emit('load', this.options)
    }
  },
  methods: {
    // 跳更多页
    more(data) {
      const cmsModelId = this.cmsId + '_' + this.options.id
      const { type, activityType } = this.options?.props
      this.$router.push(`/more?type=${type}&cmsModelId=${cmsModelId}&activityType=${activityType || ''}`)
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-many-product {
  width: 1200px;
  // margin: 0 auto;
}
.row-list {
  display: flex;
  flex-wrap: wrap;
  gap:12px;
  .list_item {
    //margin-right: 15px;
    //margin-top: 24px;
    &:nth-child(-n + 5) {
      margin-top: 0px;
    }
  }
}
.head {
  display: flex;
  align-items: center;
  .icon-title{
    margin-bottom: 16px;
    height: 32px;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #181818;
    cursor: pointer;
    width: 60px;
    margin-bottom: 24px;
  }
}
::v-deep .root {
  margin-top: 0;
}
</style>
