<template>
  <Modal 
    v-model="show" 
    title="新增采购计划" 
    @on-cancel="cancel"
    >
    <div class="upload-section">
      <div class="fail-content" v-if="failDetailPath">
        <span><span style="color: red;">*</span>导入失败</span>
        <a v-if="failDetailPath" :href="failDetailPath" target="downloadFile" class="down-load export">
          <svg-icon name="red-download"></svg-icon>
          下载失败文件.xlsx
        </a>
      </div>
      <div class="plan-name-input">
        <span style="color: red;">*</span><span>采购计划</span>
        <Input 
          v-model="purchasePlanName" 
          placeholder="请输入" 
          show-word-limit
          maxlength="30"/>
      </div>     
      <Upload 
        ref="upload" 
        action="/apis/rym-file/uploadTempFile" 
        type="drag"
        :show-upload-list="false" 
        :format="['xlsx', 'xls']"
        :on-format-error="handleFormatError" 
        :on-success="handleSuccess" 
        :on-error="handleError"
        :before-upload="handleBeforeUpload" 
        :headers="headers">
        <div class="upload-btn">
          <!-- <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon> -->
          <svg-icon name="upload" className="upload-icon"></svg-icon>
          <span class="upload-text">将文件拖到此处，或<a>点击上传</a></span>
          <span style="font-size: 12px;color: rgba(0, 0, 0, 0.4);">文件支持xlsx格式，大小不超过10M</span>
        </div>
      </Upload>
      <ul class="upload-list">
        <li class="file-item" v-for="item in uploadFileList" :key="item.uid">
          <template v-if="item.status === 'finished'">
            <span class="file-name">{{ item.response.data.originalFileName }}</span>
            <img @click="handleRemove(item)" class="del-icon" src="@/assets/images/order/del-icon.png" alt="">
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
          </template>
        </li>
      </ul>
      
    </div>
    <template #footer>
      <div class="fail-modal-footer">
        <span class="tip">
          <a href="/xlstemplate/导入采购计划.xlsx" target="downloadFile" class="down-load">
            <svg-icon name="download" className="download-icon"></svg-icon>
            模版下载
          </a>       
        </span>
        <Button type="default" @click="cancel">取消</Button>
        <Button type="primary" :loading="createPlanLoading" @click="ok"  :disabled="purchasePlanName=='' || uploadFileList?.length<=0">确认</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { getToken } from '@/common/utils'
import { importPurchasePlan} from '@/common/service'
export default {
  name: 'UploadFile',
  props: {
    btnDisabled: {
      type: Boolean,
      default: false
    },
    modalShow:{
      type:Boolean,
      default:false
    },
  },
  components: {

  },
  data() {
    return {
      show:false,
      purchasePlanName:'',
      createPlanLoading:false,
      failDetailPath:'',
      uploadFileList: [],
      headers: {
        Authorization: getToken()
      }
    }
  },
  computed: {
  },
  watch: {
    modalShow(val){
      if(val){
        this.show = val;
        this.purchasePlanName = '';
        this.uploadFileList = [];
        this.$refs.upload.fileList = [];
        this.failDetailPath = '';
      }
    }
  },
  methods: {
    
    handleFormatError(file) {
      this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传.xls,.xlsx文件。')
    },
    handleSuccess(res, file) {
      console.log(file, this.$refs.upload.fileList)
      this.$Message.success("数据导入成功！")
      this.uploadFileList = this.$refs.upload.fileList
      
    },
    handleError(error, file) {
      this.$Message.error("数据导入失败！")
      this.uploadFileList = this.$refs.upload.fileList
    },
    handleRemove(file) {
      let ids = this.$refs.upload.fileList.map(item => item.response.data.fileId) || []
      this.$refs.upload.fileList.splice(ids.indexOf(file.response.data.fileId), 1);
      this.uploadFileList = this.$refs.upload.fileList
    },
    handleBeforeUpload() {
      const check = this.$refs.upload.fileList.length < 1;
      if (!check) {
        this.$Message.warning("最多上传1个文件。");
      }
      return check;
    },
    async ok() {
      const params = {
        fileId: this.uploadFileList[0]?.response?.data.fileId,
        purchasePlanName:this.purchasePlanName
      }
      this.createPlanLoading = true
      try {
        let res = await importPurchasePlan(params)
        this.createPlanLoading = false
        if ( res.code == 200 ) {
          this.$message.success('导入成功')
          this.show = false;
          this.$emit('uploadSuccess')
          this.$emit('update:modalShow',false)
        } else {
          // 导入失败 下载失败明细
        }       
      } catch (error) {
        console.log(error,'error')
        if(error?.data?.code == 500 ) {
          this.failDetailPath =  error?.data?.data?.path
        }
        this.createPlanLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    cancel() {
      this.$nextTick(()=>{
        this.show = false;
        this.$emit('update:modalShow',false)
      })
    }
  }
}
</script>
<style scoped lang="less">
:deep(.ivu-modal-header){
  border-bottom: none;
  padding: 20px 16px 0;
}
:deep(.ivu-modal-footer){
  border-top: none;
  .tip { 
    position: absolute;
    left: 16px;
    bottom: 16px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    .download-icon {
      width: 20px;
      height: 20px;
    }
    .down-load {
      color: var(--hbrand-6, #3853E2);

      &:hover {
        cursor: pointer;
      }
    }

    .export {
      margin-left: 10px;
    }
  }
  .ivu-btn-primary[disabled] {
    background: var(--brand-3, #FDDA9F);
    color: #fff;
    border-color: #fdda9f;
  }

}
.upload-section {
  .fail-content {
    height: 32px;
    background: rgba(255, 236, 232, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
    a {
      color: rgba(56, 83, 226, 1);
    }
    .export {
      color: rgba(203, 39, 45, 1);
    }
  }
  .plan-name-input {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .ivu-input-wrapper {
      margin-left: 16px;
      width: 410px;
    }
  }
  .ivu-upload {
    margin-top: 16px;
    background: rgba(250, 250, 250, 1) !important
  }
  
  .upload-btn {
    padding: 32px;
    height: 160px;
    &:hover {
      cursor: pointer;
    }
    .upload-icon {
      width: 40px;
      height: 40px;
    }
    .upload-text {
      color: var(--font-black-1, rgba(0, 0, 0, 0.60));
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: block;
    }
  }

  .upload-list {
    .file-item {
      margin-top: 12px;
      // width: 324px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file-name {
        color: var(--font-black-2, rgba(0, 0, 0, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .del-icon {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: var(--gray-15, #F5F5F5);
      }
    }
  }
}
</style>
