const state = {
  show: false,
  data: null,
}
const getters = {
  
}
const mutations = {
  setCmsModalData(state, data) {
    // uni.hideTabBar();
    state.data = data
    state.show = true
  },
  hideCmsModal(state) {
    state.data = null
    state.show = false
    setTimeout(()=> {
      // uni.showTabBar()
    }, 150)
  }
}
const actions = {

}

export default {
  state,
  mutations,
  actions
}