<template>
  <div v-if="!loading" :id="'nav' + options.id" class="v-hot-product-recommend">
    <div class="card-list">
      <div v-for="(item, index) in productData" :key="item.id+index" :class="['card', 'card-bg' + (index + 1)]">
        <div :class="['head-wrap'+(index+1)]">
          <div class="head">
            <span class="title line-clamp-1">{{ item.orgName }}</span>
            <div class="more" @click.stop="more(item)">
              <span>更多</span>
              <Icon style="margin-left:2px" type="ios-arrow-forward" />
            </div>
          </div>
          <div :class="['second-title', 'second-bg' + (index + 1)]">
            {{ item.title }}
          </div>
        </div>

        <div class="card-wrap">
          <template v-if="item.records?.length > 0">
            <div v-for="(item, index) in item.records" :key="index" class="product-item" @click="detaiHandler(item, '1')">
              <img :src="formatPath(item) || defaultPath" alt="" class="img">
              <div class="content">
                <p class="product-name line-clamp-2" style="color:rgba(0, 0, 0, 0.90)">
                  <img v-if="item?.isMedical" class="platform-tag" src="~@/assets/images/global/medicalL.png" alt="" style="width: 32px;height: 16px">{{ productNamePrefix(item) }}{{ productName(item) }}</p>
                <PriceArea :goods-item="item" />
              </div>
            </div>
          </template>
          <div v-else class="no-product">
            <img src="~@/assets/images/home/no-product-recommend.png" alt="">
            <p>暂无爆品推荐</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { detaiHandler } from '@/views/home/index'
import { getProductByEs } from '@/common/service'
import { transformEsData } from '@/common/utils'
import defaultPath from '@/assets/images/common/good_default.png'
import PriceArea from '@/components/priceArea/index.vue'

export default {
  name: 'VMarket',
  components: {
    PriceArea
  },
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      loading: true,
      productData: [],
      defaultPath
    }
  },
  computed: {

  },
  watch: {
    productData: {
      handler(val) {
        if (val.length > 0) { this.$store.commit('setHomeNav', this.options.id) }
      },
      deep: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const list = this.options?.props?.productsList
      const base_params = {
        order: 'asc',
        sort: 'saleNum',
        requestType: 1,
        page: {
          pageNumber: 1,
          pageSize: 3
        }
      }
      const params = list.map((item) => Object.assign({}, base_params, { orgId: [item.orgId] }))
      const promiseList = params.map((param) => getProductByEs(param))
      Promise.all(promiseList).then((res) => {
        const data = res.map((item) => item.data.records || [])
        data.forEach(list => {
          list?.forEach(item => {
            if (item.custState === 'auth') {
              this.isInster = true
            } else {
              this.isInster = false
            }
            transformEsData(item)
            item._uid = item.id + item.activityId
          })
        })
        list.forEach((item, index) => {
          item.records = data[index]
        })
        // 把空的替换成有产品的
        list.forEach((item, index) => {
          if (item.records.length === 0) {
            if (index === 0) {
              const replaceItem = list.find(d => d.records.length > 0) || []
              list[index] = replaceItem
            }
            if (index > 0) {
              list[index] = list[index - 1]
            }
          }
        })
        this.loading = false
        this.setData()
        setTimeout(() => {
          this.$emit('load', this.options)
        }, 100)
      }).catch(() => {
        setTimeout(() => {
          this.$emit('load', this.options)
        }, 100)
        this.loading = false
      })
    },
    setData() {
      this.productData = this.options?.props?.productsList?.filter((item) => item.records?.length > 0) || []
    },
    // 跳更多页
    more(data) {
      const orgId = data.orgId
      this.$router.push(`/shopHome?orgId=${orgId}`)
    },

    formatPath(item) {
      const fileId = item?.fileId || item?.picture || item?.mainPicUrl
      return getFilePath('PS_01', fileId)
    },

    productNamePrefix(item) {
      const { marketing, productUnit } = item
      if (marketing?.activityType === 6) {
        return `${marketing.minPurchaseAmountIdentity}${productUnit}包邮`
      }
      return ''
    },
    productName(item) {
      const product = item
      if (!product) {
        return
      }
      const { nameDisplayType, brandName, generalChineseName, unitSpec, productName } = product

      if (nameDisplayType + '' === '1') {
        // 品牌名+' '+商品名+' '+通用名+包装规格
        return `${brandName || ''} ${productName || ''} ${generalChineseName} ${unitSpec}`.trim()
      }
      if (nameDisplayType + '' === '0') {
        return generalChineseName || productName
      }
      if (this.isGiftGood) {
        return productName
      }
      return generalChineseName
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-hot-product-recommend {
  width: 1200px;
}
.card-list {
  display: flex;
  gap: 8px;
}
.card {
  flex: 1;
  padding: 16px 12px 12px 12px;
  border-radius: 8px;
}
.card-bg1 {
  background: url('~@/assets/images/home/hot-product1.png') no-repeat;
  background-size: cover;
}
.card-bg2 {
  background: url('~@/assets/images/home/hot-product2.png') no-repeat;
  background-size: cover;
}
.card-bg3 {
  background: url('~@/assets/images/home/hot-product3.png') no-repeat;
  background-size: cover;
}
.card-wrap {
  height: 337px;
  padding: 0 12px;
  border-radius: 8px 8px 0 0;
  background-color:#fff ;
  overflow: hidden;
  .product-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0;
    cursor: pointer;
    &:nth-child(1),&:nth-child(2) {
      border-bottom: 0.5px solid #e7e7e7;
    }
    .img {
      width: 88px;
      height: 88px;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .activity-price {
      color: #CB272D;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .activity-unit {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 2px;
    }

    .sell-price {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: line-through;
    }

    .product-name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .platform-tag{
      height: 18px;
      width: auto;
      margin-right: 4px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}
.no-product {
  img{
    width: 120px;
    height: 136px;
  }
  p{
    margin-top: 6px;
    color: var(--font_black-2, rgba(0, 0, 0, 0.60));
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 337px;
}

.head-wrap1{
  color:#6A4A1B
}
.head-wrap2{
  color:#4A4453
}
.head-wrap3{
  color:#334524
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
  .title {
    width:80%;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
  .more {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }
}
.second-title {
  display: inline-block;
  border-radius: 0px 12px 12px 12px;
  padding: 0px 8px;
  margin-left: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.second-bg1 {
  background: #edd2a9;
}
.second-bg2 {
  background: #decaf9;
}
.second-bg3 {
  background: #afe6de;
}

</style>
