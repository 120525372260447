// 处理通用dialog功能
import { getCmsDialogConfig, updateCmsDialogNum, getPublicStatus } from '@/common/service'
const config = {
	app_home: {
		useId: true
	},
	plat_activity_home: {
		useId: true
	},
	merchant_home: {
		useId: true
	},
	merchant_activity_home: {
		useId: true
	},
	navigation_home: {
		useId: true
	}
}
export default {
	data() {
		return {
			
		}
	},
	mounted() {
		if( this.dialogCode ) {
			const useId = this.dialogCode && config[this.dialogCode]?.useId
			if( !useId  ) {
				this.$nextTick(()=> {
					this.getDialogConfig(this.dialogCode)
				})
			}
		}
	},
	computed: {
	},
	methods: {
		async getDialogConfig(code, id) {
			if( !code ) return
			const {data} = await getCmsDialogConfig(code, id)
			if( data?.length> 0 ) {
				for( let i = 0 ; i< data.length ; i++) {
						await this.cmsDialogShow(data[i])
				}
			}
		},
		async cmsDialogShow(data) {
			return new Promise((res, rej)=> {
					setTimeout(()=> {
						data.ok = (data)=> {
							console.log(data, data);
							this.$store.commit('hideCmsModal')
							if( data.btnLink ) {
								this.cmsDialogLink(data.btnLink)
								return
							}
							res()
						}
						data.close = (data)=> {
							this.$store.commit('hideCmsModal')
							res()
						}
						this.$store.commit('setCmsModalData', data)
						updateCmsDialogNum({settingId: data.id})
					}, 2000)
			})
		},
		cmsDialogDetaiHandler(item, type) {
		  // type： 1 代表商品，跳转到商品详情页
		  if( type == '1' ) {
			  let activityId = item.activityId || 0
			  let activityType = item.activityType || 0
			  if( activityType != '2' ) {
				  activityId = 0
				  activityType= 0
			  }
			  this.$router.push(`/detail?productId=${item.productId || item.id}&activityId=${activityId}&activityType=${activityType}`)
		  } else {
			  // type： 2 代表标品，跳转到搜索结果页
				let baseProductId = JSON.stringify([item.id])
				this.$router.push(`/pages/product/search/result?baseProductId=${baseProductId}`)
		  }
		},
		async getPublicStatusFun(data) {
			const {data: datasource} = await getPublicStatus(data)
			if( datasource == 'issue'  )
				return true
		},
		async cmsDialogLink(data) {
			if( !data ) return 
			if( data.key == "product" ) { // 商品
				let value =  data.value && data.value.at(0)
				// 商品链接
				value && this.cmsDialogDetaiHandler(value, '1')
			} else if( data.key == "cattegoried"  ) { // 商品分类
				// 商品分类
				let value =  data.value
				value && console.log(`/pages/product/search/result?categoryId=${value}`)
				this.$router.push(`/pages/product/search/result?categoryId=${value}`)
			} else if (data.key == "other") { // 其它
				let value =  data.value && data.value.at(0)
				// other, 这地比较奇葩，产品要求配置一个小程序全链接，可以直接跳转。
				this.$router.push(value)
			} else if(data.key == "activityTheme" ) { // 活动专题
				const pageId = data?.value?.at(0)?.id
				if( pageId ) {
					let status = await this.getPublicStatusFun({id: pageId, type: 'plat_activity'})
					if( status ) {
						this.$router.push(`/activity?pageId=` + pageId)
					} else {
						// 未上架跳转到默认页面
						this.$router.push(`/pages/common/ActivityOver`)
					}
				}
			} else if( data.key == 'platformFile' ) { // 文件
				const id = data?.value?.at(0)?.id
				if( id ) {
					let status = await this.getPublicStatusFun({id, type: 'plat_file'})
					if( status ) {
						this.$router.push(`/pages/explain/notice/component/certificatesInfoDetail?id=` + id)
					} else {
						console.log(this)
						this.$parent.$refs.toast?.showToast({
							toastTitle: '跳转链接无效',
							toastIcon: '/static/tabBar/home/info.png',
							showStatus: true,
						})
					}
				}
			} else if( data.key == 'announce' ) { // 公告
				const id = data?.value?.at(0)?.id
				console.log(id, 'id')
				if( id ) {
					let status = await this.getPublicStatusFun({id, type: 'notice_list'})
					if( status ) {
						this.$router.push(`/pages/explain/notice/component/noticeDetail?id=` + id)
					} else {
						this.$parent.$refs.toast?.showToast({
							toastTitle: '跳转链接无效',
							toastIcon: '/static/tabBar/home/info.png',
							showStatus: true,
						})
					}
				}
			} else if ( data.key == 'help' ){ // 帮助中心
				const id = data?.value?.at(0)?.id
				this.$router.push('/helperCenter?id=' + id)
			}
		},
	},
	watch: {
		cmsDialogId (val) {
			if( val || val == null) {
				const useId = this.dialogCode && config[this.dialogCode]?.useId
				if( this.dialogCode && useId) {
					this.$nextTick(()=> {
						this.getDialogConfig(this.dialogCode, val)
					})
				}
			}
		}
	}
}