import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 收藏列表、删除收藏接口
 */
export const listCollectProductToEs = (data) => post({
  url: `${api.baseUrl}/prd/product/listCollectProductToEs`,
  data,
})

export const deleteProductCollect = (data) => post({
  url: `${api.baseUrl}/prd/product/deleteProductCollect`,
  data,
})
