<template>
  <Spin fix v-if="isLoading" class="wrap" :class="numClass">
    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
    <div v-if="showTxt">加载中...</div>
  </Spin>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    showTxt:{
      type: Boolean,
      default: true
    },
    type:{
      type: String,
      default: ''
    },
    deal:{
      type: String,
      default: ''
    }
  },
  name:'',
  computed:{
    numClass(){
      if(this.type=='num'){
        if(this.deal=='add'){
          return 'add-wrap'
        }else if(this.deal=='plus'){
          return 'plus-wrap'
        }else{
          return 'deal-wrap'
        }
      }else{
        return ''
      }
    }
  },
  data(){
    return {
       
    }
  }
}
</script>
<style scoped lang='less'>
.wrap{
  width:100%;
  height:calc(100vh);
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  z-index: 9999;
}
.add-wrap{
  width:24px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:88px;
  top:5px;
}
.plus-wrap{
  width:24px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:0;
  top:5px;
}
.deal-wrap{
  width:64px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:24px;
  top:5px;
}
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
</style>