<template>
  <div class="goods-change">
    <Modal v-model="goodsChangeModal" :footer-hide="true" :transfer="false" class-name="vertical-center-modal" @on-visible-change="modalClose">
      <template #header>
        商品变化提醒
      </template>
      <div v-if="batchVerifyList&&Object.keys(batchVerifyList).length>0">
        <div class="price-change">
          <div class="text">以下商品库存数量有变动</div>
          <div v-for="(item, key) in batchVerifyList" :key="key">
            <div class="price-change-box batch-box">
              <div class="good-wrapper">
                <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                  <div class="img-box" >
                    <img :src="pItem.productLogo?getFilePath('PS_01',pItem.productLogo):defaultImg" alt="">
                    <span v-if="pItem.activityType==ACTIVITY_TYPE.CYCLEMINUS||pItem.activityType==ACTIVITY_TYPE.LADDERMINUS||pItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||pItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS" class="tag full-fold">满减</span>
                    <span v-if="pItem.activityType==ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||pItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE)&&pItem.expandMap.present==true" class="tag replacement">换购品</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||pItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE)&&pItem.expandMap.present!=true" class="tag full-fold">满换</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||pItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL)&&pItem.expandMap.present==true" class="tag replacement">赠品</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||pItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL)&&pItem.expandMap.present!=true" class="tag full-fold">满赠</span>
                    <img v-if="pItem.activityType==ACTIVITY_TYPE.SPECIAL" class="calculate" src="@/assets/images/trade/calculate.png" alt="">
                  </div>
                  <span class="name">{{ pItem.productName?pItem.productName:'' }}</span>
                </div>
              </div>
              <div class="change-tip">剩余可售库存:{{ item.totalStock }},请修改加购数量</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="batchLimitList&&Object.keys(batchLimitList).length>0">
        <div class="price-change">
          <div class="text">以下商品限购数量有变动</div>
          <div v-for="(item, key) in batchLimitList" :key="key">
            <div class="price-change-box batch-box">
              <div class="good-wrapper">
                <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                  <div class="img-box">
                    <img :src="pItem.productLogo?getFilePath('PS_01',pItem.productLogo):defaultImg" alt="">
                    <span v-if="pItem.activityType==ACTIVITY_TYPE.CYCLEMINUS||pItem.activityType==ACTIVITY_TYPE.LADDERMINUS||pItem.activityType==ACTIVITY_TYPE.SING_CYCLE_MINUS||pItem.activityType==ACTIVITY_TYPE.SING_LADDER_MINUS" class="tag full-fold">满减</span>
                    <span v-if="pItem.activityType==ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||pItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE)&&pItem.expandMap.present==true" class="tag replacement">换购品</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||pItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE)&&pItem.expandMap.present!=true" class="tag full-fold">满换</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||pItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL)&&pItem.expandMap.present==true" class="tag replacement">赠品</span>
                    <span v-if="(pItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||pItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL)&&pItem.expandMap.present!=true" class="tag full-fold">满赠</span>
                    <img v-if="pItem.activityType==ACTIVITY_TYPE.SPECIAL" class="calculate" src="@/assets/images/trade/calculate.png" alt="">
                  </div>
                  <span class="name">{{ pItem.productName?pItem.productName:'' }}</span>
                </div>
              </div>
              <div class="change-tip">剩余限购数量:{{ item.productResidual<0?0:item.productResidual }},请修改加购数量</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activityLimit&&Object.keys(activityLimit).length>0">
        <div class="price-change batch-box">
          <div class="text">以下商品限购数量有变动</div>
          <div v-for="(item, key) in activityLimit" :key="key">
            <div class="price-change-box">
              <div class="good-wrapper">
                <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                  <div class="img-box">
                    <img :src="pItem.productLogo?getFilePath('PS_01',pItem.productLogo):defaultImg" alt="">
                    <span class="tag replacement" v-if="pItem.activityType==ACTIVITY_TYPE.AUTOEXCHANGE||pItem.activityType==ACTIVITY_TYPE.MANUALEXCHANGE">换购品</span>
                    <span class="tag replacement" v-if="pItem.activityType==ACTIVITY_TYPE.GIFT_AUTO||pItem.activityType==ACTIVITY_TYPE.GIFT_MANUAL">赠品</span>
                  </div>
                  <span class="name">{{ pItem.productName?pItem.productName:'' }}</span>
                </div>
              </div>
              <div class="change-tip">活动限制{{item.productList[0].activityType==ACTIVITY_TYPE.AUTOEXCHANGE||item.productList[0].activityType==ACTIVITY_TYPE.MANUALEXCHANGE?'换购':'赠品'}}数量:{{ item.maxPurchase||0 }},请修改加购数量</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="changeGoodsList&&Object.keys(changeGoodsList).length>0">
        <div v-for="(item, key) in changeGoodsList" :key="key" class="price-change">
          <div class="text">{{ getTipTitle(key).tipTxt }}</div>
          <div class="price-change-box">
            <div v-for="(pItem, pIndex) in item.products" :key="pIndex" class="item">
              <div class="img-box">
                <img :src="pItem.productLogo?getFilePath('PS_01',pItem.productLogo):defaultImg" alt="">
                <div class="buy-num" v-if="key==='startLimitChange'||key==='limitChange'">
                  <span>{{ pItem.num }}</span>
                </div>
                <div class="buy-tip" v-if="key==='stockOut'||key==='soldOut'||key==='noPurchase'">
                  <span>{{ getTipTitle(key).tip }}</span>
                </div>
              </div>
              <div class="name">{{ pItem.productName?pItem.productName:'' }}</div>
              <div v-if="key==='priceChange'" class="price">¥{{ returnFloat(pItem.actualPrice) }}</div>
              <span v-if="key==='stockChange'" class="number">x{{ pItem.availableNum }}</span>
              <div v-if="key==='startLimitChange'" class="quantum">起购数量：{{ pItem.startBuyNum }}</div>
              <div v-if="key==='limitChange'" class="quantum">{{getTip(pItem)}}</div>
              <div v-if="key==='unpriced'" class="price">未定价</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <span class="back-car" @click="close">返回购物车</span>
        <span v-if="!cannotSettle&&!isNeedGoBack" class="continue" @click="goSettle">{{ btnRightText }}</span>
        <!-- <span class="continue" @click="goSettle">{{ btnRightText }}</span> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import {returnFloat, ACTIVITY_TYPE, getFilePath, getLimitInfo } from '@/common/utils'
import { mapMutations } from 'vuex'
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    checkGoodType: {
      type: String,
      default: '' // goodChange goodPackageMail
    },
    batchVerifyList: {
      type: Object,
      default: () => { }
    },
    changeGoodsList: {
      type: Object,
      default: () => { }
    },
    validCartList: {
      type: Array,
      default: () => []
    },
    originBatchVerifyList: {
      type: Object,
      default: () => { }
    },
    batchLimitList:{
      type:Object,
      default:()=>{}
    },
    activityLimit: {
      type: Object,
      default: () => { }
    },
    originActivityLimit: {
      type: Object,
      default: () => { }
    },
    changePackageMailList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      defaultImg: require('@/assets/images/common/good_default.png'),
      goodsChangeModal: false,
      checkType: '',
      getFilePath,
      ACTIVITY_TYPE: ACTIVITY_TYPE,
      returnFloat: returnFloat
    }
  },
  computed: {
    isNeedGoBack() {
      return this.batchVerifyList && Object.keys(this.batchVerifyList).length > 0 || (this.activityLimit && Object.keys(this.activityLimit).length > 0)||(this.batchLimitList&&Object.keys(this.batchLimitList).length>0)
    },
    // btnLeftText() {
    //   return this.isNeedGoBack || this.checkType === 'goodPackageMail' ? '返回购物车' : '取消'
    // },
    btnRightText() {
      return '继续结算'
    },
    cannotSettle() {
      if (this.checkType === 'goodPackageMail') {
        for (const key in this.changePackageMailList) {
          if (key === 'delivery') {
            let num = 0
            const arrIds = []
            if (this.validCartList?.length > 0) {
              for (const sItem of this.validCartList) {
                for (const aItem of sItem?.activityList) {
                  for (const gItem of aItem?.productList) {
                    if (gItem.checked == true && !arrIds.includes(sItem.storeId)) {
                      num++
                      arrIds.push(sItem.storeId)
                      break
                    }
                  }
                }
              }
            }
            const flag = this.changePackageMailList[key].every(item => item.allowed == true)
            // console.log('num',this.changePackageMailList,this.changePackageMailList[key].length,num,flag)
            return flag && this.changePackageMailList[key].length == num
          } else {
            return false
          }
        }
      } else {
        return false
      }
      return false
    }
  },
  watch: {
    showPopup(newVal) {
      this.goodsChangeModal = newVal
    },
    checkGoodType(newVal) {
      this.checkType = newVal
    }
  },
  methods: {
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    getTip(pItem) {
      return getLimitInfo(pItem)
    },
    modalClose(e){
      if(!e){
        // 购物车页面关闭弹窗
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        this.$bus.$emit('getCartListFn')
      }
    },
    close() {
      // 购物车页面关闭弹窗
      this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      this.goodsChangeModal = false
      this.$emit('update:show-popup', this.goodsChangeModal)
      this.$bus.$emit('getCartListFn')
    },
    goSettle() { // 结算页面继续提交订单
      if (this.checkType === 'goodPackageMail') {
        // 购物车页面弹窗展示去结算
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击继续结算按钮", {})
      } else {
        this.dealJumpSettle()
      }
    },
    dealJumpSettle() {
      // 店铺起配包邮校验
      if (Object.keys(this.changePackageMailList).length > 0) {
        // 店铺起配包邮校验
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        this.$emit('checkGoodPackageMail', this.changePackageMailList)
        // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      } else {
        // 去结算
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击继续结算按钮", {})
      }
    },
    getTipTitle(key) {
      let tipTxt = '',tip=''
      switch (key) {
        case 'priceChange':
          tipTxt = '以下商品价格有变动：'
          break
        case 'unpriced':
          tipTxt = '以下商品未定价：'
          break;
        case 'stockChange':
          tipTxt = '以下商品库存数量有变动：'
          break
        case 'stockOut':
          tipTxt = '以下商品缺货：'
          tip='售罄'
          break
        case 'soldOut':
          tipTxt = '以下商品已下架：'
          tip='已下架'
          break
        case 'noPurchase':
          tipTxt = '以下商品无采购权限：'
          tip='无权限采购'
          break
        case 'startLimitChange':
          tipTxt = '以下商品起购数量有变动：'
          break
        case 'limitChange':
          tipTxt = '以下商品限购数量有变动：'
          break
        default:
          break
      }
      return {
        tipTxt,
        tip
      }
    }
  }
}
</script>

<style scoped lang="less">
.goods-change {
  ::v-deep .vertical-center-modal {
    .ivu-modal {
      width: 624px !important;
      .ivu-modal-content {
        .ivu-modal-header {
          border-bottom: none;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
        }
        .ivu-modal-body {
          padding: 0px 32px 32px 30px;
          box-sizing: border-box;
          .price-change {
            margin-top: 16px;
            .text {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              margin-bottom: 8px;
            }
          }
          .price-change-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            background: #f5f5f5;
            padding: 20px 0px;
            .item {
              width: 148px;
              display: flex;
              flex-direction: column;
              align-items: center;
              &:nth-child(n + 4) {
                margin-top: 20px;
              }
              .img-box {
                flex-shrink: 0;
                display:inline-block;
                width: 74px;
                height: 74px;
                padding:8px;
                border-radius: 3.2px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                position: relative;
                img {
                  width: 56px;
                  height: 56px;
                }
                .calculate{
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width:42px;
                  height:14px;
                }
                .tag {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  padding: 2px 4px;
                  box-sizing: border-box;
                  color: #F53F3F;
                  border-radius: 4px;
                  font-weight: 500;
                  font-size: 8px;
                  line-height: 14px;
                }
                .full-fold {
                  background-color: #FEF5F5;
                  border: 1px solid #F53F3F;
                }
                .special {
                  color: #f99d33;
                  background: rgba(114, 46, 209, 0.05);
                  border: 1px solid #f99d33;
                }
                .replacement {
                  color: #722ED1;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                }
                .lose-cause {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 60px;
                  height: 60px;
                  background-color: rgba(0, 0, 0, 0.4);
                  color: #fff;
                  font-size: 14px;
                  border-radius: 50%;
                  span {
                    width: 45px;
                    text-align: center;
                  }
                }
                .number {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  color: #CB272D;
                }
                .buy-num{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  bottom: 2px;
                  right:2px;
                  width: 20px;
                  height: 18px;
                  background: rgba(0, 0, 0, 0.6);
                  border-radius: 4px;
                  span{
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 18px;
                    color: #FFFFFF;
                  }
                }
                .buy-tip{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  transform:translate(-50%,-50%);
                  left:50%;
                  top:50%;
                  width: 60px;
                  height: 60px;
                  text-align: center;
                  padding:8px;
                  box-sizing: border-box;
                  background: rgba(0, 0, 0, 0.4);
                  border-radius: 50%;
                  span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    color: #FFFFFF;
                  }
                }
              }
              .name {
                width: 100%;
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 8px;
              }
              .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #cb272d;
                margin-top: 4px;
              }
              .quantum {
                font-size: 14px;
                line-height: 22px;
                color: #cb272d;
              }
            }
          }
          .batch-box{
            display:block;
            padding: 20px 0px;
            .good-wrapper{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              background: #f5f5f5;
            }
            .change-tip{
              text-align: center;
              margin-top:23px;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #CB272D;
            }
          }
          .modal-footer {
            display: flex;
            justify-content: flex-end;
            padding-top: 24px;
            margin-top: 30px;
            cursor: pointer;
            span {
              padding: 5px 16px;
              border-radius: 3px;
              font-size: 14px;
              line-height: 22px;
              margin-left: 8px;
            }
            .back-car {
              background-color: #e7e7e7;
              color: rgba(0, 0, 0, 0.9);
            }
            .continue {
              background-color: #f99d33;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }
}
</style>
