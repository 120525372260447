<template>
  <!-- <div class="user" @mouseenter="mouseenterFun" @mouseleave.prevent="mouseleaveFun"> -->
  <div class="user">
    <div v-if="coustomData?.customName" class="custom-name line-clamp-2" @click="routerTo('/center/enterprise?customerId=' + coustomData.customerId)">
      <img v-if="coustomState" :src="require(`@/assets/home/${coustomState}.png`)" alt="" class="state-icon">
      <span>{{ coustomData?.customName }}</span>
    </div>
    <div v-else class="no-related">
      <div class="no-related-title">尚未关联采购主体</div>
      <div class="no-related-desc">关联并通过审核后，方可采购下单</div>
      <a class="no-related-btn" @click.prevent.stop="routerTo('/affiliated')">去关联</a>
    </div>
    <!-- <span v-if="!coustomData?.customName" style="font-size: 14px;color: rgba(0, 0, 0, 0.6);line-height: 22px;">关联采购主体并通过平台审核后，方可采购下单</span>
     <Tooltip :content="defaultAddress" placement="top" max-width="200">
      <p class="line-clamp-1 address">
        <svg-icon name="address" className="address"></svg-icon>
        {{defaultAddress}}
      </p>
    </Tooltip> -->
    <!--产品要求注释掉-->
    <!-- <p v-if="coustomData.state == 'under_auth'" style="margin-top: 8px;font-size: 12px;">请耐心等待平台审核，<a @click.prevent.stop="routerTo('/affiliated')">去查看审核进度</a></p> -->
    <!-- <ChangeCustom v-model="showChangeCustom" class="change-custom" @mouseenterHandler = 'mouseenterHandler' @mouseleaveHandler = 'mouseleaveHandler'></ChangeCustom> -->
    <div v-if="coustomData?.customName">
      <div class="orders">
        <div class="item" @click="routerTo('/order?tab=PAYING')">
          <p>{{ orderStatus.payingCount }}</p>
          <p>待付款</p>
        </div>
        <div class="item" @click="routerTo('/order?tab=DELIVERING')">
          <p>{{ consignment }}</p>
          <p>待发货</p>
        </div>
        <div class="item" @click="routerTo('/order?tab=DELIVERED')">
          <p>{{ orderStatus.deliveredCount }}</p>
          <p>待收货</p>
        </div>
      </div>
      <div class="collect-business">
        <div class="item" @click="routerTo('/center/collection')">
          <p><img src="@/assets/home/collect.png"></p>
          <p>收藏商品</p>
        </div>
        <div class="item" @click="routerTo('/center/merchant')">
          <p><img src="@/assets/home/mall.png"></p>
          <p>建采商家</p>
        </div>
        <div class="item" @click="routerTo('/coupon/index')">
          <p><img src="@/assets/home/coupon-icon.png"></p>
          <p>我的优惠券</p>
        </div>
      </div>
    </div>

    <Notice />
    <div class="coupon-list">
      <swiper v-if="couponList.length" :options="swiperOption">
        <swiper-slide v-for="(coupon, index) in couponList" :key="index">
          <CouponItem :key="index" :item-data="coupon" @received="received" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Notice from '@/views/home/coms/notice.vue'
import { getMyCustomerInfoAPI, countByStateAPI, cancelWarnCertState } from '@/common/service/insertCustInfo'
import { matchRecommendCoupon, getMyCouponListApi } from '@/common/service/coupon'
import { listCollectProductToEs, getorderStats } from '@/common/service'
// import ChangeCustom from '@/components/ChangeCustom.vue'
import CouponItem from './CouponItem'
import { setStorageSync } from '@/common/utils/storage'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'User',
  components: {
    // ChangeCustom,
    Notice,
    CouponItem,
    swiper,
    swiperSlide
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      coustomData: {},
      defaultAddress: '',
      state: -1,
      stateList: [
        {
          state: 'frozen',
          name: '已冻结',
          backgroundColor: '#EEE',
          color: 'rgba(0, 0, 0, 0.60)',
          borderColor: '#777'
        },
        {
          state: 'un_auth',
          name: '未认证',
          backgroundColor: 'rgba(249, 157, 51, 0.08)',
          color: '#A35511',
          borderColor: '#CE7720'
        },
        {
          state: 'under_auth',
          name: '认证中',
          backgroundColor: 'rgba(56, 83, 226, 0.08)',
          color: '#2438BC',
          borderColor: '#3853E2'
        },
        {
          state: 'auth',
          name: '已认证',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          color: '#1F7C15',
          borderColor: '#349425'
        }
      ],
      collectNum: '-', // 收藏数
      successAuthNum: '-', // 建采数
      orderStatus: {
        payingCount: 0,
        deliveredCount: 0,
        deliveringCount: null,
        billingCount: null,
        takingCount: null
      },
      showChangeCustom: false, // 切换客户
      showChangeCustomMove: false, // 切换客户
      couponList: [],
      myCouponNum: '-', // 我的优惠券数
      swiperOption: {
        notNextTick: true,
        // 循环
        loop: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        // grabCursor : true,
        // 滑动之后回调函数
        on: {
          slideChangeTransitionEnd: function() {
            // console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          }
        }
      }
    }
  },
  computed: {
    consignment() {
      let data = null
      if (this.orderStatus.deliveringCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      if (this.orderStatus.billingCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      if (this.orderStatus.takingCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      data ??= 0
      return data
    },
    coustomState() {
      return this.coustomData?.controlState === 'frozen' ? this.coustomData?.controlState : this.coustomData?.state
    }
  },
  watch: {
    showChangeCustom() {
      if (!this.showChangeCustom) {
        this.showChangeCustomMove = false
      }
    },
    data() {
      this.init()
    }
  },
  created() {
    // 用户信息
    this.getUser()
    // 收藏
  },
  methods: {
    async init() {
      const couponDatas = this.data || []
      if (couponDatas.length !== 0) {
        this._getCoupon(couponDatas, (list) => {
          if (list.length !== 0) {
            this.couponList = list
          }
        })
      }
      this.handelLoadCard()
    },
    async handelLoadCard() {
      // 获取收藏商品数
      // await this.getCollectProductToEs()
      // 获取建采数
      // await this.countByState()
      // 订单
      await this.getOrderCount()
      // 获取优惠卷数
      // await this.getMyCouponNum()
    },
    async getUser() {
      const { data } = await getMyCustomerInfoAPI()
      this.coustomData = data
      this.$store.dispatch('common/setCustomerInfo', data || {})
      setStorageSync('customerInfo', data)
      const defaultAddressItem = data.receiveAddrList.find((item) => item.isCheck === 1)
      this.defaultAddress =
        defaultAddressItem.receiveDistrict.provinceName +
        defaultAddressItem.receiveDistrict.cityName +
        defaultAddressItem.receiveDistrict.districtName +
        defaultAddressItem.receiveAddr
      if (this.coustomData.warnState === 1) {
        this.$Modal.confirm({
          title: '温馨提示',
          content: `<div style="text-align: left;color:rgba(0, 0, 0, 0.60);">根据最新药监法规要求，您需要在<span style='color:rgba(0, 0, 0, 0.90);'>${this.coustomData.warnDay}天</span>内补充提交<span style='color:rgba(0, 0, 0, 0.90);'>${this.coustomData.requiredCertNames}</span>。如未及时提交，您的账户可能会被自动冻结。给您带来不便，我们深感抱歉，感谢您的理解和配合。如有问题，请联系平台。</div>`,
          okText: '立即提交',
          cancelText: '暂不提交',
          onOk: () => {
            this.$router.push({
              name: 'AddMustCustCert',
              query: {
                name: 'AddMustCustCert'
              }
            })
          },
          onCancel: async() => {
            await cancelWarnCertState()
          }
        })
      }
    },
    stateConputed(val) {
      const returnData = this.stateList.find((item) => {
        return item?.state === val
      })
      return returnData
    },
    routerTo(path) {
      window.open(location.origin + path)
    },
    // 获取收藏商品数
    async getCollectProductToEs() {
      const { data } = await listCollectProductToEs({
        page: {
          pageNumber: 1,
          pageSize: 5
        }
      })
      if (data?.total != null) {
        this.collectNum = data?.total
      }
    },
    // 获取建采商家数
    async countByState() {
      const { code, data } = await countByStateAPI()
      if (code === 200) {
        if (data.successAuthNum != null) {
          this.successAuthNum = data.successAuthNum
        }
      }
    },
    // 获取订单信息
    async getOrderCount() {
      const { code, data } = await getorderStats()
      if (code === 200) {
        const keys = ['PAYING', 'DELIVERING', 'TAKING', 'BILLING', 'DELIVERED']
        data.forEach((item) => {
          Object.keys(item).map((key) => {
            if (keys.includes(item[key])) {
              this.$set(this.orderStatus, item[key].toLowerCase() + 'Count', item.stateCount)
            }
          })
        })
      }
    },
    mouseenterFun() {
      if (this.coustomData?.state === 'auth') {
        this.showChangeCustom = true
        if (this.coustomTimer) {
          clearTimeout(this.coustomTimer)
        }
        setTimeout(() => {
          if (this.coustomTimer) {
            clearTimeout(this.coustomTimer)
          }
        }, 200)
      }
    },
    mouseleaveFun() {
      if (this.coustomData.state === 'auth') {
        this.coustomTimer = setTimeout(() => {
          if (!this.showChangeCustomMove) {
            this.showChangeCustom = false
          }
        }, 200)
      }
    },
    mouseenterHandler() {
      this.showChangeCustomMove = true
    },
    mouseleaveHandler() {
      this.showChangeCustomMove = false
      this.mouseleaveFun()
    },
    _getCoupon(couponDatas, back) {
      matchRecommendCoupon(couponDatas).then((res) => {
        back(res?.data || [])
      })
    },
    // 获取我的优惠券数
    async getMyCouponNum() {
      const { code, data } = await getMyCouponListApi()
      if (code === 200) {
        this.myCouponNum = data?.length
      }
    },
    received() {
      this._getCoupon(this.data || [], (list) => {
        if (list.length !== 0) {
          this.couponList = list
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.user {
  height: 406px;
  // margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  // align-items: center;
  border-radius: 8px;
  position: relative;
}

.no-related {
  height: 140px;
  text-align: center;
  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.9);
  }
  &-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
  }
  &-btn {
    display: inline-block;
    width: 200px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin-top: 12px;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: var(--3, linear-gradient(332deg, #ff5c17 17.15%, #ffae35 82.37%));
  }
}

.custom-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  user-select: none;
  cursor: pointer;
  min-height: 24px;
  max-height: 48px;
  .type {
    border-radius: 4px;
    font-weight: 400;
    line-height: 10px;
    padding: 4px 4px 3px;
    margin-right: 4px;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid;
    font-size: 10px;
    box-sizing: border-box;
    vertical-align: text-top;
  }
  .state-icon {
    width: 58px;
    height: 18px;
    margin-right: 4px;
    vertical-align: text-top;
    margin-top: 2px;
  }
}

.address {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}

.orders {
  display: flex;
  // justify-content: space-between;
  margin-top: 4px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & p:nth-child(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }

    // &:nth-child(1) p:nth-child(1) {
    //   color: #f99d33;
    // }

    // &:nth-child(2) p:nth-child(1) {
    //   color: #3853e2;
    // }

    // &:nth-child(3) p:nth-child(1) {
    //   color: #1f7c15;
    // }
  }
}

.item {
  width: 73px;
  height: 52px;
  padding: 4px 0;
  border-radius: 4px;

  &:hover {
    background: #f5f5f5;
  }
}

.collect-business {
  display: flex;
  // background: #FAFAFA;
  margin-top: 4px;
  width: max-content;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    & p:nth-child(1){
      height: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.change-custom {
  left: -412px;
  top: 78px;
}

.svg-icon.address {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}

::v-deep .ivu-tooltip {
  height: 22px;
}

.coupon-list {
  position: absolute;
  bottom: 16px;
  width: 228px;
}

.user {
  ::v-deep .ivu-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .ivu-avatar > img {
    vertical-align: middle;
  }
}
</style>
