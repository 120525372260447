// 热区
<template>
  <div class="v-hot-zone" :id="'nav' + id">
    <img :src="path" class="img" @load="load" >
    <div 
        v-for="(item, index) in options.data || []" 
        :key= 'index' 
        :style="{left: item.position.left * scale + 'px', top: item.position.top * scale + 'px', width: item.position.width * scale + 'px', height: item.position.height * scale + 'px'}"
        class="zone"
        @click = "item.link && cmsLink.bind(this)(item.link)">
      </div>
  </div>
</template>

<script>
/**
 * styleType  一图六商品: 5 一图八商品:8
 */
import { getFilePath } from '@/common/utils/base'
import { cmsLink} from '@/views/home/index'
export default {
  name: "v-hot-zone",
  props: {
    options: Object,
    id: String
  },
  data() {
    return {
      getFilePath,
      cmsLink: cmsLink.bind(this),
      height: '250px',
      scale: 1
    };
  },
  components: {
  },
  created() {
    this.$store.commit('setHomeNav', this.id)
  },
  mounted() {
    const options = this.options || {}
    options.type = 'v-hot-zone'
    this.$emit('load', options)
  },
  methods: {
    load(e) {
      // let _width = e.target.naturalWidth
      // let _height = e.target.naturalHeight
      // this.height = 1200 * (_height / _width ) + 'rpx'
      // this.scale = 1200 / _width
    },
  },
  computed: {
    // 获取一图地址
    path() {
      return getFilePath("orig",  this.options?.fileId)
    },
  }
};
</script>
<style scoped lang="less">
.v-hot-zone {
  width: 1200px;
  position: relative;
  .img {
    width: 100%;
  }
  .zone {
    position: absolute;
    // background-color: red;
  }
}
</style>
