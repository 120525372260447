<template>
  <div>
    <SearchBar v-show="!isExpandPage" :keyword="keyword" />

    <div class="batch-order-wrap" :style="{ width: isExpandPage ? '100%' : '1200px' }">
      <div v-show="!isExpandPage" style="line-height: 22px; font-size: 14px;display: flex;align-items: center;">
        <Breadcrumb separator="<div class='iconfonts'></div>">
          <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
          <BreadcrumbItem class="can-click black_1"><a @click="toSelectShop()">选择商家</a></BreadcrumbItem>
          <BreadcrumbItem v-if="selectOrgName">飞速下单</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div
        class="batch-order-content"
        :style="isExpandPage ? { 'min-height': 'calc(100vh - 60px)', 'margin-top': 0 } : ''"
      >
        <ShopSearch v-if="!selectOrgName" @selectShop="selectShop" />
        <BatchOrderList v-else @expandPage="expandPage" />
      </div>
    </div>
  </div>
</template>

<script>
import ShopSearch from './shopSearch.vue'
import BatchOrderList from './batchOrderList.vue'
export default {
  name: 'SpeedOrderList',
  components: {
    ShopSearch,
    BatchOrderList
  },
  data() {
    return {
      keyword: '',
      selectOrgId: '',
      selectOrgName: '',
      isExpandPage: false
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    this.selectOrgName = localStorage.getItem('selectOrgName')
  },
  methods: {

    selectShop(orgId, orgName) {
      this.selectOrgId = orgId
      this.selectOrgName = orgName
    },
    toSelectShop() {
      this.selectOrgName = ''
    },
    expandPage(val) {
      this.isExpandPage = val
      this.$emit('header', !this.isExpandPage)
    }
  }
}
</script>
<style scoped lang="less">
.batch-order-wrap {
  margin: auto;
  width: 1200px;

  .batch-order-content {
    margin-top: 24px;
    min-height: calc(100vh - 182px);

    // padding:24px;
    // background: #FFF;
    .next-step {
      margin-top: 16px;
      height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      background: var(--brand-6, #F99D33);
      color: var(--font-white-0, #FFF);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      border: none;

      &:hover {
        cursor: pointer;
        box-shadow: none;
      }
    }

    .unable {
      background: var(--brand-3, #FDDA9F);
    }
  }
}
</style>
