import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 购物车商品列表接口
 */
export const categoryList = (data) => post({
	url:`${api.baseUrl}/prd/product/categoryList`,
  data,
})
// 查询分类数据，包含一级和二级
export const categoryClassificationSon = (data) => post({
	url:`${api.baseUrl}/prd/product/getClassificationSon`,
  data,
})

export const getClassification = (data) => post({
	url:`${api.baseUrl}/prd/product/getClassification`,
  data,
})

export const listSearchWithBasePrd = (data) => post({
	url:`${api.baseUrl}/prd/product/listSearchWithBasePrd`,
  data,
})


