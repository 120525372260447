<template>
  <Cascader v-model="current" :data="cities" @on-change="changeDistrict" />
</template>
<script>
import { listTreeAPI } from '@/common/service/insertCustInfo'
import { setStorageSync, getStorageSync } from '@/common/utils'
export default {
  name: 'Area',
  props: {
    district: {
      type: Array,
      default: () => {
        return []
      }
    },
    code: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      current: [],
      cities: []
    }
  },
  watch: {
    district(newValue) {
      this.current = newValue
    }
  },
  created() {
    this.getCities()
    this.current = this.district
  },
  methods: {
    changeDistrict(val) {
      const code = val[2]
      const name = this.flattenTreeDataClosure(this.cities).filter(item => {
        return item.id === code
      })[0]?.districtName
      this.$emit('update:district', val)
      this.$emit('update:code', code)
      this.$emit('update:name', name)
    },
    async getCities() {
      const cities = getStorageSync('cities')
      if (cities) {
        this.cities = cities
        return
      }
      const { code, data } = await listTreeAPI()
      if (code === 200) {
        this.cities = this.addKey(data)
        setStorageSync('cities', this.cities)
      }
    },
    addKey(treeData) {
      return treeData.map((item) => ({
        ...item,
        value: item.id,
        label: item.districtName,
        children: item.children ? this.addKey(item.children) : []
      }))
    },
    flattenTreeDataClosure(data) {
      const treeData = JSON.parse(JSON.stringify(data))
      const flattenData = []
      function flattenTree(data, parentId) {
        data.forEach((ele) => {
          const {
            districtName,
            id,
            children
          } = ele
          flattenData.push({
            districtName,
            id,
            parentId,
            child: children
          })
          if (children) {
            flattenTree(children, id)
          }
        })
      }
      flattenTree(treeData, null)
      return flattenData
    }
  }
}
</script>
<style scoped lang="less">
</style>
