<template>
  <div>
    <Modal v-model="showModal" title="提示" width="480" class-name="jc-modal" @on-cancel="showModal = false">
      <span>{{ jcState == 'un_auth' ? '商家开启了建采认证，完成建采后才能采购商品。您已提交建采申请，请耐心等待商家审核或联系商家处理。' : '商家开启了建采认证，完成建采后才能采购商品'
        }}</span>
      <template #footer>
        <div class="fail-modal-footer">
          <Button v-if="jcState != 'un_auth'" type="default" @click="showModal = false">取消</Button>
          <Button type="primary" @click="onOk">{{ jcState != 'un_auth' ? '去建采' : '我知道了' }}</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="isShowPurchaseModal" title="发起建采" class-name="jc-modal" cancelText="暂不" @on-ok="agreeConfirm"
      @on-cancel="onCancel">
      <span style="font-weight: 500;font-size: 16px;line-height: 22px;color: rgba(0, 0, 0, 0.9);">确认向{{ shopData.orgAbbr
        ||
        '' }}发起建采申请吗？</span>
      <p style="font-size: 14px;line-height: 22px;color: rgba(0, 0, 0, 0.6);margin-top: 12px;">
        *确认后，系统会将您的企业信息、资质证照材料公开给商家
      </p>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="onCancel">暂不</Button>
          <Button type="primary" @click="agreeConfirm">确定</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="isInstructions" title="开户说明" class-name="jc-modal" @on-ok="agreeConfirm" @on-cancel="onCancel">
      <p class="acc_type" v-if="openAccountType != '未设置'">{{ openAccountType }}</p>
      <h3 v-html="openAccountDesc"></h3>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="onCancel">暂不</Button>
          <Button type="primary" @click="agreeConfirm">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getCustRelByCustIdOrgId } from '@/common/service/product.js'
import {
  buildCustRelByCustIdOrgId,
} from '@/common/service/productDetail.js'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'CheckOrgJcStatus',
  data() {
    return {
      jcState: '', // 建采状态`
      showModal: false, // 是否打开弹框
      orgId: null,
      isShowPurchaseModal: false,
      isInstructions: false,
      openAccountType: '',
      openAccountDesc: '',
    }
  },
  computed: {
    ...mapGetters({
      shopData: 'shopHome/getShopInfo',
    })
  },
  watch: {
    shopData() {
      if (this.shopData?.isHaveOpenAccount) {
        this.getDocAndType()
      }
    }
  },
  methods: {
    // 检查商家建采状态、商家是否允许为建采客户下单
    async checkBuyStatus(orgId) {
      this.orgId = orgId
      const obj = {
        orgId: this.orgId
      }
      const { data } = await getCustRelByCustIdOrgId(obj)
      // 判断是否建采成功
      this.jcState = data?.state
      // 判断商家是否已建采，已建采的不需要做逻辑处理
      if (data?.state === "success_auth" || data?.noRelCanBuyState == 'open') {
        this.$emit('ok')
        this.showModal = false
      } else {
        this.$emit('noPass')
        this.showModal = true
      }
    },
    async onOk() {
      if (this.jcState != 'un_auth') {
        // 发起建采
        // const param = {
        //   orgId: this.orgId,
        // }
        // const res = await buildCustRelByCustIdOrgId(param)
        // if (res.code == 200) {
        //   this.$Message.info('建采成功');
        //   this.showModal = false
        // }
        this.purchaseModal()
      } else {
        this.showModal = false
      }
    },
    async getDocAndType() { //获取开户说明
      const { data } = await getOrgOpenAccount(this.orgId)
      this.openAccountDesc = data.openAccountDesc
      this.openAccountType = data.openAccountType
    },
    purchaseModal() {
      if (this.shopData?.isHaveOpenAccount) {
        this.isInstructions = true
      } else {
        this.isShowPurchaseModal = true
      }
    },
    // 商家和客户建立建采关系
    agreeConfirm:debounce(async function() {
      const param = {
        orgId: this.orgId,
      }
      const res = await buildCustRelByCustIdOrgId(param)
      this.onCancel()
    }),
    onCancel() {
      this.isInstructions = false
      this.showModal = false
      this.isShowPurchaseModal = false
    },
  }
}
</script>
<style scoped lang="less">
/deep/ .jc-modal .ivu-modal-header {
  border: none !important;
}

/deep/ .jc-modal .ivu-modal-footer {
  border: none !important;
}
</style>
