const state = {
  show: false,
  data: null,
  homeNav: [],
  showHomeNav:[]
}
const getters = {
  
}
const mutations = {
  setCmsModalData(state, data) {
    // uni.hideTabBar();
    state.data = data
    state.show = true
  },
  hideCmsModal(state) {
    state.data = null
    state.show = false
    setTimeout(()=> {
      // uni.showTabBar()
    }, 150)
  },
  setHomeNav(state, value) {
    state.homeNav.push(value)
  },
  setShopHomeNav(state, value) {
    state.showHomeNav.push(value)
  }
}
const actions = {

}
export default {
  state,
  mutations,
  actions
}