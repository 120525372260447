import { categoryClassificationSon } from '@/common/service/cate'
const state = {
  classData: []
}
const mutations = {
  SET_CLASS_DATA(state, data) {
    state.classData = data
  }
}
const actions = {
  getClassData({ state, commit }, params) {
    categoryClassificationSon().then(res => {
      commit('SET_CLASS_DATA', res.data)
    }).catch(() => {
      commit('SET_CLASS_DATA', [])
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
