<template>
  <div class="footer-wrapper">
    <UserFooter v-on="$listeners"/>
  </div>
</template>

<script>
import UserFooter from '@/components/UserFooter'
export default {
  name: 'Footer',
  components: {
    UserFooter
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
//$grayTxt: rgba(0, 0, 0, 0.6);
.footer-wrapper {
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.4);
  background-color: #F5F5F5;
  .link-item {
    padding: 0 8px;
  }
}
</style>
