<template>
  <!-- 待支付订单 开始 -->
  <div class="table">
    <div v-if="dataList?.length > 0">
      <div
        v-for="item in dataList"
        :key="item.orgOrderId"
        class="table-body w-border-radius-8 w-font-size-14 w-border-base"
      >
        <div
          class="table-body__title text-12 w-font-color-black-2 w-bg-gray-1 w-flex w-flex-align-center w-flex-justify-between w-border-bottom-base"
        >
          <div class="orgName" @click="jumpShopHome(item)">{{ item.orgName }}</div>
          <div class="w-flex">
            <div class="orderNo" @click="viewDetail(item)">订单编号：{{ item.orderNo }}</div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </div>
        <div class="table-body__centent w-flex">
          <div v-viewer class="table-column1 w-border-right-base w-padding-16 w-pb-12">
            <div class="products-thumbs">
              <template v-for="(image, index) in item.items">
                <img
                  v-if="index < 3"
                  :key="index"
                  :src="image.prodMainPic ? getFilePath('PS_01', image.prodMainPic) : defaultImg"
                  @click.stop="viewDetail(item)"
                >
              </template>
              <Tooltip content="查看更多" placement="top">
                <div v-if="item.items && item.items.length > 3" class="omit" @click="viewDetail(item)" />
              </Tooltip>
            </div>
            <div class="text-12" style="color: rgba(0, 0, 0, 0.6)">
              商品
              <span class="w-font-color-brand-7">{{ item.prodTypeTotal }}</span>
              种，共计
              <span class="w-font-color-brand-7">{{ item.prodTotal }}</span>
              件
            </div>
          </div>
          <div
            class="w-text-align-center w-flex w-flex-column w-flex-justify-center table-column2 w-border-right-base"
          >
            <div class="text-14 w-font-color-black-1 w-font-weight-500">¥{{ returnFloat(item.payAmount || 0, '0.01') }}
            </div>
            <div v-if="item.postage > 0" class="w-font-size-12 w-font-color-black-3">(含运费{{ returnFloat(item.postage || 0, '0.01') }})</div>
            <div v-if="item.payType" class="w-font-size-12">{{ item.payType }}</div>
          </div>
          <div
            class="w-text-align-center w-flex w-flex-column w-flex-justify-center table-column3 w-border-right-base"
          >
            <div
              v-if="!item.toOrgConfirm"
              :class="item.state === 'PAYING' ? 'w-font-color-error-7 text-12' : 'w-font-color-black-2 text-12'"
              class="w-flex w-flex-justify-center w-flex-align-center"
              :style="{ color: formatStatus(item).color || 'rgba(230, 125, 0, 1)' }"
            >
              {{ isGroupBuy(item) ? '未成团，' : '' }}{{ formatStatus(item) && formatStatus(item).stateName }}
            </div>
            <div v-else class="wait-audit">
              <div class="con">
                <span class="label">待付款</span>
                <span>待商家认账</span>
              </div>
            </div>
            <!-- 和忠宇确认过，待收货状态也可以看到售后按钮 -->
            <div
              v-if="'PAYING,TAKING,BILLING,DELIVERING,DELIVERED'.includes(item.state)"
              class="w-font-color-black-2 text-12"
            >
              <span v-if="'PAYING'.includes(item.state) && !item.toOrgConfirm" class="w-font-color-error-7">
                <CountDown :target="item._surplusTime" @on-end="countDownEnd(item)" />
                后订单自动关闭
              </span>
            </div>

            <div class="w-text-button-info text-12" @click="viewDetail(item)">订单详情</div>
          </div>
          <div
            class="w-text-align-center w-flex w-flex-column w-flex-justify-center w-flex-align-center table-column4"
          >
            <Button
              v-if="item.state === 'PAYING' && !item.toOrgConfirm"
              size="small"
              type="primary"
              @click="orderPay(item)"
            >立即支付</Button>
            <div
              v-if="item.state === 'PAYING'"
              class="w-text-button-info"
              @click="
                showCancleOrder = true
                selectedRow = item
              "
            >
              取消订单
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 取消订单弹框 -->
    <Modal
    v-model="showCancleOrder"
    title="取消订单"
    width="488px"
    @on-ok="confirmCancleOrder"
    @on-cancel="showCancleOrder = false"
  >
    <RadioGroup v-model="selectedCancleOrder" vertical>
      <Radio v-for="(item, index) in cancleOrderSelect" :key="index" :label="item.name">
        <span>{{ item.name }}</span>
      </Radio>
    </RadioGroup>
  </Modal>
  </div>
  <!-- 待支付订单 结束 -->
</template>
<script>
import { getOrderList, getOrderCancelTime as getValueSet, orderCancel } from '@/common/service'
import { getFilePath, returnFloat } from '@/common/utils'
export default {
  name: 'OrderList',
  data() {
    return {
      dataList: [], // 订单列表
      status: {
        PAYING: {
          stateName: '待付款',
          desc: '',
          className: 'w-text-error-color',
          color: '#CB272D'
        },
      },
      initTime: 0,
      selectedRow: {},
      showCancleOrder: false,
      cancleOrderSelect: [
        {
          name: '我不想买了'
        },
        {
          name: '信息填写错误，重新选购'
        },
        {
          name: '卖家缺货'
        },
        {
          name: '同城见面交易'
        },
        {
          name: '商品降价'
        },
        {
          name: '其他'
        }
      ],
      selectedCancleOrder: '',
      defaultImg: require('@/assets/images/common/good_default.png'),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    returnFloat,
    getFilePath,
    async init() {
      await this.getInitTime()
      return this.getOrderList()
    },
    async getInitTime() {
      await getValueSet('ORDER_CANCEL_TIME').then((res) => {
        const { itemValue } = res?.data?.find((item) => item.itemKey === 'ORDER_CANCEL_TIME') || {}
        this.initTime = parseInt(itemValue) || 0
      })
    },
    formatStatus(item) {
      return item.returnState !== 'NONE' || !item.returnState ? this.status[item.state] : this.status[item.state]
    },
    isGroupBuy(item) {
      return (
        item?.expandMap?.groupState == 'NOGROUP' && (item.state == 'PAYING' || item.state == 'TAKING' || item.state == 'BILLING' || item.state == 'DELIVERING')
      )
    },
    countDownEnd() {
      this.init()
    },
    // 获取待支付订单列表
    async getOrderList() {
      const { code, data } = await getOrderList({
        condition: {
          states: 'PAYING',
        },
        pageNum: 1,
        pageSize: 2
      })
      if (code !== 200) {
        return
      }
      this.dataList = []
      const list = data?.records ? data.records : []
      this.dataList = list.map((item) => {
        const surplusTime = new Date(item.createTime).getTime() + this.initTime * 60 * 1000 - new Date().getTime()
        item._surplusTime = surplusTime > 0 ? surplusTime : 0
        return item
      })
      console.log(this.dataList)
    },
    orderPay(item) {
      const payInfo = {
        orders: [
          {
            orderTotalId: item.orderTotalId,
            orgOrderId: item.orgOrderId,
            payAmount: item.payAmount
          }
        ]
      }
      this.$router.push({
        path: '/trade/orderPay',
        query: {
          payOrder: encodeURIComponent(JSON.stringify(payInfo))
        }
      })
    },
    async confirmCancleOrder() {
      if (!this.selectedCancleOrder) {
        this.$Message.warning('请选择取消原因')
        return
      }
      this.showCancleOrder = false
      const res = await orderCancel({
        orgOrderId: this.selectedRow.orgOrderId,
        cancelReason: this.selectedCancleOrder
      })
      if (res.code === 200) {
        this.init()
      } else {
        this.$Message.warning('订单取消失败')
      }
    },
    viewDetail(item) {
      window.open(`/order/detail?orgOrderId=${item.orgOrderId}`, '_blank')
    },
  }
}
</script>
<style scoped lang="less">
.table {
  .table-header {
    height: 36px;

    & div:first-child {
      padding-left: 16px;
    }
  }

  .table-body {
    margin-top: 12px;
    overflow: hidden;

    .table-body__title {
      height: 36px;
      padding: 0 16px;

      .orgName {
        cursor: pointer;

        &:hover {
          color: var(--brand-7, #ce7720);
        }
      }

      .orderNo {
        margin-right: 36px;

        &:hover {
          cursor: pointer;
          color: #3853E2;
        }
      }

      .time {
        // margin-right: 16px;
      }
    }
  }

  .table-column1 {
    width: 350px;

    img {
      width: 72px;
      height: 72px;
      object-fit: contain;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      margin-right: 16px;
    }

    .products-thumbs {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .omit {
        cursor: pointer;
        width: 20px;
        height: 20px;
        padding: 4px;
        background: url('../../../../assets/images/order/omit.png') no-repeat center;
        background-size: cover;
        border-radius: 4px;
        transition: 0.3s ease;

        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }

  .table-column2 {
    width: 154px;
  }

  .table-column3 {
    width: 273px;

    &>div {
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .table-column4 {
    width: 155px;

    button {
      margin-top: 4px;
      width: 64px;
      height: 24px;
    }

    &>div {
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
.w-text-button-info {
  font-size: 12px;
}
</style>
<style>
.ivu-btn-small {
  font-size: 12px !important;
}
</style>
