// 商家首页路由
export default [
  {
    path: '/shopHome',
    name: 'shopHome',
    component: () => import('@/views/shopHome/index.vue'),
  },
  {
    path: '/shopHomeActivity',
    name: 'shopHomeActivity',
    component: () => import('@/views/shopHome/activity.vue'),
  }
]
