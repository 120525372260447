<template>
  <div class="pagination">
    <div v-if="!simple" class="total">
      <span>共{{ total }}条记录</span>
      <span v-if="showPageNum" class="ml8">第 <span class="theme-color">{{ pageNumber }}</span> / {{ getPageSize }} 页</span>
    </div>
    <Page
      :total="total"
      transfer
      show-sizer
      show-elevator
      :simple="simple"
      :page-size="pageSize"
      :current="pageNumber"
      :page-size-opts="pageSizeOpts"
      @on-change="handleChange"
      @on-page-size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    showPageNum: {
      type: Boolean,
      default: true
    },
    pageSizeOpts: {
      type: Array,
      default: () => [10, 20, 50, 100]
    },
    total: Number,
    num: Number,
    size: Number
  },
  data() {
    return {
      pageSize: this.size || 10,
      // pageSize: 10,
      pageNumber: this.num ?? 1
    }
  },
  computed: {
    getPageSize() {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  watch: {
    num(val) {
      this.handleChange(val)
    }
  },
  methods: {
    handleChange(val) {
      if (this.pageNumber != val) {
        this.pageNumber = val
        this.$emit('change', { pageNumber: val, pageSize: this.pageSize })
        window.scrollTo(0,0)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.$emit('change', { pageNumber: this.pageNumber, pageSize: val })
      window.scrollTo(0,0)
    }
  }
}

</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::v-deep .ivu-page {
    .ivu-page-item {
      border: 1px solid #E7E7E7;
      border-radius: 4px;
      margin: 0 4px;
      &:hover{
        background: #eee;
        a {
          color: rgba(0, 0, 0, 0.9);
        }
      } 
    }


    .ivu-page-item-active {
      a {
        color: rgba(0, 0, 0, 0.9);
      }
      background: #eee;
    }
    .ivu-page-prev, .ivu-page-next {
      background: transparent;
      &:hover{
        background: #eee;
        border: 1px solid #E7E7E7;
        a {
          color: rgba(0, 0, 0, 0.9);
        }
      } 
    }
    .ivu-page-options-elevator input{
      text-align: center;
    }
    .ivu-page-options-elevator input:focus{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
    }
    .ivu-page-options-elevator input:hover{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
    }
    .ivu-select-selection .ivu-select-selection:hover{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
      color: rgba(0, 0, 0, 0.9);
    }
    .ivu-icon{
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .theme-color {
    color: rgba(0, 0, 0, 0.9);
  }
  .ml8 {
    margin-left: 8px;
  }
}
</style>
