import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const globalEnterSearch = (callback, pathname) => {
  document.onkeydown = (e) => {
    if (e.keyCode === 13 && window.location.href.match(pathname)) {
      callback && callback(e.target)
    }
  }
}
const findItemById = (list, id) => {
  let res = list?.find((item) => item.id == id)
  if (res) {
    return res
  } else {
    for (let i = 0; i < list.length; i++) {
      if (list[i].children instanceof Array && list[i].children.length > 0) {
        res = findItemById(list[i].children, id)
        if (res) {
          return res?.title
        }
      }
    }
    return null
  }
}

// 获取设备信息，唯一ID
export const getDeviceInfo = async() => {
  // 初始化 FingerprintJS
  const fpPromise = FingerprintJS.load()
  // 生成指纹
  fpPromise
    .then(fp => fp.get())
    .then(result => {
      // 获取用户的唯一标识
      const {components, visitorId} = result
      const deviceInfo = getInfo(components || {})
      // 将唯一标识存储到 localStorage 中
      localStorage.setItem('deviceId', visitorId)
      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo || {}))
    })
    .catch(error => {
      console.error('FingerprintJS 获取失败:', error)
    })
}
function getInfo(info) {
  const userAgent = navigator.userAgent
  const infoList = ['screenResolution', 'hardwareConcurrency', 'timezone', 'languages', 'cookiesEnabled', 'colorDepth', 'colorGamut', 'platform', 'touchSupport']
  const obj = {}
  Object.keys(info).forEach(key => {
    if (infoList.includes(key)) {
      obj[key] = info[key]?.value
    }
  })
  obj.userAgent = userAgent
  return obj
}
export const checkPrdApproval = (productType, ids, businessScopeList) => {
  const tempData = ['1', '2', '保健食品', '特殊医学用途配方食品', '特殊医学用途配方食品（特定全营养配方食品除外）', '特殊医学用途配方食品(特定全营养配方食品除外)', '婴幼儿配方乳粉']
  const tempMap = {
    '1': {
      prdApprovalUrlName: '注册证',
      prdApprovalUrlIsMust: false,
      prdApprovalValidityName: '注册证有效期',
      prdApprovalValidityIsMust: true
    },
    '2': {
      prdApprovalUrlName: '注册证/备案证',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '注册证/备案证有效期',
      prdApprovalValidityIsMust: true
    },
    '保健食品': {
      prdApprovalUrlName: '注册证/备案证',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '注册证/备案证有效期',
      prdApprovalValidityIsMust: true
    },
    '特殊医学用途配方食品': {
      prdApprovalUrlName: '注册证',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '注册证有效期',
      prdApprovalValidityIsMust: true
    },
    '特殊医学用途配方食品（特定全营养配方食品除外）': {
      prdApprovalUrlName: '注册证',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '注册证有效期',
      prdApprovalValidityIsMust: true
    },
    '特殊医学用途配方食品(特定全营养配方食品除外)': {
      prdApprovalUrlName: '注册证',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '注册证有效期',
      prdApprovalValidityIsMust: true
    },
    '婴幼儿配方乳粉': {
      prdApprovalUrlName: '产品配方注册证书',
      prdApprovalUrlIsMust: true,
      prdApprovalValidityName: '产品配方注册证书有效期',
      prdApprovalValidityIsMust: true
    }
  }
  const filterData = []
  let currentVal = ''
  let result = {
    prdApprovalUrlName: '产品批件',
    prdApprovalUrlIsMust: false,
    prdApprovalValidityName: '批件有效期',
    prdApprovalValidityIsMust: false
  }
  if (tempData.includes(productType)) {
    currentVal = productType
  } else {
    for (let i = 0; i < ids?.length; i++) {
      filterData.push(findItemById(businessScopeList, ids[i]))
    }
    for (let i = 0; i < filterData.length; i++) {
      if (tempData.includes(filterData[i])) {
        currentVal = filterData[i]
        break
      }
    }
  }
  result = currentVal ? tempMap[currentVal] : result
  return result
}
