<template>
  <div class="batch-order" style="position: relative;">
    <div class="batch-order-wrap1" :style="isExpand? 'height: calc(100vh - 86px)' : ''">
      <div class="batch-order-content1">
        <!--顶部操作按钮区-->
        <div class="search-detail">
          <div class="search">
            <h4 style="font-size: 16px;">商品明细</h4>
            <img src="@/assets/images/search/shop_icon.png">
            <span>{{ orgName }}</span>
          </div>
          <div class="operate">
            <Button type="default" @click="importGoodDetailModal = true">导入Excel文件</Button>
            <Button type="default" @click="importPurchasePlanModal = true">导入采购计划</Button>
            <Button type="default" :loading="exportLoading" @click="exportGoodDetail">导出</Button>
            <Button type="default" class="expand" @click="expandEvent">
              <svg-icon :name="isExpand?'shrink':'expand'" />
            </Button>
            <span
              class="operation-instructions"
              @mouseenter="isShowInstructModal = true"
              @mouseleave="isShowInstructModal = false"
            >快捷操作说明
              <Icon type="ios-help-circle-outline" />
            </span>
          </div>
        </div>
        <!--中间商品列表区-->
        <div class="list-wrap">
          <Table
            ref="selection"
            :max-height="height"
            :loading="dataLoading"
            :columns="columns"
            :data="purchaseData.purchaseList"
            :class="!isDelStatus ? 'isDel' : ''"
            @on-selection-change="getSelectItems"
          >
            <template #generalChineseName="{ row, index }">
              <div>
                <div v-if="row.fastOrderId">
                  <!--标准模式下商品信息-->
                  <div v-show="!isExpand">
                    <div class="w-font-color-black-1">
                      <a class="product-a w-font-color-black-1" @click="goDetail(row)">
                        <span>{{ (row.productName ? '(' + row.productName + ')' : '') }}</span>
                        <span>{{ row.generalChineseName }}</span>
                        <span>{{ row.unitSpec }}</span>
                        <span>{{ row.productUnit ? '/' + row.productUnit : '' }}</span>
                      </a>
                    </div>
                    <div class="front-display-label-box">
                      <span v-for="(item, index) in row.frontDisplayLabel" :key="index" class="front-display-label">
                        {{ item }}
                        <span v-if="!(row.frontDisplayLabel.length-1 == index)" class="front-display-label-split" />
                      </span>
                    </div>
                  </div>
                  <!--最大化商品信息-->
                  <div v-show="isExpand">
                    <a class="product-a w-font-color-black-1" @click="goDetail(row)">
                      <span>{{ (row.productName ? '(' + row.productName + ')' : '') }}</span>
                      <span>{{ row.generalChineseName }}</span>
                      <span>{{ row.unitSpec }}</span>
                      <span>{{ row.productUnit ? '/' + row.productUnit : '' }}</span>
                    </a>
                    <span>
                      <span v-for="(item, index) in row.frontDisplayLabel" :key="index" class="front-display-label">
                        {{ item }}
                        <span v-if="!(row.frontDisplayLabel.length-1 == index)" class="front-display-label-split" />
                      </span>
                    </span>
                  </div>
                  <div class="w-font-color-black-2">
                    {{ row.manufactureCompany }}
                  </div>
                </div>
                <Input v-else ref="productInput" class="product-input" placeholder="商品名称/首字母/编码" suffix="ios-search" search autofocus @on-search="(v)=>onSearch(v,row,index)" />
              </div>
            </template>
            <template #prdState="{ row }">
              <div>
                <span v-if="row.matchFlag == 1" class="match-result">匹配成功</span>
                <span v-if="row.matchFlag == 2 || row.matchFlag == 3" class="match-result match-fail">匹配失败</span>
                <p v-if="(!row.matchFlag || row.matchFlag == 1) && row.availableStock <= 0">售罄</p>
                <p v-if="(!row.matchFlag || row.matchFlag == 1) && row.status == 2">已下架</p>
                <p v-if="(!row.matchFlag || row.matchFlag == 1) && row.addFail">{{ row.prdState }}</p>
                <div v-if="row.matchFlag == 2 || row.matchFlag == 3">
                  <Tooltip
                    :max-width="row.prdState.length > 100 ? 500 : 300"
                    :content="row.prdState"
                    placement="left"
                    theme="light"
                  >
                    <p class="text-class">{{ row.prdState }}</p>
                  </Tooltip>
                </div>
              </div>
            </template>
          </Table>
          <div class="add-btn" @click="addProduct">
            <Icon type="md-add" />添加商品
          </div>
        </div>
      </div>
    </div>
    <!--底部操作按钮区-->
    <div class="operation" :style="{width: isExpand ? '100%' : '1200px'}">
      <div class="operation-check">
        <div class="check-all">
          <Checkbox v-model="checkAll" @on-change="handleSelectAll(checkAll)">全选</Checkbox>
        </div>
        <div class="delete-btn" @click="remove('batch')">
          <Button :loading="deleteLoading" class="dele" :class="checkAllDisabled ? 'uncheckAll' : ''" :disabled="!checkAllDisabled">删除选中的商品</Button>
        </div>
        <div class="delete-btn" @click="removeImportFailData">
          <Button
            :loading="deleteImportFailGoodLoading"
            class="dele"
            :class="!isExistImportFailGood ? 'uncheckAll' : ''"
            :disabled="isExistImportFailGood"
          >删除匹配失败数据</Button>
        </div>
      </div>

      <div class="detail">
        <div class="total">
          <p class="num">商品共 <span class="count">{{ purchaseData.typeNum }}</span> 种 ，共计 <span
            class="count"
          >{{ purchaseData.totalNum }}</span> 件</p>
          <p class="total-price">合计：<span class="price">¥{{ returnFloat(purchaseData.totalPrice, 0.01) }}</span></p>
        </div>
        <div class="operation-btn">
          <Button
            :loading="submitLoading"
            class="create-plan-btn"
            :disabled="disabled"
            :class="disabled ? '' : 'uncheckAll'"
            @click="createPlanModal = true"
          >生成采购计划</Button>
          <Button
            :loading="addCarLoading"
            class="submit-btn"
            :class="disabled ? 'unable' : ''"
            :disabled="disabled"
            @click="batchAddCart"
          >加入购物车</Button>
        </div>
      </div>
    </div>
    <!--快捷操作说明-->
    <div
      v-show="isShowInstructModal"
      class="instruct-modal"
    >
      <img src="@/assets/images/center/operation-instruction.png">
    </div>
    <!--生成采购计划单弹框-->
    <Modal
      v-model="createPlanModal"
      title="生成采购计划"
      width="480"
      @on-ok="submitPlan"
      @on-cancel="createPlanModal = false"
    >
      <p><span style="color: red;">*</span>采购计划名称</p>
      <Input v-model="purchasePlanName" placeholder="请输入" show-word-limit style="margin-top: 8px;" maxlength="30" />
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="createPlanModal = false">取消</Button>
          <Button type="primary" :loading="submitLoading" @click="submitPlan">确认</Button>
        </div>
      </template>
    </Modal>
    <!--手动选择商品-->
    <SelectGood
      :modal-show.sync="goodModalShow"
      :search-key-word="searchKeyWord"
      :fast-order-id="fastOrderId"
      @updateBatchPurchase="updateBatchPurchase"
    />
    <!--失败商品列表弹框-->
    <FailGoodModal
      :modal-show.sync="showFailModal"
      :fail-data="failData"
      @updateFailData="updateFailData"
    />
    <!--上传导入文件-->
    <UploadFile
      :modal-show.sync="importGoodDetailModal"
      @uploadSuccess="uploadSuccess"
    />
    <!--导入采购计划-->
    <ImportPurchasePlan
      :modal-show.sync="importPurchasePlanModal"
      @updateImportPurchasePlan="updateImportPurchasePlan"
    />
    <!--导入失败查看原始数据-->
    <ExcelOriginGood
      :modal-show.sync="excelOriginGoodModal"
      :fail-data="excelFailData"
    />
  </div>
</template>

<script>
import { add } from '@/common/utils'
import { returnFloat } from '@/common/utils/format'
import SelectGood from './component/selectGood.vue'
import FailGoodModal from './component/failGoodModal.vue'
import UploadFile from './component/uploadFile.vue'
import ImportPurchasePlan from './component/importPurchasePlan.vue'
import ExcelOriginGood from './component/excelOriginGood.vue'
import { getFastOrderGood, deleteFastOrder, fastAddPurchase, generatorPurchasePlan, updateFastOrder, speedOrderExportExcel, saveFastOrderGood } from '@/common/service'
import asyncQueryCartMixin from '@/common/mixins/asyncQueryCartMixin'
import dayjs from 'dayjs'
export default {
  name: 'BatchOrderList',
  components: {
    SelectGood,
    FailGoodModal,
    UploadFile,
    ImportPurchasePlan,
    ExcelOriginGood
  },
  mixins: [asyncQueryCartMixin],
  data() {
    return {
      current: 2,
      queryType: 'ALL',
      purchaseData: {
        purchaseList: [],
        typeNum: 0,
        totalNum: 0,
        totalPrice: '0.00'
      },
      orgId: '',
      orgName: '',
      searchKeyWord: '',
      receiveDistrictNo: '',
      msgValue: '',
      goodModalShow: false,
      currentEditRow: 0,
      rowData: {},
      checkAll: false,
      isDelStatus: false,
      deletedIdList: [],
      dataLoading: false,
      submitLoading: false,
      deleteLoading: false,
      addCarLoading: false,
      checkAllDisabled: false,
      failData: {}, // 失败商品信息
      showFailModal: false,
      returnFloat,
      isShowInstructModal: false,
      createPlanModal: false,
      purchasePlanName: '', // 采购计划名称
      exportLoading: false,
      importGoodDetailModal: false,
      importPurchasePlanModal: false,
      excelOriginGoodModal: false,
      fastOrderId: null,
      excelFailData: {},
      deleteImportFailGoodLoading: false,
      isExpand: false,
      columns: [
        {
          type: 'selection',
          width: 35,
          align: 'center',
          key: '_disabled'
        },
        {
          title: '序号',
          type: 'index',
          width: 45
        },
        {
          title: '商品信息',
          key: 'generalChineseName',
          // width: 190,
          slot: 'generalChineseName'
        },
        {
          title: '批准文号',
          key: 'approvalNumber',
          width: 82
        },
        {
          title: '商品编码',
          key: 'productNo',
          width: 70
        },
        {
          title: '采购数量',
          key: 'productCount',
          width: 112,
          render: (h, { row, index }) => {
            return h('div', {}, [
              // eslint-disable-next-line eqeqeq
              row.fastOrderId && row?.matchFlag != 2 && h('div', {
                style: {
                  display: 'flex'
                }
              }, [
                row.controlsOutside ? h('button', {
                  style: {
                    'border-radius': '4px 0 0 4px'
                  },
                  class: 'product-count-btn',
                  on: {
                    click: () => {
                      row.productCount = row.productCount - row.addBuyNum
                      this.changeNum(row, index)
                    }
                  }
                }, [h('Icon', {
                  props: {
                    type: 'ios-remove'
                  }
                })]) : h('button', {
                  class: 'product-count-hover-btn',
                  style: {
                    'border-radius': '4px 0 0 4px'
                  },
                  on: {
                    click: () => {
                      if (row.packageNum && row.packageNum > 0) {
                        row.productCount = row.packageNum
                      } else {
                        this.getTip(row, '中包装数量为空')
                      }
                      row.controlsOutside = true
                    }
                  }
                }, '中'),
                h('InputNumber', {
                  class: 'product-count-input',
                  props: {
                    value: row.productCount,
                    autofocus: !row.controlsOutside,
                    min: 1
                  },
                  on: {
                    'on-blur': () => {
                      setTimeout(() => {
                        row.controlsOutside = true
                        this.changeNum(row, index)
                      }, 0)
                    },
                    'on-change': (val) => {
                      row.productCount = val
                    },
                    'on-focus': () => {
                      const $table = document.querySelectorAll('.ivu-table-row')
                      const $productInput = $table[index].querySelector('.product-count-input .ivu-input-number-input')
                      $productInput.select()
                      this.$set(this.purchaseData.purchaseList, index, { ...row, 'controlsOutside': false })
                    }
                  },
                  nativeOn: {
                    click: (e) => {
                      this.keydown(e, row, index)
                    },
                    keyup: (e) => {
                      if (e.keyCode === 13) {
                        // 在采购数量的文本框里，操作enter后，光标跳转到近邻的一个空的商品搜索框内
                        this.$nextTick(() => {
                          const $table = document.querySelectorAll('.ivu-table-row')
                          for (let i = index; i < $table.length; i++) {
                            const $productInput = $table[i].querySelector('.product-input .ivu-input')
                            if ($productInput) {
                              $productInput.focus({
                                preventScroll: true
                              })
                              break
                            }
                          }
                        })
                      }
                    }
                  }
                }),
                row.controlsOutside ? h('button', {
                  style: {
                    'border-radius': '0 4px 4px 0'
                  },
                  class: 'product-count-btn',
                  on: {
                    click: () => {
                      row.productCount = row.productCount + row.addBuyNum
                      this.changeNum(row, index)
                    }
                  }
                }, [h('Icon', {
                  props: {
                    type: 'ios-add'
                  }
                })]) : h('button', {
                  class: 'product-count-hover-btn',
                  style: {
                    'border-radius': '0 4px 4px 0'
                  },
                  on: {
                    click: () => {
                      console.log(row.packageUnitNum, 'packageUnitNum')
                      if (row.packageUnitNum && row.packageUnitNum > 0) {
                        row.productCount = row.packageUnitNum
                      } else {
                        this.getTip(row, '件包装数量为空')
                      }
                      row.controlsOutside = true
                    }
                  }
                }, '件')
              ]),
              // eslint-disable-next-line eqeqeq
              row.fastOrderId && row.matchFlag == 2 && h('div', {
                style: {
                  display: 'flex'
                }
              }, row.productCount)
            ]
            )
          }
        },
        {
          title: '单价',
          key: 'price',
          width: 100,
          align: 'center',
          render: (h, { row }) => {
            // 单价格式化
            let text = ''
            if (row.fastOrderId && row.price) {
              text = '￥' + returnFloat(row.price)
            } else if (row.id) {
              text = '未定价'
            }
            return h('div',
              {
                style: {
                  'text-align': 'center',
                  'font-weight': 600
                }
              },
              [
                h('p', text)
                // h('p', row.fastOrderId && row.price ? row.price && '￥' + row.price : row.id ? '未定价' : '')
              ])
          }
        },
        {
          title: '商品总额',
          key: 'totalPrice',
          width: 100,
          align: 'center',
          render: (h, { row }) => {
            let text = ''
            if (row.totalPrice) {
              text = '￥' + returnFloat(row.totalPrice, 0.01)
            } else {
              text = ''
            }
            return h('div', {
              style: {
                'text-align': 'center',
                'font-weight': 600
              }
            }, [
              h('p', text)
              // h('p', row.totalPrice && '￥' + row.totalPrice)
            ])
          }
        },
        {
          title: '库存',
          key: 'availableStock',
          width: 60
        },
        {
          title: '中包装',
          key: 'packageNum',
          width: 80,
          render: (h, { row }) => {
            return h('div', {}, [
              h('p', row.packageNum),
              // eslint-disable-next-line eqeqeq
              // h('p', row.packageNum && row.allowRetail == 1 ? '(可拆零)' : row.packageNum && row.allowRetail != 1 ? '(不可拆零)' : '')
            ])
          }
        },
        {
          title: '件包装',
          key: 'packageUnitNum',
          width: 60
        },
        {
          title: '有效期至',
          key: 'nearEffectDate',
          width: 90,
          render: (h, { row }) => {
            return h('div',{ class: (!row.nearEffectDate && !row.farEffectDate) ? 'near-date-empty':'near-date' }, [
              row.nearEffectDate ? h('p',{class:row.showExpirationWarn && 'expiration-warn'}, row.nearEffectDate) : h(!(row.nearEffectDate) && !row.farEffectDate ? 'span' : 'p',{class:row.showExpirationWarn && 'expiration-warn'}, row.id && '-'),
              !( row.nearEffectDate && !row.farEffectDate) && h('span',{class:(row.showFarExpirationWarn ) && 'expiration-warn'}, row.id && '/'),
              !((!row.nearEffectDate)&&(!row.farEffectDate)) ? h('p',{class:row.showFarExpirationWarn && 'expiration-warn'}, row.farEffectDate) : h('span',{class:row.showFarExpirationWarn && 'expiration-warn'}, row.id && '-'),
              // !(row.nearEffectDate && row.farEffectDate) && h('p',{ class: 'near-date-empty' },'-/-'),
            ])
          }
        },
        {
          title: '状态',
          key: 'prdState',
          width: 100,
          slot: 'prdState'

        },
        {
          title: '操作',
          key: 'action',
          width: 68,
          render: (h, params) => {
            return h('div', [
              h('p', {
                class: {
                  'action': true
                },
                on: {
                  click: () => {
                    console.log(params, 'params')
                    this.remove('single', params.row, params.index)
                  }
                }
              }, '删除'),
              // eslint-disable-next-line eqeqeq
              (params.row.matchFlag == 2 || params.row.matchFlag == 3) && h('p', {
                class: {
                  'action': true
                },
                on: {
                  click: () => {
                    this.originGoodQuery(params.row.failVo, params.index)
                  }
                }
              }, '原始数据')
            ])
          }
        }
      ]
    }
  },
  computed: {
    disabled() {
      return this.purchaseData.purchaseList?.filter(item => item._checked && item.id)?.length <= 0
    },
    height() {
      return document.body.clientHeight - (this.isExpand ? 214 : 394)
    },
    isExistImportFailGood() {
      return this.purchaseData.purchaseList?.filter(item => item.matchFlag == 2 || item.matchFlag == 3)?.length <= 0
    }
  },
  watch: {
    goodModalShow(val) {
      if (val) {
        document.removeEventListener('keydown', this.shiftUpDownHandle, false)
      } else {
        document.addEventListener('keydown', this.shiftUpDownHandle)
      }
    },
    'purchaseData.purchaseList': {
      handler(newVal) {
        const checkedNum = this.purchaseData.purchaseList?.filter(item => item._checked).length
        const disabledNum = this.purchaseData.purchaseList?.filter(item => item._disabled).length
        const length = this.purchaseData.purchaseList?.length
        // eslint-disable-next-line eqeqeq
        this.checkAll = (checkedNum + disabledNum) == length && length != 0
      }
    }
  },
  created() {
    this.orgId = localStorage.getItem('selectOrgId') || ''
    this.orgName = localStorage.getItem('selectOrgName') || ''
    // this.receiveDistrictNo = this.$route.query.receiveDistrictNo||''
    this.batchPurchaseQuery()
  },
  mounted() {
    document.addEventListener('keydown', this.shiftUpDownHandle)
  },
  methods: {
    onSearch(value, row, index) {
      this.currentEditRow = index
      this.searchKeyWord = value
      this.fastOrderId = row.fastOrderId
      this.goodModalShow = true
    },
    goDetail(row) {
      if (row.matchFlag !== 2 && row.productId) {
        window.open(`/detail?productId=${row.productId}&activityType=0`)
      }
    },
    handleSelectAll(status) {
      this.$refs.selection.selectAll(status)
    },
    /**
    * 批量添加购物车
    * 一键加购功能的实现，用于将选中的采购商品批量添加到购物车
    * @remarks
    * 此函数首先检查是否禁用了加购按钮，然后处理选中的商品列表，构造请求数据，
    * 并发送请求到后端进行批量加购操作。根据后端返回的结果，更新界面状态，
    * 如加购成功与否，购物车数量等，并做相应的提示和页面跳转。
    * @returns {void}
    */
    async batchAddCart() { // 一键加购
      // 如果加购按钮被禁用，则直接返回，不执行任何操作
      if (this.disabled) { return }
      try {
        // 开始加购操作，显示加载状态
        this.addCarLoading = true
        // 筛选并处理选中的采购商品，构造购物车商品列表
        const purchaseProductList = this.purchaseData.purchaseList?.filter(item => item._checked && item.id)?.map(item => ({
          productId: item.productId,
          purchaseNum: item.productCount,
          price: item.price,
          orgId: item.orgId,
          nearEffectDate: item.nearEffectDate,
          farEffectDate: item.farEffectDate,
          activityId: item.prdType === 'buy_now_price' ? item.marketing?.activityId : null,
          activityType: item.prdType === 'buy_now_price' ? item.marketing?.activityType : null
        }))
        // 发送批量加购请求
        const res = await fastAddPurchase({
          purchaseProductList: purchaseProductList,
          orgId: purchaseProductList[0].orgId
        })
        // 如果请求成功
        // eslint-disable-next-line eqeqeq
        if (res.code == '200') {
          // 结束加载状态
          this.addCarLoading = false
          // 根据返回结果处理后续逻辑
          if (res.data.failProductCount === 0) {
            // 所有商品加购成功，显示成功提示，并准备跳转到购物车页面
            this.$message.success('已成功加购' + res.data.totalProductCount + '种商品')
            // 如果所有商品都已加购成功，清除本地存储的选中组织信息
            if (this.purchaseData.purchaseList.filter(item => !item._checked && item.id)?.length === 0) {
              localStorage.setItem('selectOrgName', '')
              localStorage.setItem('selectOrgId', '')
            }
            // 延迟2秒后跳转到购物车页面
            setTimeout(() => {
              // this.$router.push('/car')
            }, 2000)
          } else {
            // 有商品加购失败，显示失败模态框，并填充失败商品数据
            // 弹框显示失败数据
            this.showFailModal = true
            this.failData = res.data
          }
          //   设置购物车默认选中
          const successProductList = (res.data.successProductList || []).map(item => {
            return {
              activityId: item.activityId,
              activityType: item.activityType,
              orgId: item.orgId,
              productId: item.productId
            }
          })
          // successProductList.forEach(item => {
          //   const cachedProductList = JSON.parse(localStorage.getItem('cachedProductList') || '[]')
          //   cachedProductList.push(item)
          //   localStorage.setItem('cachedProductList', JSON.stringify(cachedProductList))
          // })
          this.getAddCartData(successProductList, successProductList[0].orgId, () => {
            this.$router.push({
              path: '/car',
            })
          })
          // 更新购物车数量
          this.$store.dispatch('car/updateCartNum')
        }
      } catch (error) {
        // 加购操作失败，结束加载状态，并显示错误提示
        this.addCarLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    /**
     * 根据已选中的项目更新采购数据列表的选中状态和全选按钮的禁用状态。
     * @param {Array} val - 已选中的项目的集合。
     */
    getSelectItems(val) {
      // 如果有项目被选中
      if (val.length > 0) {
        // 设置全选按钮为禁用状态，因为至少有一个项目已被选中
        this.checkAllDisabled = true

        // 更新采购列表中每个项目的选中状态
        this.purchaseData.purchaseList = this.purchaseData.purchaseList.map(item => {
          let checked = false // 默认为未选中
          // 遍历已选中的项目，检查是否有匹配的项目
          val.forEach((val_item) => {
            if ((item.productId === val_item.productId) && item.orgId === val_item.orgId && !item._disabled) {
              checked = true // 如果找到匹配且未禁用的项目，则设置为已选中
            }
          });
          // 返回更新后的项目对象，包括原始属性和新增的选中状态属性
          return {
            ...item,
            _checked: checked
          }
        })
      } else { // 如果没有项目被选中
        // 取消全选按钮的禁用状态
        this.checkAllDisabled = false
        // 将采购列表中所有项目的选中状态设置为未选中
        this.purchaseData.purchaseList = this.purchaseData.purchaseList.map(item => {
          return {
            ...item,
            _checked: false
          }
        })
      }
      // 更新全选按钮的状态，基于已选中的项目数量和禁用的项目数量
      this.checkAll = (val.length + this.purchaseData.purchaseList.filter(item => item._disabled).length) === this.purchaseData.purchaseList.length
      // 调用计算函数，更新相关的计算值
      this.calculator()
    },
    /**
    * 异步删除采购列表中的项目。
    * @param {string} type 删除类型，'batch' 表示批量删除，其他情况为单个删除。
    * @param {object} row 被删除的行数据。
    * @param {number} index 被删除行的索引。
    * @returns {Promise<void>} 无返回值。
    */
    async remove(type, row, index) {
      try {
        // 批量删除确认
        if (type === 'batch') {
          this.$Modal.confirm({
            title: '确定删除选中的商品吗？',
            content: '<p>删除后，无法恢复</p>',
            onOk: async() => {
              // 筛选选中的采购项，准备删除
              this.deletedIdList = this.purchaseData.purchaseList?.filter(item => item._checked && item.fastOrderId)?.map(item => ({
                productId: item.productId,
                orgId: item.orgId,
                fastOrderId: item.fastOrderId
              }))
              this.deleteLoading = true
              // 发起删除请求
              const res = await deleteFastOrder(
                this.deletedIdList
              )
              this.deleteLoading = false
              // 删除成功后的处理
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.checkAllDisabled = false
                this.checkAll = false
                // 从列表中移除已删除的项
                this.purchaseData.purchaseList = this.purchaseData.purchaseList?.filter(item => !item._checked)
                this.calculator()
              }
            },
            onCancel: () => {
              // 取消删除操作时的处理
            }
          })
        } else {
          // 单个删除处理
          this.deletedIdList = [{ productId: row.productId, orgId: row.orgId, fastOrderId: row.fastOrderId }]
          row.removeLoading = true
          // 如果有fastOrderId，发起删除请求
          if (row.fastOrderId) {
            const res = await deleteFastOrder(
              this.deletedIdList
            )
            row.removeLoading = false
            if (res.code === 200) {
              this.$message.success('删除成功')
              // 从列表中移除已删除的项
              if (this.purchaseData.purchaseList.length > 0) {
                this.purchaseData.purchaseList.splice(index, 1)
              }
              this.calculator()
              this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
            }
          } else {
            // 如果没有fastOrderId，直接从列表中移除
            this.$message.success('删除成功')
            if (this.purchaseData.purchaseList.length > 0) {
              this.purchaseData.purchaseList.splice(index, 1)
              this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
            }
          }
        }
      } catch (error) {
        // 删除操作异常处理
        if (type === 'batch') {
          this.deleteLoading = false
        } else {
          row.removeLoading = false
        }
        this.$message.error(error?.data?.message)
      }
    },
    // 更新采购列表数据
    updateBatchPurchase(val) {
      if (this.purchaseData.purchaseList.length > 0) {
        if (this.currentEditRow !== this.purchaseData.purchaseList.length - 1) {
          this.purchaseData.purchaseList.splice(this.currentEditRow, 1, ...val)
        } else {
          this.purchaseData.purchaseList.splice(this.currentEditRow, 0, ...val)
        }
        this.calculator()
        setTimeout(() => {
          document.querySelector('.ivu-table-body').scrollTop = document.querySelector('.ivu-table-body').scrollHeight
        }, 100)
        this.$nextTick(() => {
          document.querySelectorAll('.ivu-table-row')[this.currentEditRow].
            querySelector('.product-count-input .ivu-input-number-input')?.focus({
              preventScroll: true
            })
        })
      }
    },
    /**
     * 异步查询批量采购信息。
     * 此函数通过发送请求获取快速订单商品信息，并对返回的数据进行处理，以适配采购列表的展示需求。
     * 如果获取数据成功，会根据数据状态设置每个采购项的属性，并加载到采购数据列表中。
     * 如果获取数据失败，会停止加载状态，显示错误信息，并初始化采购列表。
     */
    async batchPurchaseQuery() {
      try {
        // 开始加载数据，显示加载状态
        this.dataLoading = true
        // 发送请求获取快速订单商品信息
        const res = await getFastOrderGood({
          'orgId': this.orgId
        })
        // 判断返回数据是否有效，若有记录，则处理每个采购项
        if (res.data && res.data.records) {
          // 处理每个采购项，设置其属性，并加入到采购数据列表中
          this.purchaseData.purchaseList = res.data.records.map(item => {
            // 初始化是否禁用采购项的标志
            let _disabled = false
            // 判断采购项是否可用，可用则根据状态设置是否禁用
            if (item.productId && item.availableStock && item.availableStock > 0 && (item.matchFlag!=2 && item.matchFlag != 3)) {
              _disabled = false
              // 如果采购项状态为2（例如已采购），则设置为禁用状态
              if (item.status && item.status == 2) {
                _disabled = true
              }
            } else {
              // 如果采购项不可用，则设置为禁用状态
              _disabled = true
            }
            if (item.failVo && item.matchFlag == 2) {
              // 如果采购项有失败原因，则设置失败原因
              item.approvalNumber = item.failVo.approvalNumber
              item.manufactureCompany = item.failVo.manufactureCompany
              item.productName = item.failVo.productName
              item.unitSpec = item.failVo.productSpec
              item.productCount = parseInt(item.failVo.purchaseNum)
              item.productNo = item.failVo.orgProductNo
              item.customerTypePrice = [{ price: item.failVo.purchasePrice }]
            } else if (item.failVo && item.matchFlag == 3) {
              item.productCount = parseInt(item.failVo.purchaseNum)
            }
            // 返回采购项信息，包括价格、是否外部控制及是否禁用等属性
            return {
              ...item,
              price: item.prdType === 'buy_now_price' ? item.marketing?.customerTypePrice[0]?.price : item.customerTypePrice[0]?.price,
              controlsOutside: true,
              _disabled: _disabled,
              _checked: !_disabled
            }
          })
          console.log('查询', this.purchaseData.purchaseList)
        }
        this.calculator()
        // 在采购数据列表末尾添加一个可采购项
        this.purchaseData.purchaseList = [...this.purchaseData.purchaseList, { _disabled: false }]
        // 结束加载状态
        this.dataLoading = false
      } catch (error) {
        // 获取数据失败时，结束加载状态，显示错误信息，并初始化采购列表
        this.dataLoading = false
        this.$message.error(error?.data?.message)
        this.purchaseData.purchaseList = [{ _disabled: false }]
      }
    },
    changeModel(val) {
      this.queryType = val
      this.batchPurchaseQuery()
    },
    goBack() {
      this.$router.go(-1)
    },
    /**
     * 异步提交采购计划。
     * 首先检查采购计划名称是否为空，如果为空，则提示错误并返回。
     * 接着，根据已选择的采购项生成一个包含详细信息的数组 excelImportVos。
     * 然后，调用 generatorPurchasePlan 函数提交采购计划，并在提交过程中显示加载状态。
     * 如果提交成功，关闭采购计划创建模态框并显示成功消息；如果提交失败，则显示错误消息。
     * 在任何情况下，都停止加载状态。
     */
    async submitPlan() {
      // 检查采购计划名称是否为空
      if (!this.purchasePlanName) {
        this.$message.error('请输入采购计划名称')
        return
      }
      try {
        // 开始加载状态
        this.submitLoading = true
        // 根据采购数据生成 excelImportVos 数组，包含需要提交的详细信息
        const excelImportVos = this.purchaseData.purchaseList.filter(item => item._checked && item.id).map(item => {
          return {
            orgId: item.orgId,
            orgName: item.orgName,
            orgProductNo: item.productNo,
            productName: item.generalChineseName,
            manufactureCompany: item.manufactureCompany,
            productSpec: item.unitSpec,
            approvalNumber: item.approvalNumber,
            purchaseNum: item.productCount,
            purchasePrice: item.price
          }
        })
        // 调用函数生成采购计划
        const res = await generatorPurchasePlan({
          purchasePlanName: this.purchasePlanName,
          excelImportVos
        })
        // 结束加载状态
        this.submitLoading = false
        // 根据返回结果，显示成功或错误消息
        if (res.code === 200) {
          this.createPlanModal = false
          this.$message.success('生成采购计划成功')
        } else {
          this.$message.error(res?.data?.message)
        }
      } catch (error) {
        // 在出现异常时，结束加载状态并显示错误消息
        this.submitLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    editValue(row, num, index) {
      this.$set(row, 'productCount', num)
      this.$set(this.purchaseData.purchaseList, index, row)
      // 调用修改数量接口，并计算商品总额
      if (row.matchFlag == 3) {
        this.saveMatchFailData(row, num, index)
      } else {
        this.editProductNum(row, num, index)
      }
    },
    async saveMatchFailData(row, num, index) {
      const params = [{
        productId: row.productId,
        orgId: row.orgId,
        productCount: row.productCount,
        price: row.price,
        fastOrderId: row.fastOrderId,
        productName: row.generalChineseName
      }]
      const res = await saveFastOrderGood(params)
      if (res.code === 200) {
        const data = res.data?.[0]
        this.$set(row, 'matchFlag', 1)
        this.$set(row, 'totalPrice', data.totalPrice)
        this.$set(row, '_disabled', false)
        this.$set(row, '_checked', true)
        this.calculator()
      }
    },
    getTip(row, tip) {
      this.$message.info(tip)
    },
    keydown(e, row, index) {
      console.log(row, 'keydowmrow')
      const addElement = e.target.className.includes('ivu-input-number-controls-outside-up') || e.target.className.includes('ivu-icon-ios-add')
      const minusElement = e.target.className.includes('ivu-input-number-controls-outside-down') || e.target.className.includes('ivu-icon-ios-remove')
      if (addElement || minusElement) {
        this.changeNum(row, index)
      }
    },
    /**
     * 根据不同的条件限制，修改购买数量。
     * @param {Object} row - 行数据，包含商品信息和购买数量等。
     * @param {Number} index - 行索引，用于定位数据行。
     * @param {String} type - 操作类型，用于判断是否是初始化检查。
     */
    changeNum(row, index, type) {
      // 输出row对象到控制台，用于调试
      console.log(row, 'row')

      // 获取当前商品的购买数量
      let num = row.productCount
      // 复制row对象，用于后续操作
      const purchaseMatchItem = row

      // 解构赋值，获取商品的购买限制相关属性
      const startBuyNum = purchaseMatchItem.minPurchaseAmountIdentity
      const addBuyNum = purchaseMatchItem.addBuyNum
      const availableStock = purchaseMatchItem.availableStock
      const isLimit = purchaseMatchItem.isSingleLimit
      const residualQty = purchaseMatchItem.maxPurchaseAmountIdentity
      const productUnit = purchaseMatchItem.productUnit
      const allowRetail = purchaseMatchItem.allowRetail
      const packageNum = purchaseMatchItem.packageNum
      const packageUnitNum = purchaseMatchItem.packageUnitNum

      // 计算购买数量与最小购买数量的差值对每件加购数量的取余
      const val = (num - startBuyNum) % addBuyNum

      // 如果购买数量超过限购数量且限购开启
      if (num > residualQty && isLimit > 0) {
        // 如果不是初始化检查，则提示限购信息
        if (type !== 'initCheck') {
          this.getTip(row, `商品限购${residualQty}${productUnit}`)
        }
        // 调整购买数量为限购数量，并确保数量符合加购规则
        num = residualQty - (residualQty - startBuyNum) % addBuyNum
        this.editValue(row, num, index)
        return
      }

      // 如果购买数量超过库存数量
      if (num > availableStock) {
        // 如果不是初始化检查，则提示库存不足信息
        if (type !== 'initCheck') {
          this.getTip(row, `采购数量最多为库存数量：${row.availableStock}`)
        }
        // 调整购买数量为库存数量，并确保数量符合加购规则
        num = availableStock - (availableStock - startBuyNum) % addBuyNum
        this.editValue(row, num, index)
        return
      }

      // 如果购买数量小于最小购买数量
      if (num < startBuyNum) {
        // 如果不是初始化检查，则提示最低购买数量信息
        if (type !== 'initCheck') {
          this.getTip(row, `最低${startBuyNum}${productUnit}起购`)
        }
        // 调整购买数量为最小购买数量
        num = startBuyNum
        this.editValue(row, num, index)
        return
      }

      // 如果购买数量与最小购买数量的差值对每件加购数量取余不为0
      if (val !== 0) {
        // 如果不是初始化检查，则提示加购规则信息
        if (type !== 'initCheck') {
          this.getTip(row, `商家设置每次点击加购数为${addBuyNum}`)
        }
        // 调整购买数量为符合加购规则的数量
        num = num - val
        this.editValue(row, num, index)
        return
      }

      // 如果商品设置为中包装且不可拆零，检查购买数量是否为中包装的整数倍
      // 中包装且不可拆零，数量必须是中包装的整数被
      if ((allowRetail && allowRetail !== 1) && packageNum) {
        if (num % packageNum !== 0) {
          this.getTip(row, `数量需为中包装倍数`)
        }
        this.editValue(row, num, index)
        return
      }

      // 如果购买数量超过最大显示数量
      if (num > 9999999) {
        // 如果不是初始化检查，则提示库存不足信息（这里似乎是个Bug，应提示购买数量过多）
        if (type !== 'initCheck') {
          this.getTip(row, `采购数量最多为库存数量：${row.availableStock}`)
        }
        // 调整购买数量为最大显示数量，并确保数量符合加购规则
        num = 9999999 - (9999999 - startBuyNum) % addBuyNum
        this.editValue(row, num, index)
        return
      }

      // 更新购买数量
      this.editValue(row, num, index)
    },
    addProduct() {
      this.purchaseData.purchaseList.push({ _disabled: false })
      this.checkAll = false
      setTimeout(() => {
        document.querySelector('.ivu-table-body').scrollTop = document.querySelector('.ivu-table-body').scrollHeight
      }, 100)
    },
    // 修改数据库表的采购数量
    async editProductNum(row, num, index) {
      const params = {
        productId: row.productId,
        orgId: row.orgId,
        productCount: num,
        price: row.price
      }
      const res = await updateFastOrder(params)
      if (res.code === 200) {
        this.$set(row, 'totalPrice', res.data.totalPrice)
        this.$set(this.purchaseData.purchaseList, index, row)
        this.calculator()
      }
    },
    /**
     * 计算选中采购项的数量、总数和总价。
     * 此函数用于在采购数据更新时，重新计算选中项的相关统计信息。
     * 它首先筛选出所有被选中且有id的采购项，然后分别计算类型数量、总数和总价。
     * 最后，它将这些计算结果设置为采购数据的一部分。
     */
    calculator() {
      // 打印采购列表，用于调试
      // 计算选中采购项的类型数量
      const typeNum = this.purchaseData.purchaseList?.filter(item => item._checked && item.id)?.length
      // 计算选中采购项的总数
      const totalNum = this.purchaseData.purchaseList?.filter(item => item._checked && item.id)?.reduce((prev, cur) => {
        return add(prev, cur.productCount || 0)
      }, 0)
      // 计算选中采购项的总价
      const totalPrice = this.purchaseData.purchaseList?.filter(item => item._checked && item.id)?.reduce((prev, cur) => {
        return add(prev, cur.totalPrice || 0.00)
      }, 0)
      // 将计算结果添加到采购数据中
      this.$set(this.purchaseData, 'typeNum', typeNum)
      this.$set(this.purchaseData, 'totalNum', totalNum)
      this.$set(this.purchaseData, 'totalPrice', totalPrice)
    },

    /**
     * 更新失败的采购数据。
     * 当采购项更新失败时，此函数用于更新相关采购项的状态和价格等信息。
     * 它首先筛选出所有被选中且有id的采购项，然后遍历失败数据，如果找到匹配的采购项，则更新其状态和价格等信息。
     * 最后，它移除未被更新的采购项，并重新计算采购数据。
     * @param {Array} val - 包含失败采购项信息的数组。
     */
    updateFailData(val) {
      // 筛选并更新失败的采购项
      this.purchaseData.purchaseList.filter(item => item._checked && item.id).forEach((item) => {
        val.forEach((val_item) => {
          if (item.productId === val_item.productId && item.orgId === val_item.orgId) {
            this.$set(item, 'prdState', val_item.failReason)
            this.$set(item, 'price', val_item.price)
            this.$set(item, 'availableStock', val_item.availableStock)
            this.$set(item, 'addFail', true)
          }
        })
      })
      // 移除未被更新的采购项，并重新计算采购数据
      this.purchaseData.purchaseList = this.purchaseData.purchaseList.filter(item => !item._checked || item.addFail)
      this.calculator()
    },
    /**
     * 处理Shift键与上下箭头键的组合操作，用于高亮显示表格行并处理选中状态。
     * 当按下Shift键加向上箭头时，如果已有高亮行，则将高亮移至上一行，并调整选中状态；
     * 如果没有高亮行，则高亮显示第一行并调整其选中状态。
     * 当按下Shift键加向下箭头时，操作类似于向上键，只是方向向下。
     * @param {Event} event 键盘事件，用于获取按键信息。
     */
    shiftUpDownHandle(event) {
      // 当按下Shift键加向上箭头时
      if (event.shiftKey && event.key === 'ArrowUp') {
        // 在这里编写按下上箭头键时想要执行的代码
        // 获取表格行元素
        const $table = document.querySelector('.list-wrap .ivu-table-tbody').getElementsByTagName('tr')
        let isHaveClass = false
        for (let i = 0; i < $table.length; i++) {
          console.log('父页面,向上箭头')
          // 如果当前行有高亮样式
          if ($table[i].classList.contains('ivu-table-row-hover')) {
            isHaveClass = true
            // 如果不是第一行
            if (i !== 0) {
              // 移除当前行的高亮样式，添加上一行的高亮样式
              $table[i].classList.remove('ivu-table-row-hover')
              $table[i - 1].classList.add('ivu-table-row-hover')
              // 调整当前行和上一行的选中状态
              this.$set(this.purchaseData.purchaseList[i], '_checked', this.purchaseData.purchaseList[i]['_disabled'] ? false : true)
              this.$set(this.purchaseData.purchaseList[i - 1], '_checked', this.purchaseData.purchaseList[i - 1]['_disabled'] ? false : true)
              // 调整计算相关逻辑
              this.calculator()
              // 更新全选状态
              this.checkAll = (this.purchaseData.purchaseList?.filter(item => item._checked).length + this.purchaseData.purchaseList.filter(item => item._disabled).length) == this.purchaseData.purchaseList.length;
              // 更新全选禁用状态
              this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
            }
            break
          }
        }
        // 如果没有高亮行，处理第一行
        if (!isHaveClass) {
          $table[0].classList.add('ivu-table-row-hover')
          this.$set(this.purchaseData.purchaseList[0], '_checked', this.purchaseData.purchaseList[0]['_disabled'] ? false : true)
          this.calculator()
          this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
        }
        // 当按下Shift键加向下箭头时
      } else if (event.shiftKey && event.key === 'ArrowDown') {
        // 在这里编写按下下箭头键时想要执行的代码
        // 获取表格行元素
        const $table = document.querySelector('.list-wrap .ivu-table-tbody').getElementsByTagName('tr')
        let isHaveClass = false
        for (let j = 0; j < $table.length; j++) {
          console.log('父页面,向下箭头')
          // 如果当前行有高亮样式
          if ($table[j].classList.contains('ivu-table-row-hover')) {
            isHaveClass = true
            // 如果不是最后一行
            if (j !== $table.length - 1) {
              // 移除当前行的高亮样式，添加下一行的高亮样式
              $table[j].classList.remove('ivu-table-row-hover')
              $table[j + 1].classList.add('ivu-table-row-hover')
              // 调整当前行和下一行的选中状态
              this.$set(this.purchaseData.purchaseList[j], '_checked', this.purchaseData.purchaseList[j]['_disabled'] ? false : true)
              this.$set(this.purchaseData.purchaseList[j + 1], '_checked', this.purchaseData.purchaseList[j + 1]['_disabled'] ? false : true)
              // 调整计算相关逻辑
              this.calculator()
              // 更新全选状态
              this.checkAll = (this.purchaseData.purchaseList?.filter(item => item._checked).length + this.purchaseData.purchaseList.filter(item => item._disabled).length) == this.purchaseData.purchaseList.length;
              // 更新全选禁用状态
              this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
            }
            break
          }
        }
        // 如果没有高亮行，处理第一行
        if (!isHaveClass) {
          $table[0].classList.add('ivu-table-row-hover')
          this.$set(this.purchaseData.purchaseList[0], '_checked', this.purchaseData.purchaseList[0]['_disabled'] ? false : true)
          this.calculator()
          this.checkAllDisabled = this.purchaseData.purchaseList?.filter(item => item._checked)?.length <= 0 ? false : true
        }
      }
    },
    // 导入Excel成功
    uploadSuccess() {
      this.batchPurchaseQuery()
    },

    // 导出商品明细
    exportGoodDetail() {
      const params = {
        id: this.orgId
      }
      this.exportLoading = true
      speedOrderExportExcel(params).then(data => {
        this.exportLoading = false
        const blob = new Blob([data], { type: 'application/octet-stream' })// res.data 为接口返回的数据，依实例而行
        const URL = window.URL.createObjectURL(blob)// 生成一个 Blob URL
        this.createExportUrl(URL)
      }).catch(() => {
        this.exportLoading = false
        this.$message.error('导出失败')
      })
    },
    // 生成导出文件路径
    createExportUrl(URL) {
      const time = dayjs(new Date()).format('YYYY年MM月DD HH时mm分ss秒')
      const fileName = '飞速下单商品明细导出-' + time + '.xlsx' // 文件名+后缀
      const listNode = document.createElement('a')
      listNode.download = fileName
      listNode.style.display = 'none'
      listNode.href = URL // 文件流生成的url
      document.body.appendChild(listNode)
      listNode.click() // 模拟在按钮上实现一次鼠标点击
      document.body.removeChild(listNode) // 移除 a 标签
    },
    // 导入失败的excel原始数据查看
    originGoodQuery(data) {
      this.excelOriginGoodModal = true
      this.excelFailData = data
    },
    updateImportPurchasePlan() {
      this.batchPurchaseQuery()
    },
    //   删除导入失败的商品
    removeImportFailData() {
      this.$Modal.confirm({
        title: '确定要删除全部匹配失败数据吗?',
        content: '<p>删除后，无法恢复</p>',
        onOk: async() => {
          const deletedIdList = this.purchaseData.purchaseList?.filter(item => item.matchFlag == 2 || item.matchFlag == 3)?.map(item => {
            return {
              productId: item.productId,
              orgId: item.orgId,
              fastOrderId: item.fastOrderId
            }
          })
          this.deleteImportFailGoodLoading = true
          const res = await deleteFastOrder(
            deletedIdList
          )
          this.deleteImportFailGoodLoading = false
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.purchaseData.purchaseList = this.purchaseData.purchaseList?.filter(item => {
              return item.matchFlag != 2 && item.matchFlag != 3
            })
          }
        },
        onCancel: () => {

        }
      })
    },
    expandEvent() {
      this.isExpand = !this.isExpand
      this.$emit('expandPage', this.isExpand)
      this.columns = this.columns.map(item => {
        if (item.key === 'approvalNumber') {
          item.width = this.isExpand ? 140 : 82
        }
        return item
      })
    },
  }
}
</script>
<style scoped lang="less">
:deep(.match-result) {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid var(--success-7, #349425);
  background: rgba(52, 148, 37, 0.08);
  color: var(--success-8, #1F7C15);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.match-fail) {
  border-color: var(--error-7, #CB272D);
  background: rgba(203, 39, 45, 0.08);
  color: var(--error-7, #CB272D);
}

:deep(.good-info) {
  p {
    width: 205px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

:deep(.ivu-tooltip) {
  display: flex;
}

:deep(.ivu-table-header) {
  // padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  .ivu-table-cell {
    padding: 0;

    span {
      color: var(--font-black-2, rgba(0, 0, 0, 0.90));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  th {
    background: none;
    border-bottom: none;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }
}

:deep(.ivu-table-tbody) {
  .ivu-table-cell {
    padding: 0;
  }

  tr {
    border-bottom: 1px solid var(--gray-2, #EEE);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    font-size: 12px;
    padding: 12px 0;
    border-bottom: none;
    vertical-align: top;
    background: none;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-row-hover {
    td {
      background: #FFF8F0 !important;
    }
  }
}

:deep(table) {
  border-spacing: 16px 0;
  border-collapse: collapse;
  width: 1135px !important;
  height: 36px;
}

:deep(.ivu-table) {
  border-radius: 8px;

  &:before {
    display: none;
  }
}

:deep(.ivu-table-body) {
  // padding: 0px 16px;
  // border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;
}

:deep(.ivu-table-tip) {
  height: 238px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;

  td {
    border: none;
    margin: 0 auto;
    display: block;
    width: 216px !important;
    height: 216px;
    background: url(../../../assets/center/noData.png) 0 0 no-repeat;
    background-size: 100% 100%;

    span {
      display: none;
    }
  }
}

:deep(.ivu-input-number-controls-outside-btn) {
  height: 24px;
  line-height: 22px;
  width: 28px !important;
}

:deep(.ivu-input-number-input-wrap) {
  height: 24px;
  line-height: 22px;
}

:deep(.ivu-input-number-input) {
  height: 24px;
  line-height: 22px;
  text-align: center;
}

:deep(.stock) {
  color: var(--font-black-2, rgba(0, 0, 0, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.tip) {
  margin-top: 4px;
  width: 112px;
  text-align: center;
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.price) {
  color: var(--font-black-2, rgba(0, 0, 0, 0.90));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.action) {
  color: var(--hbrand-6, #3853E2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }
}

:deep(.undelete) {
  opacity: 0.6;
}

:deep(.select) {
  margin-right: 16px;
}

:deep(.ivu-btn:focus) {
  box-shadow: none;
}

:deep(.ivu-input) {
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-2, #EEE);

  &:focus {
    box-shadow: none;
  }
}

:deep(.ivu-modal-header) {
  border-bottom: none;
  padding: 20px 16px 0;
}

:deep(.ivu-modal-footer) {
  border-top: none;
}

.batch-order-wrap1 {
  background: #fff;
  padding: 24px;
  height: calc(100vh - 266px);

  // overflow-y: auto;
  .batch-order-content1 {

    .search-detail {
      padding: 0 0 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // border-bottom: 1px solid var(--gray-2, #EEE);
      .search {
        display: flex;
        align-items: center;

        h4 {
          margin-right: 16px;
          color: #000;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .operate {
        display: flex;
        align-items: center;
        gap: 12px;
        .expand {
          line-height: normal;
        }
        .operation-instructions {
          color: var(--font-black-1, rgba(0, 0, 0, 0.60));

          i {
            margin-left: 4px;
            font-weight: 600;
          }

          &:hover {
            color: rgba(230, 125, 0, 1);
            cursor: pointer;
          }
        }
      }
    }

    .shop-name {
      padding: 12px 0;
      color: var(--font-black-2, rgba(0, 0, 0, 0.60));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .add-btn {
      margin-top: -6px;
      display: flex;
      height: 42px;
      padding: 14px 16px 8px;
      justify-content: center;
      align-items: center;
      background: var(--gray-1, #FAFAFA);
      border-radius: 0px 0px 8px 8px;
      border-right: 1px solid var(--gray-2, #EEE);
      border-bottom: 1px solid var(--gray-2, #EEE);
      border-left: 1px solid var(--gray-2, #EEE);
    }

    .unable {
      background: var(--brand-3, #FDDA9F) !important;
    }
  }
}

.operation {
  margin-top: 16px;
  width: 1200px;
  position: fixed;
  bottom: 0;
  display: flex;
  height: 68px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background: var(--font-white-0, #FFF);

  .operation-check {
    display: flex;
    align-items: center;
  }

  .message {
    display: flex;
    align-items: center;
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .detail {
    display: flex;
    align-items: center;

    .total {
      margin-right: 32px;

      .num {
        color: var(--font-black-2, rgba(0, 0, 0, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .count {
          color: var(--brand-7, #CE7720);
        }
      }

      .total-price {
        text-align: right;
        margin-top: 6px;
        color: var(--font-black-1, rgba(0, 0, 0, 0.90));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .price {
          color: var(--error-7, #CB272D);
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .operation-btn {
      .back-btn {
        margin-right: 12px;
        height: 36px;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid var(--gray-4, #DCDCDC);
        color: var(--font-black-1, rgba(0, 0, 0, 0.90));
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .submit-btn {
        margin-left: 12px;
        height: 36px;
        padding: 8px 16px;
        border-radius: 4px;
        background: var(--brand-6, #F99D33);
        border: none;
        color: var(--font-white-0, #FFF);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .unable {
        background: var(--brand-3, #FDDA9F) !important;
      }

      .create-plan-btn {
        height: 36px;
        // padding: 8px 16px;
        border-radius: 4px;
        background: var(--error-6, #FFF);
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        font-weight: 400;
        border-color: #DCDCDC;
      }

      .uncheckAll {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .check-all {
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    :deep(.ivu-checkbox) {
      margin-right: 16px;
    }
  }

  .delete-btn {
    margin-left: 16px;

    .dele {
      height: 36px;
      // padding: 8px 16px;
      border-radius: 4px;
      background: var(--error-6, #FFF);
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 400;
      border-color: #DCDCDC;
    }

    .uncheckAll {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.isDel {
  :deep(.ivu-table-header) {
    th {
      padding: 0 8px;
    }
  }

  :deep(.ivu-table-tbody) {
    td {
      padding: 4px 8px;
      height: 36px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.instruct-modal {
  z-index: 999;
  width: 487px;
  // height: 432px;
  position: absolute;
  right: 20px;
  top: 72px;
  background-color: #fff;
  border-radius: 8px;
  // padding: 12px 16px;
  box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 32px 48px 4px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 12px 60px 10px rgba(0, 0, 0, 0.03);

  img {
    width: 100%;
  }
}

:deep(.product-a) {
  &:hover {
    color: #3853E2 !important;
  }

}

.front-display-label-box {
    overflow: hidden;
    white-space: nowrap;
}
.front-display-label {
  color: rgba(160, 112, 60, 1);
}
.front-display-label-split {
    margin: 0 4px;
    font-size: 10px;
    color: rgba(160, 112, 60, 1);
    border-right: 1px solid rgba(160, 112, 60, 0.3);
}

:deep(.product-count-input) {
  border-radius: 0;
  text-align: center;
  width: 56px;
  height: 36px;
  border-left: none;
  border-right: none;

  .ivu-input-number {

    &:focus {
      border-top-color: #dcdee2;
      border-bottom-color: #dcdee2;
    }
  }

  .ivu-input-number-input {
    padding: 0;
    height: 36px;
    line-height: 36px;
  }

  .ivu-input-number-handler-wrap {
    display: none;
  }

}

:deep(.product-count-btn) {
  width: 28px;
  height: 36px;
  background: #f8f8f9;
  color: rgba(75, 75, 75, 1);
  border: 1px solid #dcdee2;
  font-size: 20px;

  &:hover {
    color: rgba(249, 157, 51, 1);
    cursor: pointer;
  }
}

:deep(.product-count-hover-btn) {
  width: 28px;
  height: 36px;
  background: #f8f8f9;
  color: rgba(230, 125, 0, 1);
  border: 1px solid #dcdee2;

  &:hover {
    cursor: pointer;
  }
}

:deep(.text-class) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 42px
}
.near-date{
  text-align: center;
}
</style>
<style lang="less">
.ivu-modal {
  top: 40%;
}
.near-date{
  text-align: center;
}
.near-date-empty{
  text-align: left;
}
.expiration-warn{
  color: #E67D00;
}
</style>
