// 我的 相关接口
import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'

// 我的足迹列表接口
export const footmarkList = (data)=> {
    return post({
        url: `${api.baseUrl}/user/footprint/query`,
        data
    })
}

// 添加足迹
export const addFootmark = (data)=> {
    return post({
        url: `${api.baseUrl}/user/footprint/addFootPrint`,
        data
    })
}

// 删除足迹
export const delFootmark = (data)=> {
    return post({
        url: `${api.baseUrl}/user/footprint/deleteFootPrint`,
        data
    })
}