// 领券中心
<template>
  <div class="root">
		<img src="@/assets/images/home/activity-over.png" />
	</div>
</template>
<script>
export default {
  name:'',
  data(){
    return {
    }
  },
}
</script>
<style scoped lang='less'>
.root {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  margin-top: 15%;
	img {
		display: block;
		width: 216px;
		height: 216px;
		margin: 164px auto 0 auto;
	}
}
</style>

<!-- pages/explain/notice/index -->