<template>
  <div
    class="list-card"
    :class="{'is-cms':isCms}"

    @mouseleave="leave"
    @click="jumpDetail"
  >
    <div @mouseenter="enter">
    <div v-if="maskInfo" class="cover-mask">
      <div class="cover-mask-tip">
        {{ maskInfo }}
      </div>
    </div>
    <!--    封面图-->
    <div class="cover-box">
      <Events :row="goodsItem" :shop="shop">
        <g-img class="cover" :src="cover" :lazy="imgLazy" />
      </Events>
    </div>
    <!--    价格-->
    <div class="price-area-box">
      <PriceArea :goods-item="goodsItem" :is-gift-good="isGiftGood" />
    </div>

    <!--    商品标题-->
    <div class="title">
      <platform-tag v-if="showPlatformTag" :goods-item="goodsItem" />
      <span>{{ productNamePrefix }}{{ productName }}</span>
    </div>

    <!--    生产厂家-->
    <div class="company" :title="goodsItem.manufactureCompany">
      {{ goodsItem.manufactureCompany }}
    </div>
    <!-- 近远效期 -->
    <div v-if="showDate" class="date">
      <span v-if="accountErr">有效期至：-/-</span>
      <span v-else-if="prdControl && !goodsItem.prdControlVisible">有效期至：-/-</span>
      <template v-else>
        <span :class="{warning:startWarning || endWarning}">{{
          (startWarning || endWarning) ? '近效期：' : '有效期至：'
        }}</span>
        <span :class="{warning:startWarning}">{{ startTime || '-' }}</span>
        <template v-if="startTime">
          <span v-if="endTime" :class="{warning:startWarning||endWarning}">/</span>
          <span :class="{warning:endWarning}">{{ endTime }}</span>
        </template>
        <template v-else>
          <span>/</span>
          <span :class="{warning:endWarning}">{{ endTime || '-' }}</span>
        </template>
      </template>
    </div>

    <!--    控销信息 和 活动标签-->
    <div class=" tag-control">
      <div v-if="prdControl" class="control">{{ prdControl }}</div>
      <div v-else>
        <ActivityTag :data="goodsItem" :is-cms="isCms" @changeStatus="changeStatus" ref="activityTag"/>
      </div>
    </div>
    </div>
    <!--    收藏后讲价-->
    <slot name="collection" />

    <div class="card-footer">
      <div v-if="showBusiness" class="shop-name" @click.stop="jumpShopHome">
        {{ shopName }}
      </div>
      <!-- 收藏后降价 -->
      <div v-if="linkInfo" class="link-info">
        {{ linkInfo }}
      </div>
    </div>

    <div v-if="isGiftGood && goodsItem.num" class="buy-count">已加购{{ goodsItem.num }}件</div>
    <div class="add-card-box" :class="{ show: flag && !isEdit && showAddCar }">
      <AddCarDiaLog
        :key="0"
        :show-popup="flag"
        :good-item="shoppingData"
        :total-num="totalNum"
        :gift-obj="giftObj"
        @computedNum="computedNum"
        @goDetail="jumpDetail"
      />
    </div>
    <div
      v-if="!prdControl && goodsItem?.marketing && [6].includes(goodsItem?.marketing?.activityType) && showAddCar"
      class="show-activity-pop"
      :class="{show: showActivityPop}"
    >
      <GroupBuyPop
        v-if="goodsItem?.marketing?.activityType === 6"
        ref="groupBuyPop"
        :data="goodsItem"
        :product-name="productName"
        @goDetail="jumpDetail"
      />
    </div>

  </div>
</template>

<script>
import GImg from '@/components/GImg.vue'
import Events from '@/components/ProductCards/Events.vue'
import { ACTIVITY_TYPE, CONTROL_MSG, debounce, getAccountErr, getFilePath, getShoppingData } from '@/common/utils'
import PriceArea from '@/components/priceArea/index.vue'
import ActivityTag from './activityTag.vue'
import giftDefaultCover from '@/assets/product/gift_default.png'
import productDefaultCover from '@/assets/images/common/good_default.png'
import PlatformTag from '@/components/ProductCards/shared/PlatformTag.vue'
import GroupBuyPop from '@/components/ProductCards/shared/GroupBuyPop.vue'
import AddCarDiaLog from '@/views/goodsItem/AddCarDiaLog/index.vue'

export default {
  name: 'ListCard',
  components: { AddCarDiaLog, GroupBuyPop, PlatformTag, ActivityTag, PriceArea, Events, GImg },
  props: {
    goodsItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    shop: { // 商家首页 选择全部商品变量
      type: Boolean,
      default: false
    },
    imgLazy: {
      type: Boolean,
      default: true
    },
    showPlatformTag: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showAddCar: {
      // 是否显示加入购物车
      type: Boolean,
      default: true
    },
    showBusiness: {
      type: Boolean,
      default: true
    },
    totalNum: {
      type: Number,
      default: 0
    },
    giftObj: {
      type: Object,
      default: () => {
      }
    },
    isCms: {
      type: Boolean,
      default: false
    },
    // 换购品专区使用
    activityCode: {
      type: String,
      default: ''
    },
    // 换购品专区使用
    activityId: {
      type: String,
      default: ''
    },
    // 换购品专区禁用购物车加购浮层属性
    disAdd: {
      type: String,
      default: ''
    },
    // 常购清单购买次数
    buyCount: {
      type: String || Number,
      default: ''
    },
    // 禁用内部跳转
    disabledJump: {
      type: Boolean,
      default: false
    },
    showDate: {
      type: Boolean,
      default: true
    },
    cmsPositionTitle: {
      type: String,
      default: '热销推荐'
    }
  },
  data() {
    return {
      flag: false,
      shoppingData: {},
      showActivityPop: false
    }
  },
  computed: {

    cover() {
      const { fileId, picture, mainPicUrl } = this.goodsItem
      const path = fileId || picture || mainPicUrl
      // 图片加载优化
      if (path) {
        if (fileId) {
          return getFilePath('PS_01', `${path}.avif`)
        }
        return getFilePath('PS_01', path)
      }
      return this.isGiftGood ? giftDefaultCover : productDefaultCover
    },
    isGiftGood() {
      if (!this.giftObj) return false
      // 是否是赠品
      const { activityType, gift } = this.giftObj
      return gift && [ACTIVITY_TYPE.GIFT_MANUAL, ACTIVITY_TYPE.GIFT_AUTO].includes(activityType)
    },
    shopName() {
      const { goodsItem } = this
      return this.isGiftGood ? goodsItem.manufactureCompany : goodsItem.orgAbbr
    },
    prdControl() {
      // 控销信息
      const reason = this.goodsItem.reason

      return getAccountErr(this.goodsItem) || Reflect.get(CONTROL_MSG, reason) || ''
    },
    accountErr() {
      return getAccountErr(this.goodsItem)
    },
    linkInfo() {
      // 常购清单买过次数
      if (this.buyCount) {
        return `买过${this.buyCount}次`
      }
      const { historyLabel } = this.goodsItem
      if (!historyLabel) {
        return ''
      }
      /*
      * orgBuyRelFlag 是否已建采
      * orgBuyFlag 买过的店 1 买过
      * orgBuyRelFlag 建采商家
      * */
      const { orderCount, orgBuyFlag, orgBuyRelFlag, orgCollectFlag } = historyLabel
      if (orderCount > 0 && orderCount < 100) {
        return `买过${orderCount}次`
      }
      if (orderCount > 99) {
        return '买过99+次'
      }
      // 商家下 不显示 买过的店 等信息
      if (this.shop) {
        return ''
      }
      if (orgBuyFlag === '1') {
        return '买过的店'
      }
      if (orgBuyRelFlag === '1') {
        return '建采商家'
      }
      if (orgCollectFlag === '1') {
        return '关注的店'
      }
      return ''
    },
    productNamePrefix() {
      const { marketing, productUnit } = this.goodsItem
      if (marketing?.activityType === 6) {
        return `${marketing.minPurchaseAmountIdentity}${productUnit}包邮`
      }
      return ''
    },
    productName() {
      const product = this.goodsItem
      if (!product) {
        return
      }
      const { nameDisplayType, brandName, generalChineseName, unitSpec, productName } = product

      if (nameDisplayType + '' === '1') {
        // 品牌名+' '+商品名+' '+通用名+包装规格
        return `${brandName || ''} ${productName || ''} ${generalChineseName} ${unitSpec}`.trim()
      }
      if (nameDisplayType + '' === '0') {
        return generalChineseName || productName
      }
      if (this.isGiftGood) {
        return productName
      }
      return generalChineseName
    },
    maskInfo() {
      // 收藏商品 collect true为失效商品,false为有效商品
      // 我的足迹 flag true为失效商品, false为有效商品
      const { collect, invalidGoods, id, flag } = this.goodsItem
      if (collect || (invalidGoods?.includes(id)) || flag) {
        return '失效'
      }
      if (!this.goodsItem?.availableStock) {
        return '售罄'
      }
      return ''
    },
    startTime() {
      // 合并逻辑： src/store/modules/product.js fetchStock
      const goodsItem = this.goodsItem
      /**
       * 近效期活动 从marketing 中取近效期
       * */
      if ((String(goodsItem?.marketing?.activityType)) === '5') {
        return goodsItem?.marketing?.nearEffectDate
      }
      return goodsItem?.nearEffectDate
    },
    endTime() {
      const goodsItem = this.goodsItem
      /**
       * 近效期活动 没有远效期
       * */
      if ((String(goodsItem?.marketing?.activityType)) === '5') {
        return ''
      }
      return goodsItem?.farEffectDate
    },
    startWarning() {
      // 合并逻辑： src/store/modules/product.js fetchStock
      const goodsItem = this.goodsItem
      // 近效期活动的开始警告 从marketing 中获得
      if ((String(goodsItem?.marketing?.activityType)) === '5') {
        return goodsItem?.marketing?.showExpirationWarn
      }
      return goodsItem.showExpirationWarn
    },
    endWarning() {
      // 近效期活动无远效期 及 远效期警告
      if (String(this.goodsItem?.marketing?.activityType) === '5') {
        return ''
      }
      return this.goodsItem.showFarExpirationWarn
    }
  },
  methods: {
    // 1活动预热前 2活动预热中 3活动进行中 4活动结束
    changeStatus(status) {
      this.countTimeStatus = status
    },
    // 跳转商品详情
    jumpDetail() {
      if (this.disabledJump) {
        return
      }
      this.$emit('jump', this.goodsItem)
      const goodsItem = this.goodsItem
      const { collect, flag, productId } = goodsItem
      let activityId = goodsItem.activityId || goodsItem.marketing?.activityId || 0
      let activityType = goodsItem.activityType || goodsItem.marketing?.activityType || 0
      // 详情获取控销需传orgId
      const orgId = goodsItem.orgId

      // 一口价活动 跳转详情不携带 活动ID
      if (Number(activityType) === 8) {
        activityId = 0
        activityType = 0
      }

      // 收藏点击
      if (collect) {
        this.$message.warning('活动已结束或商品下架啦~')
        return
      }

      // 兼容 我的收藏
      if (this.isEdit || flag) {
        return
      }

      // 赠品
      if (this.isGiftGood) {
        const { giftObj } = this
        const routeData = this.$router.resolve({
          path: '/giftDetail', query: {
            activityId: giftObj.activityId,
            activityType: giftObj.activityType,
            productId
          }
        })
        window.open(routeData.href, '_blank')
        return
      }
      const query = {
        activityId,
        activityType,
        productId,
        orgId
      }
      if (this.$route.query.activityType === '99') {
        const exchangeMarketing = goodsItem?.exchangeMarketing?.[0]
        // 起购限购换购品 取exchangeMarketing
        query.activityId = exchangeMarketing.activityId
        query.isReplace = true
        query.minPurchaseAmountIdentity = exchangeMarketing?.minPurchaseAmountIdentity
        query.maxPurchaseAmountIdentity = exchangeMarketing?.maxPurchaseAmountIdentity
      }
      // 商品详情浮层组件使用缓存数据
      sessionStorage.setItem('Events_goodsItem', JSON.stringify(goodsItem))
      const routeData = this.$router.resolve({ path: '/detail', query })
      window.open(routeData.href, '_blank')
      // 处理不同路径的埋点
      const { name } = this.$route
      console.log('name', name, goodsItem)
      if (name === 'shopHome') {
        this.$zhuge.track('店铺-点击商铺商品', {
          '店铺名称': goodsItem.orgAbbr,
          '商品名称': goodsItem.generalChineseName,
          '生产企业': goodsItem.manufactureCompany,
          '折后价格': goodsItem.currentPrice
        })
      } else if (name === 'Home') {
        this.$zhuge.track('首页-点击商品', {
          '商品名称': goodsItem.generalChineseName,
          '模块名称': this.cmsPositionTitle
        })
      }
    },
    jumpShopHome() {
      const { orgId } = this.goodsItem
      const { path, query } = this.$route
      if (path === '/shopHome' && query.orgId === orgId) {
        return
      }
      window.open(`/shopHome?orgId=${orgId}`)
    },
    enter: debounce(async function() {
      if (!this.showAddCar || getAccountErr(this.goodsItem) || this.prdControl) {
        return
      }
      if (this.$refs.activityTag.specialKeyword === '开始') {
        console.log('未开始的活动')
        return
      }
      // 详情跳转到满换活动专区
      if (this.disAdd === '1' || this.maskInfo === '失效') {
        return
      }
      if (this.isGiftGood && this.goodsItem.availableStock) {
        this.sidebarOpen()
        return
      }
      this.sidebarOpen()
    }, 10),
    leave() {
      // 延迟清除弹窗
      this.showActivityPop = false
      if (this.flag) {
        this.flag = !this.flag
      }

      // const groupBuyPopEle = document.querySelector('.show-activity-pop')
      // if (groupBuyPopEle) {
      //   groupBuyPopEle.style.bottom = '-300px'
      // }
      // if (this.isControl) {
      //   this.isControl = !this.isControl
      // }
    },
    sidebarOpen() {
      // 拼团活动不展示加购
      const { marketing } = this.goodsItem
      if (+marketing?.activityType === 6) {
        this.showActivityPop = true
        // document.querySelector('.show-activity-pop').style.bottom = 0
        return
      }
      const item = this.goodsItem
      // 加购参数
      this.shoppingData = getShoppingData(item, {
        shopName: this.shopName,
        activityId: this.activityId,
        activityType: this.activityType
      })

      // 手动满换活动专区数据处理
      if (this.activityCode === '99') {
        const exchangeMarketing = this.goodsItem?.exchangeMarketing?.[0]
        this.shoppingData = {
          ...this.shoppingData,
          activityId: this.activityId,
          activityType: '32',
          activityPrice: exchangeMarketing?.exchangePrice,
          startBuyNum: exchangeMarketing?.minPurchaseAmountIdentity,
          // maxPurchaseAmountIdentity: exchangeMarketing?.maxPurchaseAmountIdentity,
          ...{ expandMap: { present: true }}
        }
      }
      if (this.isGiftGood) {
        this.shoppingData = {
          ...this.shoppingData,
          ...{
            expandMap: { present: true },
            activityId: this.giftObj.activityId,
            activityType: this.giftObj.activityType,
            addBuyNum: item.singleExchangeNum || 1,
            startBuyNum: item.minPurchaseNum || 1,
            singleLimitNum: item.singleExchangeNum || -1,
            isSingleLimit: 0,
            currentPrice: item.price,
            activityPrice: item.activityPrice,
            mainPicUrl: item.mainPicUrl,
            num: item.num
          }
        }
      }

      this.flag = true
    },
    computedNum(val) {
      this.$emit('changeVal', val, this.goodsItem)
    }
  },
  watch: {
    // 解决 输入框隐藏后
    showActivityPop(n) {
      if (!n) {
        this.$refs.groupBuyPop?.leave()
      } else {
        this.$refs.groupBuyPop?.show()
      }
    }
  }
}
</script>

<style scoped lang="less">
.list-card {
  padding: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 8px;
  width: 230px;
  height: 392px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;

  &:not(.is-cms):hover {
    border: 1px solid #f99d33;
  }
  //&:hover .show-activity-pop {
  //  bottom:0;
  //}
}

.cover-box, .cover-mask {
  width: 198px;
  height: 198px;
  overflow: hidden;
  margin: 0 auto;
}

.card-footer {
  display: flex;
  align-items: center;
  max-width: 198px;
  overflow: hidden;
  padding-top: 8px;

  .shop-name {
    flex: 1;
    overflow: hidden;
    color: var(--font_black-2, rgba(0, 0, 0, 0.60));
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    transition: .3s ease;
    &:hover{
      color:#E67D00;
    }
  }

  .link-info {
    color: #A0703C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.control {
  height: 18px;
  background: #FFECE8;
  text-align: center;
  color: #F53F3F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: -16px;
  margin-right: -16px;
}

.tag-control {
  margin-top: 5px;
}

.title {
  height: 40px;
  line-height: 20px;
  overflow: hidden;
  font-size: 0;
  width: 100%;

  span {
    vertical-align: middle;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.90);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.company {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.60);
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 3px;
  white-space: nowrap;
}

.date {
  overflow: hidden;
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 3px;
  white-space: nowrap;

  .warning {
    color: #E67D00;
  }
}

.price-area-box {
  margin-top: 8px;
  height: 30px;
}

.add-card-box {
  position: absolute;
  left: 0;
  bottom: -1000px;
  transition: 0.3s ease;
  width: 100%;
  height: fit-content;
  z-index: 3;

  &.show {
    bottom: 0;
  }
}

.show-activity-pop {
  position: absolute;
  z-index: 9;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e7e7e7;
  bottom: -300px;
  transition: 0.2s ease-in-out;
  &.show{
    bottom: 0;
  }
}

.product-item:hover .show-activity-pop {
  bottom: 0;
}

.cover-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 16px;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .cover-mask-tip {
    border-radius: 120px;
    padding: 8px 12px;
    background: rgba(0, 0, 0, 0.50);
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

:deep(.cover-box ) {
  display: flex;

  .g-img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .events {
    display: flex;
    justify-content: center;
  }
}

.platform-tag {
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}
</style>
