<template>
  <div v-if="bool" class="pop-wrap" @click="handelClose">
    <div class="img-wrap">
      <div class="content-zone">
        <img v-if="closeIco" src="./assets/close.png" class="close"/>
        <img :src="path" v-show="closeIco" class="img img-node" @load="handelLoad" />
      </div>
      <div
        v-for="(item, index) in hotZoneList || []"
        :key="index"
        :style="{
          left: item.position.left * scale + 'px',
          top: item.position.top * scale + 'px',
          width: item.position.width * scale + 'px',
          height: item.position.height * scale + 'px'
        }"
        class="zone"
        @click="item.link && cmsLink.bind(this)(item.link)"
      ></div>
    </div>
  </div>
</template>
<script>
import { getPropsData, savePropsData } from '@/common/service'
import { cmsLink } from '@/views/home/index'
import { getFilePath } from '@/common/utils/base'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'PopWrap',
  data() {
    return {
      num: 0,
      settingContent: {},
      list: [],
      cmsLink: cmsLink.bind(this),
      scale: 1,
      bool: false,
      dbBool: false,
      path: '',
      hotZoneList: [],
      closeIco: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      setTimeout(() => {
        FingerprintJS.load().then(fp => {
          fp.get().then(result => {
            const deviceCode = result.visitorId
            const temp = {
              typeCode: 'pc',
              deviceCode
            }
            getPropsData(temp).then(res => {
              // 获取弹窗列表
              const list = res.data || []
              if (list.length !== 0) {
                this.list = list
                this.refresh()
              }
            })
          })
        })
      }, 100)
    },
    refresh() {
      const data = this.list[this.num]
      if (!data) {
        return
      }
      const settingContent = JSON.parse(data.settingContent || '') || {}
      this.settingContent = settingContent
      const imageFileId = settingContent.imageFileId
      this.closeIco = false
      this.bool = true
      setTimeout(() => {
        this.path = getFilePath('orig', imageFileId)
        this.handelSavePop(data.id)
        // 坐标位置计算
        this.handelCount()
      }, 100)
      this.dbBool = false
    },
    handelCount() {
      const hotZoneList = this.settingContent.hotZoneList || []
      console.log(hotZoneList)
      if (hotZoneList.length === 0) return
      setTimeout(() => {
        const deepGet = () => {
          const oImg = document.querySelector('.img-node')
          if (oImg.offsetWidth > 0) {
            const viewportWidth = document.documentElement.clientWidth
            const viewportHeight = document.documentElement.clientHeight
            const w = viewportWidth / 2
            const h = viewportHeight / 2

            const offW = oImg.offsetWidth / 2
            const offH = oImg.offsetHeight / 2
            hotZoneList.map((item) => {
              item.position.left += (w - offW)
              item.position.top += (h - offH)
            })

            this.hotZoneList = hotZoneList
          } else {
            setTimeout(() => {
              deepGet()
            }, 100)
          }
        }
        deepGet()
      }, 100)
    },
    handelSavePop(pageId) {
      setTimeout(() => {
        FingerprintJS.load().then(fp => {
          fp.get().then(result => {
            const deviceCode = result.visitorId
            const temp = {
              typeCode: 'pc',
              deviceCode,
              pageId
            }
            savePropsData(temp)
          })
        })
      }, 500)
    },
    handelClose() {
      if (this.dbBool) return
      this.dbBool = true
      this.num += 1
      this.bool = false
      setTimeout(() => {
        this.refresh()
      }, 1000)
    },
    handelLoad() {
      const viewportHeight = document.documentElement.clientHeight
      const oImg = document.querySelector('.img-node')
      const scale = (1 - ((oImg.height / viewportHeight) - 0.5)).toFixed(2)
      if (scale < 1) {
        const oDiv = document.querySelector('.img-wrap')
        console.log(scale)
        oDiv.style.transform = `scale(${scale})`
        this.closeIco = true
      } else {
        this.closeIco = true
      }
    }
  }
}
</script>
<style scoped lang="less">
.pop-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  z-index: 1200;
}
.img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
  .content-zone {
    position: relative;
  }
}
.zone {
  position: absolute;
}
.close {
  width: 48px;
  height: 48px;
  position: absolute;
  right: -64px;
  top: -46px;
}
</style>
