<template>
  <div v-if="show" class="shop-description-wrapper">
    <img class="bg-img" src="/img/home/cover.png">
    <!-- <template v-if="config.contentSet">
      <img v-for="(item,index) in config.contentSet" v-show="item && item.fileId" :key="index" :src="getFilePath('orig', item && item.fileId)" alt="">
    </template> -->
  </div>
</template>

<script>
import { getConfigByCode } from '@/common/service'
import { getFilePath } from '@/common/utils/base'
export default {
  name: 'ShopDescription',
  data() {
    return {
      config: {

      },
      show: false
    }
  },
  computed: {
    wrapperStyle() {
      if (!this.show) {
        return {}
      }
      return {
        // background: `url(${getFilePath('orig', this.config?.bgImage?.fileId)})`,
        // backgroundSize: this.config?.bgImage?.extendWidth === 1 ? '100% 207px' : '1200px 207px'
      }
    }
  },
  async mounted() {
    const { data } = await getConfigByCode('bottom_slogan')
    if (data.configContent && Object.keys(data.configContent).length) {
      this.show = true
    }
    this.config = data.configContent || {}
  },
  methods: {
    getFilePath
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.shop-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  background-size: 100% 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-4, #E7E7E7);
  padding-top: 24px;
  padding-bottom: 24px;
  .bg-img {
    width: 1200px;
  }
  img {
    width: 1200px;
  }
}
</style>
