<template>
  <div class="center">
    <SearchBar style="width: 1200px;margin: 0 auto;" />
    <div class="wrap">
      <Breadcrumb separator=">">
        <BreadcrumbItem class="black_2">个人中心</BreadcrumbItem>
        <BreadcrumbItem class="black_2" to="/batch/batchOrder/index">批量采购</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'RouterContainer',
  watch: {
    $route(to, from) {
      // console.log(to, from)
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  data() {
    return {}
  }

}
</script>
  <style scoped lang="less">
  .center {
    width: 1200px;
    color: rgba(0, 0, 0, 0.9);

  }
  .wrap{
    line-height: 22px; 
    padding-bottom: 24px; 
    font-size: 12px;
  }
  .black_2 {
    a{
      color: rgba(0, 0, 0, 0.60) !important;
    }
    span{
      color: rgba(0, 0, 0, 0.60) !important;
    }
    
  }
  .black_1 {
    a{
      color: rgba(0, 0, 0, 0.90) !important;
    }
    span{
      color: rgba(0, 0, 0, 0.90) !important;
    }
    
  }
  </style>

