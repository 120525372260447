import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 常购清单商品列表
 */
export const getLastBuyProductList = (data) => post({
  url: `${api.baseUrl}/order/cust/getLastBuyProductList`,
  data,
})
/**
 * 常购清单店铺列表
 */
export const getLastBuyOrgList = (data) => post({
  url: `${api.baseUrl}/order/cust/getLastBuyOrgList`,
  data,
})
/**
 * 常购清单批量加购
 */
export const addBatchProduct = (data) => post({
  url: `${api.baseUrl}/order/cart/addBatchProduct`,
  data,
})