const state = {
  refundInfo: {}, // 退款信息
}

const getters = {
  getRefundInfo(state) {
    return state.refundInfo
  },
}

const mutations = {
  SET_REFUND_INFO(state, data) {
    state.refundInfo = data
  },
}

const actions = {
  // 获取分类
  setRefundInfo({ state, commit }, data) {
    commit('SET_REFUND_INFO', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
