import { Message } from 'view-design'

const state = {
  selectedPurchaseList: [], // 常购清单选中商品信息
}

const getters = {

}

const mutations = {
  SET_SELECTED_PURCHASE_LIST(state, data) {
    state.selectedPurchaseList = data
  },
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
