import { makeClient } from '@/common/service/helper'
import { chain } from 'lodash'

const { post, get, del, cancelRequest } = makeClient('')
export { get, post, del, cancelRequest }

// {} to xxx=xxx&xxx=xxx
export const jsonToQueryString = (obj) => {
  return chain(obj)
    .entries()
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
    .value()
}
