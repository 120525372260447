
import Notice from '@/views/explain/notice/index'
import NoticeDetail from '@/views/explain/notice/component/noticeDetail'
export default [
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/notice/detail',
    name: 'NoticeDetail',
    component: NoticeDetail
  }
]
