<template>
  <Form ref="formCustom" :model="certificateInfo" :label-width="150" :label-position="'left'">
    <FormItem label="资质类型" prop="certificateName" v-if="!isAdd" :rules=" [ { required: true, message: '请选择资质类型', trigger: 'change' } ]">
      <Input v-model="certificateInfo.certificateName" type="text" :disabled="true" />
    </FormItem>
    <FormItem label="资质类型" prop="certificateId"  v-else :rules=" [ { required: true, message: '请选择资质类型', trigger: 'change' } ]">
      <Select v-model="certificateInfo.certificateId"  placeholder="请选择资质类型" @on-change="changeCertificate">
        <Option v-for="item in otherCustomerCertList" :key="item.value" :value="item.value">
          <slot>
            {{ item.label }}
          </slot>
        </Option>
      </Select>
    </FormItem>
    <FormItem label="资质图片" prop="custCertPicVoList" :rules=" [ { required: true, validator:validImage ,  trigger: 'change' } ]">
      <CustomerUpload :files.sync="certificateInfo.custCertPicVoList" />
      <div style="color: rgba(0, 0, 0, 0.6); font-size: 12px; padding-top: 10px;">支持图片格式png、jpg等，大小限制在3MB内，最多上传6张</div>
    </FormItem>
   
    <template v-for="cert in certificateInfo.sysCertDisplayFieldVoList">
      <FormItem  :label="cert.fieldName" :prop="cert.fieldCode" border-bottom v-if="isEnter && (cert.fieldCode === 'validEndDate' || cert.fieldCode === 'certNo')" :rules="[
              { required: false, message: cert.fieldName + '不能为空', trigger: 'change', type: cert.fieldCode.indexOf('Date') > -1 ? 'date' :'string' }
            ]">
        <ValidDate v-if="cert.fieldCode.indexOf('Date') > -1"  v-model="certificateInfo[cert.fieldCode]" />
        <Input v-else  v-model="certificateInfo[cert.fieldCode]" :placeholder="'请输入'+cert.fieldName"  />
      </FormItem>
      <FormItem  :label="cert.fieldName" :prop="cert.fieldCode" border-bottom v-if="!isEnter" :rules="[
              { required: cert.required, message: cert.fieldName + '不能为空', trigger: 'change', type: cert.fieldCode.indexOf('Date') > -1 ? 'date' :'string' }
            ]">
        <ValidDate v-if="cert.fieldCode.indexOf('Date') > -1"  v-model="certificateInfo[cert.fieldCode]" />
        <div v-else>
          <Input v-if="cert.fieldCode == 'addressLine' || cert.fieldCode === 'warehouseAddr'" maxlength="200" show-word-limit v-model="certificateInfo[cert.fieldCode]" :placeholder="'请输入'+cert.fieldName" @on-blur="checkEqualLicense(cert.fieldCode,certificateInfo[cert.fieldCode])" />
          <Input v-else  v-model="certificateInfo[cert.fieldCode]" :placeholder="'请输入'+cert.fieldName" @on-blur="checkEqualLicense(cert.fieldCode,certificateInfo[cert.fieldCode])" />
        </div>
      </FormItem>
    </template>

  </Form>
</template>
<script>
import { mapState } from 'vuex'
import CustomerUpload from './CustomerUploadPrivate.vue'
import ValidDate from '@/components/ValidDate.vue'
import dayjs from 'dayjs'
import {
  getCertificates
} from '@/common/service/insertCustInfo'
export default {
  name: 'CertificationChange',
  computed: {
    ...mapState('InfoEnter', ['allAuditCert'])
  },
  components: {
    CustomerUpload,
    ValidDate
  },
  props: {
    isEnter: {
      type: Boolean,
      default: false
    },
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    },
    isAdd:{
      type: Boolean,
      default: false
    },
    otherCustomerCertList:{
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      certificates:[],
      certificateInfo: {
        certificateId: '',
        certificateName: '',
        custCertPicVoList: [],
        sysCertDisplayFieldVoList:[]
      },
    }
  },
  watch:{
    value(newVal){
      this.updateModel(newVal)
    }
  },
  mounted() {
    
  },
  methods: {
    validImage(_rule, value){
      if (!value || value.length === 0) {
        return Promise.reject('请上传图片')
      }
      return Promise.resolve()
    },
    updateModel(newVal){
      this.certificateInfo = JSON.parse(JSON.stringify(newVal))
      if(this.certificateInfo?.displayFieldItemList){
        this.certificateInfo.sysCertDisplayFieldVoList = this.certificateInfo.displayFieldItemList
      }
      if (this.certificateInfo.custCertPicVoList) {
        this.certificateInfo.custCertPicVoList.forEach(item => {
          item.sb = item.watermarkUrl
        })
      }
      if (this.certificateInfo?.sysCertDisplayFieldVoList) {
        this.certificateInfo.sysCertDisplayFieldVoList?.forEach(item => {
          if (item.fieldCode === 'issuanceDate' || item.fieldCode === 'validEndDate') {
            if (item.fieldValue) {
              this.certificateInfo[item.fieldCode] = dayjs(item.fieldValue).format('YYYY-MM-DD')
            }
          } else {
            if (item.fieldValue) {
              this.certificateInfo[item.fieldCode] = item.fieldValue
            }
          }
        })
      }
    },
    changeCertificate(val) {
      const cert = this.otherCustomerCertList.find(item => {
        item.sysCertDisplayFieldVoList = item.displayFieldItemList
        return item.certificateId === val
      })
      this.updateModel(cert)
    },
     // 获取其他资质列表
     async getCertificates() {
      const res = await getCertificates({ custOrgTypeId: this.customerInfo.customerTypeId, customId: this.customerInfo.customerId })
      if (res.code === 200) {
        this.certificates = res.data.map(item => {
          item.value = item.certificateId
          item.label = item.certificateName
          item.sysCertDisplayFieldVoList = item.displayFieldItemList  
          return { ...item }
        })
      }
    },
    validate() {
      this.$refs.formCustom.validate(async(valid) => {
        if (valid) {
          this.certificateInfo.sysCertDisplayFieldVoList?.forEach(item => {
            item.fieldValue = this.certificateInfo[item.fieldCode]
            this.certificateInfo[item.fieldCode] = undefined
          })
          this.$emit('input',this.certificateInfo)
          this.$emit('validSuccess',this.certificateInfo)
        } else {
          this.$Message.error('请检查必填项')
        }
      })
    },
    resetValidate() {
      this.$refs.formCustom.resetFields()
    },
    checkEqualLicense(key, value) {
      if (this.certificateInfo.certificateName?.indexOf('营业执照') > -1) {
        return
      }
      if (!(key === 'addressLine' || key === 'legalPerson')) {
        return
      }
      if (this.allAuditCert) {
        this.allAuditCert.forEach(cert => {
          if (cert.certificateName.indexOf('营业执照') > -1) {
            if (cert.sysCertDisplayFieldVoList) {
              cert.sysCertDisplayFieldVoList.forEach(item => {
                if (item.fieldCode === key) {
                  if (item.fieldValue !== value) {
                    this.$Message.warning({ content: key === 'legalPerson' ? '法定代表人/负责人信息与营业执照不一致，请核对!' : '住所信息与营业执照不一致，请核对!', duration: 10 })
                  }
                }
              })
            }
          }
        })
      }
    },
  }
}
</script>
<style>
.demo-upload-list{
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  margin-right: 4px;
}
.demo-upload-list img{
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover{
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
}
.demo-upload-list:hover .demo-upload-list-cover{
  display: block;
}
.demo-upload-list-cover i{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.back_last {
  margin-left: 16px
}
.ivu-input {
  border-radius: 4px;
}
</style>
