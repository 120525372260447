<template>
  <div class="act_time time-special orange">
    <div class="time_doc">
      距活动{{ activityMsg }}
    </div>
    <ul class="time_num">
      <li v-if="da > 0">{{ da }}</li>
      <span v-if="da > 0">天</span>
      <li>{{ ho }}</li>
      <span>:</span>
      <li>{{ mi }}</li>
      <span>:</span>
      <li>{{ so }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    resObj: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      timeData: {},
      activityMsg: '',
      time: 0,
      status: '', // 1活动预热前 2活动预热中 3活动进行中 4活动结束
      targetTime1: new Date().getTime() + 3900000,
      da: '',
      ho: '',
      mi: '',
      so: ''
    }
  },
  mounted() {
    if (this.resObj.endTime > 0) {
      this.countTime()
    }
  },
  methods: {
    countTime() {
      // 获取当前时间
      var now = new Date().getTime()
      let leftTime
      if (now > this.resObj.endTime) { // 活动结束
        this.$emit('preIng', false)
        return
      } else if (now < this.resObj.endTime) { // 活动进行中
        this.activityMsg = '结束'
        leftTime = this.resObj.endTime - now
      }
      // 定义变量 d,h,m,s保存倒计时的时间
      var d, h, m, s
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        h = Math.floor(leftTime / 1000 / 60 / 60) % 24
        m = Math.floor(leftTime / 1000 / 60 % 60)
        s = Math.floor(leftTime / 1000 % 60)
      }
      this.da = d < 10 ? `0${d}` : d
      this.ho = h < 10 ? `0${h}` : h
      this.mi = m < 10 ? `0${m}` : m
      this.so = s < 10 ? `0${s}` : s
      // 递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 1000)
    }
  }
}
</script>
<style scoped lang='less'>
.act_time {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  // line-height: 18px;
  padding-top: 2px;
  color: #BE5F00;
  .time_doc{
    margin-right:5px;
    font-size: 12px;
  }
}
.time_num {
  display: flex;
  align-items: center;
  li {
    // padding: 0 2px;
    // margin: 0;
    list-style: none;
    font-weight: 400;
    font-size: 12px;
    // border:1px solid #FFBB96;
    // border-radius: 2px;
  }
  span{
    margin:0 2px;
  }
}
.count-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .activity-txt {
    font-weight: 400;
    font-size: 20rpx;
    line-height: 28rpx;
    color: #fa541c;
    margin-right: 8rpx;
  }
  .time-section {
    .time {
      display: flex;
      align-items: center;
      &__custom {
        padding: 5rpx 2rpx 0 2rpx;
        height: 24rpx;
        background: #ffffff;
        border: 1rpx solid #ffbb96;
        border-radius: 4rpx;
        display: flex;
        justify-content: center;
        align-items: center;
        &__item {
          font-weight: 500;
          font-size: 20rpx;
          line-height: 20rpx;
          color: #fa541c;
        }
      }
      &__doc {
        width: 10rpx;
        height: 36rpx;
        text-align: center;
        font-weight: 400;
        font-size: 20rpx;
        line-height: 36rpx;
        color: #fa541c;
      }
    }
  }
}

// 润滑算标签
.time-special{
  padding-left: 21px;
  padding-right: 3px;
  border:0.5px solid;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  display: inline-flex;
  &.green{
    border-color: #4DAC39;
    color:#086453;
    background:#F6FBF5 url("~@/assets/images/notic/time-special-green.png") no-repeat left center;
    background-size:auto 18px;
  }
  &.orange{
    border-color: #FC9C57;
    color:#A63D11;
    background:#FEFAF5 url("~@/assets/images/notic/time-special-orange.png") no-repeat left center;
    background-size:auto 18px;
  }
}
</style>
