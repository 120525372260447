<!--
@description 链接样式的按钮
-->
<template>
  <span class="link-button-wrapper" :style="styleCustom" @click="$emit('click')">
    <slot />
  </span>
</template>
<script>
export default {
  name: 'LinkButton',
  props: {
    textColor: {
      type: String,
      default() {
        return '#ce7720'
      }
    }
  },
  computed: {
    styleCustom() {
      return {
        color: this.textColor
      }
    }
  }
}
</script>
<style scoped lang="less">
.link-button-wrapper {
  color: #ce7720;
  cursor: pointer;
}
</style>
