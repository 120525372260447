export default [
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/product/detail/index')
  },
  {
    path: '/giftDetail',
    name: 'giftDetail',
    component: () => import('@/views/product/detail/giftDetail')
  },
  {
    path: '/shellList',
    name: 'shellList',
    component: () => import('@/views/product/shellList/index')
  },
  {
    path: '/searchRecord',
    name: 'searchRecord',
    component: () => import('@/views/product/lockRecord/searchRecord')
  },
  {
    path: '/detailRecord',
    name: 'detailRecord',
    component: () => import('@/views/product/lockRecord/detailRecord')
  },
  {
    path: '/completeRecord',
    name: 'completeRecord',
    component: () => import('@/views/product/lockRecord/recordComplete')
  },
]