// notice-bar 上下滚动插件
<template>
    <div class="notice-bar">
        <template v-for="(item, _index) in value">
            <!-- <p class="animate__animated" :class="index == _index && 'animate__zoomIn'" :key="_index" v-show="index == _index"> -->
            <p class="animate__animated" :class="[index == _index && start && 'animate__fadeInUp', preIndex == _index && preIndex >- 1 && start && 'animate__fadeOutUp', !start && _index==0 && 'start']" :key="_index" >
                {{item}}
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'NoticeBar',
    props: {
        value: Array
    },
    data() {
        return {
            index: 0,
            preIndex: -1,
            start: false
        }
    },
    computed: {
       
    },
    created() {
    },
    mounted() {
        this.timer && clearInterval(this.timer)
        this.$emit('change', this.value?.at(0))
        setTimeout(()=> {
            this.timer = setInterval(()=> {
                if( this.index < this.value?.length -1 ) {
                    this.preIndex = this.index
                    this.index++
                } else {
                    this.preIndex = this.index
                    this.index = 0
                }
                this.$emit('change', this.value?.at(this.index))
            }, 4000)
            this.start = true
            this.preIndex = this.index
            this.index++
            this.$emit('change', this.value?.at(this.index))
        }, 4000)
    },
    methods: {
        
    },
     destroyed() {
         this.timer && clearInterval(this.timer)
     }
}
</script>

<style lang="less" scoped>
    .notice-bar {
        overflow: hidden;
        position: relative;
        p {
            position: absolute;
            font-size: 16px;
            padding: 6px 7px;
            height: 40px;
            line-height: 28px;
            opacity: 0;
            animation-delay: 0s;
            &.start {
                opacity: 1;
            }
        }
    }
    .animate__animated {
        animation-duration: 1s;
        animation-fill-mode: both;
    }
    .animate__fadeInUp {
        animation-name: fadeInUp;
    }
    @keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes fadeOutUp {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }
    .animate__fadeOutUp {
        -webkit-animation-name: fadeOutUp;
        animation-name: fadeOutUp;
    }
</style>