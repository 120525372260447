<template>
  <div>
    <SearchBar :keyword="keyword" />
    <div class="bread-crumb">
      <Breadcrumb separator="<div class='iconfonts'></div>">
        <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
        <BreadcrumbItem>采购计划</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="purchase-content">
      <div class="search-detail">
        <div class="search">
          <h4 style="font-size: 16px;">采购计划</h4>

          <Select v-model="currentPlanid" style="width:212px" filterable @on-change="changeModel">
            <Option v-for="item in conditionList" :key="item.id" :value="item.id">{{ item.purchasePlanName }}</Option>
          </Select>
        </div>
        <div class="operate">
          <Button type="default" :disabled="!currentPlanid" :loading="exportLoading" @click="exportProduct">导出</Button>
          <Button type="default" @click="delPlanClick">删除采购计划</Button>
          <Button type="default" :disabled="!currentPlanid" @click="editPlanNameClick">修改采购计划名称</Button>
          <Button type="default" :disabled="!currentPlanid" @click="addProduct">添加商品</Button>
          <Button type="primary" @click="createPlanModal = true">新增采购计划</Button>
        </div>
      </div>
      <div class="list-wrap">
        <Table ref="selection" :max-height="height" :loading="dataLoading" :columns="columns" :data="data" />
        <Pagination
          class="pagination"
          :total="totalPage"
          :page-size-opts="[10, 20, 50, 100]"
          :size="pageSize"
          :num="pageNum"
          @change="pageChange"
        />
      </div>

    </div>
    <div style="height: 32px;"></div>
    <UploadFile :modalShow.sync="createPlanModal" @uploadSuccess="uploadSuccess" />
    <AddGood
      :modalShow.sync="createGoodModal"
      :purchasePlanId="currentPlanid"
      :title="title"
      :data="selectGood"
      @saveSuccess="saveSuccess"
    />
    <Modal
      v-model="editPlanNameModal"
      title="修改采购计划名称"
      width="480"
      @on-ok="editPlanName"
      @on-cancel="editPlanNameModal = false"
    >
      <span><span style="color: red;">*</span>采购计划</span>
      <Input
        v-model="purchasePlanName"
        placeholder="请输入"
        show-word-limit
        style="margin-left: 16px; width: 370px"
        maxlength="30"
      />
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="editPlanNameModal = false">取消</Button>
          <Button type="primary" :loading="editPlanNameLoading" @click="editPlanName">确认</Button>
        </div>
      </template>
    </Modal>
    <Modal
      v-model="delPlanModal"
      title="删除采购计划"
      width="480"
      class="del-modal"
      @on-ok="delPlan"
      @on-cancel="delPlanModal = false">
      <span><span style="color: red;">*</span>采购计划</span>
      <Select v-model="delPlanid" style="width:370px;margin-left: 16px;" filterable @on-change="changeModelModal">
        <Option v-for="item in conditionList" :key="item.id" :value="item.id">{{ item.purchasePlanName }}</Option>
      </Select>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="delPlanModal = false">取消</Button>
          <Button type="primary" :loading="delPlanLoading" @click="delPlan">确认</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { getPurchasePlanName, getPurchasePlanData, updatePurchasePlanName, deletePurchasePlan, exportExcel, deletePurchasePlanGoodDetail } from '@/common/service'
import UploadFile from './component/uploadFile.vue'
import AddGood from './component/addGood.vue'
import { returnFloat } from '@/common/utils/format'

export default {
  name: 'PurchasePlan',
  components: {
    UploadFile,
    AddGood
  },
  data() {
    return {
      keyword: '',
      dataLoading: false,
      data: [],
      conditionList: [],
      currentPlanid: 0,
      pageSize: 10,
      pageNum: 1,
      totalPage: 0,
      createPlanModal: false,
      editPlanNameModal: false,
      purchasePlanName: '',
      editPlanNameLoading: false,
      delPlanModal: false,
      delPlanLoading: false,
      exportLoading: false,
      delPlanid: 0, // 选中要删除的采购计划
      createGoodModal: false,
      title: '',
      selectGood: {},
      delGoodDetailLoading: false,
      columns: [
        {
          title: '客户商品编码',
          key: 'customerProductNo',
          width: 90
        },
        {
          title: '商家商品编码',
          key: 'orgProductNo',
          width: 90
        },
        {
          title: '商家名称',
          key: 'orgName',
          width: 136
        },
        {
          title: '商品名称',
          key: 'productName',
          width: 174
        },
        {
          title: '规格',
          key: 'productSpec',
          width: 90
        },

        {
          title: '生产企业',
          key: 'manufactureCompany',
          width: 174
        },
        {
          title: '批准文号',
          key: 'approvalNumber',
          width: 128
        },
        {
          title: '采购价',
          key: 'purchasePrice',
          width: 90
        },
        {
          title: '采购数量',
          key: 'purchaseNum',
          width: 90
        },
        {
          title: '操作',
          key: 'productNo',
          width: 90,
          render: (h, params) => {
            return h('div', [
              h('span', {
                class: {
                  'action': true
                },
                on: {
                  click: () => {
                    this.addProduct('edit', params.row)
                  }
                }
              }, '修改'),
              h('span', {
                class: {
                  'action': true
                },
                style: {
                  'margin-left': '16px'
                },
                on: {
                  click: () => {
                    this.remove('single', params.row)
                  }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  },
  computed: {
    height() {
      return document.body.clientHeight - 344
    }
  },
  created() {
    this.getPurchasePlanName()
  },
  methods: {
    changeModel(value) {
      this.currentPlanid = value
      this.pageNum = 1
      this.getPurchasePlanData()
    },
    // 查询采购计划名称列表
    async getPurchasePlanName() {
      try {
        const res = await getPurchasePlanName()
        if (res.code === 200 && res.data?.length > 0) {
          this.conditionList = res.data || []
          this.currentPlanid = this.conditionList[0]?.id
          this.getPurchasePlanData()
        }
      } catch (error) {
        this.$message.error(error?.data?.message)
      }
    },

    // 查询当前选中采购计划的商品明细列表
    async getPurchasePlanData() {
      if (!this.currentPlanid) return
      this.dataLoading = true
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        condition: {
          purchasePlanId: this.currentPlanid
        }
      }
      try {
        const res = await getPurchasePlanData(params)
        this.dataLoading = false
        if (res.code === 200) {
          const data = res.data?.records ?? []
          data.map((item) => {
            const purchasePrice = item.purchasePrice || 0
            if (purchasePrice !== 0) {
              item.purchasePrice = returnFloat(item.purchasePrice)
            }
          })
          this.data = res.data?.records || []
          this.totalPage = res.data?.total ? res.data.total : 0
        }
      } catch (error) {
        this.dataLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    pageChange(val) {
      // console.log('val',val)
      this.pageNum = val.pageNumber
      this.pageSize = val.pageSize
      this.getPurchasePlanData()
    },
    // 添加/修改商品
    addProduct(type, data) {
      this.createGoodModal = true
      if (type === 'edit') {
        this.title = '修改商品'
        this.selectGood = data
      } else {
        this.title = '添加商品'
        this.selectGood = {}
      }
    },
    // 删除商品
    remove(type, data) {
      if (type === 'single') {
        this.delGoodDetailLoading = true
        this.$Modal.confirm({
          title: '提示',
          content: '是否删除该商品？',
          onOk: async() => {
            const params = [
              data.id
            ]
            try {
              const res = await deletePurchasePlanGoodDetail(params)
              this.delGoodDetailLoading = false
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.getPurchasePlanData()
              } else {
                this.$message.error('删除失败')
              }
            } catch (error) {
              this.delGoodDetailLoading = false
              this.$message.error(error?.data?.message)
            }
          }
        })
      }
    },
    saveSuccess() {
      this.getPurchasePlanData()
    },
    // 点击修改采购计划名称
    editPlanNameClick() {
      this.editPlanNameModal = true
      this.purchasePlanName = this.conditionList.find(item => item.id === this.currentPlanid)?.purchasePlanName
    },
    // 确认修改采购计划名称
    async editPlanName() {
      if (!this.purchasePlanName) {
        this.$message.info('采购计划名称不能为空')
        this.editPlanNameModal = true
        return false
      } else {
        const params = {
          id: this.currentPlanid,
          purchasePlanName: this.purchasePlanName.trim()
        }
        this.editPlanNameLoading = true
        try {
          const res = await updatePurchasePlanName(params)
          this.editPlanNameLoading = false
          if (res.code === 200) {
            this.$message.success('修改成功')
            this.editPlanNameModal = false
            // 采购计划名称更新为新修改成功的名称
            const currentPlan = this.conditionList.find(item => item.id === this.currentPlanid)
            this.$set(currentPlan, 'purchasePlanName', this.purchasePlanName.trim())
          }
        } catch (error) {
          this.editPlanNameLoading = false
          this.$message.error(error?.data?.message)
        }
      }
    },
    // 点击删除采购计划
    delPlanClick() {
      this.delPlanModal = true
      this.delPlanid = this.currentPlanid
    },
    changeModelModal(value) {
      this.delPlanid = value
    },
    // 确认删除采购计划
    delPlan() {
      if (!this.delPlanid) {
        this.$message.info('采购计划名称不能为空')
        return false
      }
      this.$Modal.confirm({
        title: '确认删除此采购计划吗',
        content: '',
        onOk: async() => {
          const params = {
            id: this.delPlanid
          }
          this.delPlanLoading = true
          try {
            const res = await deletePurchasePlan(params)
            this.delPlanLoading = false
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.delPlanModal = false
              // 删除成功, 此处逻辑较繁琐，
              // 如果删除的采购计划id 与 当前展示采购计划id一致，就清空数据
              if (this.currentPlanid === this.delPlanid) {
                const index = this.conditionList.findIndex(item => item.id === this.delPlanid)
                this.conditionList.splice(index, 1)
                // this.currentPlanid = 0;
                this.data = []
              } else {
                // 如果删除的采购计划id 与 当前展示采购计划id不一致，还展示当前数据，但是采购名称下拉列表不能有被删除的采购计划名称
                const index = this.conditionList.findIndex(item => item.id === this.delPlanid)
                this.conditionList.splice(index, 1)
              }
            }
          } catch (error) {
            this.delPlanLoading = false
            this.$message.error(error?.data?.message)
          }
        },
        onCancel: () => {

        }
      })
    },
    // 采购计划上传成功
    uploadSuccess() {
      this.getPurchasePlanName()
    },
    // 导出采购计划商品明细
    exportProduct() {
      const params = {
        id: this.currentPlanid
      }
      this.exportLoading = true
      exportExcel(params).then(data => {
        this.exportLoading = false
        const blob = new Blob([data], { type: 'application/octet-stream' })// res.data 为接口返回的数据，依实例而行
        const URL = window.URL.createObjectURL(blob)// 生成一个 Blob URL
        this.createExportUrl(URL)
      }).catch(() => {
        this.exportLoading = false
        this.$message.error('导出失败')
      })
    },
    // 生成导出文件路径
    createExportUrl(URL) {
      const time = dayjs(new Date()).format('YYYY年MM月DD HH时mm分ss秒')
      const fileName = '采购计划导出-' + time + '.xlsx'// 文件名+后缀
      const listNode = document.createElement('a')
      listNode.download = fileName
      listNode.style.display = 'none'
      listNode.href = URL // 文件流生成的url
      document.body.appendChild(listNode)
      listNode.click()// 模拟在按钮上实现一次鼠标点击
      document.body.removeChild(listNode)// 移除 a 标签
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  .ivu-table-cell {
    padding: 0;

    span {
      color: var(--font-black-2, rgba(0, 0, 0, 0.90));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  th {
    background: none;
    border-bottom: none;
    border-right: 1px solid #eee;
    padding: 0 8px;

    &:last-child {
      border-right: none;
    }
  }
}

:deep(.ivu-table-tbody) {
  .ivu-table-cell {
    padding: 0;
  }

  tr {
    border-bottom: 1px solid var(--gray-2, #EEE);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    font-size: 12px;
    border-bottom: none;
    vertical-align: top;
    background: none;
    border-right: 1px solid #eee;
    padding: 4px 8px;
    height: 36px;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.9);

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-row-hover {
    td {
      background: #FFF8F0 !important;
    }
  }
}

:deep(table) {
  border-spacing: 16px 0;
  border-collapse: collapse;
  width: 1135px !important;
  height: 36px;
}

:deep(.ivu-table) {
  border-radius: 8px;

  &:before {
    display: none;
  }
}

:deep(.ivu-table-body) {

  border: 1px solid var(--gray-2, #EEE);
  border-top: none;
}

:deep(.ivu-modal-header) {
  border-bottom: none;
}

:deep(.ivu-modal-footer) {
  border-top: none;
}

:deep(.action) {
  color: var(--hbrand-6, #3853E2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }
}

.bread-crumb {
  line-height: 22px;
  font-size: 14px;
  padding: 0px 24px 24px 0px;
  width: 1200px;
  margin: auto;
}

.purchase-content {
  width: 1200px;
  background: #fff;
  padding: 24px;
  height: calc(100vh - 215px);
  margin: auto;

  .search-detail {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search {
      display: flex;
      align-items: center;

      h4 {
        margin-right: 16px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      img {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }

    .operate {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .list-wrap {

    .ivu-table-wrapper {
      min-height: calc(100vh - 336px);
      overflow-y: auto;
    }
  }
}
</style>