<template>
  <div class="box">
    <div class="caption">
      开票信息
      <span class="caption-right" @click="changeInfo">{{ text }}</span>
    </div>
    <div v-if="isShowAdd && companyInfo.customTypeChangeState !== 'under_auth'" class="border-bottom-dashed padding-bottom-20 margin-bottom-20">
      <Button icon="md-add" class="add-btn" @click="toPage('/center/enterprise/change', 'Invoice', 'add')">新增开票信息</Button>
    </div>
    <!-- {{ info }} -->
    <div v-for="item in info" :key="item.id">
      <div style="display: flex; align-items: center; gap: 10px;">
        <CustomerTag v-if="isShow" :color="item.state" :name="formatState(item.state)" :reason="item.rejectReason" />
        <!-- <CustomerTag v-if="!isShow" :type="'dot'" :color="item.approvalState" :name="item.approvalStateDesc" :reason="item.rejectReason" /> -->
      </div>
     
      <!-- <CustomerTag v-if="!isShow" :type="'dot'" :color="item.approvalState" :name="item.approvalStateDesc" :reason="item.rejectReason" /> -->
      <div class="box-content">
        <CustomerSteps v-if="!isShow" :nodes="item.historyLogList" :reject-reason="item?.rejectReason" />
        <div v-if="!isShow" class="border-right-dashed" />
        <div class="list">
          <div class="list-item">
            <span class="label">发票类型</span>
            <span class="value"> {{ item.invoiceTypeDesc  }}</span>
          </div>
          <div class="list-item">
            <span class="label">发票抬头</span>
            {{ item.invoiceHeader }}
          </div>
          <div class="list-item">
            <span class="label">单位税号</span>
            {{ item.unitTaxNo }}
          </div>
          <div v-if="item.invoiceType === 'vat_special_invoice'" class="list-item">
            <span class="label">注册地址</span>
            <span class="value"> {{ item.registeredAddress  }}</span>
          </div>
          <div v-if="item.invoiceType === 'vat_special_invoice'" class="list-item">
            <span class="label">注册电话</span>
            {{ item.registeredPhone }}
          </div>
          <div v-if="item.invoiceType === 'vat_special_invoice'" class="list-item">
            <span class="label">开户行</span>
            {{ item.depositBank }}
          </div>
          <div v-if="item.invoiceType === 'vat_special_invoice'" class="list-item">
            <span class="label">银行账号</span>
            {{ item.bankAccount }}
          </div>
          <div class="list-item">
            <!-- <Button v-if="isShow && item.state === 'auth'" @click="toPage('/center/enterprise/change', 'Invoice', 'edit', item)">变更</Button>
            <Button v-if="isShow && item.state === 'refuse_auth'" @click="toPage('/center/enterprise/change', 'Invoice', 'edit', item)">完善</Button>
            <Button v-if="!isShow && item.approvalState === 'refuse_auth' && refuseNum < 2" @click="toPage('/center/enterprise/change', 'Invoice', 'edit', item)">完善</Button>
            <router-link v-if="!isShow && item.approvalState === 'refuse_auth' && refuseNum > 1" to="/center/enterprise/batch">
              <Button>
                完善
              </Button>
            </router-link> -->

            <div v-if="item.approvalState !== 'under_auth' && companyInfo.customTypeChangeState !== 'under_auth'">
              <router-link v-if="refuseNum > 1 && item.approvalState == 'refuse_auth'" to="/center/enterprise/batch">
                <Button class="reBtn">
                  变更
                </Button>
              </router-link>
              <Button v-else class="reBtn" @click="toPage('/center/enterprise/change', 'Invoice', 'edit', item)">变更</Button>
            </div>
          </div>
          <!-- <div class="list-item">
            <Button>
              <router-link to="/center/enterprise/batch">批量完善</router-link></Button>
          </div> -->
        </div>
      </div>
      <!-- <div class="border-bottom-dashed margin-bottom-20" /> -->
    </div>
    <div v-if="data.length === 0 " style="color: rgba(0, 0, 0, 0.6); text-align: center; line-height: 22px;">
      <img src="../../../../assets/center/invoice.png">
      <div>请补充开票信息</div>
    </div>
  </div>
</template>
<script>
import {
  getApprovalNodeList,
  getApprovalAllInvoiceInfo
} from '@/common/service/insertCustInfo'
import { getStorageSync } from '@/common/utils/storage'
import CustomerTag from './CustomerTag.vue'
import CustomerSteps from './CustomerSteps.vue'
export default {
  name: 'Invoice',
  components: {
    CustomerTag,
    CustomerSteps
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    companyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refuseNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: true,
      info: null,
      afterChange: null
    }
  },
  computed: {
    text() {
      return this.isShow ? '查看变更' : '返回'
    },
    isShowAdd() {
      return this.info.length < 2
    }
  },
  watch: {
    isShow(newValue) {
      if (newValue) {
        this.info = [...this.data]
      } else {
        this.info = [...this.afterChange]
      }
    }
  },
  created() {
    this.info = [...this.data]
    console.error('this.info', this.info)
    this.getNodeList()
  },
  methods: {
    changeInfo() {
      this.isShow = !this.isShow
    },
    toPage(path, componentName, scene, data) {
      console.log('data', data)
      if (scene === 'edit') {
        this.$store.dispatch('InfoEnter/SET_CONSIGNEE_INFO', {
          customerId: data.customerId,
          id: data.id,
          invoiceType: data.invoiceType, // 发票类型
          invoiceHeader: data.invoiceHeader, // 发票抬头
          unitTaxNo: data.unitTaxNo, // 单位税号
          registeredAddress: data.registeredAddress, // 注册地址
          registeredPhone: data.registeredPhone, // 注册电话
          depositBank: data.depositBank, // 开户银行
          bankAccount: data.bankAccount, // 银行账号
          isDefault: data.isDefault
        })
      } else {
        const { customerId, customName, threeDocMerger } = getStorageSync('customerInfo')
        this.$store.dispatch('InfoEnter/SET_CONSIGNEE_INFO', {
          customerId,
          invoiceType: 'plain_invoice',
          invoiceHeader: customName,
          unitTaxNo: threeDocMerger
        })
      }
      this.$router.push({
        path,
        query: {
          name: componentName,
          scene
        }
      })
    },
    // 获取节点
    async getNodeList() {
      const { code, data } = await getApprovalNodeList()
      if (code === 200) {
        this.nodes = data
        this.nodes.forEach(item => {
          item.approvalState = 'under_auth'
          item.approvalName = '待审核'
          item.updateTime = ''
        })
        this.getAllInvoiceInfo()
      }
    },
    // 获取发票信息
    async getAllInvoiceInfo() {
      const { code, data } = await getApprovalAllInvoiceInfo()
      if (code === 200) {
        this.afterChange = data.map(item => {
          item.historyLogList[0].approvalState = 'auth'
          item.historyLogList[0].nodeName = '我'
          item.historyLogList[0].approvalName = '发起申请'
          if (item.historyLogList.length === 1) {
            item.historyLogList.push(this.nodes[1], this.nodes[2])
          }
          if (item.historyLogList.length === 2 && item.historyLogList[1].approvalState !== 'refuse_auth') {
            item.historyLogList.push(this.nodes[2])
          }
          const historyLogList = item.historyLogList.map(ih => {
            ih.title = `${ih.nodeName}.${ih.approvalName ? ih.approvalName : '待审核'}`
            return { ...ih }
          })
          return {
            ...item.approvalMyInvoiceWxVo,
            historyLogList
          }
        })
      }
    },
    formatState(val) {
      const enumObj = {
        'under_auth': '认证中',
        'refuse_auth': '未通过',
        'auth': '已认证'
      }
      return enumObj[val]
    }
  }
}
</script>
<style scoped lang="less">
.list {
  padding-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 14px;
    .label {
      width: 88px;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.4);
    }

    .value{
      flex: 1;
    }
  }
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.caption-right:hover{
  color: #3853E2;
  cursor: pointer;
}

.caption-right{
  color: var(--font_black-3, rgba(0, 0, 0, 0.40));
  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.border-right-dashed {
    border-right: #dcdcdc 1px dashed;
  }
</style>
