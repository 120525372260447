// 购物车商品异常状态码
const GOOD_CODE = {
  '100': '价格变动',
  '101': '库存不足，数量修改失败',
  '102': '起购不足',
  '103': '加购不足',
  '104': '超出限购',
  '105': '该商品已达限购数量',
  '110': '已下架',
  '111': '上架',
  '112': '活动结束',
  '113': '商品限购',
  '120': '售罄',
  '130': '无采购权限',
  '140': '活动失效',
  '150': '未定价',
  '156': '匹配到多商品,请手动选择',
  '106': '日限购已达上限',
  '107': '月限购已达上限',
  '121': '商品不存在'
}

// 商品活动类型  2润划算 11循环满减 12阶梯满减 13满折活动 31自动换购 32手动换购 5近效期 6拼团 71自动满赠 72手动满赠
// 14循环单价减 15阶梯单价减 8一口价
const ACTIVITY_TYPE = {
  'SPECIAL': '2',
  'CYCLEMINUS': '11',
  'LADDERMINUS': '12',
  'DISCOUNT': '13',
  'AUTOEXCHANGE': '31',
  'MANUALEXCHANGE': '32',
  'FIXEDCOMBINATION': '41',
  'CUSTOMCOMBINATION': '42',
  'NEARTERM': '5',
  'GROUPBUY': '6',
  'GIFT_AUTO': '71',
  'GIFT_MANUAL': '72',
  'SING_CYCLE_MINUS': '14',
  'SING_LADDER_MINUS': '15',
  'FIXED_PRICE': '8'
}
// 控销校验
const CONTROL_MSG = {
// '2': '不在商品的销售范围内',
  // '3': '无采购权限',
  // // '4': '商品未定价',
  // '4': '',
  // '11': '不在商品的销售范围内',
  // '21': '不能采购商家控销的商品,若有疑问联系商家',
  // '22': '不能采购商家控销的商品,若有疑问联系商家',
  // '31': '超出商品经营范围',
  // '32': '超出商品经营范围',
  // '33': '超出商品经营范围',
  // '41': '不在商品的销售范围内',
  // '42': '您不能采购商家控销品种',
  // '51': '商品已下架',
  // '52': '商品已下架',
  // '53': '暂停销售,如有疑问联系商家'
// 文档地址 https://app.rwork.crc.com.cn/sheets/shtk9W4b736wcbZUGfc15vdvrEh
  // 2: '超出经营范围',
  // 3: '',
  // 4: '',
  // 11: '超经营范围',
  // 21: '超控销范围',
  // 22: '超控销范围',
  // 31: '超经营范围',
  // 32: '超经营范围',
  // 33: '超经营范围',
  // 41: '超区域限制',
  // 42: '超控销范围',
  // 51: '已下架',
  // 52: '已下架',
  // 53: '超经营范围'
  // 2024-8-12 1.37.0需求要求修改提示信息
  2: '超出经营范围',
  3: '',
  4: '',
  11: '指定客户类型可买',
  21: '指定控销组可买',
  22: '指定控销组可买',
  31: '超经营范围',
  32: '超经营范围',
  33: '超经营范围',
  41: '指定区域可买',
  42: '商家系统控销',
  51: '已下架',
  52: '已下架',
  53: '店铺限销'
}
export {
  GOOD_CODE,
  ACTIVITY_TYPE,
  CONTROL_MSG
}
