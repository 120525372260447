<template>
  <div class="box">
    <div class="caption">
      基础信息
      <span class="caption-right" @click="changeInfo">{{ text }}</span>
    </div>
    <div>
      <!-- {{ info }} -->
      <CustomerTag v-if="isShow" :color="companyInfo.customerState" :name="formatState(companyInfo.customerState)" :reason="companyInfo.rejectReason" />
      <!-- <CustomerTag v-if="!isShow" :type="'dot'" :color="data.approvalState" :name="data.approvalStateDesc" :reason="data.rejectReason" /> -->
    </div>
    <div class="box-content">
      <CustomerSteps v-if="!isShow && info.historyLogList.length > 0" :nodes="info.historyLogList" :reject-reason="info?.rejectReason" />
      <div v-if="!isShow" class="border-right-dashed" />
      <div class="list">
        <div class="list-item">{{ info.customName }}</div>
        <div class="list-item">
          <span class="label">统一社会信用代码</span>
          {{ info.threeDocMerger }}
        </div>
        <div class="list-item" style="display: flex;align-items: center;">
          <span class="label">企业类型</span>
          {{ info.customerTypeName }}
          <Poptip trigger="hover">
            <img src="@/assets/images/common/alert-circle.png" style="width: 18px;margin-left: 3px;margin-top: 6px;">
            <template #content>
              <div class="hint">
                {{ custTypehint }}
                <a v-if="companyInfo.customTypeChangeState === 'no' || companyInfo.customTypeChangeState === 'refuse_auth' || companyInfo.customTypeChangeState === 'auth'" @click="toChange()">申请变更<Icon type="ios-arrow-forward" /></a>
              </div>
            </template>
          </Poptip>
        </div>
        <div class="list-item">
          <span class="label">法人</span>
          {{ info.legalPerson }}
        </div>
        <div class="list-item">
          <span class="label">省市区</span>
          {{ info.districtName }}
        </div>
        <div class="list-item">
          <span class="label">注册地址</span>
          <span class="value"> {{ info.addressLine }}</span>
        </div>
        <div class="list-item">
          <!-- <Button v-if="isShow && info.state != 'under_auth'" @click="toPage('/center/enterprise/change', 'CompanyInfo', 'edit')">{{ info.state
            === 'auth' ? "变更" : "完善" }}</Button>
          <Button v-if="!isShow && info.approvalState === 'refuse_auth' && refuseNum < 2" @click="toPage('/center/enterprise/change', 'CompanyInfo', 'edit')">完善</Button>
          <router-link v-if="!isShow && info.approvalState === 'refuse_auth' && refuseNum > 1" to="/center/enterprise/batch">
            <Button>
              完善
            </Button>
          </router-link> -->

          <div v-if="info.approvalState !== 'under_auth' && companyInfo.customTypeChangeState !== 'under_auth'">
            <router-link v-if="refuseNum > 1 && info.approvalState == 'refuse_auth'" to="/center/enterprise/batch">
              <Button class="reBtn">
                变更
              </Button>
            </router-link>
            <Button v-else class="reBtn" @click="toPage('/center/enterprise/change', 'CompanyInfo', 'edit', info)">变更</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  getMyCustomerInfoAPI,
  getApprovalNodeList,
  getApprovalCustInfo
} from '@/common/service/insertCustInfo'
import CustomerTag from './CustomerTag.vue'
import CustomerSteps from './CustomerSteps.vue'
export default {
  name: 'BaseInfo',
  components: {
    CustomerTag,
    CustomerSteps
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refuseNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      info: null,
      isShow: true,
      nodes: [],
      afterChangeInfo: null,
      companyInfo: {}
    }
  },
  computed: {
    custTypehint() {
      if (this.companyInfo?.customTypeChangeState === 'no') {
        return '客户类型不正确？'
      } else if (this.companyInfo?.customTypeChangeState === 'under_auth') {
        return '客户类型变更审核中，请耐心等待。'
      } else if (this.companyInfo?.customTypeChangeState === 'refuse_auth') {
        return '客户类型变更失败！'
      } else { return '已成功变更客户类型，请知晓。' }
    },
    text() {
      return this.isShow ? '查看变更' : '返回'
    },
    ...mapState('InfoEnter', ['baseInfo', 'consigneeInfo', 'certificationInfo', 'entrustInfo'])
  },
  created() {
    this.info = { ...this.data }
    console.error('www', this.info)
    this.getNodeList()
    this.getCust()
  },
  methods: {
    toPage(path, componentName, scene) {
      this.$store.commit('InfoEnter/SET_BASE_INFO', { ...this.data })
      this.$router.push({
        path,
        query: {
          name: componentName,
          scene
        }
      })
    },
    changeInfo() {
      if (this.isShow) {
        // console.log
        this.$set(this, 'info', this.afterChangeInfo)
      } else {
        this.$set(this, 'info', this.data)
      }
      this.isShow = !this.isShow
    },
    // 获取节点
    async getNodeList() {
      const { code, data } = await getApprovalNodeList()
      if (code === 200) {
        this.nodes = data
        this.nodes.forEach(item => {
          item.approvalState = 'under_auth'
          item.approvalName = '待审核'
          item.updateTime = ''
        })
        this.getCustInfo()
      }
    },
    // 获取客户信息
    async getCustInfo() {
      const { code, data } = await getApprovalCustInfo({
        customerId: this.data.customerId
      })
      if (code === 200) {
        console.log('datadatadata', data)
        data.historyLogList[0].approvalState = 'auth'
        data.historyLogList[0].nodeName = '我'
        data.historyLogList[0].approvalName = '发起申请'
        if (data.historyLogList.length === 1) {
          data.historyLogList.push(this.nodes[1], this.nodes[2])
        }
        if (data.historyLogList.length === 2 && data.historyLogList[1].approvalState !== 'refuse_auth') {
          data.historyLogList.push(this.nodes[2])
        }
        const historyLogList = data.historyLogList.map(ih => {
          ih.title = `${ih.nodeName}.${ih.approvalName ? ih.approvalName : '待审核'}`
          return { ...ih }
        })
        this.afterChangeInfo = { ...this.info, ...data.approvalCustCustomerInfoWxVo, historyLogList }
        console.log('1111', this.afterChangeInfo)
      }
    },
    formatState(val) {
      const enumObj = {
        'under_auth': '认证中',
        'refuse_auth': '未通过',
        'auth': '已认证'
      }
      return enumObj[val]
    },
    async getCust() {
      const res = await getMyCustomerInfoAPI()
      if (res.code === 200) {
        this.companyInfo = res.data
      }
    },
    toChange() {
      this.$router.push({
        name: 'CustTypeChange',
        query: {
          name: 'CustTypeChange'
        }
      })
    }
  }

}
</script>
<style scoped lang="less">
.list {
  padding-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 14px;
    .label {
      width: 88px;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.4);
    }

    .value{
      flex: 1;
    }

    .hint{
      display: flex;
      color: var(--gray-2, #EEE);
      gap: 2px;

      /* regular/14 */
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */

    a{
      color: var(--brand-6, #F99D33);
      /* regular/14 */
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      }
    }

    ::v-deep .ivu-poptip-inner{
      background-color:rgba(0, 0, 0, 0.90)
    }

    ::v-deep .ivu-poptip-popper[x-placement^="top"] .ivu-poptip-arrow:after{
      border-top-color:rgba(0, 0, 0, 0.90)
    }
  }

  &-item:last-child {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.caption-right:hover{
  color: #3853E2;
  cursor: pointer;
}

.caption-right{
  color: var(--font_black-3, rgba(0, 0, 0, 0.40));
  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

</style>
