<template>
  <div class="certification">
    <div class="certification-description">
      为保障采购合规，需要您提供相关证件信息进行审核。上传证件需为 <span>盖公章的复印件</span> ，提交后将及时为您审核。
    </div>
    <Form ref="entrustForm" style="margin: 0 218px;" :model="model" :rules="ruleValidate" :label-width="150" :label-position="'left'">
      <FormItem label="委托人姓名" prop="name">
        <Input v-model="model.name" maxlength="15" show-word-limit placeholder="请输入本人姓名,同被委托人" />
      </FormItem>
      <FormItem label="委托人身份证号" prop="idCard">
        <Input v-model="model.idCard" maxlength="18" show-word-limit placeholder="请输入委托人身份证号" />
      </FormItem>
      <FormItem label="身份证有效期至" prop="idCardEndActiveDate">
        <DatePicker v-show="!model.longTime" v-model="model.idCardEndActiveDate" :options="options" :disabled="disabledDateForm" type="date" placeholder="请选择委托人身份证号有效期截止日期" style="width: 80%" />
        <Input v-show="model.longTime" value="长期" disabled style="width: 80%;" />
        &nbsp;
        <Checkbox v-model="model.longTime"> 长期</Checkbox>
      </FormItem>
    </Form>
    <div class="card-list">
      <div v-for="(item, index) in customerAgentCertList" :key="item.certificateId" class="card-list-item">
        <div v-if="item.custCertPicVoList?.length > 0" class="card-list-item-img">
          <div class="preview" @click="previewPic(item.custCertPicVoList)">
            <Icon type="ios-eye" size="20" color="#fff" />
          </div>
          <swiper
            :ref="'mySwiper' + item.certificateId"
            v-viewer
            :options="swiperOption"
          >
            <swiper-slide v-for="p in item.custCertPicVoList" :key="p.fileId">
              <img :src="p.url" alt="" mode="aspectFit" style="width: 110px;height: 110px;">
            </swiper-slide>
          </swiper>
        </div>
        <div class="card-list-item-content">
          <Poptip trigger="hover" placement="bottom" :content="item.certificateName">
            <div class="name" :class="{required: item.isRequire === '1' || item.isRequire === 1}">{{ item.certificateName && item.certificateName.length > 14 ? item.certificateName.substring(0,12)+"..." : item.certificateName }}</div>
            <!-- <Button>Hover</Button> -->
          </Poptip>
          <!-- <div>{{ item.certNo }}</div> -->
          <div v-for="display in item.sysCertDisplayFieldVoList">{{ display.fieldName + '：' + (display.fieldCode.indexOf("Date") > -1 ? formatLongDate(display.fieldValue) : display.fieldValue) }}</div>
          <div style="padding: 5px 0;">
            <span style="color: #3853E2;" @click="handleView([item.diagram])">查看示例</span>
            <Tooltip v-if="item.tips" :content="item.tips">
              <span style=" margin-left: 5px; color: rgba(0, 0, 0, 0.25)">
                <Icon type="ios-information-circle" /></span>
            </Tooltip>
          </div>
          <Button @click="edit(index, item)">上传信息</Button>
        </div>
      </div>
    </div>
    <div style="text-align: center; padding-top: 20px;">
      <Button :disabled="disabled" type="primary" :loading="btnLoading" @click="submit">提交</Button>
    </div>
    <!--弹框-->
    <Modal
      v-model="modal"
      title="证件信息"
      :width="600"
    >
      <CertificationChange ref="certRef" v-model="model2" @validSuccess="validSuccess" />
      <template #footer>
        <Button @click="cancel">取消</Button>
        <Button type="primary" @click="ok">确定</Button>
      </template>
    </Modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { getCertByCustIdAPI, getCustInfoAPI, bindAccount, getCustomerCertificateListAPI } from '@/common/service/insertCustInfo'
import { getFilePath } from '@/common/utils/base'
import { formatLongDate } from '@/common/utils/format'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import CustomerUpload from '../../information/components/CustomerUploadPrivate.vue'
import ValidDate from '@/components/ValidDate.vue'

import CertificationChange from '@/views/personnal/information/components/CertificationChange.vue'
export default {
  name: 'Entrust',
  components: {
    swiper,
    swiperSlide,
    CustomerUpload,
    ValidDate,
    CertificationChange
  },
  props: {
    customId: {
      type: String,
      default: ''
    },
    edited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      btnLoading: false,
      getFilePath,
      formatLongDate,
      model: {
        name: '',
        idCard: '',
        idCardEndActiveDate: null,
        isLongTime: []
      },
      customerAgentCertList: [],
      ruleValidate: {
        name: [
          { required: true, message: '请输入本人姓名,同被委托人', trigger: 'blur' }
        ],
        idCard: [
          { required: true, type: 'regexp', validator: (rule, value, callback) => {
            const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            if (!value) {
              return callback(new Error('请输入身份证号'))
            }
            if (!reg.test(value)) {
              callback(new Error('身份证号格式错误'))
            } else {
              callback()
            }
          }, message: '请输入委托人身份证号', trigger: 'blur' }
        ],
        idCardEndActiveDate: [
          { required: true, type: 'date', message: '请选择委托人身份证号有效期截止日期', trigger: 'change' }
        ]
      },
      disabledDateForm: false,
      disabled: true,
      swiperOption: {
        // 分页器设置
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        notNextTick: true,
        // 循环
        loop: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        // grabCursor : true,
        // 滑动之后回调函数
        on: {
          slideChangeTransitionEnd: function() {
            // console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          }
        }
      },
      currentCustomer: 0,
      modal: false,
      isEdit: true,
      disabledDate: false,
      model2: {
        certificateId: '',
        certificateName: '',
        custCertPicVoList: [],
        // certNo: '',
        validEndDate: null
      },
      ruleValidate2: {
        certificateId: [
          { required: true, message: '请选择资质类型', trigger: 'blur' }
        ],
        custCertPicVoList: [
          { required: true, validator: (_rule, value) => {
            if (!value || value.length === 0) {
              return Promise.reject('请上传图片')
            }
            return Promise.resolve()
          }, trigger: 'change' }
        ]
      },
      otherCustomerCertList: [],
      formFlag: true,
      arrayFlag: true,
      constCertList: [],
      options: {
        disabledDate(date) {
          return date && date < dayjs().subtract(1, 'day')
        }
      }
    }
  },
  watch: {
    model: {
      handler(newVal) {
        const requiredArray = ['name', 'idCard', 'idCardEndActiveDate'].filter(key => {
          return newVal[key] === '' || newVal[key] === undefined || newVal[key] === null
        })
        if (requiredArray.length) {
          this.formFlag = true
        } else {
          this.formFlag = false
        }
        this.disabled = !(!this.formFlag && !this.arrayFlag)
      },
      immediate: true,
      deep: true
    },
    customerAgentCertList: {
      handler(newVal) {
        const arr = newVal.filter((item, index) => {
          return item.isRequire === 1 && (!item.custCertPicVoList || item.custCertPicVoList.length === 0)
        })
        if (arr.length) {
          this.arrayFlag = true
        } else {
          this.arrayFlag = false
        }
        this.disabled = !(!this.formFlag && !this.arrayFlag)
      },
      immediate: true,
      deep: true
    },
    'model.longTime'(newVal) {
      const date = new Date()
      this.disabledDateForm = false
      if (newVal) {
        this.$set(this.model, 'idCardEndActiveDate', new Date(date.setFullYear(date.getFullYear() + 100)))
        this.disabledDateForm = true
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    // 查看示意图
    handleView(list) {
      const images = list.map(item => {
        return this.getFilePath('orig', item)
      })
      this.$viewerApi({
        images
      })
    },
    async getEditInfo() {
      const { code, data } = await getCustomerCertificateListAPI(this.customId)
      if (code === 200) {
        const { realName, idNumber, validEndDate, custWxMyCertificateVoList } = data
        this.model = {
          name: realName,
          idCard: idNumber,
          idCardEndActiveDate: validEndDate,
          longTime: validEndDate && new Date(validEndDate).getTime() >= new Date('2100-01-01').getTime()
        }
        this.customerAgentCertList.forEach(cert => {
          const certificate = custWxMyCertificateVoList.find(item => {
            return item.certificateId === cert.certificateId
          })
          cert.custCertPicVoList = certificate?.custCertPicVoList
        })
      }
    },
    async getInfo() {
      const { code, data } = await getCustInfoAPI(this.customId)
      if (code === 200) {
        const { custCustomerInfo } = data
        this.getCertByCustId(custCustomerInfo.custOrgTypeId || custCustomerInfo.customerTypeId)
      }
    },
    async submit() {
      this.$refs.entrustForm.validate(async(valid) => {
        if (valid) {
          this.btnLoading = true
          const res = await bindAccount(this.setParams())
          this.btnLoading = false
          if (res.code === 200) {
            this.$message.success(res.data)
            this.$router.push({
              path: '/center/index'
            })
            setTimeout(() => {
              // 通知刷新顶部切换企业
              this.$bus.$emit('resetCurrentCustomer', false)
            }, 1000)
          }
        } else {
          this.$message.error('请输入正确的内容~')
        }
      })
    },
    // 编辑资质
    edit(index, info) {
      this.currentCustomer = index
      this.modal = true
      this.$refs.certRef.resetValidate()
      info.certificateId = info.certificateId * 1
      this.model2 = { ...info }
      this.otherCustomerCertList = [{
        value: info.certificateId,
        label: info.certificateName
      }]
    },
    changeCertificate(val) {
      const certificateName = this.otherCustomerCertList.find(item => item.certificateId === val).certificateName
      this.$set(this.model2, 'certificateName', certificateName)
    },
    ok() {
      this.$refs.certRef.validate()
    },
    validSuccess() {
      this.cancel()
      const index = this.customerAgentCertList.findIndex(cert => {
        return this.model2.certificateId === cert.certificateId
      })
      this.$set(this.customerAgentCertList, index, this.model2)
    },
    cancel() {
      //
      this.modal = false
    },
    previewPic(custCertPicVoList) {
      const images = custCertPicVoList.map(item => {
        return item.watermarkUrl
      })
      this.$viewerApi({
        images
      })
    },
    async getCertByCustId(customerTypeId) {
      const res = await getCertByCustIdAPI(customerTypeId)
      if (res.code === 200) {
        this.customerAgentCertList = res.data.filter(
          (item) => item.category === 'cust_agent'
        )
        if (this.edited) {
          this.getEditInfo()
          return
        }
        this.constCertList = res.data.filter((item) => {
          return item.category === 'cust'
        })
      }
    },
    setParams() {
      const certificateData = [
        ...this.customerAgentCertList,
        ...this.constCertList
      ]
      this.model.idCardEndActiveDate = dayjs(this.model.idCardEndActiveDate).format('YYYY-MM-DD')
      const custInfoVo = {
        ...this.model,
        customId: this.customId,
        pics: this.filterPicture(certificateData),
        custCerts: certificateData.map((item) => {
          if (item.fileList) delete item.fileList
          delete item.certificatId
          item.validEndDate ? dayjs(item.validEndDate).format('YYYY-MM-DD') : ''
          return item
        })
      }
      return custInfoVo
    },
    filterPicture(list) {
      const pics = list.map((item) => {
        return {
          certificateId: item.certificateId,
          picUrl:
            item.custCertPicVoList?.map((item) => {
              return item.fileId
            }) || []
        }
      })
      return pics
    }
  }
}
</script>
<style scoped lang="less">
.demo-carousel {
  height: 200px;
  line-height: 200px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background: #506b9e;
}
.certification {
  &-description {
    width: 744px; height: 46px; margin: 20px auto; line-height: 22px; padding: 12px 24px; box-sizing: border-box; font-size: 14px; color: rgba(0, 0, 0, .6); background-color:#F5F5F5;
    span {
      color: #A1151E; font-weight: 700;
    }
  }
}
.card-list {
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  &-item {
    display: flex;
    gap: 12px;
    width: 360px;
    padding: 12px;
    border: #eee 1px solid;
    border-radius: 4px;
    &-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      padding: 6px;
      border: #eee 1px solid;
      border-radius: 4px;
      box-sizing: border-box;
      .preview {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .4);
        border-radius: 4px;
        opacity: 0;
        transition: opacity .5s ease;
      }
      &:hover {
        .preview {
          opacity: 1;
        }
      }
      img {
        width: 100%;
      }
    }
    &-content {
      line-height: 20px;
      padding-left: 10px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      .name {
        position: relative;
        line-height: 30px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
        &.required {
          &::before {
            content: '*';
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            color: #CB272D;
          }
        }
      }
    }
  }
}
</style>
