// 标题
<template>
  <div class="v-title" :style="rootStyle" :id="'nav' + id" @click="cmsLink(options.link)">
    <img :src="path" v-if="path">
    <span class="text">
      {{options.title}}
    </span>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
export default {
  name: "v-title",
  props: {
    options: Object,
    id: String
  },
  data() {
    return {
      cmsLink: cmsLink.bind(this),
    };
  },
  components: {
  },
  created() {
    this.$store.commit('setHomeNav', this.id)
  },
  mounted() {
    this.handelLoad()
  },
  methods: {
    handelLoad() {
      this.options.type = 'v-title'
      this.$emit('load', this.options)
    }
  },
  computed: {
    path() {
      let data = this.options
      if( !data?.fileId ) return ''
      return getFilePath('PS_04', this.options?.fileId)
    },
    rootStyle () {
      let result = {}
      // if( this.options.type == '1' ) {
        result.textAlign = this.options.stylePosition
      // }
      return result
    }
  }
};
</script>
<style scoped lang="less">
.v-title {
  width: 1200px;
  height: 72px;
  padding: 24px 32px;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 0;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // position: relative;
  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: inline-block;
    vertical-align: middle;
    // position: absolute;
    // left: 32px;
  }
  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.9);    
    padding-top: 3px;
    box-sizing: border-box;
  }
}

</style>
