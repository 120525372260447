import Vue from 'vue'
import Pagination from './pagination/Pagination.vue'
import SearchBar from './SearchBar'

const components = [
  Pagination,
  SearchBar
]
components.forEach(component => {
  Vue.component(component.name, component)
})
