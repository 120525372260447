<template>
  <div class="upload-content">
    <div v-viewer class="upload-list">
      <div
        v-for="(item, index) in list"
        :key="item.url"
        class="upload-list-item"
      >
        <img :src="item.url" fit="cover" width="100%" height="100%">
        <div class="upload-list-cover">
          <Tooltip content="预览">
            <Icon type="ios-eye-outline" @click="handleView(item)" />
          </Tooltip>
          <Tooltip content="删除">
            <Icon type="ios-trash-outline" @click="handleRemove(item, index)" />
          </Tooltip>
        </div>
      </div>

      <Upload
          v-if="isShow"
          ref="upload"
          type="drag"
          class="upload"
          action="/apis/rym-file/upload"
          :accept="accept"
          :headers="headers"
          :data="params"
          :format="format"
          :multiple="multiple"
          :max-size="maxSize"
          :show-upload-list="false"
          :default-file-list="list"
          :disabled="disabled"
          :on-success="handleSuccess"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :before-upload="handleBeforeUpload"
        >
          <div class="upload-icon">
            <div v-if="disabled" class="upload-icon-loading">
              <Button type="text" loading shape="circle" />
              <div>上传中</div>
            </div>
            <div v-else>
              <Icon type="ios-add" size="20" />
              <div>点击上传</div>
            </div>
          </div>
        </Upload>
    </div>

  </div>
</template>
<script>
import { getToken, getFilePath } from '@/common/utils'
export default {
  name: 'CustomerUpload',
  props: {
    scene: {
      type: String,
      default: 'C1'
    },
    files: {
      type: Array,
      default: () => {
        return []
      }
    },
    count: {
      type: Number,
      default: 6
    },
    accept: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      getFilePath,
      headers: {
        Authorization: getToken()
      },
      params: {
        scene: this.scene,
        isPrivate: true
      },
      format: ['jpg', 'jpeg', 'png'],
      maxSize: 3072,
      multiple: false,
      disabled: false,
      list: []
    }
  },
  computed: {
    isShow() {
      return this.list.length < this.count
    }
  },
  watch: {
    files(newValue) {
      this.list = newValue?.map(item => {
        item.url = item.sb || item.thumbnailUrl
        return { ...item }
      })
      this.list = this.list || []
    }
  },
  created() {
    this.list = this.files?.map(item => {
      // item.url = getFilePath('PS_01', item.fileId)
      item.url = item.sb || item.thumbnailUrl || item.watermarkUrl
      return { ...item }
    })
    this.list = this.list || []
  },
  methods: {
    // 预览
    handleView(item) {
      const images = [item.watermarkUrl]
      this.$viewerApi({
        images
      })
    },
    // 删除
    handleRemove(file, index) {
      this.list.splice(index, 1)
      this.$emit('update:files', this.list)
    },
    // 上传成功
    handleSuccess(res, file) {
      const { code, data, message } = res
      if (code === 200) {
        this.disabled = false
        this.list.push({
          name: file.name,
          fileId: data.fileId,
          url: data.path,
          picUrl: data.fileId,
          sb: data.path,
          watermarkUrl: data.watermarkUrl
        })
        this.$emit('update:files', this.list)
      } else {
        this.disabled = false
        this.$message.error(message)
      }
    },
    // 图片格式判断
    handleFormatError(file) {
      this.disabled = false
      this.$message.warning(`只允许上传jpg, jpeg, png格式的图片`)
    },
    // 图片大小判断
    handleMaxSize(file) {
      this.disabled = false
      this.$message.warning('图片超过3M')
    },
    handleBeforeUpload() {
      this.disabled = true
    }
  }
}
</script>
<style scoped lang="less">
.upload-content {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  .upload {
    .ivu-upload-drag {
      border: 1px solid #dcdee2 !important;
    }
    .upload-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 118px;
      height: 118px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      &-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  .upload-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    &-item {
      width: 120px;
      height: 120px;
      text-align: center;
      line-height: 120px;
      border: 1px solid #dcdee2;
      padding: 6px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      // box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
      .upload-list-cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.5s ease;
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          margin: 0 2px;
        }
      }
      &:hover .upload-list-cover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
</style>
