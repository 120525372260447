import axios from 'axios'
import qs from 'qs'
import { rymRefreshToken } from '@/common/service'
import { setToken, setRefreshToken, getStorageSync, debounce } from '@/common/utils'

// 递归去空格
const trimData = (data) => {
  if (typeof data === 'number' || !data) {
    return data
  }
  if (typeof data === 'string') {
    return data.trim()
  }
  if (['object', 'array'].includes(typeof data)) {
    Object.keys(data).forEach((key) => {
      data[key] = trimData(data[key])
    })
    return data
  }
  return data
}

export function makeClient(baseUrl) {
  function resHandler(res) {
    if ('result' in res) return res.result
    return res
  }
  // 创建一个 Map 来存储请求的 controller
  const abortRequestMap = new Map()
  function createRequestConfig(params) {
    const controller = new AbortController()
    const signal = controller.signal
    // 将 controller 存储在 Map 中，以便之后取消
    abortRequestMap.set(baseUrl + params.url, controller)

    return { signal }
  }
  return {
    post(params) {
      const config = createRequestConfig(params)
      return axios.post(baseUrl + params.url, params.data, { ...params.config, ...config }).then(resHandler)
    },
    get(params) {
      let fullUrl = baseUrl + params.url
      const config = createRequestConfig(params)
      if (params.data) {
        const query = qs.stringify(params.data)
        fullUrl += '?' + query
      }
      return axios.get(fullUrl, { ...params.config, ...config }).then(resHandler)
    },
    delete(params) {
      return axios.delete(baseUrl + params.url, { data: params.data }).then(resHandler)
    },
    // 取消请求
    cancelRequest(url) {
      if (abortRequestMap.has(url)) {
        const controller = abortRequestMap.get(url)
        controller.abort()
        abortRequestMap.delete(url)
      } else {
        console.log(`没有找到请求：${url}`)
      }
    }
  }
}

// 请求拦截器
axios.interceptors.request.use((config) => {
  try {
    config.data = trimData(config.data)
  } catch (e) {
    console.log(e)
    console.log('去空格转换错误')
  }
  return config
})
// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    dealToken(response)
    const code = response.data.code || (!response.data.code && response.status)
    if (code !== 200 && code !== 610001) {
      return Promise.reject(response)
    } else {
      return response
    }
  },
  (error) => {
    if (error.config?.noCheckStatusCode === true) {
      return false
    } else {
      return Promise.reject(error)
    }
  }
)
// 处理token请求，在此处写主要是获取header中的服务器时间
function dealToken(response) {
  if (
    response &&
    response.config &&
    response.config.url &&
    (response.config.url.indexOf('v1/wx/rymRefreshToken') > -1 || response.config.url.indexOf('v1/wx/login') > -1)
  ) {
    if (response.data && response.data.data) {
      let responseData // login和这个接口返回的response结构不一样
      if (response.config.url.indexOf('v1/wx/rymRefreshToken') > -1) {
        responseData = response.data.data.data || {}
      } else {
        responseData = response.data.data
      }
      setToken('Bearer ' + responseData.access_token)
      setRefreshToken({
        token: responseData.refresh_token,
        tokenLocTimeStap: new Date(response.headers.date).getTime(),
        expressIn: responseData.expires_in * 1000
      })
    }
  } else {
    refreshToken(response.headers.date)
  }
}
const refreshToken = debounce(function refreshToken(curTime) {
  const refreshToken = getStorageSync('refreshToken')
  const token = getStorageSync('accessToken')
  if (token && refreshToken?.token) {
    if (new Date(curTime).getTime() - refreshToken.tokenLocTimeStap > refreshToken.expressIn / 2) {
      rymRefreshToken({
        refresh_token: refreshToken.token,
        domain: window.location.protocol + '//' + window.location.host
      })
    }
  }
}, 2000)
window.axios = axios
