<template>
  <div class="select-good">
    <Modal v-model="show" width="1158" class-name="vertical-center-modal" @on-cancel="cancel">
      <Table
        :loading="dataLoading"
        :columns="columns"
        :data="goodsData"
        class="select-good-table"
        @on-selection-change="getSelectItems"
        @on-row-click="checkRow"
      >
        <!-- <template #select="{ row }">
          <Radio v-model="row.checked" @on-change="changeSelectGood(row)"
            :disabled="!(row.availableStock && row.availableStock > 0)"></Radio>
        </template> -->
      </Table>
      <Pagination
        class="pagination"
        :total="totalPage"
        :page-size-opts="[10, 20, 50, 100]"
        :size="pageSize"
        :num="pageNum"
        @change="pageChange"
      />
      <template #footer>
        <div>
          <div class="check">
            <Button
              type="primary"
              :loading="saveLoading"
              :disabled="selectedGoods.length <= 0"
              @click="saveGood"
            >确定（Enter）</Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { productListByEs, saveFastOrderGood } from '@/common/service'
import { getFilePath } from '@/common/utils'
export default {
  name: 'SelectGood',
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    searchKeyWord: {
      type: String,
      default: ''
    },
    fastOrderId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      show: false,
      selectedGoodId: '',
      dataLoading: false,
      getFilePath,
      goodsData: [],
      pageNum: 1,
      pageSize: 10,
      totalPage: 0,
      saveLoading: false,
      selectedGoods: [],
      columns: [
        {
          type: 'selection',
          width: 35,
          align: 'center',
          key: '_checked'
        },
        {
          title: '序号',
          type: 'index',
          width: 45
        },
        {
          title: '商品信息',
          key: 'generalChineseName',
          width: 180,
          render: (h, { row, index }) => {
            return [h('div', {},
              (row.productName ? '(' + row.productName + ')' : '') + row.generalChineseName),
            h('p', { class: 'front-display-label-box' },
                row.frontDisplayLabel?.length > 0 ? row.frontDisplayLabel.map((item, index) => {
                  return [
                    h('span', {
                      class: 'front-display-label'
                    }, item),
                    !(row.frontDisplayLabel.length - 1 === index) && h('span', { class: 'front-display-label-split' })
                  ]
                }) : ''
            )
            ]
          }
        },
        {
          title: '批准文号',
          key: 'approvalNumber',
          width: 82
        },
        {
          title: '产品规格',
          key: 'unitSpec',
          width: 100
        },
        {
          title: '商品编码',
          key: 'productNo',
          width: 70
        },
        {
          title: '单价',
          key: 'price',
          width: 116,
          align: 'right',
          render: (h, { row }) => {
            return h('div', {
              style: {
                'text-align': 'right',
                'font-weight': 600
              }
            }, [
              h('p', row.price ? row.price && '￥' + row.price : '未定价')
            ])
          }
        },
        {
          title: '生产企业',
          key: 'manufactureCompany',
          width: 180
        },
        {
          title: '库存',
          key: 'availableStock',
          width: 70,
          render: (h, { row, index }) => {
            return row.availableStock > 0 ? h('span', row.availableStock) : h('span', '售罄')
          }
        },
        {
          title: '中包装',
          key: 'packageNum',
          width: 70,
          render: (h, { row }) => {
            return h('div', {}, [
              h('p', row.packageNum),
              // h('p', row.packageNum && row.allowRetail === 1 ? '(可拆零)' : row.packageNum && row.allowRetail !== 1 ? '(不可拆零)' : '')
            ])
          }
        },
        {
          title: '件包装',
          key: 'packageUnitNum',
          width: 70
        },
        {
          title: '有效期至',
          key: 'nearEffectDate',
          width: 146,
          render: (h, { row }) => {
            /* return h('div', { class: 'near-date' }, [
              row.nearEffectDate && h('p', row.nearEffectDate),
              row.nearEffectDate && row.farEffectDate && h('span', '/'),
              row.farEffectDate && h('p', row.farEffectDate)
            ]) */
            return h('div',{ class: (!row.nearEffectDate && !row.farEffectDate) ? 'near-date-empty':'near-date1' }, [
              row.nearEffectDate ? h('span',{class:row.showExpirationWarn && 'expiration-warn'}, row.nearEffectDate):h('span',{class:row.showExpirationWarn && 'expiration-warn'}, '-'),
              !( row.nearEffectDate && !row.farEffectDate) && h('span',{class:(row.showFarExpirationWarn ) && 'expiration-warn'}, '/'),
              !((!row.nearEffectDate)&&(!row.farEffectDate)) ? h('span',{class:row.showFarExpirationWarn && 'expiration-warn'}, row.farEffectDate):h('span',{class:row.showFarExpirationWarn && 'expiration-warn'}, '-'),
              // !(row.nearEffectDate && row.farEffectDate) && h('p',{ class: 'near-date-empty' },'-/-'),
            ])
          }
        }
      ]
    }
  },
  computed: {
  },
  watch: {
    modalShow(val) {
      console.log(val, 'modalShow')
      if (val) {
        this.show = val
        this.goodsData = []
        this.pageNum = 1
        this.chooseGoodQuery()
        document.addEventListener('keydown', this.upDownHandle)
      } else {
        document.removeEventListener('keydown', this.upDownHandle, false)
      }
    },
    goodsData: {
      handler(val) {
        const data = val.filter(item => item._checked)
        this.selectedGoods = data
      },
      deep: true
    }
  },
  mounted() {

  },
  methods: {
    // 增加点击行时，可以选中或者取消选中；
    checkRow(row, index) {
      if (!row._disabled) {
        this.$set(this.goodsData[index], '_checked', !this.goodsData[index]['_checked'])
      }
    },
    changeSelectGood(row) {
      if (row.availableStock && row.availableStock > 0 && !this.saveLoading) {
        const params = {
          productId: row.productId,
          orgId: row.orgId,
          productCount: row.minPurchaseAmountIdentity || 1,
          price: row.price,
          fastOrderId: this.fastOrderId,
          productName: row.generalChineseName
        }
        this.saveGood(params, row)
      }
    },
    // 因为监听事件中不会改变_checked值，所以在各监听事件中将_checked值设置为true或false
    getSelectItems(selection) {
      this.selectedGoods = selection
      this.goodsData.forEach((item, index) => {
        const selectGood = this.selectedGoods.find(good => good.id === item.id)
        if (selectGood) {
          if (!selectGood._disabled) {
            this.$set(this.goodsData[index], '_checked', true)
          }
        } else {
          this.$set(this.goodsData[index], '_checked', false)
        }
      })
    },
    async saveGood(unChecked) {
      if (this.fastOrderId && this.selectedGoods.length > 1) {
        this.$message.info('匹配失败的商品只能选中一个')
        return
      }
      const params = this.selectedGoods.map(item => {
        return {
          productId: item.productId,
          orgId: item.orgId,
          productCount: item.minPurchaseAmountIdentity || 1,
          price: item.price,
          fastOrderId: this.fastOrderId,
          productName: item.generalChineseName
        }
      })
      this.saveLoading = true
      try {
        const res = await saveFastOrderGood(params)
        this.saveLoading = false
        if (res.code === 200) {
          if (res.data?.length > 0) {
            const successData = res.data.map(item => {
              const selectedItem = this.selectedGoods.find(sel_item => sel_item.productId === item.productId && sel_item.orgId === item.orgId)
              return {
                ...selectedItem,
                productCount: selectedItem.minPurchaseAmountIdentity || 1,
                totalPrice: item.totalPrice,
                controlsOutside: false,
                _disabled: false,
                _checked: true,
                fastOrderId: item.id
              }
            })
            this.$emit('updateBatchPurchase', successData)
          }
        }
        this.$message.success(res?.message)
        this.show = false
        this.$emit('update:modalShow', false)
      } catch (error) {
        this.saveLoading = false
        this.$message.error(error?.data?.message)
        if (unChecked) { // 为true时表明是未选中，回车默认当前hover条逻辑，需要把数据清空，否则报错后会不进是否未选中的判断逻辑
          this.selectedGoods = []
        }
      }
    },
    cancel() {
      this.$nextTick(() => {
        this.show = false
        this.$emit('update:modalShow', false)
      })
    },
    async chooseGoodQuery() {
      try {
        this.dataLoading = true
        const res = await productListByEs({
          keyword: this.searchKeyWord.trim(),
          prdType: ['normal', 'buy_now_price'],
          orgId: [localStorage.getItem('selectOrgId')],
          page: {
            'pageNumber': this.pageNum,
            'pageSize': this.pageSize
          }
        })
        this.dataLoading = false
        this.goodsData = res.data.records.map(item => {
          return {
            ...item,
            price: item.prdType === 'normal' ? item.customerTypePrice[0]?.price : item.marketing?.customerTypePrice[0]?.price,
            _disabled: !(item.availableStock && item.availableStock > 0)
          }
        })
        this.totalPage = res.data?.total ? res.data.total : 0
      } catch (error) {
        this.dataLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    pageChange(val) {
      // console.log('val',val)
      this.pageNum = val.pageNumber
      this.pageSize = val.pageSize
      this.chooseGoodQuery()
    },
    upDownHandle(event) {
      // 阻止事件冒泡
      event.stopPropagation()
      if (event.key === 'ArrowUp') {
        // 在这里编写按下上箭头键时想要执行的代码
        const $table = document.querySelector('.select-good-table .ivu-table-tbody').getElementsByTagName('tr')
        let isHaveClass = false
        console.log('子页面,向上箭头')
        for (let i = 0; i < $table.length; i++) {
          if ($table[i].classList.contains('ivu-table-row-hover')) {
            isHaveClass = true
            if (i !== 0) {
              $table[i].classList.remove('ivu-table-row-hover')
              $table[i - 1].classList.add('ivu-table-row-hover')
              if ((event.shiftKey && event.key === 'ArrowUp')) {
                console.log('子页面,向上箭头+shift')
                // 调整当前行和上一行的选中状态
                this.$set(this.goodsData[i], '_checked', !this.goodsData[i]['_disabled'])
                this.$set(this.goodsData[i - 1], '_checked', !this.goodsData[i - 1]['_disabled'])
              }
            }
            break
          }
        }
        if (!isHaveClass) {
          $table[0].classList.add('ivu-table-row-hover')
          if ((event.shiftKey && event.key === 'ArrowUp')) {
            this.$set(this.goodsData[0], '_checked', !this.goodsData[0]['_disabled'])
          }
        }
      } else if (event.key === 'ArrowDown') {
        // 在这里编写按下下箭头键时想要执行的代码
        const $table = document.querySelector('.select-good-table .ivu-table-tbody').getElementsByTagName('tr')
        let isHaveClass = false
        console.log('子页面,向下箭头')
        for (let j = 0; j < $table.length; j++) {
          if ($table[j].classList.contains('ivu-table-row-hover')) {
            isHaveClass = true
            if (j !== $table.length - 1) {
              $table[j].classList.remove('ivu-table-row-hover')
              $table[j + 1].classList.add('ivu-table-row-hover')
              if ((event.shiftKey && event.key === 'ArrowDown')) {
                console.log('子页面,向下箭头+shift')
                // 调整当前行和上一行的选中状态
                this.$set(this.goodsData[j], '_checked', !this.goodsData[j]['_disabled'])
                this.$set(this.goodsData[j + 1], '_checked', !this.goodsData[j + 1]['_disabled'])
              }
            }
            break
          }
        }
        if (!isHaveClass) {
          $table[0].classList.add('ivu-table-row-hover')
          if ((event.shiftKey && event.key === 'ArrowDown')) {
            this.$set(this.goodsData[0], '_checked', !this.goodsData[0]['_disabled'])
          }
        }
      } else if (event.key === 'Enter') {
        if (!this.selectedGoods.length > 0) {
          const $table = document.querySelector('.select-good-table .ivu-table-tbody').getElementsByTagName('tr')
          for (let j = 0; j < $table.length; j++) {
            if ($table[j].classList.contains('ivu-table-row-hover')) {
              this.selectedGoods.push(this.goodsData[j])
              break
            }
          }
          if (!this.selectedGoods.length > 0) {
            this.$message.info('请先选中一个商品')
            return
          }
          this.saveGood(true)
        } else {
          this.saveGood()
        }
      }
    }

  }
}
</script>
<style scoped lang="less">
:deep(.vertical-center-modal) {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

:deep(.ivu-modal-header) {
  padding: 24px;
  border: none;

  .ivu-modal-header-inner {
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    height: 26px;
  }
}

:deep(.ivu-modal-close) {
  right: 24px;
  top: 8px;

  .ivu-icon-ios-close {
    font-size: 24px;
  }
}

:deep(.ivu-modal-body) {
  padding: 48px 16px 16px;
  // height: 704px;
}

:deep(.ivu-modal-footer) {
  border: none;
  padding: 24px 32px;

  .check {
    position: absolute;
    left: 33px;
    bottom: 20px;
    font-size: 16px;
  }

}

:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  th {
    border: none;
    background: none;
    padding: 0 8px;
    height: 36px;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-cell {
    padding: 0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

:deep(table) {
  width: 1066px !important;
  border-spacing: 16px 0;
  border-collapse: collapse;
}

:deep(.ivu-table-body) {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: 0;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}

:deep(.ivu-table-tbody) {
  tr {
    border-bottom: 1px solid var(--gray-2, #EEE);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    border-bottom: none;
    padding: 4px 8px;
    vertical-align: top;
    vertical-align: middle;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-row-hover {
    td {
      background: rgba(249, 157, 51, 0.2) !important;
    }
  }

  .ivu-table-cell {
    padding: 0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

:deep(.ivu-table) {
  &:before {
    display: none;
  }
}

.good-info {
  p {
    width: 205px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
:deep(.front-display-label-box) {
    overflow: hidden;
    white-space: nowrap;
    .front-display-label {
      color: rgba(160, 112, 60, 1);
    }
    .front-display-label-split {
        margin: 0 4px;
        font-size: 10px;
        color: rgba(160, 112, 60, 1);
        border-right: 1px solid rgba(160, 112, 60, 0.3);
    }
}
:deep(.ivu-tooltip) {
  display: flex;
}

:deep(.ivu-table-tip) {
  height: 238px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;

  td {
    margin: 0 auto;
    display: block;
    width: 216px !important;
    height: 216px;
    background: url(../../../../assets/center/noData.png) 0 0 no-repeat;
    background-size: 100% 100%;
    border: none;

    span {
      display: none;
    }
  }
}

.img-box {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .good-img {
    width: 64px;
    height: 64px;
  }
}

.pagination {
  margin-top: 10px;
}
.near-date{
  // text-align: center;
}
.near-date1{
  text-align: left;
}
.near-date-empty{
  text-align: left;
}
.expiration-warn{
  color: #E67D00;
}
</style>
