import RouterContanst from '@/router/router-contanst'
import UserLayout from '@/components/layout/UserLayout'

export default [
  {
    path: '/user',
    name: RouterContanst.USER.LAYOUT,
    component: UserLayout,
    children: [
      {
        path: 'regist',
        name: RouterContanst.USER.REGIST,
        component: () => import('@/views/personnal/regist')
      },
      {
        path: 'forgetPwd',
        name: RouterContanst.USER.FORGET_PWD,
        component: () => import('@/views/personnal/forgetPwd')
      },
      {
        path: 'privacy',
        name: RouterContanst.USER.PRIVACY,
        component: () => import('@/views/personnal/privacy')
      },
      {
        path: 'cmsFileDetail',
        name: RouterContanst.USER.ANNOUNCE_FILE,
        component: () => import('@/views/personnal/cmsFileDetail')
      },
      {
        path: 'userServiceAgreement',
        name: RouterContanst.USER.UserServiceAgreement,
        component: () => import('@/views/explain/notice/component/userServiceAgreement')
      },
      {
        path: 'privacyAgreement',
        name: RouterContanst.USER.PrivacyAgreement,
        component: () => import('@/views/explain/notice/component/privacyAgreement')
      },
      {
        path: 'exchangeAgreement',
        name: RouterContanst.USER.ExchangeAgreement,
        component: () => import('@/views/explain/notice/component/exchangeAgreement')
      }
    ]
  },
  {
    path: '/login',
    name: RouterContanst.USER.LOGIN,
    meta: { title: '润药商城-欢迎登录' },
    component: () => import('@/views/personnal/login')
  },
  {
    path: '/ProductEntryForm',
    name: 'ProductEntryForm',
    component: () => import('@/views/entry/productEntryForm.vue')
  },
  {
    path: '/ProductEntry',
    name: 'ProductEntry',
    component: () => import('@/views/entry/entry.vue')
  },
  {
    path: '/otherPayment',
    name: 'otherPayment',
    component: () => import('@/views/trade/otherPayment/index.vue')
  },
  {
    path: '/helperCenter',
    name: RouterContanst.USER.HELPER_CENTER,
    component: () => import('@/views/personnal/helperCenter')
  },
  {
    path: '/feedback',
    name: RouterContanst.USER.FEED_BACK,
    component: () => import('@/views/personnal/feedback')
  }

]
