<template>
  <div class="select-good">
    <Modal v-model="show" width="1215" class-name="vertical-center-modal" @on-cancel="cancel">
      <template #header>
        <h4>选择采购计划</h4>
        <span>若商家名称非本次飞速下单前选择的商家，则不可导入</span>
      </template>
      <div class="search">
        <h4>采购计划</h4>
        <Select v-model="currentPlanid" style="width:212px" filterable @on-change="changeModel">
          <Option v-for="item in conditionList" :value="item.id" :key="item.id">{{ item.purchasePlanName }}</Option>
        </Select>
      </div>
      <Table
        ref="selection"
        :loading="dataLoading"
        :columns="columns"
        :data="goodsData"
        @on-selection-change="getSelectItems"
      />
      <Pagination
        class="pagination"
        :total="totalPage"
        :page-size-opts="[10, 20, 50, 100]"
        :size="pageSize"
        :num="pageNum"
        @change="pageChange"
      />
      <template #footer>
        <div>
          <div class="check">
            <!-- <Checkbox v-model="checkAll">全选</Checkbox>   -->
            <Button type="primary" :loading="saveLoading1" @click="ok(1)">导入全部商品</Button>
          </div>
          <Button type="default" @click="cancel">取消</Button>
          <Button type="primary" :loading="saveLoading2" :disabled="selectGoodList.length <= 0" @click="ok(2)">确认</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ImportPurchasePlan, getPurchasePlanName, getPurchasePlanData } from '@/common/service'
export default {
  name: 'ImportPurchasePlan',
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    searchKeyWord: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      selectedGoodId: '',
      dataLoading: false,
      currentPlanid: '',
      checkAll: false,
      columns: [
        {
          type: 'selection',
          width: 35,
          align: 'center',
          key: '_checked'
        },
        {
          title: '序号',
          type: 'index',
          width: 45
        },
        {
          title: '商家名称',
          key: 'orgName',
          width: 136
        },
        {
          title: '客户商品编码',
          key: 'customerProductNo',
          width: 90
        },
        {
          title: '商家商品编码',
          key: 'orgProductNo',
          width: 90
        },

        {
          title: '商品名称',
          key: 'productName',
          width: 174
        },
        {
          title: '规格',
          key: 'productSpec',
          width: 90
        },
        {
          title: '采购价',
          key: 'purchasePrice',
          width: 90
        },
        {
          title: '采购数量',
          key: 'purchaseNum',
          width: 90
        },
        {
          title: '生产企业',
          key: 'manufactureCompany',
          width: 174
        },
        {
          title: '批准文号',
          key: 'approvalNumber',
          width: 128
        }
      ],
      goodsData: [],
      pageNum: 1,
      pageSize: 10,
      totalPage: 0,
      saveLoading1: false,
      saveLoading2: false,
      conditionList: [],
      selectGoodList: []
    }
  },
  computed: {
  },
  watch: {
    modalShow(val) {
      if (val) {
        this.show = val
        this.goodsData = []
        this.pageNum = 1
        this.checkAll = false
        this.selectGoodList = []
        this.getPurchasePlanName()
      }
    }
  },
  mounted() {

  },
  methods: {

    changeModel(value) {
      this.currentPlanid = value
      this.selectGoodList = []
      this.pageNum = 1
      this.getPurchasePlanData()
    },
    // 查询采购计划名称列表
    async getPurchasePlanName() {
      try {
        const res = await getPurchasePlanName()
        if (res.code === 200 && res.data?.length > 0) {
          this.conditionList = res.data || []
          this.currentPlanid = this.conditionList[0]?.id
          this.getPurchasePlanData()
        }
      } catch (error) {
        this.$message.error(error?.data?.message)
      }
    },

    // 查询当前选中采购计划的商品明细列表
    async getPurchasePlanData() {
      if (!this.currentPlanid) return
      this.dataLoading = true
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        condition: {
          purchasePlanId: this.currentPlanid
        }
      }
      try {
        const res = await getPurchasePlanData(params)
        this.dataLoading = false
        if (res.code === 200) {
          this.goodsData = res.data?.records || []
          if (this.selectGoodList.length > 0) {
            this.selectGoodList.forEach(item => {
              this.goodsData.forEach(good => {
                if (item === good.id) {
                  good._checked = true
                }
              })
            })
          }
          this.totalPage = res.data?.total ? res.data.total : 0
        }
      } catch (error) {
        this.dataLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    pageChange(val) {
      // console.log('val',val)
      this.pageNum = val.pageNumber
      this.pageSize = val.pageSize
      this.getPurchasePlanData()
    },
    /**
     * 根据当前选中项更新商品数据列表的选中状态，并更新已选商品ID列表。
     * @param {Array} selection - 当前选中的商品项数组。
     */
    getSelectItems(selection) {
      // 遍历商品数据列表，为每个商品添加选中状态。
      this.goodsData = this.goodsData.map(item => {
        let checked = false
        // 检查当前商品是否在选中项中。
        selection.forEach((selected) => {
          if (item.id === selected.id) {
            checked = true
          }
        })
        return {
          ...item,
          _checked: checked
        }
      })
      // 筛选并去重已选商品的ID列表。
      const ids = selection?.map(item => { return item.id })
      this.selectGoodList = [...new Set(this.selectGoodList.concat(ids))]
      // 筛选出未被选中的商品数据。
      const unCheckedData = this.goodsData.filter(item => { return !item._checked })
      // 从已选商品ID列表中移除未被选中的商品ID。
      if (unCheckedData.length > 0) {
        unCheckedData.forEach(item => {
          this.selectGoodList.forEach((id, index) => {
            if (item.id === id) {
              this.selectGoodList.splice(index, 1)
            }
          })
        })
      }
      console.log('this.selectGoodList', this.selectGoodList)
    },
    async ok(selectAllFlag) {
      selectAllFlag === 1 ? this.saveLoading1 = true : this.saveLoading2 = true
      this.orgId = localStorage.getItem('selectOrgId') || ''
      const params = {
        purchaseId: this.currentPlanid,
        orgId: this.orgId,
        selectAllFlag: selectAllFlag,
        idList: this.selectGoodList
      }
      try {
        const res = await ImportPurchasePlan(params)
        this.saveLoading = false
        selectAllFlag === 1 ? this.saveLoading1 = false : this.saveLoading2 = false
        if (res.code === 200) {
          this.$message.success(res.data?.des)
        }
        this.$emit('updateImportPurchasePlan')
        this.show = false
        this.$emit('update:modalShow', false)
      } catch (error) {
        selectAllFlag === 1 ? this.saveLoading1 = false : this.saveLoading2 = false
        this.$emit('updateImportPurchasePlan')
        this.$message.error(error?.data?.message)
      }
    },
    cancel() {
      this.$nextTick(() => {
        this.show = false
        this.$emit('update:modalShow', false)
      })
    }
  }
}
</script>
<style scoped lang="less">
:deep(.vertical-center-modal) {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

:deep(.ivu-modal-header) {
  padding: 24px 24px 0;
  border: none;
  display: flex;

  .ivu-modal-header-inner {
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    height: 26px;
  }

  h4 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.90);
  }

  span {
    margin-left: 16px;
    color: #F53F3F;
  }
}

:deep(.ivu-modal-body) {
  padding: 16px 24px;
}

:deep(.ivu-modal-footer) {
  border: none;
  padding: 0 24px 20px;

  .check {
    position: absolute;
    left: 33px;
    bottom: 20px;
    font-size: 16px;
  }

}

:deep(.ivu-table-header) {
  margin-top: 16px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  th {
    border: none;
    background: none;
    padding: 0 8px;
    height: 36px;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-cell {
    padding: 0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

:deep(table) {
  width: 1066px !important;
  border-spacing: 16px 0;
  border-collapse: collapse;
}

:deep(.ivu-table-body) {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: 0;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}

:deep(.ivu-table-tbody) {
  tr {
    border-bottom: 1px solid var(--gray-2, #EEE);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    border-bottom: none;
    padding: 4px 8px;
    vertical-align: top;
    vertical-align: middle;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-row-hover {
    td {
      background: #FFF8F0 !important;
    }
  }

  .ivu-table-cell {
    padding: 0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

:deep(.ivu-table) {
  &:before {
    display: none;
  }
}

:deep(.ivu-tooltip) {
  display: flex;
}

:deep(.ivu-table-tip) {
  height: 238px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;

  td {
    margin: 0 auto;
    display: block;
    width: 216px !important;
    height: 216px;
    background: url(../../../../assets/center/noData.png) 0 0 no-repeat;
    background-size: 100% 100%;
    border: none;

    span {
      display: none;
    }
  }
}

.search {
  display: flex;
  align-items: center;

  h4 {
    margin-right: 16px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}

.pagination {
  margin-top: 10px;
}
</style>