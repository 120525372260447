import { getCartNum, prdControlForClient } from '@/common/service'
import { api } from '@/common/service/config'
import { getToken, ACTIVITY_TYPE } from '@/common/utils'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
// 更新购物车tabbar角标
export const updateCartNum = () => {
  return new Promise((resolve, reject) => {
    getCartNum()
      .then((res) => {
        // getApp().globalData.carTextNum = res?.data;
        resolve(res.data)
      })
      .catch((error) => {
        // 如果当前登录用户必要信息缺失600 用户未绑定客户提示：601
        if (
          error?.data?.code == '600' ||
          error?.data?.code == '601' ||
          error?.data?.code == '602' ||
          error?.data?.code == '603'
        ) {
          return
        }
        // Message.error(error?.data?.message)
        reject(error)
      })
  })
}
// 防抖函数
export function debounce(func, delay) {
  let timer // 定时器
  return function() {
    const context = this
    const args = arguments
    const isImmediately = !timer
    timer ? clearTimeout(timer) : null
    timer = setTimeout(() => {
      timer = null
    }, delay)
    // isImmediately 为 true 则 执行函数(即首次触发事件)
    isImmediately ? func.apply(context, args) : null
  }
}
export function isValidDate(date) {
  try {
    const curDate = new Date(dayjs(date).format('YYYY-MM-DD'))
    const isTure = curDate instanceof Date && !isNaN(curDate.getTime())
    return isTure && curDate.getTime() > new Date('1971-01-01').getTime()
  } catch (error) {
    return false
  }
}
/**
 * @desc 函数节流
 * @param func 回调函数
 * @param limit 时间限制
 */
export function throttle(func, limit) {
  let inThrottle
  return function() {
    const context = this
    const args = arguments
    console.log('inThrottle', inThrottle)
    // 跳出时间限制
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      // 开启定时器计时
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}
/*
 *前端分页函数
 * 入参:
 * list(Array): 商品id, 活动id, 活动类型 的数组
 * num(Number): 每次加载几条商品
 *
 * 返回:
 * ids: 为 Array 继续加载, 为 null 数据加载完毕
 * list: 每次调用完重新给传入的数组赋值
 */
export const pagingLoad = (list, num) => {
  let ids = []
  if (list.length > num) {
    ids = list.splice(0, num)
  } else if (list.length) {
    ids = list.splice(0, list.length)
  }
  return {
    ids,
    list
  }
}

// 完整的URL的组成
// {协议域名路径} / {尺寸标识thumb} / {fileId}
const filePreviewHttp = api.filePreviewHttp
export const getFilePath = (thumb, fileId) => {
  if (!fileId) return ''
  return filePreviewHttp + '/' + thumb + '/' + fileId
}

/**
 * 上传文件
 */
const baseFileUrl = api.baseFileUrl
export const uploadFile = ({ filePath, scene }) => {
  return new Promise((resolve, reject) => {
    uni.uploadFile({
      url: `${baseFileUrl}/upload`,
      filePath,
      name: 'file',
      formData: {
        scene: scene
      },
      header: {
        Authorization: getToken()
      },
      success(res) {
        resolve(res)
      },
      fail(err) {
        reject(err)
      }
    })
  })
}
export function getUuid() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID()
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c)
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16)
      }
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, callback)
    }
  }
  let timestamp = new Date().getTime()
  let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0
      timestamp = Math.floor(timestamp / 16)
    } else {
      random = (perforNow + random) % 16 | 0
      perforNow = Math.floor(perforNow / 16)
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
  })
}

// 获取限购信息
export const getLimitInfo = (data, prefix = '') => {
  /**
   *
   *  相关文档  https://app.rwork.crc.com.cn/docx/doxk9BSHhyuHddwRmvVOvxyvzqf
   * limitFlag  限购类型
   *   limit_single 单笔限购
   *    limit_day   当日限购
   *    limit_month 当月限购
   *    limit_activity 活动限购
   *  residualQty 剩余可购买数量
   *  limitQty 限购数量
   * limitQty - 已购数量 = residualQty
   */
  const { limitFlag, residualQty, limitQty } = data
  console.log('limitQty')
  console.log(limitQty)
  if (limitFlag === 'limit_single') {
    return `${prefix}单笔限购数量：${residualQty}`
  }
  if (limitFlag === 'limit_day') {
    return residualQty <= 0 ? `${prefix}当天限购已达上限` : `${prefix}当天剩余采购数量：${residualQty}`
  }
  if (limitFlag === 'limit_month') {
    return residualQty <= 0 ? `${prefix}当月限购已达上限` : `${prefix}当月剩余采购数量：${residualQty}`
  }
  if (limitFlag === 'limit_all') {
    return `${prefix}商品限购，剩余采购：${residualQty}`
  }
  if (limitFlag === 'limit_activity_single') {
    return `${prefix}活动单笔限购：${limitQty || residualQty}`
  }
  if (limitFlag === 'limit_activity_customer') {
    return `${prefix}活动限购，剩余采购：${residualQty}`
  }
  return ''
}

/**
 * 获取账户异常状态
 * */
export const getAccountErr = (data) => {
  const { controlState, certState, custState } = data
  if (custState !== 'auth') {
    return '认证后可见'
  } else if (controlState === 'frozen') {
    return '解冻后可见'
  } else if (certState === 'expire') {
    return '更新资质后可见'
  }
  return ''
}
/**
 * 获取加购数据
 * */
export const getShoppingData = (data, before = {}, after = {}) => {
  const { orgId,
    productId,
    brandName = '',
    unitSpec,
    picture,
    productUnit,
    marketing,
    availableStock,
    collect = false,
    estimatePrice,
    packageNum,
    packageUnitNum,
    saleAmount,
    noPostage,
    manufactureCompany,
    generalChineseName,
    productName,
    orgName,
    productNo,
    isMedical
  } = data

  // 商品原价
  const currentPrice = data?.customerTypePrice?.[0]?.price || ''
  // 获取活动价
  const activityPrice = data?.marketing?.customerTypePrice?.[0]?.price || ''

  // 起购数
  const startBuyNum = data.marketing?.minPurchaseAmountIdentity || data.minPurchaseAmountIdentity || 1
  // 限购数
  const singleLimitNum = data?.marketing?.maxPurchaseAmountIdentity || data.maxPurchaseAmountIdentity
  // 加购数
  const addBuyNum = data?.marketing?.addBuyNum || data.addBuyNum || 1
  const activityId = data?.marketing?.activityId || 0
  const activityType = data?.marketing?.activityType || 0
  const isSingleLimit = data?.exchangeMarketing?.[0]?.maxPurchaseAmountIdentity ? 1 : 0

  let displayName = generalChineseName || productName
  if (data.nameDisplayType === '1') {
    // 品牌名+' '+商品名+' '+通用名+包装规格
    displayName = `${brandName || ''} ${productName || ''} ${generalChineseName || ''}${unitSpec || ''}`
  }
  const output = {
    orgId, // 商家id,
    id: productId, // 商品id
    activityId, // 活动id
    activityType, // 活动类型
    addBuyNum, // 每次加购数量
    startBuyNum,
    singleLimitNum, // 限购数量
    isSingleLimit, // 是否限购 //0不限购 1限购
    currentPrice, // 原价
    activityPrice, // 商品活动价商品原价
    brand: brandName, // 商品品牌
    displayName, // 加购弹窗展示商品名称
    generalChineseName: productName || generalChineseName, // 商品名称
    productSpec: unitSpec, // 包装规格
    mainPicUrl: picture, // 商品主图
    productUnit, // 商品单位
    marketing,
    availableStock,
    collect,
    estimatePrice, // 折后约
    packageNum, // 中包装
    packageUnitNum, // 件包装
    manufactureCompany,
    saleAmount,
    noPostage,
    orgName, // 商家名称
    productNo,
    isMedical
  }

  return Object.assign(before, output, after)
}

export const getCartFieldData = (list) => {
  let selectedProductList = []
  let presentProductList = []
  let cancelProductList = []
  if (list && list.length > 0) {
    for (const sItem of list) {
      for (const aItem of sItem?.activityList) {
        for (const gItem of aItem?.productList) {
          const flag = (aItem.activityType === ACTIVITY_TYPE.AUTOEXCHANGE || aItem.activityType === ACTIVITY_TYPE.MANUALEXCHANGE || aItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || aItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && gItem.expandMap?.present === true
          if (gItem.selected) {
            if (!flag) {
              selectedProductList.push({
                id: gItem.id,
                productId: gItem.productId,
                activityId: aItem.activityId,
                activityType: aItem.activityType,
                orgId: gItem.orgId,
                num: gItem.num,
                price: gItem.price,
                actualPrice: gItem.actualPrice
              })
            }
          } else {
            if (!flag) {
              cancelProductList.push({
                id: gItem.id,
                productId: gItem.productId,
                activityId: aItem.activityId,
                activityType: aItem.activityType,
                orgId: gItem.orgId,
                num: gItem.num,
                price: gItem.price,
                actualPrice: gItem.actualPrice
              })
            }
          }
          // 31自动换购 32手动换购
          if (flag) {
            presentProductList.push({
              productId: gItem.productId,
              activityId: aItem.activityId,
              activityType: aItem.activityType,
              orgId: gItem.orgId,
              selected: gItem.checked,
              num: gItem.num,
              actualPrice: gItem.actualPrice,
              price: gItem.price
            })
          }
        }
      }
    }
  }
  return {
    selectedProductList,
    presentProductList,
    cancelProductList
  }
}

/**
 * 批量查询控销信息，将查询结果与商品列表数据合并
 */
export const batchGetControl = (paramData, updateData = null) => {
  const products = paramData.map((item) => {
    return {
      esId: item.id,
      activeId: item.activityId || item.marketing?.activityId || '',
      activityType: item.activityType || item.marketing?.activityType || '',
      productId: item.productId,
      orgId: item.orgId
    }
  })
  const updateTarget = updateData || paramData
  return prdControlForClient({ products }).then((controlData) => {
    updateTarget.forEach((item) => {
      const obj = controlData?.data?.find((i) => i.esId === item.id)
      item.reason = obj?.reason
      item.prdControlVisible = obj?.prdControlVisible
      // if(item.id==='9181916649085957_9345565505064965_6'){
      //   item.reason = 22
      //   item.prdControlVisible = true
      // }
    })
    return Promise.resolve(cloneDeep(updateTarget))
  })
}
