// 全局图片
<template>
    <div class="g-img" ref="dom" :data-id='uuid'>
        <img class="default" v-show="!showCurrentPath" :src="defaultPath" />
        <img class="current" v-show="showCurrentPath" v-if="(!lazy || lazyShow || !intersectionObserver) && src" :src="src" @load="loadHandler"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { getUuid } from '@/common/utils/index'
import defaultPath from '@/assets/images/common/good_default.png'
export default {
    name: 'CartBtn',
    props: {
        defaultPath: {
            type: String,
            default: defaultPath
        },
        src: String,
        lazy: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: false, 
            showCurrentPath: false,
            uuid: getUuid(),
            lazyShow: false
        }
    },
    inject: ["intersectionObserver"],
    computed: {
    },
    created() {
        
    },
    mounted() {
        if( this.lazy && this.intersectionObserver && this.src ) {
            let dom = this.$refs.dom
            this.intersectionObserver.observe(dom, this.uuid, ()=> {
                this.lazyShow = true
                this.intersectionObserver.unobserve(dom)
            })
        }
    },
    methods: {
       loadHandler() {
        this.showCurrentPath = true
       }
    }
}
</script>

<style lang="less" scoped>
.g-img {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>