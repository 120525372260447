<template>
  <div>
    <!-- 账号冻结弹窗 -->
    <Modal v-model="showFrozenModal" width="360" :closable="false" :mask-closable="false" class-name="frozen-modal">
      <div class="frozen-content">
        <div class="title">温馨提示</div>
        <div class="content">{{ tip }}</div>
      </div>
      <template #footer>
        <span @click="showFrozenModal = false">知道了（{{ showFrozenModalTime }}s）</span>
      </template>
    </Modal>
  </div>
</template>
<script>
export default {
  name:'',
  props:{
    showFrozenPopup:{
      type: Boolean,
      default: false
    },
    tip:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      showFrozenModal: false,
      showFrozenModalTime: 3
    }
  },
  watch:{
    showFrozenPopup(newVal){
      if(newVal){
        this.showFrozenModal = newVal;
        this.showFrozenModalTime = 3;
        this.setFrozenTime()
      }
    }
  },
  methods: {
    // 冻结弹窗倒计时
    setFrozenTime () {
      const timer = setInterval(() => {
        this.showFrozenModalTime--
        if (this.showFrozenModalTime === 0) {
          this.close()
          this.showFrozenModalTime = 3
          clearInterval(timer)
        }
      }, 1000)
    },
    close(){
      this.showFrozenModal = false
      this.$emit('update:showFrozenPopup',this.showFrozenModal)
      this.$router.push('/')
      document.body.style.overflow='unset'
    }
  }
}
</script>
<style scoped lang='less'>
::v-deep .frozen-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    .ivu-modal-content {
      .ivu-modal-body {
        .frozen-content {
          text-align: center;
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }
          .content {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
</style>