const state = {
    shopInfo: {}, // 商家基础信息
    isOperating: false, // 商家是否正常营业
    isProcurementRelationState: false, // 用户是否和商家已建立建采关系
  }
  
  const getters = {
    getShopInfo(state) {
      return state.shopInfo
    },
    getOperatingStatus(state) {
        return state.isOperating
    },
    getProcurementRelationState(state) {
        return state.isProcurementRelationState
    }
  }
  
  const mutations = {
    SET_SHOP_INFO(state, data) {
        state.shopInfo = data
    },
    SET_OPERATING_STATUS(state, isOperating) {
        state.isOperating = isOperating
    },
    SET_PROCUREMENT_RELATION_STATE(state, isProcurementRelationState) {
        state.isProcurementRelationState = isProcurementRelationState
    },
  }
  
  const actions = {
    setShopInfo({ state, commit }, data) {
        commit('SET_SHOP_INFO', data)
    },
    setOperatingStatus({ state, commit }, isOperating) {
        commit('SET_OPERATING_STATUS', isOperating)
    },
    setProcurementRelationState({ state, commit }, isProcurementRelationState) {
        commit('SET_PROCUREMENT_RELATION_STATE', isProcurementRelationState)
    },
  }

  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  