// 所商品
<template>
  <div class="v-price-down" v-if="list?.length > 0" :id="'nav' + options.id">
    <div class="ttitle">{{ options.props.title }}</div>
    <div class="more" @click="more">
      更多
      <Icon type="ios-arrow-forward" />
    </div>
    <div class="list">
      <swiper
        ref="mySwiper"
        :options="swiperOption"
      >
        <swiper-slide v-for="(product, index) in list" :key="index" style="width: 215px;">
          <ProductCard
            size="c204x292"
            :goods-item="product"
            :is-org-abbr="false"
            :img-lazy="true"
            :show-add-car="false"
            is-cms
            cms-position-title="润划算"
            class="list_item"
          />
        </swiper-slide>
      </swiper>
    </div>
    <img src="@/assets/images/home/swiper-left.png" class="swiper-img img-right"   @click="slideToLeft()"/>
    <img src="@/assets/images/home/swiper-right.png" class="swiper-img img-left"   @click="slideToRight()"/>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink, detaiHandler } from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/index.vue'
import { getProductByIdS, getProductByEs } from '@/common/service'
import { transformEsData } from '@/common/utils'
import defaultPath from '@/assets/images/common/good_default.png'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: "v-price-down",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
      defaultPath,
      swiperOption: {
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        notNextTick: true,
        // 循环
        loop: false,
        slidesPerView: 'auto',
        slidesPerGroup:  5,
        // loopFillGroupWithBlank: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        spaceBetween:0,
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 300,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        grabCursor : true,
      },
    };
  },
  components: {
    ContentTitle,
    ProductCard,
    swiper,
    swiperSlide
  },
  created() {
    if (this.options.props.data?.products?.length > 0) {
      this.getActivityData()
    } else {
      if (this.options.props.data?.autoLoading == '1') {
        this.getActivityByEs()
      } else {
        this.handelLoad()
        this.list = []
      }
    }
  },
  methods: {
    handelLoad() {
      this.$emit('load', this.options)
    },
    more(data) {
      let query = {
        cmsModelId: `${this.cmsId}_${this.options.id}`,
        cmsCustom: true,
        autoLoading: this.options.props.data.autoLoading,
        title: this.options.props.title
      }
      this.$router.push({ path: `/activity/priceDownCenter`, query })
    },
    async getActivityData() {
      try {
        const { data } = await getProductByIdS({
          pageSize: 20,
          startIndex: 0,
          // searchPrescription: this.$route.name  == 'Home' || this.$route.name  == 'shopHome' ? false : true,
          cmsModelId: `${this.cmsId}_${this.options.id}`
        })
        data.records?.forEach(item => {
          if (item.custState == 'auth') {
            this.isInster = true
          } else {
            this.isInster = false
          }
          transformEsData(item)
          item._uid = item.id + item.activityId
        })
        this.list = data.records
        if (this.list.length < 5 && this.options.props.data?.autoLoading == '1') {
          this.getActivityByEs()
        }
        this.handelLoad()
      } catch (error) {
        this.handelLoad()
      }
    },
    async getActivityByEs() {
      const data = {
        // searchPrescription: this.$route.name  == 'Home' || this.$route.name  == 'shopHome' ? false : true,
        activityType: '2',
        page: {
          pageNumber: 1,
          pageSize: 20,
          sort: "marketing.startTime",
          order: "desc"
        }
      }
      try {
        const { data: datasource } = await getProductByEs(data)
        datasource.records?.forEach(item => {
          if (item.custState == 'auth') {
            this.isInster = true
          } else {
            this.isInster = false
          }
          transformEsData(item)
          item._uid = item.id + item.activityId
        })
        this.list = this.list.concat(datasource?.records)
        this.handelLoad()
      } catch (error) {
        this.handelLoad()
      }
    },
    formatPath(item) {
      let fileId = item?.fileId || item?.picture || item?.mainPicUrl
      return getFilePath('PS_01', fileId)
    },
    slideToLeft() {
      this.$refs.mySwiper.swiper.slidePrev()
    },
    slideToRight() {
      const index = this.$refs.mySwiper.swiper.activeIndex
      if(index != this.list.length-5 ) {
        this.$refs.mySwiper.swiper.slideNext()
      } else {
        this.$message.info("点击更多可查看全部商品")
      }
    }
  },
  computed: {
  },
  watch: {
    list: {
      handler(val) {
        if (val.length > 0)
          this.$store.commit('setHomeNav', this.options.id)
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="less">
.v-price-down {
  width: 1200px;
  height: 421px;
  background-image: url('~@/assets/images/home/pc-price-down.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 42px;
  padding-bottom: 12px;

  .ttitle {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: center;
    width: 228px;
    left: 486px;
    position: absolute;
    top: 10px;
    height: 56px;
  }

  .list {
    position: absolute;
    left: 58px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1088px;
    margin-top: 86px;
  }

  .more {
    border-radius: 4px;
    background: #F7BA1E;
    width: 48px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 20px;
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .swiper-img {
    width: 24px;
    height: 32px;
    position: absolute;
    top: 52%;
    z-index: 999;
  }
  .img-right {
    left: 36px;
  }
  .img-left {
    right: 36px;
  }
}

::v-deep .root {
  margin-top: 0;
}
</style>
