import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 购物车商品列表接口
 */
 export const getCartList = (data) => post({
	url:`${api.baseUrl}/order/cart/queryCart`,
  data
})
/**
 * 更新购物车商品数量
 */
 export const updateSkuNum = (data) => post({
	url: `${api.baseUrl}/order/cart/modifyProductCount`,
	data,
	isLoading : true
})
/**
 * 购物车商品选中或取消选中接口
 */
 export const updateSkuChecked = (data) => post({
	url: `${api.baseUrl}/order/cart/selectedProduct`,
	data
})
/**
 * 购物车删除商品
 */
 export const deleteGoodsBatch = (data) => post({
	url: `${api.baseUrl}/order/cart/deleteProductBatch`,
  data
})
/**
 * 购物车清空失效商品
 */
 export const deleteLoseProductBatch = (data) => post({
	url: `${api.baseUrl}/order/cart/deleteLoseProductBatch`,
  data
})
/**
 * 点击去结算校验商品
 */
 export const accountProduct = (data) => post({
	url: `${api.baseUrl}/order/cart/verifySettle`,
  data
})
/**
 * 获取商品库存
 */
export const getProductLeftStock = (data) => post({
  url: `${api.baseUrl}/prd/stock/getAvailableStock`,
  data: {
    ...data,
	activityId: data.activityId || 0,
	activityType: data.activityType || 0,
  }
})
/**
 * 加购
 */
export const addProduct = (data) => post({
	url: `${api.baseUrl}/order/cart/addProduct`,
    data: {
    ...data,
    activityId: data.activityId || 0,
    activityType: data.activityType || 0,
  }
})
/**
 * 获取购物车角标数量
 */
 export const getCartNum = () => post({
	url: `${api.baseUrl}/order/cart/productClassTotal`,
})
/**
 * 获取加购弹窗润划算商品可购买数量
 */
 export const getValidPurchaseNum = (data) => post({
	url: `${api.baseUrl}/marketing/activity/sale/data/get/validPurchaseNum`,
	data
})
/**
 * 获取购物车已采购数量
 */
export const getProduct = (data) => post({
	url: `${api.baseUrl}/order/cart/getProduct`,
	data
})
/**
 * 获取购物车换促销接口
 */
export const changeGoodPromotion = (data) => post({
	url: `${api.baseUrl}/order/cart/changeProductActivity`,
	data
})
/**
 * 获取加购弹窗算价接口
 */
export const getPromotionComputed = (data) => post({
	url: `${api.baseUrl}/order/cart/shopProductFreight`,
	data
})
/**
 * 校验当前账号是否关联企业
 */
export const checkCustomerNumberByUserId = () => post({
	url: `${api.baseUrl}/cust/custuser/findCustomerNumberByUserId`,
})
/**
 * 组合活动专区页回显
 */
 export const getQueryProducts = (data) => post({
	url: `${api.baseUrl}/order/cart/queryProducts`,
	data,
	isLoading: true
})
/**
 * 获取商品限购数量
 */
 export const getGoodLimit = (data) => post({
	url: `${api.baseUrl}/prd/product/queryPrdLimitQty`,
  data
})
/**
 * 获取凑券信息
 */
 export const getMergeCouponInfo = (data) => post({
	url: `${api.baseUrl}/order/cart/getMergeCouponInfo`,
  data
})

/**
 * 加购物车后查询商品活动信息
 */
export const addCartForSelectedProduct = (data) => post({
	url: `${api.baseUrl}/order/cart/addCartForSelectedProduct`,
  data
})

/**
 * 异步查询购物车信息
 */
export const asyncQueryCart = (data) => post({
	url: `${api.baseUrl}/order/cart/asyncQueryCart`,
  data
})

/**
 * 删除购物车缓存信息
 */
export const deleteQueryCartCache = (data) => post({
	url: `${api.baseUrl}/order/cart/deleteQueryCartCache`,
  data
})




