<template>
  <div class="v-poster" :style="rootStyle" :id="'nav' + id">
    <div v-if="path1">
      <img class="img" :src="path1" style="margin-right: 16rpx" @click="cmsLink(options.link1)" />
    </div>
    <div v-if="path2">
      <img class="img" :src="path2" style="margin-right: 16rpx" @click="cmsLink(options.link2)" />
    </div>
    <div v-if="path3">
      <img class="img" :src="path3" style="margin-right: 16rpx" @click="cmsLink(options.link3)" />
    </div>
    <div v-if="path4">
      <img class="img" :src="path4" style="margin-right: 16rpx" @click="cmsLink(options.link4)" />
    </div>
    <div v-if="path5">
      <img class="img" :src="path5" style="margin-right: 16rpx" @click="cmsLink(options.link5)" />
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
/**
 * styleType 1: 通栏 2: 剧中
 * bgType 1：纯色 2: 背景图
 */
export default {
  name: 'home-center',
  props: {
    options: Object,
    id: String
  },
  data() {
    return {
      getFilePath,
      cmsLink: cmsLink.bind(this)
    }
  },
  components: {},
  created() {
    this.$store.commit('setHomeNav', this.id)
  },
  computed: {
    path1() {
      return getFilePath('orig', this.options?.fileId1)
    },
    path2() {
      return getFilePath('orig', this.options?.fileId2)
    },
    path3() {
      return getFilePath('orig', this.options?.fileId3)
    },
    path4() {
      return getFilePath('orig', this.options?.fileId4)
    },
    path5() {
      return getFilePath('orig', this.options?.fileId5)
    },
    bgType() {
      return this.options?.bgType
    },
    rootStyle() {
      let style = {}
      if (this.options?.styleType == '1') {
        style.width = '1920px'
      }
      if (this.options.bgColor) {
        style.backgroundColor = this.options.bgColor
      }
      return style
    }
  },
  mounted() {
    // 图片加载忒慢了,不等待直接 返回
    this.handelLoad()
  },
  methods: {
    handelLoad() {
      // 图片加载 成功 错误 都会执行回调,每次执行一组
      this.options.type = 'v-poster'
      this.$emit('load', this.options)
    }
  }
}
</script>
<style scoped lang="less">
.v-poster {
  width: 1200px;
  display: flex !important;
  flex-direction: row;
  // height: 272px;
  // margin: 0 auto;
  & > div {
    flex: 1;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
  .img {
    width: 100%;
    border-radius: 8px;
  }
}
</style>
