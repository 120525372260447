<template>
  <div class="count-down-wrapper">
    <div v-if="innerTarget > 0" class="count-down">
      <slot>
        <span v-if="innerTarget > 60*60*1000">{{ time.hours }}时</span>
        <span v-if="innerTarget > 60*1000">{{ time.minutes }}分</span>
        <span>{{ time.seconds }}秒</span>
      </slot>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
export default {
  name: 'CountDown',
  props: {
    target: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      innerTarget: 0,
      timeInterval: null,
      time: {},
    }
  },
  watch: {
    target: {
      immediate: true,
      handler(value) {
        this.innerTarget = value
        this.formTime()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timeInterval)
  },
  deactivated() {
    clearInterval(this.timeInterval)
  },
  activated() {
    this.formTime()
  },
  methods: {
    formTime() {
      clearInterval(this.timeInterval)

      const { $d } = dayjs.duration(this.innerTarget)
      this.time = $d
      this.timeInterval = setInterval(() => {
        this.innerTarget -= 1000
        if (this.innerTarget <= 0) {
          clearInterval(this.timeInterval)
          this.$emit('on-end')
        } else {
          const { $d } = dayjs.duration(this.innerTarget)
          this.time = $d
          this.$emit('on-change', $d)
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
    .count-down-wrapper {
        display: inline-block
    }
</style>
