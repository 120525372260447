// 电梯导航
<template>
  <div ref="root" class="v-lift-wrapper">
    <div class="v-lift-nav">
      <a v-for="(item, index) in data" :key="index" href="javascript:void(0);" @click="toNav(item.id)">
        {{ item.title }}
      </a>
      <a href="javascript:void(0);" class="top" @click="toNav('top')">
        <svg-icon name="top-delta" />
        <p class="top-text">顶部</p>
      </a>

    </div>
    <div v-if="couponList.length > 0" @click="gotoCouponCenter">
<!--      <svg-icon name="coupon-entry1" class="coupon" />-->
      <img class="coupon-icon" src="@/assets/coupon.png" alt="">
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
import { getCouponCenterListApi } from '@/common/service/coupon'

/**
 * styleType 1: 通栏 2: 剧中
 * bgType 1：纯色 2: 背景图
 */
export default {
  name: 'VLiftNav',
  components: {
  },
  props: {
    options: Object,
    autoTop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getFilePath,
      cmsLink: cmsLink.bind(this),
      rootStyle: {},
      couponList: []
    }
  },
  computed: {
    data() {
      return this.options?.list?.filter((item) => {
        this.$nextTick(() => {
          this.setPostion()
        })
        return item.type !== 'v-lift-nav' &&
               item.type !== 'v-page-couplet' &&
               this.$store.state.cms.homeNav.includes(item.id)
      })
    }
  },
  created() {
  },
  mounted() {
    this.setPostion()
    this.getCouponList()
  },
  methods: {
    toNav(id) {
      if( id == 'top' ) {
        window.scrollTo(0, 0)
        return
      }
      const dom = document.querySelector('#nav' + id)
      if (dom) {
        const oPos = dom.offsetTop
        const parentTiop = dom.parentElement.offsetTop || 0
        window.scrollTo(0, parentTiop + oPos - 80)
      }
    },
    setPostion() {
      if(this.autoTop) return
      const rootDom = this.$refs.root
      if (rootDom) {
        const clientHeight = rootDom.clientHeight
        const top = (document.body.clientHeight - clientHeight) / 2
        rootDom.style.marginBottom = `-${clientHeight + 32}px`
        rootDom.style.bottom = `-${clientHeight + 32}px`
        rootDom.style.top = `${top}px`
      }
    },
    gotoCouponCenter() {
      this.$router.push({ path: '/home/coupon' })
    },
    async getCouponList() {
      this.couponList = []
      const { code, data } = await getCouponCenterListApi(
        {},
        (requestTask) => { }
      )
      if (code !== 200) {
        return
      }
      this.couponList = data || []
      this.$nextTick(() => {
        this.setPostion()
      })
    }
  }
}
</script>
<style scoped lang="less">
.v-lift-wrapper{
  width: 58px;
  position: sticky;
  right: 20px;
  top: 44px;
  float: right;
  z-index: 1;
  overflow: hidden;
  .coupon {
    width: 86px;
    height: 86px;
    position: relative;
    right: 12px;
  }
}

.v-lift-nav {

  background: #FFFFFF;
  //box-shadow: 0px 12px 60px rgba(0, 0, 0, 0.03), 0px 32px 48px rgba(0, 0, 0, 0.02), 0px 16px 20px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 8;
  & > a {
    padding: 12px 17px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
    line-height: 18px;
    border-bottom: 1px solid #EEEEEE;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
      color: #3853E2;
    }
  }
  .svg-icon {
    width: 8px;
    height: 8px;
  }
  .top {
    padding: 12px 0;
    cursor: pointer;
  }
  .top-text {
    margin: 8px  0 0 0;
    color: #CE7720;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
.coupon-icon{
  width: 100%;
  cursor: pointer;
}
</style>
