export default [
  {
    path: '/trade/orderConfirm',
    name: 'OrderConfirm',
    meta: { title: '订单结算' },
    component: () => import('@/views/trade/orderConfirm/index.vue')
  },
  {
    path: '/trade/orderPay',
    name: 'orderPay',
    meta: { title: '订单支付' },
    component: () => import('@/views/trade/orderPay/index.vue')
  },
  {
    path: '/trade/wxPay',
    name: 'wxPay',
    component: () => import('@/views/trade/wxPay/index.vue')
  },
  {
    path: '/trade/zfbPay',
    name: 'zfbPay',
    component: () => import('@/views/trade/zfbPay/index.vue')
  },
  {
    path: '/trade/otherPay',
    name: 'otherPay',
    component: () => import('@/views/trade/otherPay/index.vue')
  },
  {
    path: '/trade/payResult',
    name: 'payResult',
    component: () => import('@/views/trade/payResult/index.vue')
  },
  {
    path: '/trade/transferResult',
    name: 'payResult',
    component: () => import('@/views/trade/orderPay/transferResult.vue')
  }
]
