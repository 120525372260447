<template>
<!-- 内容区标题 -->
<div class="root">
    <span class="title">{{title}}</span>
    <svg-icon name="content-arrow" className="content-arrow-icon"></svg-icon>
</div>
</template>

<script>
export default {
    name: 'PTabs',
    props: {
        title: {
            type: String,
            default: ''
        }
    },
   
}
</script>

<style lang="less" scoped>
    .root {
        width: 100%;
        margin: 0 auto 12px auto;
        .title {
            font-size: 24px;
            line-height: 32px;
            color: #000000;
            font-weight: 500;
        }
        .content-arrow-icon {
            margin-left: 8px;
            width: 23px;
            height: 19px;
        }
    }
</style>