<template>
  <div class="affiliated">
    <div style="line-height: 22px; padding: 10px 0; font-size: 14px;">
      <Breadcrumb>
        <BreadcrumbItem>请选择关联药店</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="affiliated-content">
      <div style=" margin-bottom: 20px; color: rgba(0, 0, 0, 0.6);">
        查询您的药店可以快速关联，若未找到您的药店，请点击
        <span style=" margin-left: 10px; color: #CE7720" @click="enter('/information', 'enter')">我要入驻</span>
      </div>
      <Form ref="formCustom" :model="formCustom" :label-position="'top'" :inline="true">
        <FormItem label="客户名称" prop="customName" class="cust_bor">
          <Input v-model="formCustom.customName" />
        </FormItem>
        <FormItem label="客户类型" prop="businessType">
          <Select v-model="formCustom.businessType" style="width:200px">
            <Option v-for="item in customerTypeList" :key="item.value" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="地区" prop="districtCode" class="cust_bor">
          <CustomerArea :district.sync="formCustom.district" :code.sync="formCustom.districtCode" :name.sync="formCustom.districtName" />
        </FormItem>
        <FormItem style="padding-top: 23px;">
          &nbsp;&nbsp;
          <Button type="primary" @click="getCustomListByName">搜索</Button>
          &nbsp;&nbsp;
          <Button @click="reset">重置</Button>
        </FormItem>
      </Form>
      <Table border size="small" height="500" :columns="columns" :data="data">
        <template #action="{ row }">
          <div v-if="!row.association" style=" line-height: 22px; font-size: 14px; color: #3853E2" @click="relevancy('/information', 'relevancy', row)">关联</div>
          <div v-else @click="tips()" style="line-height: 22px; font-size: 14px; color: rgba(0, 0, 0, 0.4)">关联</div>
        </template>
      </Table>
      <div v-if="data.length" style="padding-top: 20px;">
        <Pagination :total="total" @change="handleChange" />
      </div>
    </div>

    <ComModal :isShow.sync="showModal" @confirm="modalConfirm" :title="`该企业已被${modalData.length}个用户关联，是否继续关联？`">
      <Table border :columns="modalColumns" :data="modalData" class="modalTable" />
    </ComModal>
  </div>
</template>
<script>
import { getCustTypeMapAPI, findCustomerByNameAPI, getCustInfoAPI } from '@/common/service/insertCustInfo'
import CustomerArea from '../information/components/CustomerArea.vue'
import {globalEnterSearch} from "@/utils"
import ComModal from '@/components/ComModal'
import { Table } from 'view-design';

export default {
  name: 'Affiliated',
  components: {
    CustomerArea,
    ComModal
  },
  data() {
    return {
      modalData:[],
      modalColumns: [
        {   
          title: '序号',
          type: 'index',
          width: 80,
          align: 'center'
        },
        {
          title: '手机号',
          key: 'phone',
          width: 165,
          render: (h, { row }) => {
            return h('span', {}, row.phone || '-')
          }
        },
        {
          title: '认证状态',
          key: 'approvalState',
          width: 200,
          render: (h, {row}) => {
                return h('span', {
                    class: {
                      'tag': true,
                      'refuse_auth': row.approvalState === 'refuse_auth',
                      'un_auth': row.approvalState === 'under_auth',
                      'auth': row.approvalState === 'auth',
                    },
                  }, row.approvalStateDesc || '-')
              }
        }
      ],
      curCustomerId:'',
      showModal:false,
      formCustom: {
        customName: '',
        businessType: '',
        districtCode: '',
        districtName: '',
        district: []
      },
      columns: [
        {
          title: '药店名称',
          key: 'customName'
        },
        {
          title: '注册地址',
          key: 'addressLine'
        },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ],
      data: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      customerTypeList: []
    }
  },
  mounted() {
    this.getCustomerTypeList()
    globalEnterSearch(this.handleChange, '/affiliated')
  },
  methods: {
    handleChange({ pageNumber, pageSize }) {
      this.pageNum = pageNumber
      this.pageSize = pageSize
      this.getCustomListByName()
    },
    async getCustomListByName() {
      const condition = {
        customName: this.formCustom.customName
      }
      if (this.formCustom.businessType && this.formCustom.businessType !== '0') {
        condition.businessType = this.formCustom.businessType
      }
      if (this.formCustom.districtCode) {
        condition.districtCode = this.formCustom.districtCode
      }
      const { code, data } = await findCustomerByNameAPI({
        condition,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (code === 200 && data.total != 0) {
        console.log(data, 'data')
        this.total = data.total
        this.data = data.records
        return
      }
      this.total = 0
      this.data = []
    },
    reset() {
      this.formCustom = {
        customName: '',
        businessType: '',
        districtCode: '',
        districtName: '',
        district: []
      }
    },
    async getCustomInfo(id, path, scene) {
      const { code, data } = await getCustInfoAPI(id)
      if (code === 200) {
        const { custCustomerInfo, custReceiveAddr, custCertPicsVos, clientVo } = data
        custCustomerInfo.customId = id
        // 若存在区域代码、则生成省市区编码
        const area = custCustomerInfo.districtCode
        let province = ''
        let city = ''
        if (area) {
          province = area.substr(0, 2) + '0000'
          city = area.substr(0, 4) + '00'
          custCustomerInfo.district = [province, city, area]
        }
        this.$store.commit('InfoEnter/SET_BASE_INFO', { ...custCustomerInfo })
        // if (!custReceiveAddr) {
        //   this.$store.commit('InfoEnter/SET_CONSIGNEE_INFO', {
        //     district: [province, city, area],
        //     receiveDistrictName: custCustomerInfo.districtName,
        //     receiveDistrictCode: custCustomerInfo.districtCode,
        //     receiveAddr: custCustomerInfo.addressLine,
        //     receiveContact: custCustomerInfo.legalPerson,
        //     isInvoice: '1',
        //     invoiceType: 'plain_invoice',
        //     invoiceHeader: custCustomerInfo.customName,
        //     unitTaxNo: custCustomerInfo.threeDocMerger
        //   })
        // } else {
        //   this.$store.commit('InfoEnter/SET_CONSIGNEE_INFO', { ...custReceiveAddr })
        // }
        this.$store.commit('InfoEnter/SET_CERTIFICATION_INFO', { customerCertList: [...custCertPicsVos] })
        if (clientVo && clientVo.idCardEndActiveDate) {
          clientVo.idCardEndActiveDate = new Date(clientVo.idCardEndActiveDate).getTime()
        }
        this.$store.commit('InfoEnter/SET_ENTRUST_INFO', { ...clientVo })
        setTimeout(() => {
          this.toPage(path, scene)
        }, 100)
      }
    },
    async getCustomerTypeList() { // 获取企业类型
      this.customerTypeList = [{
        value: '0',
        label: '全部'
      }]
      const { code, data } = await getCustTypeMapAPI()
      if (code === 200 && data) {
        for (let index = 0; index < data.length; index++) {
          const label = data[index].custOrgTypeName
          const value = data[index].id
          this.customerTypeList.push({
            value,
            label
          })
        }
        // console.log('this.customerTypeList', this.customerTypeList)
      }
    },
    tips() {
      this.$Message.warning('您已关联该企业，请勿重复关联！')
    },
    enter(path, scene) {
      this.$store.dispatch('InfoEnter/SET_ALL_INFO')
      this.toPage(path, scene)
    },
    relevancy(path, scene, row) {
      if (row.userList && row.userList.length !== 0) {
        this.showAssociationConfirm(row)
      } else {
        this.$store.dispatch('InfoEnter/SET_ALL_INFO')
        this.getCustomInfo(row.customerId, path, scene)
      }
    },
    // 去关联
    showAssociationConfirm(row) {
      // this.$Modal.confirm({
      //   title: '提示',
      //   content: '该企业已被其他用户关联，是否继续关联？',
      //   okText: '去关联',
      //   cancelText: '取消',
      //   onOk: () => {
      //     this.$router.push({
      //       path: '/association',
      //       query: {
      //         customId: customerId
      //       }
      //     })
      //   },
      //   onCancel: () => {
      //   // this.$message.info('Clicked cancel')
      //   }
      // })

      this.curCustomerId = row.customerId
      this.modalData = row.userList || []
      this.showModal = true
    },
    modalConfirm(closeLoading){
      closeLoading()
      this.$router.push({
        path: '/association',
        query: {
          customId: this.curCustomerId
        }
      })
    },
    toPage(path, scene) {
      this.$router.push({
        path,
        query: {
          scene
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.affiliated {
  width: 1200px;
  margin: 0 auto;
  &-content {
    margin-bottom: 20px;
    padding: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);
    cursor: default;
    box-sizing: border-box;
    background-color: #fff;
  }
}
::v-deep .cust_bor {
  .ivu-input {
    border-radius: 4px !important;
  }
}

::v-deep .modalTable {
  width: 100%;

  

  .ivu-table-wrapper-with-border{
    border-radius: 8px 8px 0px 0px;
  }

  .ivu-table-cell{
    overflow: hidden;
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    text-overflow: ellipsis;
    white-space: nowrap;
    /* regular/12 */
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
}
</style>
