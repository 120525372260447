<template>
  <div class="more-list">
    <div class="head">
      <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true" />
      <ContentTitle style="width: 1200px;" :title="'商品列表'" />
    </div>
    <div class="status_bar">
      <!-- 这里是状态栏 -->
    </div>
    <!-- <c-head type="text" title="更多" @change="backToIndex" :showCard="true" :cartNum="cartNum"></c-head> -->
    <div class="product-list">
      <div v-if="isLoading">
        <div class="loading-outer">
          <Spin fix>
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load" />
            <div>Loading</div>
          </Spin>
        </div>
      </div>
      <template v-else>
        <ProductCard v-for="(item, index) in productsArr" :key="index" :goods-item="item" class="list_item" show-platform-tag/>
      </template>
    </div>
    <!-- <c-purchased-car :showPopup.sync="isShowPopup" :goodItem="shoppingData" @addProduct="addProduct" /> -->
    <Pagination class="pagination-more" :total="total" :page-size-opts="[10, 20, 50, 100]" :size="pageSize" :num="pageNum" @change="handleChange" />
  </div>
</template>

<script>

import {
  getProductByIdS, // 获取商品type 1
  getStandandByIdS // 获取标品
} from '@/common/service/home.js'
import { productListByEs } from '@/common/service/product'
import { batchGetControl, transformEsData } from '@/common/utils'
// import CList from '@/components/c-list/index'
import SearchBar from '@/components/SearchBar.vue'
import Tabs from '@/components/Tabs'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/ListCard.vue'

export default {
  name: 'More',
  components: {
    SearchBar,
    Tabs,
    ContentTitle,
    ProductCard
  },
  props: {

  },
  data() {
    return {
      cartNum: 0, // getApp().globalData.carTextNum,
      isShowPopup: false, // 是否显示加购组件
      isJsHe: true,
      shoppingData: {},
      lists: [],
      productsArr: [],
      productType: '', // type：1 商品，type：2 标品
      isInster: false, // 是否认证
      cmsModelId: '',
      pageSize: 10,
      pageNum: 1,
      total: 0,
      type: '',
      cmsPageNum: 0,
      isLoading: false
    }
  },
  created() {
    const query = this.$route.query
    if (!query || !query.type) return
    this.type = query.type
    this.cmsModelId = query.cmsModelId
    if (this.type == 1) {
      this.getProductSp()
    } else if (this.type == 2) {
      this.getProductBp()
    } else if (this.type == 3) {
      this.getProductEs()
    } else if (this.type === 'tags') {
      this.getTagsProducts()
    } else if (this.type === 'label') {
      this.getLabelsProducts()
    }
  },
  methods: {
    // 加购组件回调
    addProduct(num) {
      this.cartNum = 0 // getApp().globalData.carTextNum
    },
    addProductCart(val) {
      this.shoppingData = {
        orgId: val.orgId, // 商家id,
        id: val.productId, // 商品id
        activityId: val.activityId, // 活动id
        activityType: val.activityType, // 活动类型
        addBuyNum: val.addBuyNum, // 每次加购数量
        startBuyNum: val.startBuyNum, // 起购数量
        availableStock: val.availableStock, // 库存
        singleLimitNum: val.singleLimitNum, // 限购数量
        isSingleLimit: val.isSingleLimit, // 是否限购 //0不限购 1限购
        currentPrice: val.currentPrice, // 商品原价
        activityPrice: val.activityPrice, // 商品活动价
        brand: val.brand, // 商品品牌
        generalChineseName: val.displayName, // 商品名称
        productSpec: val.productSpec, // 包装规格
        mainPicUrl: val.mainPicVo ? val.mainPicVo.picUrl : '', // 商品主图
        productUnit: val.productUnit, // 单位
        displayName: val.displayName
      }
      this.isShowPopup = true
    },
    // go() {
    //   console.log('tiaozhuan')
    //   uni.navigateTo({
    //     url: '/pages/product/search/index'
    //   })
    // },
    // backToIndex() {
    //   uni.navigateBack({
    //     delta: 1
    //   })
    // },
    async getProductSp() {
      this.isLoading = true
      const { data } = await getProductByIdS({
        pageSize: this.pageSize,
        startIndex: this.cmsPageNum,
        cmsModelId: this.cmsModelId
      })
      this.isLoading = false
      data.records.forEach((item) => {
        transformEsData(item)
      })
      this.productsArr = data.records
      batchGetControl(data.records).then((res) => {
        this.productsArr = res
      })
      this.total = data.total
      this.getInster()
    },
    async getProductBp() {
      this.isLoading = true
      const { data } = await getStandandByIdS({
        pageSize: 200,
        startIndex: 0,
        cmsModelId: this.cmsModelId
      })
      this.isLoading = false
      data.records.forEach((item) => {
        transformEsData(item)
      })
      this.productsArr = data.records
      // this.getInster()
      // if (data[0].custState === 'auth') {
      //   this.isInster = true
      //   return
      // }
      // this.isInster = false
    },

    async getProductEs() {
      this.isLoading = true
      const activityType = this.$route.query.activityType === 'all' ? '' : this.$route.query.activityType
      const params = {
        page: {
          pageNumber: this.pageNum,
          pageSize: this.pageSize,
          sort: 'marketing.startTime',
          order: 'desc'
        }
      }
      // 由于使用的是商家端的活动类型，需要匹配平台端的活动类型
      if (activityType == '1') {
        params.activityTypes = [11, 12, 13, 14, 15]
      } else if (activityType == '3') {
        params.activityTypes = [31, 32]
      } else if (activityType == '4') {
        params.activityTypes = [41, 42]
      } else if (activityType == '7') {
        params.activityTypes = [71, 72]
      } else {
        params.activityType = activityType
      }

      if (this.$route.query.orgId) {
        params.orgId = [this.$route.query.orgId]
      }
      const { data } = await productListByEs(params)
      this.isLoading = false
      data.records.forEach((item) => {
        transformEsData(item)
      })
      this.productsArr = data.records
      batchGetControl(data.records).then((res) => {
        this.productsArr = res
      })
      this.total = data.total
    },
    async getInster() {
      if (this.productsArr?.at(0)?.custState === 'auth') {
        this.isInster = true
        return
      }
      this.isInster = false
    },
    handleChange(o) {
      const { pageNumber, pageSize } = o
      if (this.type === 'tags') {
        this.pageNum = pageNumber
        this.pageSize = pageSize
        this.getTagsProducts()
      } else if (this.type === 'label') {
        this.pageNum = pageNumber
        this.pageSize = pageSize
        this.getLabelsProducts()
      } else if (this.type == 3) {
        this.pageNum = pageNumber
        this.pageSize = pageSize
        this.getProductEs()
      } else {
        this.cmsPageNum = pageNumber - 1
        this.pageSize = pageSize
        this.getProductSp()
      } 
    },
    // 商家运营标签
    async getTagsProducts() {
      this.isLoading = true
      const query = this.$route.query
      const labels = query.labels.split(',')
      const params = {
        productLabelIds: labels,
        orgId: [query.orgId],
        page: {
          pageSize: this.pageSize,
          pageNumber: this.pageNum
        }
      }
      const data = await productListByEs(params)
      this.isLoading = false
      if (data.code === 200) {
        this.productsArr = data.data.records
        this.total = data.data.total
        batchGetControl(this.productsArr).then((res) => {
          this.productsArr = res
        })
      }
    },
    // 平台端商品标签
    async getLabelsProducts() {
      this.isLoading = true
      const query = this.$route.query
      const params = {
        productLabelId: [query.labelId],
        page: {
          pageSize: this.pageSize,
          pageNumber: this.pageNum
        }
      }
      const data = await productListByEs(params)
      this.isLoading = false
      if (data.code === 200) {
        this.productsArr = data.data.records
        this.total = data.data.total
        batchGetControl(this.productsArr).then((res) => {
          this.productsArr = res
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.status_bar {
  height: var(--status-bar-height);
  width: 100%;
  background: #fff;
}
.more-list {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  width: 1200px;
  margin: auto;
}
.list_item {
  &:nth-child(5n) {
    margin-right: 0;
  }
}
.product-list {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap:12px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.loading-outer{
  min-height: calc(100vh - 300px);
}
::v-deep .u-tag__text {
  font-size: 20rpx !important;
}
::v-deep .u-tag {
  height: 30rpx !important;
}
::v-deep .acti_tag {
  .line-clamp-1 {
    max-width: 400rpx;
    text-overflow : ellipsis;
    white-space : nowrap;
    overflow : hidden;
  }
}
.pagination-more {
  margin: 20px auto;
}
// @media screen and (min-height:800px){
//   .more-list {
//     .list_item:nth-child(3) {
//         margin-top: 154rpx;
//     }
//   }
// }
</style>
