import RouterContanst from '@/router/router-contanst'

export default [
  // nest_son1会被渲染在 nest的 <router-view> 中
  {
    path: '/home',
    name: RouterContanst.HOME_COMMON_LAYOUT,
    component: () => import('@/components/layout/HomeLayout'),
    children: [
      {
        path: 'index',
        name: RouterContanst.HOME.HOME_PAGE,
        component: () => import('@/views/home/index')
      }
    ]
  }
]
