<template>
  <div class="shop-search">
    <div class="speed-font">
      <img src="@/assets/images/center/speed-font.png" />
    </div>
    <div class="search-content">
      <!-- <span class="title">商家</span> -->
      <div class="input-search">
        <Input
          v-model="searchKey"
          size="large"
          search
          placeholder="请选择商家"
          @on-focus="inputFocusHandler"
          @input="getKeywordList()"
          @on-blur="focus = false"
        />
        <span v-if="!focus && searchKey" class="search_key">{{ searchKey }}
          <Icon type="md-close" @click="clearSearchKey" />
        </span>
        <div v-if="showKeywordList" class="search-relate">
          <p
            v-for="(item) in keywordList"
            :key="item.orgId"
            class="key-word-item"
            @click.stop="handleSuggestList(item)"
          >
            <span class="orgname">{{ item.orgName }}</span>
            <span :class="item.tag" class="tag">{{ item.name }}</span>
          </p>
        </div>
      </div>
      <Button class="next-step" :class="disabled ? 'unable' : ''" :disabled="disabled" @click="jumpUpload">下一步</Button>
    </div>
    <!-- 检查商家建采状态 开始 -->
    <CheckOrgJcStatus ref="checkOrgJcStatus" @ok="ok" />
    <!-- 检查商家建采状态 结束 -->
  </div>
</template>
<script>
import CheckOrgJcStatus from '@/components/CheckOrgJcStatus/index.vue'
import { debounce } from '@/common/utils/format'
import { getOrgList } from '@/common/service'
export default {
  name: 'Address',
  components: {
    CheckOrgJcStatus
  },
  props: {
    btnDisabled: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      searchKey: '',
      keywordList: [], // 关键词列表
      showKeywordList: false,
      focus: false,
      disabled: true,
      orgId: '',
      orgName: '',
      states: [
        {
          name: '已建采',
          tag: 'successAuth',
          state: 'success_auth'
        },
        {
          name: '建采中',
          tag: 'unAuth',
          state: 'un_auth'
        },
        {
          name: '建采拒绝',
          tag: 'refuseAuth',
          state: 'refuse_auth'
        },
        {
          name: '未建采',
          tag: 'noAuth',
          state: null
        }
      ]
    }
  },
  methods: {
    inputFocusHandler() {
      this.focus = true
      this.getKeywordList()
    },
    // 获取关联词列表
    getKeywordList: debounce(async function() {
      this.$emit('update:btnDisabled', true)
      this.showKeywordList = true
      try {
        const { data } = await getOrgList({
          orgName: this.searchKey
        })
        this.keywordList = data?.map(item => {
          const matchState = this.states.find(sta_item => sta_item.state === item.state)
          return {
            ...item,
            ...matchState
          }
        }) || []
      } catch (error) {
        this.$message.error(error?.data?.message)
      }
    }, 1000),

    // 点击推荐关联词搜索
    async handleSuggestList(item) {
      this.showKeywordList = false
      this.searchKey = item.orgName
      this.$emit('update:btnDisabled', false)
      this.orgId = item.orgId
      this.orgName = item.orgName
      this.$refs.checkOrgJcStatus.checkBuyStatus(this.orgId)
    },
    ok() {
      this.$emit('selectShop', this.orgId, this.orgName)
      localStorage.setItem('selectOrgName', this.orgName)
      localStorage.setItem('selectOrgId', this.orgId)
    },
    clearSearchKey() {
      this.searchKey = ''
      this.$emit('update:btnDisabled', true)
    },
    async jumpUpload() {
      if (this.disabled) { return }
      this.$router.push(`/batch/uploadTemplate?orgId=${this.selectOrgId}&orgName=${this.selectOrgName}`)
    }
  }

}
</script>
<style scoped lang="less">
.shop-search {
  background: #fff;
  min-height: calc(100vh - 340px);
  padding: 24px;

  .speed-font {
    margin: 80px auto 40px;
    width: 135px;

    img {
      width: 100%;
    }
  }

  .search-content {
    display: flex;
    justify-content: center;
  }

  .next-step {
    padding: 12px 16px;
    border-radius: 4px;
    background: var(--brand-3, #F99D33);
    color: #fff;
    border: none;
    margin-left: 16px;
    height: 40px;
  }

  .unable {
    background: var(--brand-3, #FDDA9F);
  }

  .title {
    margin-top: 10px;
    margin-right: 16px;
  }

  .input-search {
    position: relative;

    .search_key {
      letter-spacing: 1px;
      position: absolute;
      top: 7px;
      left: 10px;
      z-index: 9;
      border-radius: 4px;
      border: 1px solid #DCDCDC;
      background: #F5F5F5;
      color: #4B4B4B;
      font-size: 12px;
      line-height: 20px;
      padding: 2px 4px;
    }
  }

  .search-relate {
    margin-top: 8px;
    width: 428px;
    max-height: 356px;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 8px 0;
    border-radius: 4px;
    background: var(--font-white-0, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);

    &::-webkit-scrollbar {
      display: none;
    }

    .key-word-item {
      display: flex;
      justify-content: space-between;
      padding: 4px 8px;
      color: var(--gray-7, #4B4B4B);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      align-items: center;

      &:hover {
        cursor: pointer;
        background: var(--gray-15, #F5F5F5);
      }

      .orgname {
        width: 343px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .tag {
        padding: 4px;
        border-radius: 4px;
        font-size: 12px;
      }

      // 去建采
      .noAuth {
        background-color: rgba(245, 245, 245, 1);
        color: rgba(0, 0, 0, 0.9);
      }

      // 建采中
      .unAuth {
        background-color: rgba(56, 83, 226, 0.1);
        color: #2438BC;
      }

      // 已建采
      .successAuth {
        background-color: rgba(77, 172, 57, 0.1);
        color: #034D00;
      }

      // 拒绝建采
      .refuseAuth {
        background-color: rgba(245, 63, 63, 0.05);
        color: rgba(250, 84, 28, 1);
      }
    }
  }
}

:deep(.ivu-input) {
  display: flex;
  align-items: center;
  width: 428px;
  border-radius: 4px;
  border: 1px solid var(--gray-3, #E7E7E7);
  padding: 8px 12px 8px 16px;
  color: var(--font-black-1, rgba(0, 0, 0, 0.90));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:focus {
    box-shadow: none;
  }
}
</style>
