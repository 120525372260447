<!-- cms自定义弹窗，和默认弹窗ui不一样，并且支持cms配置参数，所以单独开发 -->
<template>
	<div class="cms-dialog" v-if="show">
		<div class="_shade"></div>
		<div class="box" :style="{'background-image': 'url(' + data.bgImg + ')'}">
			<div class="header" @click="closeHandler">
				<div class="title" v-if="data.title">{{ data.title }}</div>
				<img src="./assets/close.png" class="close">
			</div>
			<div class="region"  v-if="data.radio != 3">
				<div class="content">{{ data.content }}</div>
				<div class="btns"  v-if="data.radio != 3">
					<template v-for="(item, index) in data.btn">
						<div class="btn"
							@click.stop="clickBtn(item)" 
							:key="index">{{ item.btnText }}</div>
					</template>
				</div>
			</div>
			<!-- 热区配置 -->
			<div
				v-if="data.radio === 3"
				v-for="(item, index) in data.hotZoneList || []" 
				:key= 'index' 
				:style="{
					left: item.position.left * scale + 'px',
					top: item.position.top * scale + 'px',
					width: item.position.width * scale + 'px',
					height: item.position.height * scale + 'px'
				}"
				class="zone"

				@click = "item.link && cmsLink.bind(this)(item.link)">
			</div>
		</div>
	</div>
</template>

<script>
import { cmsLink } from '@/views/home/index'
export default {
	name: "CmsModal",
	computed: {
		show() {
			return this.$store.state.cmsModal.show;
		},
		data() {
			let _data = this.$store.state.cmsModal.data
			let result = {}
			let settingContent = _data?.settingContent
			if( settingContent ) {
				result = Object.assign({},{
					title: _data?.settingTitle,
					content: settingContent?.tipCon,
					bgImg: settingContent?.imageUrl,
					btn: [],
					radio: settingContent.radio, // 弹框类型： 1 单按钮弹框； 2 双按钮弹框； 3 热区配置弹框
					hotZoneList: settingContent.hotZoneList || []
				})
			}
			if(  settingContent?.leftBtnCon && settingContent?.leftBtnCon?.btnText ) {
				result.btn.push(settingContent?.leftBtnCon)
			}
			if(  settingContent?.rightBtnCon && settingContent?.rightBtnCon?.btnText ) {
				result.btn.push(settingContent?.rightBtnCon)
			}
			return result
		}
	},
	methods: {
		clickBtn(data) {
			this.$store.state.cmsModal?.data?.ok && this.$store.state.cmsModal?.data?.ok(data)
		},
		closeHandler() {
			this.$store.state.cmsModal?.data?.close && this.$store.state.cmsModal?.data.close()
		}
	},
	beforeDestroy() {
		this.$modalStore.commit('hideModal')
	},
	data() {
		return {
			scale: 1.4,
			cmsLink: cmsLink.bind(this),
		};
	}
}
</script>

<style lang="less" scoped>
.cms-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;

	._shade {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		opacity: .6;
		z-index: 110000;
	}

	.box {
		width: 548px;
		background-size: 100% auto;
		height: 358px;
		background-color: #fff;
		border-radius: 8px;
		position: absolute;
		background-repeat: no-repeat;
		z-index: 110001;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 24px;
		box-sizing: border-box;
		.header {
			height: 22px;
			display: flex;
			.title {
				font-size: 18px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.9);
				text-align: center;
			}
			.close {
				margin: 0 0 0 auto;
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}
		.region {
			box-sizing: border-box;
			display: flex;
			height: 284px;
			flex-direction: column;
			.content {
				font-size: 14px;
				line-height: 48px;
				color: rgba(0, 0, 0, 0.6);
				// margin-top: 24px;
				flex: 1;
				white-space:pre-wrap;
				word-break: break-all;
				overflow: hidden auto;
			}
		}

		.btns {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 36px;
			// padding: 48px 40px;
			box-sizing: border-box;

			.btn {
				// flex: 1;
				line-height: 36px;
				text-align: center;
				border-radius: 4px;
				font-size: 14px;
				width: 92px;
				// width: 30%;
				// height: 36px;
				cursor: pointer;
				&:nth-child(1) {
					color: rgb(255, 255, 255);
    				background-color: rgb(249, 157, 51);
				}
				&:nth-child(2) {
					color: rgba(0, 0, 0, 0.4);
    				background-color: rgb(238, 238, 238);
					margin-left: 16px;
				}
			}
		}
		.zone {
			position: absolute;
		}
	}
}
</style>