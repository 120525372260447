<template>
  <div class="enterprise">
    <ChangeEnterprise :scene="'enterprise'" @changeCompany="changeCompany" />
    <!--经营范围-->
    <div v-if="companyInfo?.custWxMyCertificateVoList" class="box">
      <div class="caption border-bottom-dashed">经营范围</div>
      <div v-if="companyInfo" class="business-description">
        {{ businessScopeList }}
      </div>
      <div v-if="companyInfo?.businessScopeTipsList" class="business-description" style="margin-top: -12px;">
       说明： {{ companyInfo?.businessScopeTipsList?.join("；")}}
      </div>
    </div>
    <!--基础信息-->
    <BaseInfo v-if="companyInfo && baseInfo && companyInfo?.custWxMyCertificateVoList" :data="baseInfo" :refuse-num="refuseNum" />
    <!--收货地址-->
    <!-- <Address v-if="companyInfo && receiveAddrList && companyInfo?.custWxMyCertificateVoList" :company-info="companyInfo" :data="receiveAddrList" :refuse-num="refuseNum" @addrChange="getCustomerInfo" /> -->
    <!--开票信息-->
    <Invoice v-if="companyInfo && invoiceListVoList && companyInfo?.custWxMyCertificateVoList" :data="invoiceListVoList" :refuse-num="refuseNum" :company-info="companyInfo" />
  </div>
</template>
<script>
import ChangeEnterprise from '../components/ChangeEnterprise.vue'
import BaseInfo from '../components/BaseInfo.vue'
// import Address from '../components/Address.vue'
import Invoice from '../components/Invoice.vue'
import { getMyCustomerInfoAPI } from '@/common/service'
import { setStorageSync } from '@/common/utils/storage'
export default {
  name: 'CenterEnterprise',
  components: {
    ChangeEnterprise,
    BaseInfo,
    // Address,
    Invoice
  },
  data() {
    return {
      companyInfo: null,
      refuseNum: 0
    }
  },
  computed: {
    businessScopeList() {
      return this.companyInfo?.businessScopeList?.join(',')
    },
    baseInfo() {
      return this.companyInfo?.baseInfo
    },
    receiveAddrList() {
      return this.companyInfo?.receiveAddrList?.map(item => {
        item.invoiceHeader = this.companyInfo.customName
        item.unitTaxNo = this.companyInfo.threeDocMerger
        return { ...item }
      }) || []
    },
    invoiceListVoList() {
      return this.companyInfo?.invoiceListVoList || []
    }
  },
  created() {
    this.getCustomerInfo()
  },
  methods: {
    changeCompany() {
      this.getCustomerInfo()
    },
    // 获取企业信息
    async getCustomerInfo() {
      this.companyInfo = null
      const res = await getMyCustomerInfoAPI()
      const { code, data } = res
      if (code === 200) {
        this.companyInfo = data
        if (!this.companyInfo) {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>当前账号未关联企业，请先关联</p>',
            okText: '去关联',
            cancelText: '暂不关联',
            onOk: () => {
              this.$router.push('/affiliated')
            },
            onCancel: () => {
            // this.$message.info('Clicked cancel')
            }
          })
          return false
        }
        setStorageSync('customerInfo', this.companyInfo)
        this.refuseNum = data.refuseNum
        this.$set(this.companyInfo, 'baseInfo', {
          customerId: data.customerId,
          customName: data.customName, // 企业名称
          threeDocMerger: data.threeDocMerger, // 统一社会信用代码
          legalPerson: data.legalPerson, // 法人
          addressLine: data.addressLine, // 注册地址
          customerTypeId: data.customerTypeId + '', // 企业类型ID
          customerTypeName: data.customerTypeName, // 企业类型
          districtName: data.district?.provinceName + data.district?.cityName + data.district?.districtName, // 省市区
          district: [data.district?.provinceCode, data.district?.cityCode, data.district?.districtCode],
          districtCode: data.district?.districtCode, // 省市区 CODE
          state: data.state,
          stateDesc: data.stateDesc,
          approvalState: data.approvalState, // 认证状态标识
          approvalStateDesc: data.approvalStateDesc // 认证状态描述
        })
      }
    }
  }
}
</script>
<style lang="less">
.enterprise {
  min-height: 100vh;
  .business-description {
    line-height: 24px;
    padding-top: 20px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
  .border-bottom-dashed {
    border-bottom: #dcdcdc 1px dashed;
  }
  .border-right-dashed {
    border-right: #dcdcdc 1px dashed;
  }
}
</style>
