// 外跳链接
import { getPublicStatus,customerSignUp } from '@/common/service'
let signLoading = false
async function getPublicStatusFun(data) {
    const res = await getPublicStatus(data)
    if( res.data === 'issue'  ){
        return true
    }else {
        return false
    }
}
async function signHandler(id) {
    signLoading = true
    const data = await customerSignUp({signUpId:id})
    signLoading = false
    if(data.code == 200) {
      this.$message.success(data.data)
    } else {
      this.$message.error(data.message)
    }
  }
export async function skipLink(options, orgId) {
    if(options) {
        const type = options.key
        const itemData = options.value[0]
        if(type === 'product') {
            // 跳转 商品详情
            const productId = itemData.productId || itemData.id
            let activityId = itemData.activityId || '0'
            let activityType = itemData.activityType || '0'
            // if(itemData.activityType != '2') {
            //     activityId = '0'
            //     activityType = '0'
            // }
            this.$router.push(`/detail?productId=${productId}&activityId=${activityId}&activityType=${activityType}`)
        }else if(type === 'activityTheme'){
            // 跳转 专题/活动页
            let url = ''
            if(itemData.id && orgId && await getPublicStatusFun({id: itemData.id, type: 'plat_activity'})){
                url = `/shopHomeActivity?pageId=${itemData.id}&orgId=${orgId}`
            }else {
                url = '/pages/common/ActivityOver'
            }
           this.$router.push(url)
        } else if ( type == 'promotionActivity' ){ // 活动
            const id = itemData.id
            const activityAllDesc = itemData.activityAllDesc
            const activityType = itemData.activityType
            this.$router.push(`/shellList?activityId=${id}&activityAllDesc=${activityAllDesc}&activityType=${activityType}`)
        } else if ( type == 'category'){
            const id = itemData.id
            window.open(`/shopHome?orgId=${orgId}&categoryId=${id}&type=cmsCategory`,'_blank')
            // this.$router.push(`/shopHome?orgId=${orgId}&categoryId=${id}&type=cmsCategory`)
        } else if( type == 'sign' ) { // 报名活动
            const id = itemData.id
            if( id && !signLoading) {
            signHandler.bind(this)(id)
            }
        } else if ( type == 'tags' ) {
            const value = options.value
            if (value?.length > 0) {
                let labels = ''
                value.forEach((item,index) => {
                    if ( index == value.length - 1 ) {
                        labels += item.id
                    } else {
                        labels += item.id + ','
                    }
                });
                this.$router.push(`/more?type=${type}&labels=${labels}&orgId=${orgId}`)
            }
        }
    }
}