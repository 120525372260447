import {
  Button,
  Form,
  FormItem,
  Table,
  Icon,
  Input,
  Checkbox,
  Modal,
  Page,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  MenuItem,
  CheckboxGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  RadioGroup,
  Radio,
  Option,
  Scroll,
  TabPane,
  Tabs,
  Tooltip,
  Poptip,
  InputNumber,
  TimePicker,
  DatePicker,
  Steps,
  Step,
  Cascader,
  Upload,
  Row,
  Col,
  Avatar,
  Message,
  Progress,
  Spin,
  Switch
} from 'view-design'

import CountDown from '@/components/CountDown'
export default function init(Vue) {
  Vue.component('Button', Button)
  Vue.component('Checkbox', Checkbox)
  Vue.component('CheckboxGroup', CheckboxGroup)
  Vue.component('Form', Form)
  Vue.component('FormItem', FormItem)
  Vue.component('Icon', Icon)
  Vue.component('Input', Input)
  Vue.component('Modal', Modal)
  Vue.component('Page', Page)
  Vue.component('Select', Select)
  Vue.component('Breadcrumb', Breadcrumb)
  Vue.component('BreadcrumbItem', BreadcrumbItem)
  Vue.component('MenuItem', MenuItem)
  Vue.component('Dropdown', Dropdown)
  Vue.component('DropdownMenu', DropdownMenu)
  Vue.component('DropdownItem', DropdownItem)
  Vue.component('RadioGroup', RadioGroup)
  Vue.component('Radio', Radio)
  Vue.component('Option', Option)
  Vue.component('Scroll', Scroll)
  Vue.component('Table', Table)
  Vue.component('TabPane', TabPane)
  Vue.component('Tabs', Tabs)
  Vue.component('Tooltip', Tooltip)
  Vue.component('Poptip', Poptip)
  Vue.component('InputNumber', InputNumber)
  Vue.component('TimePicker', TimePicker)
  Vue.component('DatePicker', DatePicker)
  Vue.component('Steps', Steps)
  Vue.component('Step', Step)
  Vue.component('Cascader', Cascader)
  Vue.component('Upload', Upload)
  Vue.component('Row', Row)
  Vue.component('Col', Col)
  Vue.component('Avatar', Avatar)
  Vue.component('CountDown', CountDown)
  Vue.component('Progress', Progress)
  Vue.component('Spin', Spin)
  Vue.component('VSwitch', Switch)
  Vue.prototype.$message = Message
  Vue.prototype.$Message = Message
  Vue.prototype.$Modal = Modal
}
