<template>
  <div style="width: 1200px;margin: 0 auto">
    <SearchBar v-if="$route.path.includes('/coupon/index')" />
    <div style="line-height: 22px; font-size: 14px; display: flex; align-items: center">
      <Breadcrumb v-if="$route.path.includes('/coupon/index')" separator="<div class='iconfonts'></div>">
        <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
        <BreadcrumbItem class="last">
          我的优惠券
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="w-page">
      <Tabs v-if="showTab" v-model="activedTab" class="tab-box">
        <TabPane v-for="item in tabs " :key="item.value" :label="item.name" :name="item.value" />
      </Tabs>
      <div class="content-wrapper">
        <div class="coupon-btns">
          <Button
            v-for="item in selectOptions"
            :key="item.value"
            :class="item.btnSelected ? 'btn-selected btn' : 'btn-default btn'"
            @click="changeData(item)"
          >{{ item.label }}({{ item.num }})</Button>
          <div v-if="!showTab" class="link-mycoupon" @click="toMyCoupon()">
            <img src="@/assets/images/coupon/mycoupon-icon.png">
            <a>
              <span>我的优惠券</span>
              <img class="right-arrow" src="@/assets/images/coupon/right-arrow.png">
            </a>
          </div>

        </div>
        <template v-if="filtedDataList.length > 0">
          <div v-for="item in filtedDataList" :key="item.orgId" style="margin-top: 24px">
            <div class="org-info">
              <img class="org-logo" :src="item.orgLogo" alt="">
              <div class="org-name">{{ item.orgName }}</div>
            </div>
            <div class="items">
              <CouponItem
                v-for="(coupon, index) in item.couponList"
                :key="index"
                :item-data.sync="item.couponList[index]"
                :type="activedTab"
                @recieveCoupon="recieveCoupon"
              />
            </div>
          </div>
        </template>
        <div v-else class="cms-config-empty">
          <div class="cms-config-wrap">
            <img src="@/assets/images/coupon/coupon-noData.png">
            <p>暂无优惠券</p>
          </div>
        </div>

      </div>
      <div style="padding:20px 20px 0">
        <Pagination v-if="activedTab==='0' && filtedDataList.length" :total="total" :page-size-opts="[10, 20, 50, 100]" :size="pageSize" :num="pageNum" @change="pageChange" />
      </div>
    </div>

  </div>
</template>
<script>

import { getCouponCenterListApi, getMyCouponListApi } from '@/common/service/coupon'
import CouponItem from '@/components/CouponItem'
import _ from 'lodash'
import Pagination from '@/components/pagination/Pagination.vue'
import SearchBar from '@/components/SearchBar.vue'
import defaultLogo from '@/assets/images/shopHome/default-logo.png'
import { getFilePath } from '@/common/utils'
export default {
  name: 'MyCoupon',
  components: {
    SearchBar,
    Pagination,
    CouponItem
  },
  props: {
    showTab: {
      type: Boolean,
      default: true
    }
    // activedTab:{
    //   type:String,
    //   default:'0'
    // }
  },
  data() {
    return {
      currentName: 'CenterInfo',
      curentMap: {
        CenterInfo: '个人中心首页',
        CenterEnterprise: '我的企业',
        CenterCertification: '资质管理',
        CenterMerchant: '我的商家',
        CenterCollection: '我的收藏',
        CenterFootmark: '我的足迹',
        CenterPurchaseList: '常购清单',
        OrderList: '商城订单',
        RefundList: '退款/售后',
        CouponList: '我的优惠券',
        MsgList: '消息通知',
        modify_mobile: '修改手机号',
        modifyPwd: '修改密码',
        Lottery: '抽奖中心',
        Squared: '九宫格抽奖',
        RedEnvelope: '我的红包',
        DrugReportDonload: '药检报告',
        DocumentDownload: '首营资料',
        InvoiceDownload: '电子发票'
      },
      scendMap: {
        CompanyInfo: '基础信息变更',
        ReceiverAddress: '收货地址变更',
        CertificationUpdate: '资质信息变更',
        Consignor: '委托人信息变更',
        CustTypeChange: '信息变更',
        AddMustCustCert: '补充资质',
        UnbindEnterprise: '解绑企业'
      },
      curScendReleativeMap: {
        CustTypeChange: 'CenterEnterprise',
        AddMustCustCert: 'CenterEnterprise',
        CompanyInfo: 'CenterEnterprise',
        ReceiverAddress: 'CenterEnterprise',
        UnbindEnterprise: 'CenterEnterprise',
        CertificationUpdate: 'CenterCertification',
        Consignor: 'CenterCertification',
        DrugReportDonload: 'DrugReportDonload',
        DocumentDownload: 'DocumentDownload',
        InvoiceDownload: 'InvoiceDownload'
      },

      requestTask: {},
      selectValue: 'all',
      dataList: [],
      activedTab: '0',
      tabs: [{
        name: '我的优惠券',
        value: '0'
      },
      {
        name: '领券中心',
        value: '1'
      }],
      isLoading: true,
      orgId: null,
      pageNum: 1,
      total: 0,
      pageSize: 10
    }
  },
  computed: {
    routerName() {
      return this.$route.query.name
    },
    selectOptions() {
      if (this.activedTab === '0') {
        return [
          {
            label: '可用',
            value: 'effective_unuse',
            btnSelected: true,
            num: 0
          },
          {
            label: '未生效',
            value: 'not_effective',
            btnSelected: false,
            num: 0
          },
          {
            label: '已使用',
            value: 'used',
            btnSelected: false,
            num: 0
          },
          {
            label: '已失效',
            value: 'expired',
            btnSelected: false,
            num: 0
          }
        ]
      } else {
        return [
          {
            label: '全部',
            value: 'all',
            btnSelected: true,
            num: 0
          },
          {
            label: '满减券',
            value: 'coupon_full_decrement',
            btnSelected: false,
            num: 0
          },
          {
            label: '满折券',
            value: 'coupon_full_discount',
            btnSelected: false,
            num: 0
          },
          {
            label: '商品券',
            value: 'coupon_commodity',
            btnSelected: false,
            num: 0
          }
        ]
      }
    },
    filtedDataList() {
      if (this.activedTab === '0') {
        return this.buildGroupByOrg(this.dataList?.filter(item => item.state === this.selectValue) || [])
      } else {
        return this.buildGroupByOrg(this.dataList?.filter(item => this.selectValue === 'all' || item.couponType === this.selectValue) || [])
      }
    }
  },
  watch: {
    '$route.query.orgId': {
      immediate: true,
      handler() {
        if (this.showTab) { // 初始化加载领券中心页面，会多次调用优惠券接口，增加判断
          this.init()
        }
      }
    },
    activedTab: {
      immediate: true,
      handler(v) {
        if (v === '0') this.selectValue = 'effective_unuse'
        else this.selectValue = 'all'
        if (this.showTab) { // 初始化加载领券中心页面，会多次调用优惠券接口，增加判断
          this.init()
        }
      }
    }
  },
  created() {
    this.activedTab = this.showTab ? '0' : '1'
    if (!this.showTab) { // 初始化加载领券中心页面，会多次调用优惠券接口，增加判断
      this.init()
    }
  },
  mounted() {
    document.body.addEventListener('click', this.closeCoupon)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.closeCoupon)
  },
  methods: {
    init() {
      this.dataList = []
      return this.getDataList()
    },
    /*
    * 根据orgId 对返回的数据进行分组处理
    * @param data 优惠券列表
    * */
    buildGroupByOrg(data) {
      const _chunk = []
      data.forEach(item => {
        const orgTarget = _chunk.find(b => b.orgId === item.orgId)
        if (!orgTarget) {
          _chunk.push({
            orgId: item.orgId,
            orgName: item.orgName,
            orgLogo: item.orgLogo ? getFilePath('PS_01', item.orgLogo) : defaultLogo,
            couponList: [item]
          })
        } else {
          orgTarget.couponList.push(item)
        }
      })
      return _chunk
    },
    async getDataList() {
      this.isLoading = true
      const listApi = this.activedTab === '1' ? getCouponCenterListApi : getMyCouponListApi
      let params = {}
      if (this.$route.query.orgId) params.orgId = this.$route.query.orgId
      if (this.activedTab === '0') {
        const { pageNum, pageSize } = this
        params = {
          // ascs: [],
          // descs: [],
          pageNum,
          pageSize,
          condition: {
            state: this.selectOptions.find(item => item.btnSelected).value || 'effective_unuse'
          }
        }
      }
      const { code, data } = await listApi(params)
      this.$emit('loadTime', new Date().getTime())
      if (code !== 200) {
        return
      }
      this.isLoading = false
      this.dataList = data || []
      if (this.activedTab === '0') {
        this.dataList = data?.customerCouponPage?.records || []
        this.total = data?.customerCouponPage?.total
        this.setCouponTabNum('effective_unuse', data.unuseCount || 0)
        this.setCouponTabNum('not_effective', data.notEffectiveCount || 0)
        this.setCouponTabNum('used', data.usedCount || 0)
        this.setCouponTabNum('expired', data.expiredCount || 0)
        return
      }
      this.handlerData(this.dataList, 'couponType')
    },
    setCouponTabNum(state, num) {
      this.selectOptions.find(item => item.value === state).num = num
    },
    recieveCoupon(data) {
      if (data.type === 'success') {
        this.getDataList()
        this.$forceUpdate()
      }
    },
    pageSizeChange(size) {
      this.pageSize = size
    },
    changeData(item) {
      this.selectOptions.forEach((it) => {
        if (it.value == item.value) {
          this.$set(it, 'btnSelected', true)
        } else {
          this.$set(it, 'btnSelected', false)
        }
      })
      this.$forceUpdate()
      this.selectValue = item.value
      this.getDataList()
    },
    toMyCoupon() {
      window.open('/coupon/index')
    },
    handlerData(data, field) {
      const tmpData = _.groupBy(data, field)
      const _this = this
      this.selectOptions.forEach((item) => {
        if (item.value == 'all') _this.$set(item, 'num', data.length)
        _.forEach(tmpData, function(value, key) {
          if (item.value == key) {
            _this.$set(item, 'num', value.length)
          }
        })
      })
      this.$forceUpdate()
    },
    pageChange({ pageNumber, pageSize }) {
      this.pageSize = pageSize
      this.pageNum = pageNumber
      this.getDataList()
    },
    closeCoupon() {
      // 关闭优惠券详情
      this.$bus.$emit('close-coupon')
    }
  }
}
</script>
<style scoped lang="less">
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }

  img {
    width: 216px;
    height: 216px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tab-box {
  padding: 20px 20px 0 20px;
}

.w-page {
  background: #fff;
  // overflow: scroll;
  padding-bottom: 20px;
  position: relative;
  width: 1200px;
  margin: 12px auto;
  :deep(.ivu-tabs-bar){
    margin-bottom: 0;
  }

  .content-wrapper {
    padding:24px;

    // height: calc(100% - 85px);
    // overflow: auto;
    ::v-deep .ivu-select {
      margin-bottom: 10px;
      width: 75px;

      .ivu-select-selection {
        border: none;
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 16px 16px;
      justify-content: flex-start;
      grid-template-columns: auto auto;
      margin-top: 12px;
    }

    .coupon-btns {

      .btn {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border-radius: 8px;
        margin-left: 20px;
      }

      button:first-child {
        margin-left: 0;
      }

      .btn-default {
        color: rgba(75, 75, 75, 0.70);
        background-color: #eee;
        border: none;
      }

      .btn-selected {
        color: #CE7720;
        background: #FFF8E8;
        border: 1.5px solid #F99D33;
        font-weight: 500;
      }

      .link-mycoupon {
        margin-left: 40px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border: none;
        display: inline-block;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        a {
          color: rgba(0, 0, 0, 0.90);
          margin-left: 4px;
          display: inline-block;
          vertical-align: middle;

          .right-arrow {
            width: 16px;
            height: 16px;

          }
        }

      }
    }
  }

  // .page-wrapper {
  //   position: sticky  ;
  //   bottom: 0;
  //   z-index: 100;
  //   height: 32px;
  // }
}

.content-wrapper {
  ::v-deep .ivu-select-item {
    color: rgba(0, 0, 0, .4);
  }

  ::v-deep .ivu-select-item-selected {
    color: #000;
  }

  ::v-deep .ivu-select-selected-value {
    color: #000;
    font-weight: 500;
  }

  ::v-deep .ivu-select-visible .ivu-select-selection {
    box-shadow: none;
  }
  :deep(.coupon-item){
    width:calc((100% - 32px)/3);
  }
  .org-info{
    display: flex;
    .org-logo{
      display: block;
      width: 24px;
      height: 24px;
      border:1px solid #e7e7e7;
      border-radius: 4px;
    }
    .org-name{
      color: rgba(0, 0, 0, 0.90);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-left: 8px;
    }
  }
}
.main-index{
  background: #eee;
}
</style>
