var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":_vm.title},on:{"on-cancel":_vm.cancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"fail-modal-footer"},[_c('Button',{attrs:{"type":"default"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary","loading":_vm.createPlanLoading},on:{"click":_vm.ok}},[_vm._v("确认")])],1)]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"modal-content"},[_c('Form',{ref:"formValidate",attrs:{"model":_vm.formItem,"label-width":108}},[_c('FormItem',{attrs:{"label":"选择添加方式","required":""}},[_c('RadioGroup',{on:{"on-change":_vm.handleRadioChange},model:{value:(_vm.formItem.radio),callback:function ($$v) {_vm.$set(_vm.formItem, "radio", $$v)},expression:"formItem.radio"}},[_c('Radio',{attrs:{"label":"1"}},[_vm._v("方式一")]),_c('Radio',{attrs:{"label":"2"}},[_vm._v("方式二")]),_c('Radio',{attrs:{"label":"3"}},[_vm._v("方式三")])],1)],1),_c('FormItem',{attrs:{"label":"客户商品编码","prop":"customerProductNo","rules":[
                {
                    required: _vm.formItem.radio === '1', message: _vm.formItem.radio === '1' ? '必填项' : '', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.customerProductNo),callback:function ($$v) {_vm.$set(_vm.formItem, "customerProductNo", $$v)},expression:"formItem.customerProductNo"}})],1),_c('FormItem',{attrs:{"label":"商家名称","prop":"orgId","rules":[
                {
                    required: true, message: '必填项', trigger: 'blur'
                }
            ]}},[_c('Select',{attrs:{"filterable":"","remote-method":_vm.getOrgList,"loading":_vm.orgLoading,"label-in-value":""},on:{"on-change":_vm.changeOrg},model:{value:(_vm.formItem.orgId),callback:function ($$v) {_vm.$set(_vm.formItem, "orgId", $$v)},expression:"formItem.orgId"}},_vm._l((_vm.orgList),function(item,index){return _c('Option',{key:index,attrs:{"value":item.orgId}},[_vm._v(" "+_vm._s(item.orgAbbr)+" ")])}),1)],1),_c('FormItem',{attrs:{"label":"采购数量","prop":"purchaseNum","rules":[
                {
                    required: true, message: '必填项', trigger: 'blur',type: 'number'
                }
            ]}},[_c('InputNumber',{staticStyle:{"width":"364px"},attrs:{"min":1,"precision":0,"placeholder":"请输入内容"},model:{value:(_vm.formItem.purchaseNum),callback:function ($$v) {_vm.$set(_vm.formItem, "purchaseNum", $$v)},expression:"formItem.purchaseNum"}})],1),_c('FormItem',{attrs:{"label":"商家商品编码","prop":"orgProductNo","rules":[
                {
                    required: _vm.formItem.radio == '2', message: '必填项', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.orgProductNo),callback:function ($$v) {_vm.$set(_vm.formItem, "orgProductNo", $$v)},expression:"formItem.orgProductNo"}})],1),_c('FormItem',{attrs:{"label":"商品名称","prop":"productName","rules":[
                {
                    required: _vm.formItem.radio == '3', message: '必填项', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.productName),callback:function ($$v) {_vm.$set(_vm.formItem, "productName", $$v)},expression:"formItem.productName"}})],1),_c('FormItem',{attrs:{"label":"规格","prop":"productSpec","rules":[
                {
                    required: _vm.formItem.radio == '3', message: '必填项', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.productSpec),callback:function ($$v) {_vm.$set(_vm.formItem, "productSpec", $$v)},expression:"formItem.productSpec"}})],1),_c('FormItem',{attrs:{"label":"生产企业","prop":"manufactureCompany","rules":[
                {
                    required: _vm.formItem.radio == '3', message: '必填项', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.manufactureCompany),callback:function ($$v) {_vm.$set(_vm.formItem, "manufactureCompany", $$v)},expression:"formItem.manufactureCompany"}})],1),_c('FormItem',{attrs:{"label":"批准文号","prop":"approvalNumber","rules":[
                {
                    required: _vm.formItem.radio == '3', message: '必填项', trigger: 'change',
                }
            ]}},[_c('Input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.formItem.approvalNumber),callback:function ($$v) {_vm.$set(_vm.formItem, "approvalNumber", $$v)},expression:"formItem.approvalNumber"}})],1),_c('FormItem',{attrs:{"label":"采购价","prop":"purchasePrice"}},[_c('InputNumber',{staticStyle:{"width":"364px"},attrs:{"min":0,"placeholder":"请输入内容"},model:{value:(_vm.formItem.purchasePrice),callback:function ($$v) {_vm.$set(_vm.formItem, "purchasePrice", $$v)},expression:"formItem.purchasePrice"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }