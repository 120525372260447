// 处理通用异步查询购物车功能
import { addCartForSelectedProduct, asyncQueryCart } from '@/common/service'
import { getCartFieldData } from '@/common/utils'
import { mapState, mapMutations } from 'vuex'
import { ACTIVITY_TYPE } from '@/common/utils'

export default {
  computed: {
    ...mapState({
      selectedProductList: (state) => state.car.selectedProductList || []
    }),
  },
	methods: {
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    // 获取添加购物车数据
    async getAddCartData(addProductList, orgId, callback) {
      addProductList?.length > 0 && addProductList.forEach((item) => {
        item.activityType = String(item.activityType)
      })
      if (this.selectedProductList && this.selectedProductList.length > 0) {
        addProductList.forEach((item) => {
          const index = this.selectedProductList && this.selectedProductList.length > 0 ? this.selectedProductList.findIndex(sItem => sItem.storeId === item.orgId) : -1
          if (index !== -1) {

            // 判断是否为润划算、近效期、一口价、组合购
            if ([ACTIVITY_TYPE.SPECIAL, ACTIVITY_TYPE.NEARTERM, ACTIVITY_TYPE.FIXED_PRICE, ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(item.activityType)) {
              const aIndex = this.selectedProductList[index].activityList.findIndex(ele => {
                return item.activityId === ele.activityId && item.activityType === ele.activityType
              })
              if (aIndex !== -1) {
                if (this.selectedProductList[index].activityList[aIndex].productList && this.selectedProductList[index].activityList[aIndex].productList.length > 0) {
                  const pIndex = this.selectedProductList[index].activityList[aIndex].productList.findIndex(pItem => {
                    return pItem.productId === item.productId && item.activityId === pItem.activityId && item.activityType === pItem.activityType
                  })
                  if (pIndex !== -1) {
                    delete this.selectedProductList[index].activityList[aIndex].productList[pIndex]?.id
                    this.selectedProductList[index].activityList[aIndex].productList[pIndex].selected = true
                  } else {
                    this.selectedProductList[index].activityList[aIndex].productList.push({
                      activityId: item.activityId,
                      activityType: item.activityType,
                      productId: item.productId,
                      orgId: item.orgId,
                      selected: true
                    })
                  }
                }
              } else {
                const params = {
                  activityId: item.activityId,
                  activityType: item.activityType,
                  productList: [
                    {
                      activityId: item.activityId,
                      activityType: item.activityType,
                      productId: item.productId,
                      orgId: item.orgId,
                      selected: true
                    }
                  ]
                }
                this.selectedProductList[index].activityList.push(params)
              }
            } else {
              let flag = false
              this.selectedProductList[index].activityList.forEach((aItem) => {
                if (aItem.productList && aItem.productList.length > 0) {
                  aItem.productList.forEach((pItem) => {
                    if (pItem.productId === item.productId && ![ACTIVITY_TYPE.SPECIAL, ACTIVITY_TYPE.NEARTERM, ACTIVITY_TYPE.FIXED_PRICE, ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(pItem.activityType)) {
                      flag = true
                      pItem.selected = true
                    }
                  })
                }
              })
              if (!flag) {
                this.selectedProductList[index].activityList.push({
                  activityId: item.activityId,
                  activityType: item.activityType,
                  productList: [
                    {
                      activityId: item.activityId,
                      activityType: item.activityType,
                      productId: item.productId,
                      orgId: item.orgId,
                      selected: true
                    }
                  ]
                })
              }
            }
          } else {
            addProductList.forEach((item) => {
              const params = {
                storeId: item.orgId,
                activityList: [
                  {
                    activityId: item.activityId,
                    activityType: item.activityType,
                    productList: [
                      {
                        activityId: item.activityId,
                        activityType: item.activityType,
                        productId: item.productId,
                        orgId: item.orgId,
                        selected: true
                      }
                    ]
                  }
                ]
              }
              this.selectedProductList.push(params)
            })
          }
        })
      } else {
        addProductList?.length > 0 && addProductList.forEach((item) => {
          const params = {
            storeId: orgId,
            activityList: [
              {
                activityId: item.activityId,
                activityType: item.activityType,
                productList: [
                  {
                    activityId: item.activityId,
                    activityType: item.activityType,
                    productId: item.productId,
                    orgId: item.orgId,
                    selected: true
                  }
                ]
              }
            ]
          }
          this.selectedProductList.push(params)
        })
      }
      this.$store.commit('car/SET_SELECTED_PRODUCT_LIST', JSON.parse(JSON.stringify([...this.selectedProductList])));
      this.setAsyncQueryCart([...this.selectedProductList])
      callback && callback()
    },
		// 异步查询购物车
    async setAsyncQueryCart(storeList) {
      const tempObj = getCartFieldData(storeList)
      const prams = {
        selectedProductList: tempObj?.selectedProductList || [],
        presentProductList: tempObj?.presentProductList || [],
        cancelProductList: tempObj?.cancelProductList || [],
      }
      await asyncQueryCart(prams)
    }
	},
}