<template>
  <div class="no-data-view">
    <img class="img" src="@/assets/images/trade/noData.png" />
    <span class="text">购物车空空</span>
  </div>
</template>
<script>
export default {
  name:'EmptyData',
  props:{
    icon:{
      type:String,
      default:''
    },
    text:{
      type:String,
      default:''
    }
  },
  data(){
    return {
       
    }
  }
}
</script>
<style scoped lang='less'>
.no-data-view {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    width:216px;
  }
  .text {
    margin-top:-22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>