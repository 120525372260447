<template>
  <div class="menu">
    <div class="left-menu">
      <div v-if="data?.type=='1' || !data?.type" class="left-list">
        <div
          v-for="item in categoryList?.slice(0, 5)"
          :key="item.value"
          class="left-list-item"
        >
          <div class="item-title" @click="routerTo('/search/list?categoryId=' + item.value + '&categoryTitle=' + item.title)">
            <div class="icon-box">
              <template v-if="item.picUrl">
                <img class="cate-icon" width="16px" :src="getFilePath('orig',item.picUrl)" alt="">
              </template>
              <template v-else>
                <svg-icon v-if="item.title == '药品'" name="drug" class-name="icon" />
                <svg-icon v-if="item.title == '非药品'" name="noDrug" class-name="icon" />
                <svg-icon v-if="item.title == '食品'" name="food" class-name="icon" />
                <svg-icon v-if="item.title == '器械'" name="apparatus" class-name="icon" />
                <svg-icon v-if="item.title == '其他'" name="other" class-name="icon" />
              </template>
            </div>
            <span>{{ item.title }}</span>
          </div>
          <div class="two-level">
            <span v-for="item2 in item.children?.slice(0, 3)" :key="item2.id" class="two-level-name" @click="routerTo('/search/list?categoryId=' + item2.value)">{{ item2.title }}</span>
          </div>
        </div>
      </div>
      <div v-else class="business">
        <div class="title">
          <img v-if="data.fileId" src="/img/home/4ea618d7-9f1e-4789-902b-1facdb10f9f.png">
        </div>
        <div class="img-list">
          <img v-for="(item, index) in data.list" :key="index" :src="getFilePath('orig', item.fileId)" @click="cmsLink(item.link)">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
export default {
  name: 'Menu',
  props: {
    data: Object
  },
  data() {
    return {
      getFilePath,
      cmsLink
    }
  },
  created() {
    this.list = {}
    this.getClassData()
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.home.classData
    })
  },
  methods: {
    ...mapActions({
      getClassData: 'home/getClassData'
    }),
    routerTo(path) {
      this.$router.push(path)
    }
  }
}
</script>
<style scoped lang="less">
.menu {
  position: relative;
}
.left-menu {
  width: 260px;
  height: 406px;
  border-radius: 8px;
  overflow: hidden;
}
.left-list {
  background: #FFFFFF;
}
.left-title {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding:0 16px;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.all-product-icon {
  margin-right: 11px;
}
.left-list {
  overflow: hidden;
  padding: 24px 18px;
}
.left-list-item {
  padding:0px 0px 10px;
  height: 74px;
  &.hover {
    background: #F5F5F5;
  }
}
.item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #CE7720;
  }
  .icon-box{
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.two-level {
  padding-left: 32px;
  height: 40px;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // padding-top: 4px;
  .two-level-name {
    box-sizing: content-box;
    //display: inline-flex;;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //padding-right: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    padding-right: 16px;
    cursor: pointer;
    &:hover {
      color: #CE7720;
    }
  }
}
.business {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .title {
    height: 46px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img-list {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px;
    box-sizing: border-box;
    flex: 1;
    img {
      width: 100%;
      height: 60px;
      border-bottom: 0.5px solid #E7E7E7;
      cursor: pointer;
      &:last-child {
        border: none;
      }
    }

  }
}

.cate-icon{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
</style>
