
import Home from '@/views/home/index.vue'
import Activity from '@/views/home/activity.vue'
import MoreList from '@/views/product/moreList/index'
import Licence from '@/views/home/licence.vue'
import Coupon from '@/views/home/coupon.vue'
import ActivityOver from '@/views/home/ActivityOver.vue'
import ShopAll from '@/views/home/shopAll.vue'
export default [
  {
    path: '/',
    name: 'Home',
    meta: { keepAlive: false, title: '润药商城首页-药品采购'},
    component: Home
  },
  {
    path: '/activity',
    name: 'HomeActivity',
    component: Activity
  },
  {
    path: '/more',
    name: 'More',
    component: MoreList
  },
  {
    path: '/licence',
    name: 'Licence',
    component: Licence
  },
  {
    path: '/home/coupon',
    name: 'Coupon',
    component: Coupon
  },
  {
    path: '/pages/common/ActivityOver',
    name: 'ActivityOver',
    component: ActivityOver
  },
  {
    path: '/shop-all',
    name: 'ShopAll',
    component: ShopAll
  }
]
