import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
// 清空历史记录
export const keywordsDeleteByUser = () =>
  get({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsDeleteByUser?keywordsType=purchase_org`
  })
// 单个清除历史记录
export const keywordsDelete = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsDelete`,
    data
  })
// 关键字新增
export const keywordsAdd = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsAdd`,
    data
  })
// 历史记录列表查询
export const keywordsQuery = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsQuery`,
    data
  })
// 按关键词搜索列表查询
export const listProductOrgNameByEs = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductOrgNameByEs`,
    data
  })
// 批量采购列表查询
export const batchPurchaseQuery = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/query`,
    data
  })
// 批量采购去选择商品列表
export const chooseGoodQuery = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/chooseQuery`,
    data
  })
// 批量采购商品列表选择商品
export const chooseGood = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/choose`,
    data
  })
// excel模板商品匹配
export const excelProductMatching = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/excelProductMatching`,
    data
  })
// 批量采购商品列表选择商品
export const deleteGood = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/delete`,
    data
  })
// 一键加购
export const batchAddCart = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/addCart`,
    data
  })
// 提交采购单
export const commitPurchase = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/purchase/commitPurchase`,
    data
  })

// 飞速下单保存单个商品信息
export const saveFastOrderGood = (data) =>
  post({
    url: `${api.baseUrl}/fastOrder/saveFastOrder`,
    data
  })

// 飞速下单查询已保存过的商品信息
export const getFastOrderGood = (data) =>
  post({
    url: `${api.baseUrl}/fastOrder/list`,
    data
  })

// 飞速下单修改单个商品信息采购数量
export const updateFastOrder = (data) =>
  post({
    url: `${api.baseUrl}/fastOrder/updateFastOrder`,
    data
  })
// 飞速下单删除商品信息
export const deleteFastOrder = (data) =>
  post({
    url: `${api.baseUrl}/fastOrder/deleteFastOrder`,
    data
  })
// 飞速下单加入购物车
export const fastAddPurchase = (data) =>
  post({
    url: `${api.baseUrl}/order/quick/addPurchase`,
    data
  })
// 飞速下单生成采购计划
export const generatorPurchasePlan = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlan/generatorPurchasePlan`,
    data
  })

// 查询采购计划名称列表
export const getPurchasePlanName = () =>
  get({
    url: `${api.baseUrl}/behavior/purchasePlan/listByName`
  })

// 查询采购计划商品明细列表
export const getPurchasePlanData = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlanDetail/selectPage`,
    data
  })

// 修改采购计划名称
export const updatePurchasePlanName = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlan/updatePurchasePlan`,
    data
  })

// 导入采购计划
export const importPurchasePlan = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlan/importPurchasePlan`,
    data
  })

// 删除采购计划
export const deletePurchasePlan = (params) =>
  get({
    url: `${api.baseUrl}/behavior/purchasePlan/deletePurchasePlan?id=${params.id}`
  })

// 保存采购计划商品明细
export const savePurchasePlanGoodDetail = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlanDetail/save`,
    data
  })
// 删除采购计划商品明细
export const deletePurchasePlanGoodDetail = (data) =>
  post({
    url: `${api.baseUrl}/behavior/purchasePlanDetail/deleteByIds`,
    data
  })
export const exportExcel = (params) =>
  get({
    url: `${api.baseUrl}/behavior/purchasePlanDetail/exportExcel?purchasePlanId=${params.id}`,
    config: { responseType: 'blob' }
  })

// 新增对码关系
export const addPrdCodeMapping = (data) =>
  post({
    url: `${api.baseUrl}/prd/codeMapping/addPrdCodeMapping`,
    data
  })

// 更新对码关系
export const updatePrdCodeMapping = (data) =>
  post({
    url: `${api.baseUrl}/prd/codeMapping/updatePrdCodeMapping`,
    data
  })

// 查询对码关系列表
export const getMappingList = (data) =>
  post({
    url: `${api.baseUrl}/prd/codeMapping/getMappingByCondition`,
    data
  })

// 删除对码关系
export const removeCodeMapping = (data) =>
  post({
    url: `${api.baseUrl}/prd/codeMapping/removePrdCodeMapping`,
    data
  })
// 导入对码关系
export const importCodeMapping = (data) =>
  get({
    url: `${api.baseUrl}/prd/codeMapping/importCodeMappingFile`,
    data
  })

// 导出对码关系
export const exportCodeMappingFile = (data) =>
  get({
    url: `${api.baseUrl}/prd/codeMapping/exportCodeMappingFile`,
    data,
    config: { responseType: 'blob' }
  })


// 导出飞速下单商品明细
export const speedOrderExportExcel  = (params) => get({
  url: `${api.baseUrl}/fastOrder/exportList?orgId=${params.id}`,
  config: { responseType: 'blob' },
})
// 飞速下单导入excel
export const speedOrderImportFile = (data) => get({
  url: `${api.baseUrl}/fastOrder/importFile?fileId=${data.fileId}&orgId=${data.orgId}`,
})

// 飞速下单导入采购计划商品明细
export const ImportPurchasePlan = (data) =>post({
  url: `${api.baseUrl}/fastOrder/importPurchasePlan`,
  data
})

// 飞速下单查询商家列表
export const getOrgList = (data) =>post({
  url: `${api.baseUrl}/cust/custuserrel/getOrgList`,
  data
})
  