<template>
  <div>
    <SearchBar :keyword="keyword" />
    <div class="bread-crumb">
      <Breadcrumb separator="<div class='iconfonts'></div>">
        <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
        <BreadcrumbItem>对码关系管理</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="codemapping-content">
      <div class="search-detail">
        <h4 style="font-size: 16px; flex: 1">对码关系管理</h4>
        <div class="search">
          <Input
            v-model="condition"
            style="margin-right: 16px; width: 420px"
            placeholder="可输入编码/商品名称/厂家名称/生产厂家/店铺名称进行查询"
            @on-enter="handleSearch">
            <template #append>
              <Button icon="ios-search" @click="handleSearch" />
            </template>
          </Input>
        </div>
        <div class="operate">
          <Button type="default" @click="handleAdd">添加</Button>
          <Button type="default" @click="handleBatchDelete">删除</Button>
          <Button type="default" :loading="exportLoading" @click="exportCodeMapping">导出</Button>
          <Button type="default" @click="batchAddModal = true">批量导入</Button>
        </div>
      </div>
      <div class="list-wrap">
        <Table ref="selection" :max-height="height" :loading="dataLoading" :columns="columns" :data="pageList" @on-selection-change="onTableSelection" />
        <Pagination :total="totalPage" :page-size-opts="[10, 20, 50, 100]" :size="pageSize" :num="pageNum" class="pagination" @change="pageChange" />
      </div>
    </div>
    <div style="height: 32px" />
    <UploadFile :modal-show.sync="batchAddModal" @uploadSuccess="uploadSuccess" />
    <Modal v-model="showEditModal" :title="addForm.id ? '编辑对码关系' : '添加对码关系'" width="480" @on-cancel="showEditModal = false">
      <Form ref="addFormRef" :model="addForm" :rules="addFormRules" :label-width="140">
        <FormItem label="编码(买家)" prop="outCode">
          <Input v-model="addForm.outCode" maxlength="30" placeholder="请输入内容" />
        </FormItem>
        <FormItem label="编码(卖家)" prop="inCode">
          <Input v-model="addForm.inCode" maxlength="30" placeholder="请输入内容" />
        </FormItem>
        <FormItem label="店铺名称" prop="shopName">
          <Select
            v-model="addForm.shopName"
            placeholder="请选择内容"
            clearable
            filterable
            :remote-method="remoteSearchStore"
            :loading="storeSearchLoading"
            @on-change="handleShopChange">
            <Option v-for="(option, index) in storeList" :key="index" :value="option.orgAbbr">{{ option.orgAbbr }}</Option>
          </Select>
        </FormItem>
        <FormItem label="商品名称(买家)" prop="productName">
          <Input v-model="addForm.productName" maxlength="30" placeholder="请输入内容" />
        </FormItem>
        <FormItem label="规格(买家)" prop="specifications">
          <Input v-model="addForm.specifications" maxlength="30" placeholder="请输入内容" />
        </FormItem>
        <FormItem label="生产厂家(买家)" prop="manufacturer">
          <Input v-model="addForm.manufacturer" maxlength="30" placeholder="请输入内容" />
        </FormItem>
      </Form>
      <template #footer>
        <div class="fail-modal-footer">
          <Button type="default" @click="showEditModal = false">取消</Button>
          <Button type="primary" :loading="editPlanNameLoading" @click="handleSave">确认</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { exportCodeMappingFile, listProductOrgNameByEs, addPrdCodeMapping, updatePrdCodeMapping, getMappingList, removeCodeMapping } from '@/common/service'
import UploadFile from './component/uploadFile.vue'
import moment from 'moment'
const addFormInitData = {
  id: '',
  outCode: '',
  inCode: '',
  orgId: '',
  shopName: '',
  productName: '',
  specifications: '',
  manufacturer: ''
}
export default {
  name: 'PurchasePlan',
  components: {
    UploadFile
  },
  data() {
    return {
      keyword: '',
      dataLoading: false,
      data: [],
      conditionList: [],
      currentPlanid: 0,
      pageSize: 10,
      pageNum: 1,
      totalPage: 0,
      batchAddModal: false,
      editPlanNameModal: false,
      purchasePlanName: '',
      editPlanNameLoading: false,
      delPlanModal: false,
      delPlanLoading: false,
      exportLoading: false,
      storeSearchLoading: false,
      storeList: [],
      condition: '',
      showEditModal: false,
      addForm: addFormInitData,
      addFormRules: {
        outCode: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        inCode: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        shopName: [{ required: true, message: '请输入内容', trigger: 'change' }]
      },
      columns: [
        {
          type: 'selection',
          width: 35,
          align: 'center',
          key: '_disabled'
        },
        {
          title: '编号',
          type: 'index',
          width: 46
        },
        {
          title: '编码(买家)',
          key: 'outCode',
          width: 100
          //   render: (h, { row, index }) => {
          //     return h('Input', {
          //       class: 'product-input',
          //       props: {
          //         value: row.outCode,
          //         placeholder: '请输入'
          //       },
          //       on: {
          //         'on-blur': (val) => {
          //           row.outCode = val.target._value
          //         }
          //       }
          //     })
          //   }
        },
        {
          title: '商品名称(买家)',
          key: 'productName',
          width: 120
        },
        {
          title: '规格(买家)',
          key: 'specifications',
          width: 136
        },
        {
          title: '生产厂家(买家)',
          key: 'manufacturer',
          width: 160
        },
        {
          title: '编码(卖家)',
          key: 'inCode',
          width: 100
        },
        {
          title: '店铺名称',
          key: 'shopName'
        },
        {
          title: '最后修改时间',
          key: 'updateTime',
          width: 140,
          render: (h, { row, index }) => {
            return h('span', {}, row.updateTime && moment(row.updateTime).format('YYYY-MM-DD HH:mm:ss'))
          }
        },
        {
          title: '操作',
          width: 80,
          //   slot: 'action'
          render: (h, { row }) => {
            return h('div', [
              h(
                'span',
                {
                  class: {
                    action: true,
                    undelete: row.removeLoading
                  },
                  on: {
                    click: () => {
                      this.handleEdit(row)
                    }
                  }
                },
                '修改'
              ),
              h(
                'span',
                {
                  class: {
                    action: true,
                    undelete: row.removeLoading
                  },
                  on: {
                    click: () => {
                      //   console.log(params, 'params')
                      //   this.remove('single', params.row, params.index)
                      this.handleDelete(row)
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ],
      pageList: [],
      selectedList: [] // 选中的数据
    }
  },
  computed: {
    height() {
      return document.body.clientHeight - 344
    }
  },
  created() {
    this.getCodeMappingList()
  },
  methods: {
    // 商家选择事件
    handleShopChange(val) {
      const orgId = this.storeList.find((item) => item.orgAbbr === val)?.orgId
      this.addForm.orgId = orgId
    },
    // 表格选择事件
    onTableSelection(data) {
      this.selectedList = data
    },

    // 添加
    handleAdd() {
      this.showEditModal = true
      this.$refs.addFormRef.resetFields()
      this.addForm = addFormInitData
      this.storeList = []
    },

    // 编辑
    handleEdit(data) {
      //   const obj = {
      //     id: data.id,
      //     outCode: data.outCode,
      //     inCode: data.inCode,
      //     orgId: data.orgId || '',
      //     shopName: data.shopName,
      //     productName: data.productName,
      //     specifications: data.specifications,
      //     manufacturer: data.manufacturer
      //   }
      const obj = Object.keys(addFormInitData).reduce((acc, cur) => {
        acc[cur] = data[cur]
        return acc
      }, {})
      this.storeList = [{ orgId: data.orgId, orgAbbr: data.shopName }]
      this.showEditModal = true
      setTimeout(() => {
        this.addForm = obj
      }, 100)
    },

    // 删除
    handleDelete(data) {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除这条对码关系吗',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteCodeMapping([data?.id])
        }
      })
    },
    // 批量删除
    handleBatchDelete() {
      const list = this.selectedList.map((i) => i.id)
      if (!list?.length) {
        return this.$message.error('请勾选要删除的数据')
      }
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除这些对码关系吗',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteCodeMapping(list)
        }
      })
    },
    async deleteCodeMapping(idList) {
      try {
        const res = await removeCodeMapping(idList)
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.getCodeMappingList()
        }
      } catch (error) {
        this.$message.error(error?.data?.message)
      }
    },
    // 远程搜索商家
    async remoteSearchStore(query) {
      if (query) {
        try {
          this.storeSearchLoading = true
          const { data } = await listProductOrgNameByEs({
            orgName: query,
            page: {
              pageSize: 100,
              pageNumber: 1
            }
          })
          this.storeSearchLoading = false
          this.storeList = data || []
        } catch (error) {
          this.$message.error(error?.data?.message)
          this.storeList = []
        }
      } else {
        this.storeList = []
      }
    },

    // 保存
    handleSave(data) {
      // eslint-disable-next-line space-before-function-paren
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const params = {
            ...this.addForm
          }
          try {
            let res = {}
            if (this.addForm.id) {
              res = await updatePrdCodeMapping(params)
            } else {
              res = await addPrdCodeMapping(params)
            }
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.getCodeMappingList()
              this.showEditModal = false
            }
          } catch (error) {
            this.$message.error(error?.data?.message)
          }
        }
      })
    },

    handleSearch() {
      this.pageNum = 1
      this.getCodeMappingList()
    },

    // 查询对码关系列表
    async getCodeMappingList() {
      this.dataLoading = true
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        condition: this.condition
      }
      try {
        const res = await getMappingList(params)
        this.dataLoading = false
        if (res.code === 200) {
          this.pageList = res.data?.records || []
          this.totalPage = res.data?.total ? res.data.total : 0
          this.selectedList = []
        }
      } catch (error) {
        this.dataLoading = false
        // this.$message.error(error?.data?.message)
      }
    },
    pageChange(val) {
      this.pageNum = val.pageNumber
      this.pageSize = val.pageSize
      this.getCodeMappingList()
    },

    // 上传成功
    uploadSuccess() {
      this.getCodeMappingList()
    },
    // 导出
    exportCodeMapping() {
      const params = {
        condition: this.condition
      }
      this.exportLoading = true
      exportCodeMappingFile(params)
        .then((data) => {
          this.exportLoading = false
          const blob = new Blob([data], { type: 'application/octet-stream' }) // res.data 为接口返回的数据，依实例而行
          const URL = window.URL.createObjectURL(blob) // 生成一个 Blob URL
          this.createExportUrl(URL)
        })
        .catch(() => {
          this.exportLoading = false
          this.$message.error('导出失败')
        })
    },
    // 生成导出文件路径
    createExportUrl(URL) {
      const time = dayjs(new Date()).format('YYYY年MM月DD HH时mm分ss秒')
      const fileName = '对码关系导出-' + time + '.xlsx' // 文件名+后缀
      const listNode = document.createElement('a')
      listNode.download = fileName
      listNode.style.display = 'none'
      listNode.href = URL // 文件流生成的url
      document.body.appendChild(listNode)
      listNode.click() // 模拟在按钮上实现一次鼠标点击
      document.body.removeChild(listNode) // 移除 a 标签
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #eee);
  background: var(--gray-1, #fafafa);
  .ivu-table-cell {
    padding: 0;
    span {
      color: var(--font-black-2, rgba(0, 0, 0, 0.9));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  th {
    background: none;
    border-bottom: none;
    border-right: 1px solid #eee;
    padding: 0 8px;
    &:last-child {
      border-right: none;
    }
  }
}
:deep(.ivu-table-tbody) {
  .ivu-table-cell {
    padding: 0;
  }
  tr {
    border-bottom: 1px solid var(--gray-2, #eee);
    &:last-child {
      border-bottom: none;
    }
  }
  td {
    font-size: 12px;
    border-bottom: none;
    vertical-align: top;
    background: none;
    border-right: 1px solid #eee;
    padding: 4px 8px;
    height: 36px;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.9);
    &:last-child {
      border-right: none;
    }
  }
  .ivu-table-row-hover {
    td {
      background: #fff8f0 !important;
    }
  }
}
:deep(table) {
  border-spacing: 16px 0;
  border-collapse: collapse;
  width: 100% !important;
  height: 36px;
}
:deep(.ivu-table) {
  border-radius: 8px;
  &:before {
    display: none;
  }
}
:deep(.ivu-table-body) {
  border: 1px solid var(--gray-2, #eee);
  border-top: none;
}
:deep(.ivu-modal-header) {
  border-bottom: none;
}
:deep(.ivu-modal-footer) {
  border-top: none;
}

.bread-crumb {
  line-height: 22px;
  font-size: 14px;
  padding: 0px 24px 24px 0px;
  width: 1200px;
  margin: auto;
}
.codemapping-content {
  width: 1200px;
  background: #fff;
  padding: 24px;
  height: calc(100vh - 215px);
  margin: auto;
  .search-detail {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      display: flex;
      align-items: center;
      h4 {
        margin-right: 16px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
    .operate {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .list-wrap {
    .ivu-table-wrapper {
      min-height: calc(100vh - 336px);
      overflow-y: auto;
    }
  }
  :deep(.action) {
    color: var(--hbrand-6, #3853e2);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
</style>
