const state = {
  baseInfo: null,
  consigneeInfo: null,
  certificationInfo: null,
  entrustInfo: null,
  allAuditCert: null
}
const getters = {

}
const mutations = {
  SET_BASE_INFO(state, data) { // 基础信息
    state.baseInfo = data
  },
  SET_CONSIGNEE_INFO(state, data) { // 收货信息
    state.consigneeInfo = data
  },
  SET_CERTIFICATION_INFO(state, data) { // 资质证照
    state.certificationInfo = data
  },
  SET_ALL_AUDIT_CERT(state, data) { // 所有当前已审核过的信息（不包含委托）
    state.allAuditCert = data
  },
  SET_ENTRUST_INFO(state, data) { // 委托信息
    if (data) {
      state.entrustInfo = {
        ...data
      }
    }
  },
  SET_ALL_INFO(state) { // 清楚该模块缓存
    state.baseInfo = null
    state.consigneeInfo = null
    state.certificationInfo = null
    state.entrustInfo = null
  }
}
const actions = {
  SET_BASE_INFO({ commit }, data) { // 基础信息
    commit('SET_BASE_INFO', data)
  },
  SET_CONSIGNEE_INFO({ commit }, data) { // 收货信息
    commit('SET_CONSIGNEE_INFO', data)
  },
  SET_CERTIFICATION_INFO({ commit }, data) { // 资质证照
    commit('SET_CERTIFICATION_INFO', data)
  },
  SET_ENTRUST_INFO({ commit }, data) { // 委托信息
    commit('SET_ENTRUST_INFO', data)
  },
  SET_ALL_INFO({ commit }) { // 清楚该模块缓存
    commit('SET_ALL_INFO')
  },
  SET_ALL_AUDIT_CERT({ commit }) { // 所有当前已审核过的信息（不包含委托）
    commit('SET_ALL_AUDIT_CERT')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
