import { getStorageSync, removeStorageSync, setStorageSync } from '@/common/utils/storage'
import { JSEncrypt } from 'jsencrypt'
import { api } from '@/common/service/config'
// 清除登录的缓存信息
export const clearLoginInfo = () => {
  removeStorageSync('accessToken')
  // removeStorageSync('loginInfo')
  removeStorageSync('customerInfo')
  removeStorageSync('login_phone')
}
/**
 * 验证是否登录如果没登录则去登录
 * @param {*} val
 * @returns
 */

export const forceLogin = () => {
  if (!isLogin()) {
    uni.navigateTo({
      url: '/pages/personal/login/index'
    })
    return false
  }
  return true
}
// 是否登录
export const isLogin = () => {
  const token = getToken()
  return !isUndefined(token) && !isNull(token) && token
}
// 获取accessToken
export const getToken = () => {
  return getStorageSync('accessToken')
}

// 获取accessToken
export const getLoginMobile = () => {
  return getStorageSync('login_phone')
}
/**
 * 登录后跳转判断
 * 计算出当前router路径
 * 1.如果跳转的链接为登录页面或跳转的链接为空页面。则会重新跳转到首页
 * 2.都不满足返回跳转页面
 * @param type  'default' || 'wx'  //返回地址会做判断默认为default
 */

export const whetherNavigate = (type = 'default') => {
  console.log(getCurrentPages())
  if (getCurrentPages().length > 1 && getCurrentPages().length != 4) {
    uni.navigateBack({
      delta: getCurrentPages().length - 2
    })
  } else {
    console.log('**')
    uni.switchTab({
      url: '/pages/tabBar/home/home'
    })
  }
}

/**
 * 跳转到登录页面
 */
export function navigateToLogin() {
  uni.navigateTo({
    url: '/pages/personal/login/index'
  })
}
// 设置accessToken
export const setToken = (accessToken) => {
  setStorageSync('accessToken', accessToken)
}
// 设置RefreshToken
export const setRefreshToken = (token) => {
  setStorageSync('refreshToken', token)
}
// 设置accessToken
export const setLoginMobile = (loginPhone) => {
  setStorageSync('login_phone', loginPhone)
}

// 密码规则
export const passwordRule = (password) => {
  // let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[^]{8,16}$/
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
  return reg.test(password)
}
// 验证码规则
export const codeRule = (password) => {
  // let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[^]{8,16}$/
  const reg = /^[0-9]{6}$/
  return reg.test(password)
}


/**
 * 加密
 * @param {String}  需要加密的参数
 */
export function passwordEncryption(param) {
  // 后台给的公钥
  const publicKey = api.publicKey
  // 新建JSEncrypt对象
  const encryptor = new JSEncrypt()
  // 设置公钥
  encryptor.setPublicKey(publicKey)
  // 对密码进行加密
  return encryptor.encrypt(param)
}

/**
 * 加密
 * @param {String}  记住密码专用
 */
export function remPasswordEncryption(param) {
  // 后台给的公钥
  const publicKey = api.remPassPublicKey
  // 新建JSEncrypt对象
  const encryptor = new JSEncrypt()
  // 设置公钥
  encryptor.setPublicKey(publicKey)
  // 对密码进行加密
  return encryptor.encrypt(param)
}

/**
 *
 * @param {String}  记住密码专用
 */
export function remPasswordDecrypt(param) {
  // 后台给的
  const ppKey = api.remPassPPKey
  // 新建JSEncrypt对象
  const encryptor = new JSEncrypt()
  // 设置
  encryptor.setPrivateKey(ppKey)
  // 对密码进行
  return encryptor.decrypt(param)
}