<template>
  <div class="user-layout-wrapper">
    <UserToolBar v-if="![RouterContanst.USER.ANNOUNCE_FILE,RouterContanst.USER.ExchangeAgreement].includes($route.name)" />
    <router-view />
    <UserFooter />
  </div>
</template>

<script>
import UserToolBar from '@/components/user/UserToolBar'
import UserFooter from '@/components/UserFooter'
import RouterContanst from '@/router/router-contanst'
// import ContentLayout from '@/components/layout/ContentLayout'
export default {
  name: 'UserLayout',
  components: {
    UserToolBar,
    UserFooter
  },
  data() {
    return {
      RouterContanst
    }
  },
  created() {
    this.$emit('footer', false)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.user-layout-wrapper {
  background-color: #FFFFFF !important;
  padding-bottom: 50px;
  height: calc(100vh - 36px);
}
</style>
