import xss from 'xss'
const options = {
  whiteList: {
    a: ["target", "href", "title","style"],
    abbr: ["title"],
    address: [],
    area: ["shape", "coords", "href", "alt"],
    article: [],
    aside: [],
    audio: [
      "autoplay",
      "controls",
      "crossorigin",
      "loop",
      "muted",
      "preload",
      "src",
    ],
    b: [],
    bdi: ["dir"],
    bdo: ["dir"],
    big: [],
    blockquote: ["cite","style"],
    br: [],
    caption: [],
    center: [],
    cite: [],
    code: ["style"],
    col: ["align", "valign", "span", "width"],
    colgroup: ["align", "valign", "span", "width"],
    dd: [],
    del: ["datetime"],
    details: ["open"],
    div: ["data-w-e-type"],
    dl: [],
    dt: [],
    em: [],
    figcaption: [],
    figure: [],
    font: ["color", "size", "face"],
    footer: [],
    h1: ["style"],
    h2: ["style"],
    h3: ["style"],
    h4: ["style"],
    h5: ["style"],
    h6: ["style"],
    header: [],
    hr: ["style"],
    i: [],
    img: ["src", "alt", "title", "width", "height", "style"],
    ins: ["datetime"],
    li: ["style"],
    mark: [],
    nav: [],
    ol: ["style"],
    p: ["style"],
    pre: [],
    s: [],
    section: [],
    small: [],
    span: [],
    sub: [],
    summary: [],
    sup: [],
    strong: [],
    strike: [],
    table: ["width", "border", "align", "valign", "style"],
    tbody: ["align", "valign", "style"],
    td: ["width", "rowspan", "colspan", "align", "valign", "style"],
    tfoot: ["align", "valign"],
    th: ["width", "rowspan", "colspan", "align", "valign", "style"],
    thead: ["align", "valign"],
    tr: ["rowspan", "align", "valign", "style"],
    tt: [],
    u: [],
    ul: ["style"],
    video: [
      "autoplay",
      "controls",
      "crossorigin",
      "loop",
      "muted",
      "playsinline",
      "poster",
      "preload",
      "src",
      "height",
      "width",
      "style"
    ],
    source: ["src", 'type']
  }
}
const _xss = (html)=> {
  if( !html ) return html
  return xss(html, options)
}
export default _xss