import { getMsgNum } from '@/common/service'
import { Message } from 'view-design'

const state = {
  totalMsgNum: 0, // 消息总数量
  noticeMsgNum: 0, // 通知消息数量
  logisticsMsgNum: 0, // 交易物流消息数量
  activityMsgNum: 0, // 优惠活动消息数量
  exchangeMsgNum: 0, // 首营交换消息数量
}

const getters = {
  getMsgTextNum(state) {
    return state.msgTextNum
  }
}

const mutations = {
  SET_MSG_TEXT_NUM(state, data) {
    state.totalMsgNum = data.total
    state.noticeMsgNum = data.notice
    state.logisticsMsgNum = data.logistics
    state.activityMsgNum = data.activity
    state.exchangeMsgNum = data.firstCampExchange
  },
}

const actions = {
  // 获取消息数量
  updateMessageNum({ state, commit }, params) {
    getMsgNum()
      .then((res) => {
        commit('SET_MSG_TEXT_NUM', res.data)
      })
      .catch((error) => {
        if (error?.data?.code == '600' || error?.data?.code == '601' || error?.data?.code == '602' || error?.data?.code == '603') {
          commit('SET_MSG_TEXT_NUM', 0)
          return
        }
        Message.error(error?.data?.message)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
