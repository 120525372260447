<template>
  <div class="notice">
    <div class="head" >
      <div class="search-bar" :class="showBoxShadow && 'box-shadow'">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true" />
      </div>
      <div style="background: #fff;">
        <allProductClass />
      </div>
      <div class="breadcrumb" v-if="type=='home'">
        <div class="breadcrumb-warp">
          <span class="text">您所在的位置</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem to="/">首页</BreadcrumbItem>
            <BreadcrumbItem to="/notice">商城公告</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div class="breadcrumb" v-if="type=='shopHome'">
        <div class="breadcrumb-warp">
          <span class="text">您所在的位置</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem :to='toShopHome'>商家首页</BreadcrumbItem>
            <BreadcrumbItem :to="toShopNotice">商家公告</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
    </div>
    <div class="search-warp">
      <Tabs v-model="current">
        <TabPane :label="item.name" v-for="(item, index) in tabList" :key="index"></TabPane>
      </Tabs>
      <Input
        v-model="searchValue"
        icon="md-search"
        placeholder="请输入标题关键字"
        class="search"
        style="width:320px;"
        @on-enter="searchHandler"
        @on-click="searchHandler"
      />
    </div>
    <div class="notice-content">
      <div v-for="item in listArr[tabKey]" :key="item.id" class="info_item" @click="toDetail(item.id)">
        <img v-if="item.attachUrl" :src="getFilePath('PS_01',item.attachUrl)" style="width:44px;height:44px;border-radius:4px">
        <span class="title">【{{ item.noticeTitle }}】</span>
        <!-- <span class="content line-clamp-1">{{noticeContentFomat(item.noticeContent)}}</span> -->
        <span class="time">{{ item.issueTime }}</span>
      </div>
      <img v-if="listArr[tabKey]?.length == 0" class="empty" src="@/assets/images/global/empty.png">
    </div>
    <Pagination class="pagination" :show-page-num="showPageNum" :total="total" :num="pageNum" @change="changeHandler" />
  </div>
</template>
<script>
import { getFilePath } from '@/common/utils/base'
import {
  getValMapList,
  findNoticeListPage
} from '@/common/service'
import {findOrgNoticeListPage} from '@/common/service/shopHome.js'
import { getPcTabData } from '@/common/service/home'
import SearchBar from '@/components/SearchBar.vue'
import Pagination from '@/components/pagination/Pagination'
import { cmsLink } from '@/views/home/index'
import allProductClass from '../../home/coms/allProductClass.vue' // 全部商品分类

export default {
  name: 'notice',
  components: {
    SearchBar, Pagination, allProductClass
  },
  data() {
    return {
      getFilePath,
      listArr: {},
      pageNum: 1,
      total: 0,
      searchValue: '',
      pageData: {},
      showPageNum: true,
      current: 0,
      showBoxShadow: false,
      tabList: [],
      tabKey: '',
      type:'home',
      orgId:0,
      toShopHome:'',
      toShopNotice:''
    }
  },
  created() {
    this.type = this.$route.query.type || 'home'
    this.orgId = this.$route.query.orgId || 0
    this.toShopHome = '/shopHome?orgId='+this.orgId
    this.toShopNotice = '/notice?type=shopHome&orgId='+this.orgId
    this.getCmsNoticeType(this.type)
  },
  methods: {
    toDetail(id) {
      // uni.navigateTo({
      //   url: `/pages/explain/notice/component/noticeDetail?id=${id}`
      // })
      this.$router.push('notice/detail?id=' + id+'&type='+this.type+'&orgId='+this.orgId)
    },
    async getList(itemKey) {
      console.log(itemKey, 'itemKey');
      const param = {
        condition: {
          noticeStatus: 'issue',
          keywords: this.searchValue,
          noticeTypeCode: itemKey,
          orgId:this.orgId
        },
        descs: [
          'sorted',
          'create_time'
        ],
        pageNum: this.pageNum,
        pageSize: 10
      }
      const func = this.type=='home'?findNoticeListPage(Object.assign({}, param, this.pageData)):findOrgNoticeListPage(Object.assign({}, param, this.pageData))
      const { data } = await func
      this.total = data.total
      this.listArr[itemKey] ??= {}
      this.listArr[itemKey] = data.records
      this.$forceUpdate()
      return
    },
    changeHandler(data) {
      this.pageNum = data.pageNumber
      this.pageData = data
      this.getList(this.tabKey)
    },
    searchHandler() {
      this.pageNum = 1
      this.getList(this.tabKey)
    },
    noticeContentFomat(str) {
      return str.replace(/<.*?>/g, '').replace(/<.*?>/g, '')
    },
    getCmsNoticeType(type) {
      let code = type=='home'?'cms_notice_type':'cms_org_notice_type'
      getValMapList(code).then(res=> {
        if( res.code == 200 ) {
          this.noticeTypeData = res.data
          this.tabKey = res.data?.[0].itemKey
          this.getList( this.tabKey)
          this.tabList = res.data?.map(item=> {
            // this.getList(item.itemKey)
            return {
              name: item.itemValue,
              value: item.itemKey
            }
          })
        }
      })
    },
  },
  watch: {
    current(val) {
      this.pageNum = 1
      this.total = 0
      this.searchValue = ''
      this.tabKey = this.tabList[val].value;
      // if( !this.listArr[this.tabKey] ) {
        this.getList(this.tabKey)
      // }
    }
  }
}
</script>
<style scoped lang='less'>
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
  .notice {
    height: 100%;
    // overflow: hidden;
    :deep(.ivu-tabs-bar){
      margin-bottom: 0;
    }
    .info_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-style: solid;
      border-color: #E7E7E7;
      border-width: 0 0 0.5px 0;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
        // padding-bottom: 0;
      }
      &:hover {
        background: #FAFAFA;
      }
      .title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        flex: 1;
      }
      .time {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-left: auto;
      }
    }
  }
  .notice-content {
    width: 1200px;
    margin: 0 auto 0;
    background: #FFFFFF;
    padding: 0px 32px;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
  }
  .search-warp {
    width: 1200px;
    margin: 16px auto 0;
    position: relative;
    padding-top: 14px;
    background: #FFFFFF;
    border-radius: 8px 8px 0 0;
    :deep(.ivu-tabs-nav-scroll){
      padding: 0 20px;
    }
  }
  .search {
    // float: right;
    margin-left: auto;
    position: absolute;
    right: 24px;
    top: 11px;
  }
  .pagination {
    width: 1200px;
    margin: 32px auto 0;
  }
  .breadcrumb {
    background: #eee;
    padding: 4px 0;
    .breadcrumb-warp {
      width: 1200px;
      margin: 0px auto 0;
      display: flex;
    }
    .text {
      margin-right: 32px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
    :deep(.ivu-breadcrumb .ivu-breadcrumb-item-separator){
      color: #A6A6A6;
    }
  }
  .empty {
    width: 216px;
    height: 238px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
