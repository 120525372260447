import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
/**
 * 登录
 */
export const register = (data) => post({
  url: `${api.baseUrl}/v1/user/register`,
  data
})

export const login = (data) => post({
  url: `${api.baseUrl}/v1/wx/login`,
  data
})
export const codeLogin = (data) => post({
  url: `${api.baseUrl}/v1/phone/login/login`,
  data
})
// 奇怪的传参，post请求却采用了query传参
export const rymRefreshToken = (data) => post({
  url: `${api.baseUrl}/v1/wx/rymRefreshToken?refresh_token=${data.refresh_token}&domain=${data.domain}`
})

// 注册获取验证码
export const getRegisterCode = (data) => post({
  url: `${api.baseUrl}/register/obtainVCode`,
  data
})

// 校验注册验证码
export const validCode = (data) => post({
  url: `${api.baseUrl}/register/validateVCode`,
  data
})

// 提交注册
export const registerSubmit = (data) => post({
  url: `${api.baseUrl}/register/submit`,
  data
})

// 忘记密码获取验证码
export const getForgetCode = (data) => post({
  url: `${api.baseUrl}/forgot/obtainVCode`,
  data
})
// login获取验证码
export const getLoginCode = (data) => post({
  url: `${api.baseUrl}/v1/phone/login/obtainVCode`,
  data
})

// 校验忘记密码 验证码
export const getForgetValidCode = (data) => post({
  url: `${api.baseUrl}/forgot/validateVCode`,
  data
})

// 忘记密码提交
export const getForgetSubmit = (data) => post({
  url: `${api.baseUrl}/forgot/submit`,
  data
})

// 退出登录
export const getLogout = () => get({
  url: `${api.baseUrl}/my/logout`
})

// 个人设置--修改密码  POST /my/setting/modifypwd
export const getModifyPwd = (data) => post({
  url: `${api.baseUrl}/my/setting/modifypwd`,
  data
})

// 协议相关-登录/注册页：获取最新协议
export const getAgreementInfo = () => get({
  url: `${api.baseUrl}/agr/obtainLatestAgreement?sourceType=PC`
})

// 协议相关-登录/注册页：获取最新协议
export const getAppAgreementInfo = () => get({
  url: `${api.baseUrl}/agr/obtainLatestAgreement?sourceType=APP`
})

// 验证码滑动验证/获取
export const generate = () => get({
  url: `${api.baseUrl}/slider/generate`
})

// 验证码滑动验证/提交验证
export const sliderCheck = (data) => post({
  url: `${api.baseUrl}/slider/check`,
  data
})

// 用户登录信息 中台信息
export const getLoginData = () => post({
  url: `${api.baseRipplesUrl}/security/getLoginData`
})

// 保存意见反馈
export const feedbackSave = (data) => post({
  url: `${api.baseUrl}/ub/feedback/save`,
  data
})

// 获取意见反馈的反馈类型
export const getValueSet = (data) => post({
  url: `${api.baseUrl}/opt/valueSetItem/list/codes`,
  data
})

// 获取意见反馈列表
export const getFeedbackList = (data) => post({
  url: `${api.baseUrl}/ub/feedback/getList`,
  data
})

export const obtainVCode = (data) => post({
  url: `${api.baseUrl}/changePhone/obtainVCode`,
  data
})

// 修改手机号  2 校验原手机号-提交验证码
export const submitVCode = (data) => post({
  url: `${api.baseUrl}/changePhone/submitVCode`,
  data
})

// 修改手机号  3 绑定新手机-获取验证码
export const obtainVCodeNew = (data) => post({
  url: `${api.baseUrl}/changePhone/newPhone/obtainVCode`,
  data
})

// 修改手机号  4 绑定新手机-提交验证码
export const submitVCodeNew = (data) => post({
  url: `${api.baseUrl}/changePhone/newPhone/submitVCode`,
  data
})

// 微信获取scheme
export const schemeWx = (data) => post({
  url: `${api.baseUrl}/v1/wx/scheme`,
  data
})

// 获取登陆二维码ticket
export const getQrcodeTicket = (data) => get({
  url: `${api.baseUrl}/v1/qrcode/getTicket`,
  data
})
// 获取登陆二维码
export const getQrcode = (data) => post({
  url: `${api.baseUrl}/v1/qrcode/createQRCode` + data,
  config: { responseType: 'blob' }
})
// 检测扫码
export const qrcodeCheck = (data) => get({
  url: `${api.baseUrl}/v1/qrcode/check`,
  data
})

// 解绑企业
export const unBindCustomerForFrontend = (data) => post({
  url: `${api.baseUrl}/cust/custuser/unBindCustomerForFrontend`,
  data
})

