// 页联
<template>
  <div class="v-page-couplet">
    <img 
      v-for="(item, index) in options?.list"
      :key="index"
      :src="getFilePath('PS_11', item.fileId)" 
      class="img" 
      @click="cmsLink.bind(this)(item?.link)">
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink } from '@/views/home/index'
/**
 * styleType 1: 通栏 2: 剧中
 * bgType 1：纯色 2: 背景图
 */
export default {
  name: "v-page-couplet",
  props: {
    options: Object
  },
  data() {
    return {
      getFilePath,
      cmsLink: cmsLink.bind(this),
    };
  },
  components: {
  },
  created() {
  },
  methods: {},
  computed: {
  }
};
</script>
<style scoped lang="less">
.v-page-couplet {
  width: 1662px;
  position: absolute;
  top: 56px;
  left: 50%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding: 0 16px;
  pointer-events: none;
  transform: translateX(-50%);
  // margin: 0 auto;
  .img {
    width: 204px;
    pointer-events: visible;
    border-radius: 8px;
    box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
    // height: 100px;
  }
}

</style>
