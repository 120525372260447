import { render, staticRenderFns } from "./OutOfStockReg.vue?vue&type=template&id=1b546166&scoped=true&"
import script from "./OutOfStockReg.vue?vue&type=script&lang=js&"
export * from "./OutOfStockReg.vue?vue&type=script&lang=js&"
import style0 from "./OutOfStockReg.vue?vue&type=style&index=0&id=1b546166&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b546166",
  null
  
)

export default component.exports