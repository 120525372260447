<template>
  <div>
    <!-- 开户电子资料交换申请弹窗 -->
    <Modal v-model="showPopup" width="400" :closable="false" :mask="false" :footer-hide="true" class-name="exhange-popup">
      <div class="exhange-popup-wrap">
        <div class="title-box">
          <div>
            <Icon type="ios-information-circle" size="16" color="#3853E2" />
            <span class="title-text">{{ exchangePopupObj.lastMsgTitle }}</span>
          </div>
          <Icon type="md-close" size="16" color="#A6A6A6" style="cursor: pointer;" @click="userClose()" />
        </div>
        <div class="content"><p v-html="msgContent" /></div>
        <div class="btn-wrap">
          <Button type="primary" @click="goWatch">去查阅({{ showTime }}s)</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    exchangePopupObj: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showPopup: false,
      // 用户手动关闭弹窗
      isUserClose: false,
      showTime: 10,
      timer: null
    }
  },
  computed: {
    // 消息内容
    msgContent() {
      const content = this.exchangePopupObj?.lastMsgContent
      if (content && /^有(\d)(.*)$/.test(content)) {
        return content.replace(/^有(\d)(.*)$/, `有<span class='store-num'>$1</span>$2`)
      }
      return content
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.showPopup = newVal
        this.showTime = 10
        this.setTime()
      }
    }
  },

  destroyed() {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    // 弹窗倒计时
    setTime() {
      this.timer = setInterval(() => {
        this.showTime--
        if (this.showTime === 0) {
          // 用户没有手动关就跳转
          if (!this.isUserClose) {
            this.goWatch()
          } else {
            this.close()
          }
        }
      }, 1000)
    },
    close() {
      this.showPopup = false
      this.$emit('update:visible', this.showPopup)
      this.timer && clearInterval(this.timer)
    },
    goWatch() {
      this.$emit('showModal', true)
      this.close()
    },

    userClose() {
      this.isUserClose = true
      this.close()
    }

  }
}
</script>
<style scoped lang='less'>
::v-deep .exhange-popup {
  top: 91px;
  right: -801px;
  .ivu-modal {
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 20px 24px;
        .exhange-popup-wrap {
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-text {
              margin-left: 8px;
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.90);
            }
          }
          .content {
            font-size: 14px;
            margin: 12px 0;
            padding-left: 25px;
            .store-num {
              color: #E67D00;
              padding: 0 2px;
            }
          }
          .btn-wrap {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
</style>
