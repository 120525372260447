<template>
  <div class="tag">
    <slot name="dot">
      <i v-if="type==='dot'" class="dot" :style="setDotStyle" />
    </slot>
    <slot>
      <span :style="setStyle">{{ name }}</span>
    </slot>
    <slot name="tip">
      <Tooltip v-if="color === 'refuse_auth'" :content="reason" style="min-width:80px;" max-width="200">
        <Icon size="16" type="ios-help-circle-outline" />
      </Tooltip>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'CustomerTag',
  props: {
    type: { // 默认、dot
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: '认证中'
    },
    color: {
      type: String,
      default: 'under_auth'
    },
    size: {
      type: String,
      default: 'default' // large、medium、default
    },
    reason: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      obj: {
        refuse_auth: {
          state: 'refuse_auth',
          backgroundColor: 'rgba(203, 39, 45, 0.08)',
          borderColor: '#CB272D',
          color: '#CB272D'
        },
        under_auth: {
          state: 'under_auth',
          backgroundColor: 'rgba(56, 83, 226, 0.08)',
          borderColor: '#3853E2',
          color: '#2438BC'
        },
        auth: {
          state: 'auth',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          borderColor: '#34A425',
          color: '#1F7C15'
        },
        tempAuth: {
          state: 'tempAuth',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          borderColor: '#34A425',
          color: '#1F7C15'
        },
        advent: {
          state: 'advent',
          backgroundColor: '#FFF8E8',
          borderColor: '#CE7720',
          color: '#A35511'
        },
        expire: {
          state: 'expire',
          backgroundColor: 'rgba(203, 39, 45, 0.08)',
          borderColor: '#CB272D',
          color: '#CB272D'
        },
        '有资质未通过': {
          state: '有资质未通过',
          backgroundColor: 'rgba(203, 39, 45, 0.08)',
          borderColor: '#CB272D',
          color: '#CB272D'
        },
        '已认证': {
          state: '已认证',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          borderColor: '#34A425',
          color: '#1F7C15'
        },
        '审核通过': {
          state: '审核通过',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          borderColor: '#34A425',
          color: '#1F7C15'
        },
        '认证中': {
          state: '认证中',
          backgroundColor: 'rgba(56, 83, 226, 0.08)',
          borderColor: '#3853E2',
          color: '#2438BC'
        },
        '临期': {
          state: '临期',
          backgroundColor: '#FFF8E8',
          borderColor: '#CE7720',
          color: '#CE7720'
        },
        '到期': {
          state: '到期',
          backgroundColor: 'rgba(203, 39, 45, 0.08)',
          borderColor: '#CB272D',
          color: '#CB272D'
        }
      }
    }
  },
  computed: {
    setStyle() {
      const color = this.obj[this.color]?.color
      const backgroundColor = this.obj[this.color]?.backgroundColor
      const borderColor = this.obj[this.color]?.borderColor
      return `padding: 2px 4px; color: ${color}; background-color:${backgroundColor}; border-radius: 4px; border: 1px solid ${borderColor}`
    },
    setDotStyle() {
      const color = this.obj[this.color]?.color
      return `background-color:${color}`
    }
  },
  methods: {
    set16ToRgba(str) {
      var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
      if (!reg.test(str)) return
      let newStr = (str.toLowerCase()).replace(/\#/g, '')
      const len = newStr.length
      if (len === 3) {
        let t = ''
        for (let i = 0; i < len; i++) {
          t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
        }
        newStr = t
      }
      const arr = [] // 将字符串分隔，两个两个的分隔
      for (let j = 0; j < 6; j = j + 2) {
        const str = newStr.slice(j, j + 2)
        arr.push(parseInt('0x' + str))
      }
      return 'rgba(' + arr.join(',') + ',.06)'
    },
    setRgbTo16(str) {
      const reg = /^(rgb|RGB)/
      if (!reg.test(str)) return
      var arr = str.slice(4, str.length - 1).split(',')
      let color = '#'
      for (var i = 0; i < arr.length; i++) {
        var t = Number(arr[i]).toString(16)
        if (t === '0') { // 如果为'0'的话，需要补0操作,否则只有5位数
          t = t + '0'
        }
        color += t
      }
      return color
    }
  }
}
</script>
<style scoped lang="less">
.tag {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  .dot {
    width: 8px;
    height: 8px;
    top: 50%;
    border-radius: 50%;
  }
  &-green {
    color: #034D00;
    background-color: rgba(77, 172, 57, 0.1);
  }
  &-blue {
    color: #2438BC;
    background-color: rgba(56, 83, 226, 0.1);
  }
  &-orange {
    color: #CE7720;
    background: #FFF8E8;
    border: #CE7720 1px solid;
  }
  &-red {
    color: #D4380D;
    background: #FFF2E8;
    border: #FF7A45 1px solid;
  }
}
.review {
  position: relative;
  margin-right: 10px;
  padding-left: 16px;
  font-size: 16px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  &-success {
    color: #1F7C15;
    &::before {
      background-color: #4DAC39;
    }
  }
  &-waiting {
    color: #2438BC;
    &::before {
      background-color: #3853E2;
    }
  }
  &-refuse {
    color: #CB272D;
    &::before {
      background-color: #CB272D;
    }
  }
}
</style>
