<template>
  <div :id="'nav' + options.id" class="v-market">
    <div
      class="card-list"
      :style="{
        gap:options?.props?.selectBy == '1'?'12px':'8px'
      }"
    >
      <div v-for="(item,index) in data" :key="item.id" class="card">
        <div class="head" :style="{background: item.color}">
          <img v-if="isIconTitle(index)" class="icon-title" :src="getFilePath('PS_01', getIcon(index))" alt="">
          <span v-else class="title">{{ item.title }}</span>
          <div class="more" @click.stop="more(item)">
            更多
            <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
          </div>
        </div>
        <div class="card-wrap">
          <ProductCard
            v-for="(item, index) in item.records"
            :key="index"
            :goods-item="item"
            :is-org-abbr="false"
            :img-lazy="true"
            :size="options?.props?.selectBy == '1' ? 'market1' : 'market2' "
            :show-add-car="false"
            :cms-position-title="options?.props?.title"
            is-cms
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler } from '@/views/home/index'
// import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/ListCard.vue'
export default {
  name: 'VMarket',
  components: {
    ProductCard
  },
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      show: false,
      data: []
    }
  },
  computed: {
    // 获取一图地址
    path() {
      return getFilePath('PS_05', this.options?.props?.fileId)
    },
    styleType() {
      return this.options?.props?.styleType
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const list = this.options?.props?.productsList
      list?.forEach((item) => {
        getData.bind(this)(this.options?.props?.selectBy === '1' ? 3 : 2, item.id, '1').then((data) => {
          if (data?.length > 0) {
            item.records = data
            if (!this.show) {
              this.$store.commit('setHomeNav', this.options.id)
              this.show = true
            }
            this.setData()
          }
          setTimeout(() => {
            this.$emit('load', this.options)
          }, 100)
        }).catch(() => {
          setTimeout(() => {
            this.$emit('load', this.options)
          }, 100)
        })
      })
    },
    setData() {
      this.data = this.options?.props?.productsList?.filter((item) => item.records?.length > 0) || []
    },
    // 跳更多页
    more(data) {
      const cmsModelId = data.id
      // const type = data.type
      this.$router.push(`/more?type=1&cmsModelId=${cmsModelId}`)
    },

    isIconTitle(index) {
      const { selectBy, titleTypeLeft, fileIdLeft, titleTypeRight, fileIdRight, titleTypeMiddle, fileIdMiddle } = this.options?.props
      if (selectBy === '1') {
        return (titleTypeLeft === '2' && fileIdLeft && index === 0) || (titleTypeRight === '2' && fileIdRight && index === 1)
      } else {
        return (titleTypeLeft === '2' && fileIdLeft && index === 0) || (titleTypeMiddle === '2' && fileIdMiddle && index === 1) || (titleTypeRight === '2' && fileIdRight && index === 2)
      }
    },

    getIcon(index) {
      const { selectBy, fileIdLeft, fileIdRight, fileIdMiddle } = this.options?.props
      if (selectBy === '1') {
        return index === 0 ? fileIdLeft : fileIdRight
      } else {
        return index === 0 ? fileIdLeft : index === 1 ? fileIdMiddle : fileIdRight
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-market {
  width: 1200px;
}
.card-list {
  display: flex;
  //gap: 12px;
}
.card {
  flex: 1;
}
.card-wrap {
  display: flex;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.head {
  display: flex;
  align-items: center;
  height: 58px;
  padding: 0 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #EEE;
  .icon-title{
    height: 32px;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #181818;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.60);
    cursor: pointer;
  }
}
::v-deep .term_of_validity {
  left: 50% !important;
  transform: translateX(-50%);
}

:deep(.card-wrap) {
  .list-card{
    width: 198px;
    height: auto;
    padding: 16px 12px;
    border-radius: 0;
    &:nth-child(2){
      border-left: 0.5px solid #eee;
      border-right: 0.5px solid #eee!important;
    }
  }
  .cover-box,.cover-mask{
    width: 120px!important;
    height: 120px!important;
  }
  .price-area-box{
    margin-top: 0;
  }
}
</style>
